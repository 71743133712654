import * as types from '../actionTypes/types';
import { httpRequest } from '../NetworkConfig/Config';
import { Url, methods, headers, kongParams } from '../NetworkConfig/ApiUrlConstatnts';
export const loginFunction = inpuData => {
  const formData = new FormData();
  formData.append('username', inpuData.username);
  formData.append('password', inpuData.password);
  return async dispatch => {
    dispatch(fetchingUserLoginDetailsRequest());
    return httpRequest({
      method: methods.post,
      url: Url.LoginURL,
      headers: headers.headFinancing,
      data: formData,
    }).then(JsonResponse => {
      // console.log(JsonResponse);
      // console.log(inpuData);
      if (JsonResponse !== undefined) {
        if (JsonResponse.status == 200 || JsonResponse.status == 203) {
          let fetchedData =
            JsonResponse.data !== undefined && JsonResponse.data !== null && Object.keys(JsonResponse.data).length !== 0
              ? JsonResponse.data
              : {};
          dispatch(fetchingUserLoginDetailsSuccess(fetchedData));
        } else {
          dispatch(fetchingUserLoginDetailsFailed());
        }
        return JsonResponse;
      } else {
        dispatch(fetchingUserLoginDetailsFailed());
      }
    });
  };
};
export const fetchingUserLoginDetailsRequest = () => ({
  type: types.FETCHING_USER_LOGIN_DETAILS_REQUEST,
});
export const fetchingUserLoginDetailsSuccess = json => ({
  type: types.FETCHING_USER_LOGIN_DETAILS_SUCCESS,
  payload: json,
});
export const fetchingUserLoginDetailsFailed = error => ({
  type: types.FETCHING_USER_LOGIN_DETAILS_FAILED,
  payload: error,
});

// export const LogOutFunction = inpuData => {
//   return async dispatch => {
//     dispatch(fetchingUserLogOutDetailsRequest());
//     return httpRequest({
//       method: methods.post,
//       url: Url.LogOutURL,
//       headers: headers.head,
//       data: inpuData,
//     }).then(JsonResponse => {
//       if (JsonResponse !== undefined) {
//         if (JsonResponse.status == 200 || JsonResponse.status == 203) {
//           let fetchedData =
//             JsonResponse.data !== undefined &&
//             JsonResponse.data !== null &&
//             Object.keys(JsonResponse.data).length !== 0
//               ? JsonResponse.data
//               : {};
//           dispatch(fetchingUserLogOutDetailsSuccess(fetchedData));

//         } else {
//           dispatch(fetchingUserLogOutDetailsFailed());
//         }
//         return JsonResponse;
//       } else {
//         dispatch(fetchingUserLogOutDetailsFailed());
//       }
//     });
//   };
// };
// export const fetchingUserLogOutDetailsRequest = () => ({
//   type: types.FETCHING_USER_LOGOUT_DETAILS_REQUEST,
// });
let languageCheck = localStorage.getItem('languageCheck');
if (!languageCheck) {
  languageCheck = 'en';
}
export const fetchingUserLogOutDetailsSuccess = history => (
  window.open('/signin?lang=' + `${languageCheck}`, '_self'),
  // history.push('/'),
  {
    type: types.FETCHING_USER_LOGOUT_DETAILS_SUCCESS,
  }
);
// export const fetchingUserLogOutDetailsFailed = (error) => ({
//   type: types.FETCHING_USER_LOGOUT_DETAILS_FAILED,
//   payload: error,
// });
