import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import IntlMessages from '@jumbo/utils/IntlMessages';
import { List, ListItem, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';

let selected = localStorage.getItem('i18nextLng')?.includes('en') ? 'en' : localStorage.getItem('i18nextLng');
if (selected === undefined || selected === null) {
  selected = 'en';
}
const styles = theme => ({
  block: {
    color: 'inherit',
    padding: '0.9375rem',
    fontWeight: '500',
    fontSize: '12px',
    textTransform: 'uppercase',
    borderRadius: '3px',
    textDecoration: 'none',
    position: 'relative',
    textAlign: 'center !important',
    display: 'block',
  },
  left: {
    float: 'center !important',
    display: 'block',
  },
  right: {
    padding: '15px 0',
    margin: '0',
    float: 'right!important',
    color: '#FFF',
  },
  footer: {
    textAlign: 'center',
    zIndex: '2',
    position: 'relative',
    backgroundColor: '#FFF',
    color: '#000',
  },
  a: {
    color: '#FFF',
    textDecoration: 'none',
    backgroundColor: 'transparent',
  },
  footerWhiteFont: {
    '&,&:hover,&:focus': {
      color: '#FFFFFF',
    },
  },
  list: {
    marginBottom: '0',
    padding: '0',
    marginTop: '0',
  },
  inlineBlock: {
    display: 'inline-block',
    padding: '0px',
    width: 'auto',
  },
});

const useStyles = makeStyles(styles);

export default function Footer(props) {
  const classes = useStyles();
  const { t } = useTranslation();
  const { whiteFont } = props;
  const footerClasses = classNames({
    [classes.footer]: true,
    [classes.footerWhiteFont]: whiteFont,
  });

  return (
    <footer className={footerClasses}>
      <div className={classes.container}>
        <div className={classes.left}>
          <List className={classes.list}>
            <ListItem className={classes.inlineBlock}>
              <Typography className={classes.block}>
                {' '}
                {<IntlMessages id="Copy rights" />} {<IntlMessages id="Company" />}
                &nbsp; {1900 + new Date().getYear()}
                {<IntlMessages id="Rights" />} V-1.0.31
              </Typography>
            </ListItem>
          </List>
        </div>
      </div>
    </footer>
  );
}

Footer.propTypes = {
  whiteFont: PropTypes.bool,
};
