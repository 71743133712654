const colors = {
  primary: '#1E396C',
  primaryLight: '#37297C',
  primaryDark: '#15153A',
  primaryGradientStart: '#28A4DB',
  primaryGradientEnd: '#37AA57',
  secondaryGradientStart: '#28A4DB',
  secondaryGradientEnd: '#FF1358',
  profileGradientStart: '#54CBF6',
  profileGradientEnd: '#49D2D0',
  secondary: '#39c',
  grey: '#6F6F6FE6',
  gray: '#5f5f5f',
  darkGray: '#4d4d4d',
  lightGray: '#9b9b9b',
  greyLight: '#C8C8C8',
  white: '#ffffff',
  blue: '#5A81F7',
  bluish: '#F1F1F7',
  black: '#000000',
  green: '#37AA57',
  yellow: '#ffc247',
  red: 'red',
  customGrey: '#F0F0F0',
  gradient: 'linear-gradient(0.25turn, #28A4DB, #3AB15F)',
  gradientheader: 'linear-gradient(0.25turn, #28A4DB, #37AA57)',
};
export default colors;
