const Url = {
  // baseUrl: 'http://95.177.174.220',
  // baseUrl: 'https://raqamyahjeddah.com',
  baseUrl: 'https://secure.raqamyah.com',
  LoginURL: '/restApi/login',
  InvestorAllDetail: '/api/v1/investor/allDetails',
  InvestorDashboardStatus: '/api/v1/investor/investorStatusBox',
  AllLoanOffers: '/api/v1/investor/loanOffers',
  LogOutURL: '/restApi/logout',
  tagName: '/api/v1/investor/fetchTagName',
  LoanDetailsIndividual: '/api/v1/investor/loanDetails',
  AllOffers: '/api/v1/investor/bids',
  FinancedAmount: '/api/v1/investor/investments',
  PersonalProfileUpdate: '/api/v1/investor/personalProfileUpdate',
  //  Recievables: '/api/v1/investor/showReceivables',
  Transactions: '/api/v1/allTransactions',
  // Receivables: '/api/v1/investor/showReceivables',
  Receivables: 'https://raqamyah.net/raqamyah/investor/receivables/',
  FinancingCriteria: '/api/v1/investor/showCriteria',
  WithdrawWalletAmount: '/api/v1/loanApplication/withdrawAmount',
  ChangePassword: '/api/v1/internalApi/changePassword',
  SendOtpMobile: '/api/v1/verify/sendOtp',
  VerifyOtpMobile: '/api/v1/verify/phone',
  EmailVerificationLink: '/api/v1/send/emailIdVerificationMail',
  RegistrationStepOneBasic: '/api/v1/investor/stepOne',
  RegistrationStepTwoIndividual: '/api/v1/investor/stepTwoIndividual',
  RegistrationStepThreeEmployment: '/api/v1/investor/createOrUpdateEmploymentDetail',
  RegistrationStepFourBank: '/api/v1/investor/profileUpdate',
  RegistrationStepFiveDeclaration: '/api/v1/investor/acceptTermsAndConditions',
  RegistrationBorrowerStepOneBasic: '/api/v1/borrower/stepOne',
  RegistrationBorrowerStepTwo: '/api/v1/borrower/stepTwoIndividual',
  RegistrationBorrowerStepTwoCompany: '/api/v1/borrower/stepTwoCompany',
  RegistrationBorrowerApplyLoan: '/api/v1/loanApplication/apply',
  RegistrationBorrowerStepFiveDeclaration: '/api/v1/borrower/acceptTermsAndConditions',
  FundLoanApplication: '/api/v1/loanApplication/placeBid',
  addFinancingCriteria: '/api/v1/investor/addCriteria',
  CitizenInfoInvestor: '/api/v1/yakeen/getCitizenInfo',
  DeclarationFileUpload: '/api/v1/investor/documentUpload',
  DeclarationFileUploadBorrower: '/api/v1/borrower/uploadDocument',
  CitiesList: '/api/v1/restConfig/commonPropertySuggest?propertyType=CITY',
  IndustryList: '/api/v1/restConfig/commonPropertySuggest?propertyType=INVESTOR_EMPLOYMENT_DETAILS_VARIABLE1',
  ExposureList: '/api/v1/restConfig/commonPropertySuggest?propertyType=INVESTOR_EMPLOYMENT_DETAILS_VARIABLE3',
  IncomeList: '/api/v1/restConfig/commonPropertySuggest?propertyType=INVESTOR_EMPLOYMENT_DETAILS_VARIABLE4',
  IndustryListBorrower: '/api/v1/restConfig/commonPropertySuggest?propertyType=INVESTOR_EMPLOYMENT_DETAILS_VARIABLE1',
  CompanyList: '/api/v1/restConfig/commonPropertySuggest?propertyType=COMPANY_VARIABLE1',
  CitizenShip: '/api/v1/restConfig/commonPropertySuggest?propertyType=CITIZENSHIP',
  BankName: '/api/v1/restConfig/commonPropertySuggest?propertyType=BANK_NAME',
  EmploymentStatus: '/api/v1/restConfig/commonPropertySuggest?propertyType=INVESTOR_DETAILS_EMPLOYMENT_TYPE',
  SectorList: '/api/v1/restConfig/commonPropertySuggest?propertyType=INVESTOR_COMPANY_VARIABLE2',
  LoanPublishingSoon: '/api/v1/investor/loansPublishing',
  ForgotPassword: '/restApi/forgotPassword',
  DeleteFinancingCriteria: '/api/v1/investor/deleteInvestmentCriteria',
  BankDetails: '/api/v1/bankDetails/show',
  BankDetailsUpdate: '/api/v1/bankDetails/update',
  LenderCategory: '/api/v1/restConfig/investorOnboarding',
  OtpVerification: '/restApi/loginWithOtpRest',
  GoogleOtpVerification: '/restApi/verifyGoogleOtp',
  UpdateFinancingCriteria: '/api/v1/investor/updateCriteria',
  Status: '/api/v1/investor/fetchInvestorDashboardLoanApplicationStatusCount',
  InvestorStatusDetails: 'api/v1/investor/fetchInvestorDashboardLoanApplicationsByStatus',
  FinancingCreditability: '/api/v1/investor/fetchFinancialCredibility',
  FinancingCompany: '/api/v1/investor/companyFinancialDetail',
  EmploymentDetail: '/api/v1/investor/fetchEmploymentDetail',
  ProfileUpdate: '/api/v1/investor/profileUpdate',
  CompanyProfileUpdate: '/api/v1/investor/companyProfileUpdate',
  AddCriteriaOtpVerify: '/api/v1/investor/verifyOtpForInvestmentCriteria',
  ForgotPasswordVerification: '/restApi/verifyForgotPasswordOtp',
  GetCitizenAddressInfo: '/api/v1/yakeen/getCitizenAddressInfo',
  GetAlienAddressInfo: '/api/v1/yakeen/getAlienAddressInfo',
  GetAlienInfo: '/api/v1/yakeen/getAlienInfo',
  RayahSendOtp: '/api/v1/rayah/sendOtp',
  RayahVerifyOtp: '/api/v1/rayah/verifyOtp',
  InvestorMandatory: '/api/v1/restConfig/investorOnboarding',
  BorrowerMandatory: '/api/v1/restConfig/borrowerOnboarding',
  BankDetailsAdd: '/api/v1/bankDetails/add',
  RegistrationStepTwoCompany: '/api/v1/investor/stepTwoCompany',
  CompanyRegistrationCheck: 'https://api.wathq.sa/v5/commercialregistration/info',
  CheckUniqueEmail: '/api/guest/restPublic/checkUniqueEmail?email=',
  CheckUniqueMobile: '/api/guest/restPublic/checkUniquePhoneNumber?phoneNumber=',
  RepaymentDetail: '/api/v1/investor/repaymentSchedule',
  MobileNumberOtp: '/restApi/sendSignupOtp',
  MobileNumberVerifyOtp: '/restApi/verifySignupOtp',
  RecentlyFullyFunded: '/api/v1/investor/fetchRecentlyFullyFundedRequest',
  ResetPassword: '/restApi/resetForgotPassword',
  Activation: '/activate?verificationToken',
  ConcurrencyControl: '/restApi/concurrencyControlLogin',
  StatusChange: '/api/v1/investor/investorStatusChange',
  WithdrawSendOtp: '/api/v1/send/OtpForTransactionWithdrawal',
  WithdrawVerifyOtp: '/api/v1/verify/OtpForTransactionWithdrawal',
  NafathApiValidation: 'https://raqamyah.net/api/nafathCheck',
  NafathApiValidationStatus: 'https://raqamyah.net/api/nafathStatus',
  NafathApiResponse: 'https://raqamyah.net/api/nafathDetailsFetch',
  PosLoanRepayment: 'https://raqamyah.net/raqamyah/investor/posrepayment/',

  // borrower

  BorrowerDash: '/api/v1/borrower/borrowerDetail',
  BorrowerAppliedLoans: '/api/v1/borrower/loanDetails',
  BorrowerAllDetail: '/api/v1/borrower/allBorrowerDetails',
  BorrowerLoanApplicationDetails: '/api/v1/loanApplication/loanDetailWithRepayment',
  BorrowerLoanApplicationWithdraw: '/api/v1/loanApplication/withdraw',
  BorrowerQuestionAdd: '/api/v1/loanQuestion/add',
  BorrowerAnswer: '/api/v1/loanQuestion/answer',
  BorrowerApplyLoan: '/api/v1/loanApplication/apply',
  BorrowerUpdateLoan: '/api/v1/borrower/updateLoanApplication',
  BorrowerFunding: '/api/v1/loanApplication/acceptRejectFunding',
  BorrowerDocument: '/borrower/uploadDocument',
  BorrowerTransactions: '/api/v1/allTransactions',
  BorrowerDocumentShow: '/api/v1/supportingDocuments/loanApplication',
  BorrowerFinancedAmount: '/api/v1/loanApplication/loanInvestmentList',
};
const methods = {
  post: 'post',
  get: 'get',
  patch: 'patch',
  delete: 'delete',
  put: 'put',
};
// const headers = {
//   head: {
//     'Content-Type': 'multipart/form-data',
//   },
//   headJson: {
//     accept: 'application/json',
//     'Content-Type': 'application/json',
//   },
//   headWithoutAccept: {
//     apiKey: 'd6d2a1c8-34e2-431d-bbd5-fb8ce5838478',
//     'Content-Type': 'application/json',
//   },
//   headwithMultipart: {
//     'Content-Type': 'multipart/form-data',

//     apiKey: 'd6d2a1c8-34e2-431d-bbd5-fb8ce5838478',
//     'Access-Control-Allow-Origin': 'http://staging.raqamyah.com/',
//   },
//   header: {
//     'Content-Type': 'application/x-www-form-urlencoded',

//     // apiKey: 'd6d2a1c8-34e2-431d-bbd5-fb8ce5838478',
//   },
//   headerempty: {},
// };
const headers = {
  head: {
    'Content-Type': 'application/x-www-form-urlencoded',
  },
  json: {
    accept: 'application/json',
    'Content-Type': 'application/json',
  },
  headWithoutAccept: {
    'Content-Type': 'application/json',
  },
  headwithMultipart: {
    'Content-Type': 'multipart/form-data',
  },
  headurlencoded: {
    Accept: 'application/x-www-form-urlencoded',
  },
  headurlencodedcontent: {
    'Content-Type': 'application/x-www-form-urlencoded',
  },
  headFinancing: {
    'Content-Type': 'application/json',
  },
  headWathq: {
    apiKey: 'XABVFhs8zEkf2nxG7iqfRAmhYfnVDDhD',
    // apiKey: 'xOskJuAzY8zYg7WUyj83gRxyrFVyeAfU',
  },
  header: {},
};
const kongParams = {};
export { Url, methods, headers, kongParams };
