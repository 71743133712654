import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import styles from './Style'; // Tell webpack this JS file uses this image
import { Link } from 'react-router-dom';
import { websiteUrl } from '../Header/Header';
const useStyles = makeStyles(styles); // /logo.84287d09.png
function Logo() {
  const logo = 'https://d1rfd0ppcouvna.cloudfront.net/public/assetsNew/images/logo/logo.webp';
  const classes = useStyles();
  // Import result is the URL of your image
  return (
    <div style={{ width: '130px', height: '60px', cursor: 'pointer' }} onClick={() => window.open(websiteUrl, '_self')}>
      <a to="/">
        <img src={logo} className={classes.logo} alt="Logo" />
      </a>
    </div>
  );
}

export default Logo;
