/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, fade, FormControl, InputLabel, MenuItem, Select } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Typography from '@material-ui/core/Typography';
import { CurrentAuthMethod } from '@jumbo/constants/AppConstants';
import IntlMessages from '@jumbo/utils/IntlMessages';
import AuthWrapper from '@jumbo/components/Common/authComponents/AuthWrapper';
import { history } from 'redux/store';
import { registrationStepFourBankFunction } from 'redux/actions/registrationStepFourBank';
import { Url } from 'redux/NetworkConfig/ApiUrlConstatnts';
import Axios from 'axios';
import { Dialog, DialogActions, DialogContent, DialogContentText } from '@material-ui/core';
import Swal from 'sweetalert2';
import Loader from 'routes/Components/pageLoaderCustom/loader';
import PageLoader from '@jumbo/components/PageComponents/PageLoader';

const useStyles = makeStyles(theme => ({
  authThumb: {
    backgroundColor: fade(theme.palette.primary.main, 0.12),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: 20,
    [theme.breakpoints.up('md')]: {
      width: '50%',
      order: 2,
    },
  },
  authContent: {
    padding: 30,
    [theme.breakpoints.up('md')]: {
      width: props => (props.variant === 'default' ? '50%' : '100%'),
      order: 1,
    },
    [theme.breakpoints.up('xl')]: {
      padding: 50,
    },
  },
  titleRoot: {
    marginBottom: 14,
    color: theme.palette.text.primary,
  },
  textFieldRoot: {
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: fade(theme.palette.common.dark, 0.12),
    },
  },
  textCapital: {
    textTransform: 'capitalize',
  },
  textAcc: {
    textAlign: 'center',
    '& a': {
      marginLeft: 4,
    },
  },
  alrTextRoot: {
    textAlign: 'center',
    [theme.breakpoints.up('sm')]: {
      textAlign: 'right',
    },
  },
}));

//variant = 'default', 'standard', 'bgColor'
const SignUpBorrowerBank = ({ method = CurrentAuthMethod, variant = 'standard', wrapperVariant = 'default' }) => {
  const [bankName, setBankName] = useState('');
  const [accountName, setAccountName] = useState('');
  const [IBAN, setIBAN] = useState('');
  const dispatch = useDispatch();
  const [status, setStatus] = useState('');
  const [message, setMessage] = useState('');
  const [isFetching, setIsFetching] = useState(false);
  const [code, setCode] = useState('');
  const [showAlert, setShowAlert] = useState(false);
  const classes = useStyles({ variant });

  const Register_step4 = () => {
    let newUser = localStorage.getItem('newUser');
    // navigation.navigate('Register_investor_step_five');
    let inputData = {
      access_token: newUser,
      bankName: bankName,
      accountHolderName: accountName,
      accountNumber: IBAN,
    };

    setIsFetching(true);
    dispatch(registrationStepFourBankFunction(inputData)).then(res => {
      setIsFetching(false);

      if (res.data.code === '200') {
        setIsFetching(false);

        Swal.fire({
          title: res.data.status,
          text: res.data.message,
          showDenyButton: false,
          showCancelButton: false,
          icon: 'success',
          confirmButtonText: 'Okay',
        }).then(result => {
          /* Read more about isConfirmed, isDenied below */
          if (result.isConfirmed) {
            history.push('/signupborrowersix');
          } else if (result.isDenied) {
            return;
          }
        });
      } else {
        setIsFetching(false);

        Swal.fire(res.data.status, res.data.accountHolderName, 'error');
      }
    });
  };
  const [bankNamearray, setBankNameArray] = useState([]);
  const getBankName = () => {
    Axios.post(Url.baseUrl + Url.BankName)
      .then(function(response) {
        // handle success
        // alert(JSON.stringify(response.data.totalInvestmentCount));
        setBankNameArray(response.data);
      })
      .catch(function(error) {
        // handle error
        alert(error.message);
      });
  };
  useEffect(() => {
    getBankName();
  }, []);
  let isLoading = useSelector(state => state.registrationStepFourBankReducer.isFetching);
  return (
    <AuthWrapper variant={wrapperVariant}>
      {isFetching && (
        <div>
          <PageLoader />
        </div>
      )}
      <Box className={classes.authContent}>
        <Loader isLoading={isLoading} />
        <Typography component="div" variant="h1" className={classes.titleRoot}>
          <IntlMessages id="registration.bankdetails" />
        </Typography>
        <form
          onSubmit={e => {
            Register_step4();
            e.preventDefault();
          }}>
          <Box mb={2}>
            <FormControl fullWidth>
              <InputLabel style={{ marginLeft: 20 }} id="demo-simple-select-helper-label">
                <IntlMessages id="registration.bankname" />
              </InputLabel>
              <Select
                labelId="demo-simple-select-helper-label"
                id="demo-simple-select-helper"
                value={bankName}
                variant="outlined"
                required
                onChange={event => setBankName(event.target.value)}
                label="Age">
                <MenuItem value="">
                  <em>None</em>
                </MenuItem>
                {bankNamearray.map(item => {
                  return (
                    <MenuItem key={item.uuid} value={item.name}>
                      {item.name}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Box>
          <Box mb={2}>
            <TextField
              label={<IntlMessages id="registration.accountholdername" />}
              required
              fullWidth
              onChange={event => setAccountName(event.target.value)}
              // defaultValue={email}
              margin="normal"
              variant="outlined"
              className={classes.textFieldRoot}
            />
          </Box>
          <Box mb={2}>
            <TextField
              label={<IntlMessages id="registration.ibannumber" />}
              required
              fullWidth
              onChange={event => setIBAN(event.target.value)}
              // defaultValue={email}
              margin="normal"
              variant="outlined"
              className={classes.textFieldRoot}
            />
          </Box>

          <Box
            display="flex"
            flexDirection={{ xs: 'column', sm: 'row' }}
            alignItems={{ sm: 'center' }}
            justifyContent={{ sm: 'space-between' }}
            mb={3}>
            <Box mb={{ xs: 2, sm: 0 }}>
              <Button type="submit" variant="contained" color="primary">
                <IntlMessages id="appModule.next" />
              </Button>
            </Box>
          </Box>
        </form>
        <div>
          <Dialog open={showAlert}>
            <DialogContent>
              <DialogContentText>{status}</DialogContentText>

              <DialogContentText>{message}</DialogContentText>
            </DialogContent>

            <DialogActions>
              <Button onClick={() => setShowAlert(false)}>Cancel</Button>
              <Button onClick={() => (code === '200' ? history.push('/signupborrowersix') : setShowAlert(false))}>Ok</Button>
            </DialogActions>
          </Dialog>
        </div>
      </Box>
    </AuthWrapper>
  );
};

export default SignUpBorrowerBank;
