import React, { useContext, useEffect, useState } from 'react';
import TextField from '@material-ui/core/TextField';
import IntlMessages from '../../../utils/IntlMessages';
import { useDispatch, useSelector } from 'react-redux';
import Button from '@material-ui/core/Button';
import {
  Box,
  Fab,
  fade,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import ContentLoader from '../../ContentLoader';
import makeStyles from '@material-ui/core/styles/makeStyles';
import CmtImage from '../../../../@coremat/CmtImage';
import Typography from '@material-ui/core/Typography';
import { CurrentAuthMethod } from '../../../constants/AppConstants';
import { NavLink, useHistory } from 'react-router-dom';
import AuthWrapper from './AuthWrapper';
import { loginFunction } from 'redux/actions/loginFunction';
import { otpVerification } from 'redux/actions/otpVerfication';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@material-ui/core';
import { Url } from 'redux/NetworkConfig/ApiUrlConstatnts';
import moment from 'moment';
import Swal from 'sweetalert2';
import AppContext from '@jumbo/components/contextProvider/AppContextProvider/AppContext';
import Loader from 'routes/Components/pageLoaderCustom/loader';
import Axios from 'axios';
import packageJson from '../../../../../package.json';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import clsx from 'clsx';

const useStyles = makeStyles(theme => ({
  authThumb: {
    backgroundColor: fade(theme.palette.primary.main, 0.12),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: 20,
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: '50%',
      order: 2,
    },
  },
  authContent: {
    padding: 30,
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: props => (props.variant === 'default' ? '50%' : '100%'),
      order: 1,
    },
    [theme.breakpoints.up('xl')]: {
      padding: 50,
    },
  },
  titleRoot: {
    marginBottom: 14,
    color: theme.palette.text.primary,
  },
  textFieldRoot: {
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: fade(theme.palette.common.dark, 0.12),
    },
  },
  formcontrolLabelRoot: {
    '& .MuiFormControlLabel-label': {
      [theme.breakpoints.down('xs')]: {
        fontSize: 12,
      },
    },
  },
}));
//variant = 'default', 'standard'
const SignIn = ({ method = CurrentAuthMethod, variant = 'default', wrapperVariant = 'default' }) => {
  let userEmail = localStorage.getItem('userEmail');
  // console.log(userEmail, 'email');
  const [email, setEmail] = useState(userEmail ? userEmail : '');
  const [password, setPassword] = React.useState('');
  // console.log(password, 'password');
  const dispatch = useDispatch();
  const classes = useStyles({ variant });
  const history = useHistory();
  const [open, setOpen] = useState(false);
  const [conOpen, setconOpen] = useState(false);
  const [authOpen, setauthOpen] = useState(false);
  const [message, setMessage] = useState('');
  const [conMessage, setconMessage] = useState('');
  const [conUuid, setconUuid] = useState('');
  const [verifymessage, setVerify] = useState('');
  const [otp, setOtp] = useState('');
  const [gOtp, setgOtp] = useState('');
  const [userUuid, setuserUuid] = useState('');
  const onSubmit = () => {
    let inputData = {
      username: email,
      password: password,
    };
    setTimeLeft(60);
    setResendButton(true);
    dispatch(loginFunction(inputData)).then(res => {
      const userid = res?.data?.userUuid;
      localStorage.setItem('userEmail', email);
      setuserUuid(userid);
      // console.log(res?.data, 'loginres');
      let userType = String(res?.data?.userType).toLowerCase();
      if (userType === 'admin' && res?.data?.targetToRedirect === 'GOOGLE_TOTP') {
        setauthOpen(true);
        return;
      }
      if (userType === 'admin') {
        // Swal.fire('Error!', 'This is not admin', 'error');
        const url = Url.baseUrl + '/public/redirectToLanding?access_token=' + res?.data?.access_token + '&lang=en';

        window.open(url, '_self');
        return;
      }
      if (res?.data?.code == '200') {
        let msg = `${transformEntry(res.data.message.slice(28, 50), 'phone')}`;
        setMessage(String(msg));
        // setMessage('OTP sent');
        setOpen(true);
      } else if (res?.data?.targetToRedirect == 'ACTIVE_SESSION_FOUND') {
        let conMsg = res.data.message;
        let conUuid = res.data.userUuid;
        setconMessage(
          languageCheck === 'en'
            ? 'User already login with someother device.'
            : 'المستخدم مسجل دخول حاليا في جهاز آخر، هل تريد الغاء الدخول السابق والمتابعة في الدخول؟',
        );
        setconOpen(true);
        setconUuid(conUuid);
        // console.log(res.data, 'data');
      } else {
        // alert(res.data.message);
        Swal.fire('Error/خطأ', res.data.message, 'error');
        return;
      }
    });
  };
  const storeLoginTime = async seconds => {
    try {
      await localStorage.setItem('loginTime', String(seconds));
    } catch (error) {
      // console.log(error);
    }
  };
  const transformEntry = (item, type) => {
    switch (type) {
      case 'email':
        var parts = item.split('@'),
          len = parts[0].length;
        return email.replace(parts[0].slice(1, -1), '*'.repeat(len - 2));
      case 'phone':
        return item[0] + item[1] + '*'.repeat(item.length - 4) + item.slice(-2);
      default:
        throw new Error('Undefined type: ' + type);
    }
  };
  let languageCheck = localStorage.getItem('languageCheck');
  if (!languageCheck) {
    languageCheck = 'en';
  }
  // console.log(userUuid, 'useruuid');
  const verfiyOtp = () => {
    if (otp.length == 0) {
      Swal.fire(
        languageCheck === 'en' ? 'Error' : 'خطأ',
        languageCheck === 'en' ? 'OTP Field Cannot be empty' : 'لا يمكن أن يكون حقل OTP فارغًا',
        'error',
      );
      return;
    }
    let inputOtp = otp;
    // let url = Url.OtpVerification + '?otp=' + inputOtp;
    let url = Url.OtpVerification + '?otp=' + inputOtp + '&userUuid=' + userUuid;

    dispatch(otpVerification(inputOtp, url, userUuid)).then(async res => {
      // console.log(res.data, 'otpverification');
      // return;
      if (res.data.code == '200') {
        setOpen(false);
        setOtp('');
        setMessage('');
        let timeStamp = moment();
        // console.log(timeStamp);
        await storeLoginTime(timeStamp);
        localStorage.setItem('userDetails', JSON.stringify(res.data));
        let userType = String(res?.data?.userType).toLowerCase();
        if (userType == 'investor') {
          window.open('/investor/dashboard', '_self');
        } else if (userType == 'borrower') {
          window.open('/borrower/dashboard', '_self');
        } else {
          const url = Url.baseUrl + '/public/redirectToLanding?access_token=' + res?.data?.access_token + '&lang=en';
          // console.log(url, 'url');
          window.open(url, '_self');
        }
      } else {
        setOtp('');
        setMessage('');
        setVerify(res.data.message);
      }
    });
    // let inputData = {
    //   username: email,
    //   password: password,
    // };
    // dispatch(loginFunction(inputData)).then(res => {
    //   // console.log(res.data);
    //   // if (res.data.code == '200') {
    //   //   // localStorage.setItem('userDetails', JSON.stringify(res.data));

    //   //   // window.open('dashboard/Dashboard','_self');
    //   //   setMessage(res.data.message)
    //   //   setOpen(true)
    //   // } else {
    //   //   alert(res.data.message);
    //   //   return;
    //   // }
    // });
  };

  //verifyGoogleAuthenticatorOTP
  const verfiyGoogleOtp = () => {
    if (gOtp.length == 0) {
      Swal.fire(
        languageCheck === 'en' ? 'Error' : 'خطأ',
        languageCheck === 'en' ? 'OTP Field Cannot be empty' : 'لا يمكن أن يكون حقل OTP فارغًا',
        'error',
      );
      return;
    }
    let input = {
      otp: gOtp,
    };
    // console.log(input, 'concurrencyres');
    const url = Url.baseUrl + Url.GoogleOtpVerification + '?userUuid=' + userUuid + '&otp=' + input.otp;
    // console.log(url, 'concurrencyres');
    Axios.post(url)
      .then(function async(response) {
        // console.log(response.data, 'concurrencyres');
        let userType = String(response?.data?.userType).toLowerCase();
        if (userType === 'admin') {
          // Swal.fire('Error!', 'This is not admin', 'error');
          const url = Url.baseUrl + '/public/redirectToLanding?access_token=' + response?.data?.access_token + '&lang=en';

          window.open(url, '_self');
          return;
        } else {
          Swal.fire('Error/خطأ', response.data.message, 'error');
          setauthOpen(false);
        }
      })
      .catch(function(error) {
        // handle error
        console.log(error);
      });
  };
  const [seconds, setSeconds] = useState(60);
  const [resendButton, setResendButton] = useState(true);
  const [resendButtonCount, setResendButtonCount] = useState(3);
  const [timeLeft, setTimeLeft] = useState(seconds);
  const { locale, setLocale } = useContext(AppContext);
  const [showpassword, setshowpassword] = useState(false);

  const concurrencyLoginControl = () => {
    let input = {
      userUuid: conUuid,
    };
    // console.log(input, 'concurrencyres');
    const url = Url.baseUrl + Url.ConcurrencyControl + '?userUuid=' + userUuid + '&sessionOverride=CONTINUE';
    // console.log(url, 'concurrencyres');
    Axios.post(url)
      .then(function async(response) {
        // console.log(response.data, 'concurrencyres');
        let userType = String(response?.data?.userType).toLowerCase();
        if (userType === 'admin' && response?.data?.targetToRedirect === 'GOOGLE_TOTP') {
          setauthOpen(true);
          return;
        }
        if (userType === 'admin') {
          // Swal.fire('Error!', 'This is not admin', 'error');
          const url = Url.baseUrl + '/public/redirectToLanding?access_token=' + response?.data?.access_token + '&lang=en';

          window.open(url, '_self');
          return;
        }
        if (response.data.code == '200') {
          let msg = `${transformEntry(response.data.message.slice(28, 50), 'phone')}`;
          setMessage(String(msg));
          // setMessage('OTP sent');
          setOpen(true);
          setconOpen(false);
        } else {
          Swal.fire('Error/خطأ', response.data.message, 'error');
          setconOpen(false);
        }
      })
      .catch(function(error) {
        // handle error
        console.log(error);
      });
  };

  const cacheClear = () => {
    let version = localStorage.getItem('version');
    // console.log(packageJson.version, 'appversion');
    if (version != packageJson.version) {
      if ('caches' in window) {
        caches.keys().then(names => {
          // Delete all the cache files
          names.forEach(name => {
            caches.delete(name);
          });
        });

        // Makes sure the page reloads. Changes are only visible after you refresh.
        window.location.reload(true);
        // console.log('im');
      }

      localStorage.setItem('version', packageJson.version);
    }
  };
  useEffect(() => {
    cacheClear();
    if (!open) {
      return;
    }
    if (timeLeft == 0) {
      setResendButton(false);
    }
    // exit early when we reach 0
    if (!timeLeft) return;
    // save intervalId to clear the interval when the
    // component re-renders
    const intervalId = setInterval(() => {
      setTimeLeft(timeLeft - 1);
    }, 1000);
    // clear interval on re-render to avoid memory leaks
    return () => {
      clearInterval(intervalId);
    };
    // add timeLeft as a dependency to re-rerun the effect
    // when we update it
  }, [timeLeft, open]);
  const refreshButton = () => {
    setTimeLeft(seconds);
    setResendButton(true);
    setResendButtonCount(resendButtonCount - 1);
    onSubmit();
  };
  const query = new URLSearchParams(window.location.search);
  const token = query.get('lang');
  const switchLanguage = async item => {
    await setLocale(item);
    if (item.locale === 'en') {
      // setDirection('ltr');
      localStorage.setItem('languageCheck', 'en');
      window.location.reload();
    } else {
      // setDirection('rtl');
      localStorage.setItem('languageCheck', 'ar_JO');
      window.location.reload();
    }
    // handleClose();
  };
  let languagedata = [
    {
      languageId: 'english',
      locale: 'en',
      name: 'English',
      icon: 'us',
    },
    {
      languageId: 'saudi-arabia',
      locale: 'ar',
      name: 'عربى',
      icon: 'sa',
    },
  ];
  const [readOnlyAttr, setReadOnlyAttr] = useState(true);
  const [readOnlyAttrEmail, setReadOnlyAttrEmail] = useState(true);

  useEffect(() => {
    let session = localStorage.getItem('sessionExpired');
    if (session === 'TRUE') {
      Swal.fire({
        title: languageCheck == 'en' ? 'Session is Expired!' : 'انتهت الجلسة!',
        text:
          languageCheck == 'en'
            ? 'You are already logged in with another device.'
            : 'لقد قمت بتسجيل الدخول بالفعل بجهاز آخر.',
        showDenyButton: false,
        showCancelButton: false,
        allowOutsideClick: false,
        icon: 'error',
        confirmButtonText: languageCheck == 'en' ? 'Okay' : 'إغلاق',
      }).then(async result => {
        /* Read more about isConfirmed, isDenied below */
        if (result.isConfirmed) {
          //   dispatch(logoutFunction());
          localStorage.removeItem('sessionExpired');
          return;
        } else if (result.isDenied) {
          return;
        }
      });
    }

    if (token !== null) {
      if (token.includes('en') || token.includes('ar')) {
        if (languageCheck.includes(token)) {
          return;
        } else if (token == 'en') {
          switchLanguage(languagedata[0]);
        } else {
          switchLanguage(languagedata[1]);
        }
      }
    }
  }, []);

  let isFetchingLogin = useSelector(state => state.loginReducer.isFetching);
  const handleChange = prop => event => {
    setPassword({ ...password, [prop]: event.target.value });
  };

  const handleClickShowPassword = () => {
    setPassword({ ...password, showPassword: !password.showPassword });
  };

  const handleMouseDownPassword = event => {
    event.preventDefault();
  };

  const theme = useTheme();

  const veryWideScreen = useMediaQuery(theme.breakpoints.down('xs'), {
    noSsr: true,
  });
  // console.log(password, 'password');
  return (
    <AuthWrapper variant={veryWideScreen ? 'signup' : wrapperVariant}>
      {variant === 'default' ? (
        <Box className={classes.authThumb}>
          <CmtImage src={'/images/auth/login-img.png'} />
        </Box>
      ) : null}

      <Box className={classes.authContent}>
        <Typography component="div" variant="h1" className={classes.titleRoot}>
          <IntlMessages id="registration.login" />
        </Typography>
        <Loader isLoading={isFetchingLogin} />

        <form>
          <Box mb={2}>
            <TextField
              label={<IntlMessages id="appModule.email" />}
              fullWidth
              // value={userEmail}
              onChange={event => setEmail(event.target.value)}
              onBlur={() => setReadOnlyAttrEmail(true)}
              onClick={() => setReadOnlyAttrEmail(false)}
              // readOnly={readOnlyAttrEmail}
              defaultValue={userEmail}
              margin="normal"
              variant="outlined"
              className={classes.textFieldRoot}
            />
          </Box>
          <Box mb={2} style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            {/* <TextField
              type="password"
              label={<IntlMessages id="appModule.password" />}
              fullWidth
              onPaste={e => {
                e.preventDefault();
                return false;
              }}
              value={password}
              onChange={event => setPassword(event.target.value)}
              margin="normal"
              variant="outlined"
              className={classes.textFieldRoot}
            /> */}
            <FormControl
              autoComplete="none"
              fullWidth
              className={clsx(classes.margin, classes.textField)}
              variant="outlined">
              <InputLabel htmlFor="outlined-adornment-password">
                <IntlMessages id="appModule.password" />
              </InputLabel>
              <OutlinedInput
                autoComplete="none"
                fullWidth
                // readOnly={readOnlyAttr}
                id="outlined-adornment-password"
                type={password.length > 0 ? (showpassword ? 'text' : 'password') : 'text'}
                // value={password}
                onBlur={() => setReadOnlyAttr(true)}
                onClick={() => setReadOnlyAttr(false)}
                onChange={e => setPassword(e.target.value)}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() => {
                        setshowpassword(!showpassword);
                        setPassword(password);
                      }}
                      onMouseDown={handleMouseDownPassword}
                      edge="end">
                      {showpassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                }
                labelWidth={70}
              />
            </FormControl>
          </Box>
          <Box display="flex" justifyContent="flex-end" mb={5}>
            <Box component="p" fontSize={{ xs: 12, sm: 16 }}>
              <NavLink to="/forgot-password">
                <IntlMessages id="appModule.forgotPassword" />
              </NavLink>
            </Box>
          </Box>

          <Box display="flex" alignItems="center" justifyContent="space-between" mb={5}>
            <Button onClick={onSubmit} variant="contained" color="primary">
              <IntlMessages id="appModule.signIn" />
            </Button>

            <Box component="p" fontSize={{ xs: 12, sm: 16 }}>
              <NavLink to="/signup">
                <IntlMessages id="signIn.signUp" />
              </NavLink>
            </Box>
          </Box>
          <div>
            <Dialog fullWidth maxWidth={'xs'} open={open}>
              <DialogTitle>{<IntlMessages id="registration.enterotp" />}</DialogTitle>
              <DialogContent>
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'row' }}>
                  <DialogContentText style={{ marginRight: '5px', marginLeft: '5px' }}>
                    {<IntlMessages id="registration.otpsent" />}
                  </DialogContentText>
                  <DialogContentText style={{ direction: 'ltr', flexDirection: 'row' }}>{' ' + message}</DialogContentText>
                </div>
                <TextField
                  autoFocus
                  margin="dense"
                  autoComplete="off"
                  id="name"
                  label="OTP"
                  type="text"
                  onKeyPress={event => {
                    if (!/[0-9]/.test(event.key)) {
                      event.preventDefault();
                    }
                  }}
                  InputProps={{ inputProps: { min: 0 } }}
                  onChange={e => {
                    const { value } = e.target;
                    //  console.log(value)
                    setOtp(value);
                  }}
                  fullWidth
                  variant="outlined"
                />
              </DialogContent>
              <DialogActions style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    setOpen(false);
                  }}>
                  <IntlMessages id="registration.cancel" />
                </Button>

                {resendButton == true ? (
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => {
                      verfiyOtp();
                    }}>
                    <IntlMessages id="registration.submit" />
                  </Button>
                ) : (
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => {
                      refreshButton();
                    }}>
                    <IntlMessages id="registration.resend" />
                  </Button>
                )}
              </DialogActions>
              {timeLeft != 0 ? (
                <DialogContentText style={{ textAlign: 'center' }}>
                  <IntlMessages id="registration.resendotp" /> {timeLeft}s
                </DialogContentText>
              ) : null}
              <DialogContentText>
                <Typography style={{ marginLeft: 10, marginRight: 10, textAlign: 'center' }}>{verifymessage}</Typography>
              </DialogContentText>
            </Dialog>
          </div>
          <Dialog fullWidth maxWidth={'xs'} open={conOpen}>
            <DialogContent>
              <Typography variant="h4" color="textprimary" style={{ textAlign: 'center' }}>
                {'  ' + conMessage}
              </Typography>
            </DialogContent>
            <DialogActions style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <Button
                variant="contained"
                color="primary"
                onClick={() => {
                  setconOpen(false);
                }}>
                <IntlMessages id="registration.cancel" />
              </Button>
              <Button
                variant="contained"
                color="primary"
                onClick={() => {
                  concurrencyLoginControl();
                }}>
                <IntlMessages id="registration.continue" />
              </Button>
            </DialogActions>
          </Dialog>
          <Dialog fullWidth maxWidth={'xs'} open={authOpen}>
            <DialogTitle>{<IntlMessages id="registration.googleAuth" />}</DialogTitle>
            <DialogContent>
              <TextField
                autoFocus
                margin="dense"
                autoComplete="off"
                id="name"
                label="Enter Google Authenticator Six Digit Code"
                type="text"
                onKeyPress={event => {
                  if (!/[0-9]/.test(event.key)) {
                    event.preventDefault();
                  }
                }}
                InputProps={{ inputProps: { min: 0 } }}
                onChange={e => {
                  const { value } = e.target;
                  //  console.log(value)
                  setgOtp(value);
                }}
                fullWidth
                variant="outlined"
              />
            </DialogContent>
            <DialogActions style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <Button
                variant="contained"
                color="primary"
                onClick={() => {
                  setauthOpen(false);
                }}>
                <IntlMessages id="registration.cancel" />
              </Button>
              <Button
                variant="contained"
                color="primary"
                onClick={() => {
                  verfiyGoogleOtp();
                }}>
                <IntlMessages id="registration.submit" />
              </Button>
            </DialogActions>
          </Dialog>
        </form>

        <ContentLoader />
      </Box>
    </AuthWrapper>
  );
};

export default SignIn;
