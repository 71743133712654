import Footer from '@jumbo/components/Footer/Footer';
import FooterTop from '@jumbo/components/Footer/FooterTop';
import Header from '@jumbo/components/Header/Header';
import React from 'react';
import SignUpTwo from 'routes/ExtraPages/sign-up/SignupTwo';

const RegisterBasic = () => {
  return (
    <div style={{ flex: 1, margin: '0px' }}>
      <Header />
      <SignUpTwo variant="standard" wrapperVariant="bgColor" />
      <FooterTop />
      <Footer />
    </div>
  );
};

export default RegisterBasic;
