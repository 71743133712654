import Footer from '@jumbo/components/Footer/Footer';
import FooterTop from '@jumbo/components/Footer/FooterTop';
import Header from '@jumbo/components/Header/Header';
import React from 'react';
import SignUpBorrowerDocs from '../ExtraPages/sign-up/SignupBorrowerDocs';

const RegisterBorrowerDocument = () => {
  return (
    <div style={{ flex: 1 }}>
      <Header />
      <SignUpBorrowerDocs variant="standard" wrapperVariant="bgColor" />
      <FooterTop />
      <Footer />
    </div>
  );
};

export default RegisterBorrowerDocument;
