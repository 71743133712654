import Footer from '@jumbo/components/Footer/Footer';
import FooterTop from '@jumbo/components/Footer/FooterTop';
import Header from '@jumbo/components/Header/Header';
import React from 'react';
import SignupBorrowerFinance from '../ExtraPages/sign-up/SignupBorrowerFinance';

const RegisterBorrowerFinance = () => {
  return (
    <div style={{ flex: 1 }}>
      <Header />
      <SignupBorrowerFinance variant="standard" wrapperVariant="bgColor" />
      <FooterTop />
      <Footer />
    </div>
  );
};

export default RegisterBorrowerFinance;
