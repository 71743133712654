import { Snackbar } from '@material-ui/core';
import React from 'react';
import MuiAlert from '@material-ui/lab/Alert';

export default function SnackBarComponent({ setResponse, visible, status, message, setResponseStatus, setResponseMessage }) {
  function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }
  return (
    <Snackbar
      open={visible}
      autoHideDuration={2000}
      onClose={() => {
        setResponse(false);
        setResponseMessage('');
        setResponseStatus('');
      }}>
      <Alert
        onClose={() => {
          setResponse(false);
          setResponseMessage('');
          setResponseStatus('');
        }}
        severity={status === '200' ? 'success' : 'error'}>
        {message}
      </Alert>
    </Snackbar>
  );
}
