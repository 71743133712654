export const languageData = [
  {
    languageId: 'english',
    locale: 'en',
    name: 'English',
    icon: 'us',
  },
  {
    languageId: 'saudi-arabia',
    locale: 'ar',
    name: 'عربى',
    icon: 'sa',
  },
];

export const flags = {
  en: '/images/flag/globe.png',
  ar: '/images/flag/saudi-arabia.png',
};
