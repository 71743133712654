import * as types from '../actionTypes/types';

const initialState = {
  profileUpdate: {},
  isFetching: false,
  errorMessage: '',
};

const profileUpdateReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.FETCHING_PROFILE_UPDATE_REQUEST:
      return { ...state, isFetching: true };
    case types.FETCHING_PROFILE_UPDATE_FAILED:
      return {
        ...state,
        isFetching: false,
        errorMessage: action.payload,
      };
    case types.FETCHING_PROFILE_UPDATE_SUCCESS:
      return {
        ...state,
        isFetching: false,
        profileUpdate: action.payload,
      };

    case types.FETCHING_USER_LOGOUT_DETAILS_SUCCESS:
      state = initialState;
      return state;

    default:
      return state;
  }
};
export default profileUpdateReducer;
