import * as types from '../actionTypes/types';
import { httpRequest } from '../NetworkConfig/Config';
import { Url, methods, headers, kongParams } from '../NetworkConfig/ApiUrlConstatnts';
import { fetchingUserLoginDetailsSuccess } from './loginFunction';
export const otpVerification = (inpuData, url) => {
  return async dispatch => {
    dispatch(fetchingOtpVerificationRequest());
    return httpRequest({
      method: methods.post,
      url: url,
      headers: headers.head,
    }).then(async JsonResponse => {
      if (JsonResponse !== undefined) {
        if (JsonResponse.status == 200 || JsonResponse.status == 203) {
          let fetchedData =
            JsonResponse.data !== undefined && JsonResponse.data !== null && Object.keys(JsonResponse.data).length !== 0
              ? JsonResponse.data
              : {};
          dispatch(fetchingOtpVerificationSuccess(fetchedData));
          dispatch(fetchingUserLoginDetailsSuccess(fetchedData));
        } else {
          dispatch(fetchingOtpVerificationFailed());
        }
        return JsonResponse;
      } else {
        dispatch(fetchingOtpVerificationFailed());
      }
    });
  };
};
export const fetchingOtpVerificationRequest = () => ({
  type: types.FETCHING_OTP_VERIFICATION_REQUEST,
});
export const fetchingOtpVerificationSuccess = json => ({
  type: types.FETCHING_OTP_VERIFICATION_SUCCESS,
  payload: json,
});
export const fetchingOtpVerificationFailed = error => ({
  type: types.FETCHING_OTP_VERIFICATION_FAILED,
  payload: error,
});
export const fetchingUserLogOutDetailsSuccess = () => ({
  type: types.FETCHING_USER_LOGOUT_DETAILS_SUCCESS,
});
