import React, { useContext } from 'react';
import { Menu, MenuItem, MenuList, Paper, Popover, Typography } from '@material-ui/core';
import CmtAvatar from '../../../../@coremat/CmtAvatar';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { useDispatch } from 'react-redux';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import PersonIcon from '@material-ui/icons/Person';
import SettingsIcon from '@material-ui/icons/Settings';
import SidebarThemeContext from '../../../../@coremat/CmtLayouts/SidebarThemeContext/SidebarThemeContext';
import { fetchingUserLogOutDetailsSuccess } from 'redux/actions/loginFunction';
import { history } from 'redux/store';
import { useHistory } from 'react-router';
import { investorAllDetailFunction } from 'redux/actions/investorAllDetail';
import IntlMessages from '../../../utils/IntlMessages';
import Logo from './Logo';
import LayoutContext from '@coremat/CmtLayouts/LayoutContext';
import CmtImage from '@coremat/CmtImage';

const useStyles = makeStyles(theme => ({
  root: {
    padding: '30px 16px 12px 16px',
    borderBottom: props => `solid 1px ${props.sidebarTheme.borderColor}`,
  },
  userInfo: {
    paddingTop: 24,
    transition: 'all 0.1s ease',
    height: 75,
    opacity: 1,
    '.Cmt-miniLayout .Cmt-sidebar-content:not(:hover) &': {
      height: 0,
      paddingTop: 0,
      opacity: 0,
      transition: 'all 0.3s ease',
    },
  },
  userTitle: {
    color: props => props.sidebarTheme.textDarkColor,
    marginBottom: 8,
  },
  userSubTitle: {
    fontSize: 14,
    fontWeight: theme.typography.fontWeightBold,
    letterSpacing: 0.25,
  },
}));

const SidebarHeader = () => {
  let data = localStorage.getItem('userDetails');
  let userDetail = JSON.parse(data);
  const { sidebarTheme } = useContext(SidebarThemeContext);
  const classes = useStyles({ sidebarTheme });
  const dispatch = useDispatch();

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handlePopoverOpen = event => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const onLogoutClick = () => {
    handlePopoverClose();
    // return;
    dispatch(fetchingUserLogOutDetailsSuccess(history));
    let languageCheck = localStorage.getItem('languageCheck');
    if (!languageCheck) {
      languageCheck = 'en';
    }

    window.open('/signin?lang=' + `${languageCheck}`, '_self');
    localStorage.removeItem('userDetails');
    localStorage.removeItem('investorAllDetail');
    localStorage.removeItem('dash');
    localStorage.removeItem('pr');
    localStorage.removeItem('tp');
    localStorage.removeItem('of');
    localStorage.removeItem('loanUuid');
    let logout = { message: 'You have logout success' };
    // localStorage.clear();
  };
  let lang = localStorage.getItem('languageCheck');
  let historyS = useHistory();
  const handleOnClick = async () => {
    await dispatch(investorAllDetailFunction(userDetail.access_token));
    history.push('/investor/accountDetail');
  };
  const handleOnChange = () => {
    history.push('/investor/changePassword');
  };
  const { isSidebarOpen } = useContext(LayoutContext);
  const singleRlogo = require('./RAQ_RGB_Blue2.png');
  return (
    <div className={classes.root}>
      {/* {isSidebarOpen ? (
        <Logo />
      ) : (
        <CmtImage src={singleRlogo} alt="User Avatar" style={{ height: '80px', alignSelf: 'center', display: 'flex' }} />
      )} */}

      <CmtAvatar src={userDetail.profilePicDownloanUrl} alt="User Avatar" />
      <div className={classes.userInfo}>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'flex-end',
          }}>
          <div className="mr-2">
            <Typography className={classes.userTitle} component="h3" variant="h6">
              {userDetail?.userType == 'BORROWER' ? userDetail?.borrowerName : userDetail?.investorName || ''}
            </Typography>
            <Typography className={classes.userSubTitle}>{userDetail?.username ?? ''}</Typography>
          </div>
          {/* <ArrowDropDownIcon /> */}
        </div>
      </div>

      {open && (
        <Menu
          open={open}
          anchorEl={anchorEl}
          container={anchorEl}
          onClose={handlePopoverClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: lang == 'ar_JO' ? 'left' : 'right',
          }}>
          <Paper>
            <MenuList>
              <MenuItem onClick={handlePopoverClose}>
                <PersonIcon />
                <div className="ml-2">
                  <div href="" onClick={() => handleOnClick()}>
                    <IntlMessages id="dashboard.profile" />
                  </div>
                </div>
              </MenuItem>
              <MenuItem onClick={handlePopoverClose}>
                <SettingsIcon />
                <div onClick={() => handleOnChange()} className="ml-2">
                  <IntlMessages id="dashboard.settings" />
                </div>
              </MenuItem>
              <MenuItem onClick={onLogoutClick}>
                <ExitToAppIcon />
                <div className="ml-2">
                  <IntlMessages id="dashboard.logout" />
                </div>
              </MenuItem>
            </MenuList>
          </Paper>
        </Menu>
      )}
    </div>
  );
};

export default SidebarHeader;
