import React, { useEffect, useRef, useState } from 'react';
import { Redirect, Route, Switch } from 'react-router';
import ForgotPassword from './Auth/ForgotPassword';
import Login from './Auth/Login';
import Signup from './Auth/Register';
import RegisterBank from './Auth/RegistrationBank';
import RegisterBasic from './Auth/RegistrationBasic';
import RegisterCompany from './Auth/RegistrationCompany';
import RegisterDocument from './Auth/RegistrationDocuments';
import RegisterEmployment from './Auth/RegistrationEmployment';
import RegisterStage from './Auth/RegistrationStage';
import Dashboards from './Dashboards';
import Borrower from './Borrower';

import { IdleTimerProvider } from 'react-idle-timer';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { fetchingUserLogOutDetailsSuccess } from 'redux/actions/investorAllDetail';
import { history } from 'redux/store';
import OnLogoutClick from '../@coremat/CmtNavigation/Vertical/NavMenuItem';
import ActivationPage from './Auth/Activation';
import RegisterBorrower from './Auth/RegisterBorrower';
import RegisterBasicBorrower from './Auth/RegistrationBasicBorrower';
import RegisterBorrowerBank from './Auth/RegistrationBorrowerBank';
import RegisterBorrowerBusiness from './Auth/RegistrationBorrowerBusiness';
import RegisterBorrowerDocument from './Auth/RegistrationBorrowerDocs';
import RegisterBorrowerFinance from './Auth/RegistrationBorrowerFinance';
import RegisterCompanyBorrower from './Auth/RegistrationCompanyBorrower';
import ResetPasswordPage from './Auth/ResetPassword';
import TourGuide from './TourGuide';

// import LayoutBuilder from './LayoutBuilder';
const RestrictedRoute = ({ component: Component, ...rest }) => {
  // iidle session time out
  const dispatch = useDispatch();
  const [isTimedOut, setIsTimedOut] = useState(false);
  const idleTimer = useRef();

  const onAction = () => {
    setIsTimedOut(false);
  };

  const onActive = e => {
    setIsTimedOut(false);
  };

  let languageCheck = localStorage.getItem('languageCheck');
  if (!languageCheck) {
    languageCheck = 'en';
  }

  const onIdle = e => {
    // alert('timeout');
    try {
      if (!isTimedOut) {
        OnLogoutClick(dispatch);
        // dispatch(fetchingUserLogOutDetailsSuccess(history));
        // // localStorage.clear();
        // localStorage.removeItem('userDetails');
        // localStorage.removeItem('investorAllDetail');
        // localStorage.removeItem('dash');
        // localStorage.removeItem('pr');
        // localStorage.removeItem('tp');
        // localStorage.removeItem('of');
        // window.open('/signin?lang=' + `${languageCheck}`, '_self');
        // idleTimer.current.reset();
        // setIsTimedOut(true);
        return;
      }
    } catch (error) {
      if (!isTimedOut) {
        dispatch(fetchingUserLogOutDetailsSuccess(history));
        // localStorage.clear();
        localStorage.removeItem('userDetails');
        localStorage.removeItem('investorAllDetail');
        localStorage.removeItem('dash');
        localStorage.removeItem('pr');
        localStorage.removeItem('tp');
        localStorage.removeItem('of');
        localStorage.removeItem('loanUuid');
        window.open('/signin?lang=' + `${languageCheck}`, '_self');
        idleTimer.current.reset();
        setIsTimedOut(true);
        return;
      }
    }
  };

  // let userDetail = useSelector(state => state.loginReducer.userDetail);
  let data = localStorage.getItem('userDetails');
  let userDetail = JSON.parse(data);
  if (!userDetail) {
    // window.open('/signin', '_self');
    window.open('/signin?lang=' + `${languageCheck}`, '_self');
    return;
  }
  return (
    <>
      <IdleTimerProvider
        ref={idleTimer}
        element={document}
        onActive={onActive}
        onIdle={onIdle}
        onAction={onAction}
        debounce={250}
        timeout={5 * 60 * 1000}
        // timeout={5000}
      />
      <Route
        {...rest}
        render={props =>
          userDetail.code == '200' || userDetail.message == null ? (
            <Component {...props} />
          ) : (
            <Redirect
              to={{
                pathname: '/signin',
                state: { from: props.location },
              }}
            />
          )
        }
      />
    </>
  );
};
const Routes = props => {
  const location = useLocation();
  let data = localStorage.getItem('userDetails');
  let userDetail = JSON.parse(data);
  // console.log(location.pathname, 'pathname');
  if (location.pathname === '' || location.pathname === '/' || location.pathname.includes('raqamyah1')) {
    return (
      <Redirect
        to={{
          pathname: '/signin',
          state: { from: props.location },
        }}
      />
    );
  }
  // if (location.pathname === '' || location.pathname === '/' || location.pathname.includes('raqamyah')) {
  //   return <Redirect to={'/signin'} />;
  // } else if (authUser && location.pathname === '/signin') {
  //   return <Redirect to={'/signin'} />;
  // }

  return (
    <React.Fragment>
      {/* <BrowserRouter basename="/raqamyah1"> */}
      <Switch>
        {userDetail?.userType === 'INVESTOR' ? (
          <RestrictedRoute path="/investor" component={Dashboards} />
        ) : (
          <RestrictedRoute path="/borrower" component={Borrower} />
        )}

        <Route path="/signin" component={Login} />
        <Route path="/signup" component={Signup} />
        <Route path="/signuptwo" component={RegisterBasic} />
        <Route path="/signupthree" component={RegisterEmployment} />
        <Route path="/signupfour" component={RegisterBank} />
        <Route path="/signupfive" component={RegisterDocument} />
        <Route path="/signupborrower" component={RegisterBorrower} />
        <Route path="/signupstage" component={RegisterStage} />
        <Route path="/signupcompany" component={RegisterCompany} />
        <Route path="/signupborrowerthree" component={RegisterCompanyBorrower} />
        <Route path="/forgot-password" component={ForgotPassword} />
        <Route path="/activation" component={ActivationPage} />
        <Route path="/reset-password" component={ResetPasswordPage} />
        <Route path="/signupborrowertwo" component={RegisterBasicBorrower} />
        <Route path="/signupborrowerfour" component={RegisterBorrowerBusiness} />
        <Route path="/signupborrowerfive" component={RegisterBorrowerBank} />
        <Route path="/signupborrowersix" component={RegisterBorrowerFinance} />
        <Route path="/signupborrowerseven" component={RegisterBorrowerDocument} />
        {/*<Route path="/layout-builder" component={LayoutBuilder} />*/}
      </Switch>
      {/* </BrowserRouter> */}
      {location.pathname !== '/signin' &&
        location.pathname !== '/signup' &&
        location.pathname !== '/forgot-password' &&
        location.pathname !== '/reset-password' && <TourGuide />}
    </React.Fragment>
  );
};
export default Routes;
