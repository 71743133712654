import React, { useContext } from 'react';
import FooterLogo from './FooterLogo';
import { Box, Button, Grid, makeStyles } from '@material-ui/core';
import Hidden from '@material-ui/core/Hidden';
import AppContext from '../../contextProvider/AppContextProvider/AppContext';
import { THEME_TYPES } from '../../../constants/ThemeOptions';
import GridContainer from '../../GridContainer';
import IntlMessages from '../../../utils/IntlMessages';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  btnRoot: {
    [theme.breakpoints.down('xs')]: {
      padding: '6px 12px',
      fontSize: 11,
    },
  },
}));
const useStyles1 = makeStyles(theme => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  btnRoot: {
    [theme.breakpoints.down('xs')]: {
      padding: '6px 12px',
      fontSize: 11,
    },
  },
}));
const Footer = props => {
  const classes = useStyles();
  const classes1 = useStyles1();
  const { themeType } = useContext(AppContext);
  const date = new Date();
  let data = localStorage.getItem('userDetails');
  let userDetail = JSON.parse(data);
  let userType = String(userDetail?.userType).toLowerCase();
  return (
    <Box className={userType === 'investor' ? classes.root : classes1.root} {...props}>
      <Box display="flex" alignItems="center">
        <Hidden xsDown>
          <FooterLogo mr={5} color={themeType === THEME_TYPES.DARK ? 'white' : ''} />
        </Hidden>
        {userType === 'investor' ? (
          <GridContainer spacing={2}>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <Box
                style={{ textAlign: 'center', color: userType === 'investor' ? 'black' : 'white' }}
                fontSize={{ xs: 12, sm: 14 }}
                component="p">
                <IntlMessages id="dashboard.disclaimer" />
              </Box>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <Box style={{ color: 'black', textAlign: 'center' }}>
                <IntlMessages id="dashboard.copyright" /> © {date.getFullYear()} V-1.0.31
              </Box>
            </Grid>
          </GridContainer>
        ) : (
          <GridContainer spacing={2}>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <Box style={{ color: 'black', textAlign: 'center' }}>
                <IntlMessages id="dashboard.copyright" /> © {date.getFullYear()} V-1.0.31
              </Box>
            </Grid>
          </GridContainer>
        )}
      </Box>
      <Box display="flex" alignItems="center">
        <Hidden xsDown>
          <Box component="span" fontSize={16} fontWeight={700} color="primary.main" mr={5}></Box>
        </Hidden>
      </Box>
    </Box>
  );
};

export default Footer;
