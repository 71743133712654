import * as types from '../actionTypes/types';
const initialState = {
  financedAmount: {},
  OngoingFinanceRequest: [],
  sumCompleted: 0,
  completedSumCount: 0,
  isFetching: false,
  errorMessage: '',
};
const financedAmountReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.FETCHING_FINANCED_AMOUNT_REQUEST:
      return { ...state, isFetching: true };
    case types.FETCHING_FINANCED_AMOUNT_FAILED:
      return {
        ...state,
        isFetching: false,
        errorMessage: action.payload,
      };
    case types.FETCHING_FINANCED_AMOUNT_SUCCESS:
      return {
        ...state,
        isFetching: false,
        financedAmount: action.payload,
        OngoingFinanceRequest: action.OngoingFinanceRequest,
        sumCompleted: action.sumCompleted,
        completedSumCount: action.completedSumCount,
      };
    case types.FETCHING_USER_LOGOUT_DETAILS_SUCCESS:
      state = initialState;
      return state;
    default:
      return state;
  }
};
export default financedAmountReducer;
