import * as types from '../actionTypes/types';

const initialState = {
  registeredUserStepFour: {},
  isFetching: false,
  errorMessage: '',
};

const registrationStepFourBankReducer = (state = initialState, action) => {
  switch (action.type) {
      case types.FETCHING_REGIRSTRATION_STEP_FOUR_BANK_REQUEST:
          return { ...state, isFetching: true };
      case types.FETCHING_REGIRSTRATION_STEP_FOUR_BANK_FAILED:
          return {
              ...state,
              isFetching: false,
              errorMessage: action.payload,
          };
      case types.FETCHING_REGIRSTRATION_STEP_FOUR_BANK_SUCCESS:
          return {
              ...state,
              isFetching: false,
              registeredUserStepFour: action.payload,
          };

      case types.FETCHING_USER_LOGOUT_DETAILS_SUCCESS:
          state = initialState;
          return state;

      default:
          return state;
  }
};
export default registrationStepFourBankReducer;
