import * as types from '../actionTypes/types';
import { httpRequest } from '../NetworkConfig/Config';
import { Url, methods, headers } from '../NetworkConfig/ApiUrlConstatnts';

export const investorAllDetailFunction = access_token => {
  // console.log(access_token, 'tst');
  const formData = new URLSearchParams();
  formData.append('access_token', access_token);

  return async dispatch => {
    dispatch(fetchingInvestorAllDetailsRequest());
    return httpRequest({
      method: methods.post,
      url: Url.InvestorAllDetail,
      headers: headers.headurlencodedcontent,
      data: formData,
    }).then(JsonResponse => {
      // console.log(JsonResponse, 'dashboard');
      if (JsonResponse !== undefined) {
        if (JsonResponse.status == 200 || JsonResponse.status == 203) {
          let fetchedData =
            JsonResponse.data !== undefined && JsonResponse.data !== null && Object.keys(JsonResponse.data).length !== 0
              ? JsonResponse.data
              : {};
          dispatch(fetchingInvestorAllDetailsSuccess(fetchedData));
        } else {
          dispatch(fetchingInvestorAllDetailsFailed());
        }
        return JsonResponse;
      } else {
        dispatch(fetchingInvestorAllDetailsFailed());
      }
    });
  };
};
export const fetchingInvestorAllDetailsRequest = () => ({
  type: types.FETCHING_INVESTOR_ALL_DETAIL_REQUEST,
});
export const fetchingInvestorAllDetailsSuccess = json => ({
  type: types.FETCHING_INVESTOR_ALL_DETAIL_SUCCESS,
  payload: json,
});
export const fetchingInvestorAllDetailsFailed = error => ({
  type: types.FETCHING_INVESTOR_ALL_DETAIL_FAILED,
  payload: error,
});
export const fetchingUserLogOutDetailsSuccess = () => ({
  type: types.FETCHING_USER_LOGOUT_DETAILS_SUCCESS,
});
