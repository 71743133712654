import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import { useLocation, Link } from 'react-router-dom';
import { makeStyles, createTheme } from '@material-ui/core/styles';
import { Container, ListItem, List, Box ,Toolbar,Grid,Divider, CssBaseline} from '@material-ui/core';
import AppBar from '@material-ui/core/AppBar';
import IconButton from '@material-ui/core/IconButton';
import Hidden from '@material-ui/core/Hidden';
import Drawer from '@material-ui/core/Drawer';
// import HeaderLinks from "./HeaderLinks";
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import Logo from '../../components/Logo/logo';
import Menu from '@material-ui/icons/Menu';
import IntlMessages from '@jumbo/utils/IntlMessages';

let selected = localStorage.getItem('languageCheck')?.includes('en') ? 'en' : localStorage.getItem('languageCheck');
if (selected === undefined || selected === null) {
  selected = 'en';
}
let languageCheck = localStorage.getItem('languageCheck');
if (!languageCheck) {
  languageCheck = 'en';
}
const lang = languageCheck == 'en' ? 'en' : 'ar';
let langParam = '?lang=' + lang;
export const websiteUrl = 'https://raqamyah.com/' + languageCheck + '/';

const drawerWidth = 240;
const useStyles = makeStyles((theme) => ({
    buttonToolbar: {
        background: "linear-gradient(0.25turn, #28A4DB, #3AB15F) !important",
        minHeight: "40px !important",
        display: 'flex',
        justifyContent: 'end'
    },
    buttonToolbarBox: {
        display: 'flex',
        padding: '0px !important',
        justifyContent: 'end'
    },
    scmButtonToolbarBox:{
        display: 'flex',
        padding: '0px !important',
    },
    menuToolbar: {
        backgroundColor: "#fff !important",
        minHeight: "70px !important",
        // paddingLeft: "0px",
        // paddingRight: "0px",
        paddingTop: '1px',
        paddingBottom: '1px',
        width: "100%", // Set width to 100% for full width
        [theme.breakpoints.up("xl")]: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            margin: '0 auto', // Center the header horizontally (optional if you want it centered)
        },
        [theme.breakpoints.down("sm")]: {
            paddingLeft: "0px",
            paddingRight: "0px",
        }
    },
    menuContainer: {
        display: 'flex',
        alignItems:'center',
        padding: '0px',
        [theme.breakpoints.down("lg")]: {
            // paddingLeft: "50px",
            // paddingRight: "50px",
        }
    },
    buttonContainer: {
        display: 'inline-flex',
        justifyContent: selected === 'en' ? 'end' : 'end',
        padding: '0px !important',
    
    },
    MenuIcons: {
        display: "none",
        [theme.breakpoints.down("lg")]: {
            display: "flex",
            color: "#37A753",
            width: "25px",
            height: "25px",
        },
        [theme.breakpoints.down("md")]: {
            display: "flex",
            color: "#37A753",
            width: "25px",
            height: "25px",
        },
        [theme.breakpoints.down("sm")]: {
            display: "flex",
            color: "#37A753",
            width: "25px",
            height: "25px",
        },
    },
    listItem: {
        float: selected === "en" ? "left" : "right",
        color: "inherit",
        position: "relative",
        display: "flex",
        width: "auto",
        padding: "5px",
        [theme.breakpoints.down("lg")]: {
            width: "100%",
            display: "flex",
            paddingRight: "10px",
            paddingLeft: "10px",
        },
        [theme.breakpoints.down("md")]: {
            width: "100%",
            display: "flex",
            paddingRight: "10px",
            paddingLeft: "10px",
        },

        [theme.breakpoints.down("sm")]: {
            width: "100%",
            display: "flex",
            paddingRight: "10px",
            paddingLeft: "10px",
        },
    },
    list: {
        position: 'relative',
        //   right: locale === "en" ? "0px" : "150px",
        //   left: locale === "en" ? "0px" : "150px",
        fontSize: "14px",
        marginLeft: "10px",
        paddingLeft: "0",
        listStyle: "none",
        color: "inherit",
        display: "flex !important",
        [theme.breakpoints.down("lg")]: {
          position: 'relative',
          //   right: locale === "en" ? "0px" : "150px",
          //   left: locale === "en" ? "0px" : "150px",
          fontSize: "14px",
          marginLeft: "10px",
          paddingLeft: "0",
          listStyle: "none",
          color: "inherit",
          display: "flex !important",
        },
        [theme.breakpoints.down("md")]: {
            fontSize: "14px",
            marginLeft: "10px",
            paddingLeft: "0",
            listStyle: "none",
            color: "inherit",
            display: "block !important",
            position: 'relative',
            right: "0px",
            left: "0px",
        },
        [theme.breakpoints.down("sm")]: {
            fontSize: "14px",
            marginLeft: "10px",
            paddingLeft: "0",
            listStyle: "none",
            color: "inherit",
            display: "block !important",
            position: 'relative',
            right: "0px",
            left: "0px",
        },
    },
    divider: {
        width: "2px",
        content: '""',
        height: "35px",
        display: "block",
        marginTop: "0px",
        backgroundColor: "#28A4DB",
        [theme.breakpoints.down("lg")]: {
          width: "2px",
          content: '""',
          height: "35px",
          display: "block",
          marginTop: "0px",
          backgroundColor: "#28A4DB",
        },
        [theme.breakpoints.down("md")]: {
            display: "none",
        },
        [theme.breakpoints.down("sm")]: {
            display: "none",
        },
    },
    navLink: {
        color: "#555555",
        position: "relative",
        whiteSpace:'nowrap',
        padding: "0.5rem",
        fontWeight: "400",
        fontSize: "16px",
        marginLeft: "4px",
        marginRight: "4px",
        textTransform: "uppercase",
        lineHeight: "20px",
        textDecoration: "none",
        display: "inline-flex",
        "&:hover,&:focus": {
            color: "#555555",
            backgroundColor: "transparent",
            textDecoration: "#28A4DB",
        },
        [theme.breakpoints.down("lg")]: {
            width: "calc(100% - 30px)",
            marginBottom: "8px",
            marginTop: "8px",
            textAlign: "left",
            "& > span:first-child": {
                justifyContent: "flex-start",
            },
        },
        [theme.breakpoints.down("md")]: {
            width: "calc(100% - 30px)",
            marginBottom: "8px",
            marginTop: "8px",
            textAlign: "left",
            "& > span:first-child": {
                justifyContent: "flex-start",
            },
        },
        [theme.breakpoints.down("sm")]: {
            width: "calc(100% - 30px)",
            marginBottom: "8px",
            marginTop: "8px",
            textAlign: "left",
            "& > span:first-child": {
                justifyContent: "flex-start",
            },
        },
    },
    drawerButton: {
        color: '#000 !important',
    },
    drawerBox: {
        display: "flex",
        flexDirection: 'row',
        justifyContent: 'end',
        alignItems: 'center',
        [theme.breakpoints.down("lg")]: {
            position: 'relative',
            left: '80%',
            right: '80%',
        },
        [theme.breakpoints.down("md")]: {
            position: 'relative',
            left: '70%',
            right: '70%',
        },
        [theme.breakpoints.down("sm")]: {
            position: 'relative',
            left: '50%',
            right: '50%',
        },
        [theme.breakpoints.down("xs")]: {
            position: 'relative',
            left: '30%',
            right: '30%',
        },
    },

}));

function Header(props) {
  
    const getFinanceIcon = "https://d1rfd0ppcouvna.cloudfront.net/public/assetsNew/images/MenuIcons/getFinacneIcons.webp";
    const financeIcon = "https://d1rfd0ppcouvna.cloudfront.net/public/assetsNew/images/MenuIcons/financeIcons.webp";
    const posFinanceIcon ="https://d1rfd0ppcouvna.cloudfront.net/public/assetsNew/images/MenuIcons/posFinanceIcons.webp";
    const howItWorksIcon ="https://d1rfd0ppcouvna.cloudfront.net/public/assetsNew/images/MenuIcons/howItWorksIcons.webp";
    const aboutIcon ="https://d1rfd0ppcouvna.cloudfront.net/public/assetsNew/images/MenuIcons/aboutIcon.webp";
    const contactIcon ="https://d1rfd0ppcouvna.cloudfront.net/public/assetsNew/images/MenuIcons/contactIcons.webp";
    const SmesIcon ="https://d1rfd0ppcouvna.cloudfront.net/public/assetsNew/images/MenuIcons/balance.webp";
    const supplyChain= "https://d1rfd0ppcouvna.cloudfront.net/public/assetsNew/images/MenuIcons/production.webp";
    const classes = useStyles();
    const { window } = props;
    const [mobileOpen, setMobileOpen] = React.useState(false);
    const location = useLocation();
    const { t } = useTranslation('common');
    const [isLoading, setIsLoading] = React.useState(true);
    const headerContent = [
        {
            menu: '1',
            link: websiteUrl + `getFinanced`,
            pageName: selected === 'en' ? 'Get Financed' : "تقدم بطلب تمويل",
            icon: <img width={25} height={25} alt="getFinanceIcon" src={getFinanceIcon} />,
        },
        {
            menu: '2',
            link: websiteUrl + `finance`,
            pageName: selected === 'en' ? 'Finance' : "ابدأ بالتمويل",
            icon: <img width={25} height={25} alt="financeIcon" src={financeIcon} />,
        },
        {
            menu: '3',
            link: websiteUrl + `posFinance`,
            pageName: selected === 'en' ? 'Pos Finance' : "تمويل نقاط البيع",
            icon: <img width={25} height={25} alt="posFinanceIcon" src={posFinanceIcon} />,
        },
        {
            menu: '4',
            link: websiteUrl + `smes`,
            pageName: selected === 'en' ? 'Financing Rates' : "أسعار المنتجات",
            icon: <img width={25} height={25} alt="Supplychain" src={SmesIcon} />,
        },
        {
            menu: '5',
            link: websiteUrl + `supplyChain`,
            pageName: selected === 'en' ? 'Supply Chain' : "سلاسل الامداد",
            icon: <img width={25} height={25} alt="supplyChain" src={supplyChain} />,
        },
        {
            menu: '5',
            link: websiteUrl + `howItWorks`,
            pageName: selected === 'en' ? 'How It Works' : "كيف تعمل المنصة",
            icon: <img width={25} height={25} alt="howItWorksIcon" src={howItWorksIcon} />,
        },
        {
            menu: '6',
            link: websiteUrl + `about`,
            pageName: selected === 'en' ? 'About' : "عن رقمية",
            icon: <img width={25} height={25} alt="aboutIcon" src={aboutIcon} />,
        },
        {
            menu: '7',
            link: websiteUrl + `contactUs`,
            pageName: selected === 'en' ? 'Contact Us' : "تواصل معنا",
            icon: <img width={25} height={25} alt="contactIcon" src={contactIcon} />,
        },
    ];
    const handleDrawerToggle = () => {
        setMobileOpen((prevState) => !prevState);
    };

    const drawer = (
        <Box onClick={handleDrawerToggle} sx={{ textAlign: 'center' }}>
            <Box sx={{ my: 2 }}>
                <Logo /></Box>
            <Divider />

            <List variant="ul" className={classes.list} >
                {headerContent.map((item, index) => (
                    <div key={index}>
                        <ListItem variant="li" className={classes.listItem}>
                            <span className={classes.MenuIcons}>{item.icon}</span>
                            <a className={classes.navLink} href={item.link} style={{ borderBottom: location.pathname.includes(item.link) && "2px solid #3AB15F", borderRadius: "none !important", width: "auto", }} key={index}>
                                {t(`${item.pageName}`)}
                            </a>
                        </ListItem>

                        {index !== headerContent.length - 1 && (
                            <ListItem variant="li" className={classes.listItem}>
                                <div className={classes.divider} />
                            </ListItem>
                        )}
                    </div>
                ))}
            </List>
        </Box>
    );

    const container = window !== undefined ? () => window().document.body : undefined;

    return (
        <Box sx={{ display: 'flex' }}>
            <CssBaseline />
            <AppBar component="nav">
 
                <Toolbar className={classes.menuToolbar}>
                    <Container  className={classes.menuContainer}>
                        <Box sx={{ flexGrow: 1, display: { sm: 'block' } }}>
                            <Logo />
                        </Box>
                        
                        <Hidden lgUp implementation="css">
                        <IconButton
                            color="#000"
                            aria-label="open drawer"
                            edge="start"
                            onClick={handleDrawerToggle}
                            sx={{ margin:'0px 16px 0px 16px', display: { lg: 'none' } }}>
                            <Menu  style={{ margin:'0px 16px 0px 16px', color:"#000",fontSize:'30px' }}/>
                        </IconButton>
                        </Hidden>
                    

                        <Hidden mdDown implementation="css">
                            <Box >
                                <List variant="ul" className={classes.list}>
                                    {headerContent.map((item, index) => (
                                        <div key={index}>
                                            <ListItem variant="li" className={classes.listItem}>
                                                <a className={classes.navLink} href={item.link} style={{ borderBottom: location.pathname.includes(item.link) && "2px solid #3AB15F", borderRadius: "none !important", width: "auto", }} key={item.menu} >
                                                    {t(`${item.pageName}`)}
                                                </a>
                                                {index !== headerContent.length - 1 && (
                                                    <div className={classes.divider} />
                                                )}
                                            </ListItem>
                                        </div>
                                    ))}
                                </List>
                            </Box>
                        </Hidden>
                    </Container>
                </Toolbar>
            </AppBar>
            <Hidden lgUp implementation="js">
                <Box component="nav">
                    <Drawer
                        container={container}
                        variant="temporary"
                        open={mobileOpen}
                        onClose={handleDrawerToggle}
                        ModalProps={{
                            keepMounted: true, // Better open performance on mobile.
                        }}
                        sx={{
                            display: { xs: 'block', lg: 'none' },
                            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
                        }}
                    >
                        {drawer}
                    </Drawer>
                </Box>
            </Hidden>
        </Box>
    );
}

Header.propTypes = {
    window: PropTypes.func,
};

export default Header;
