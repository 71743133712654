import * as types from '../actionTypes/types';
import { httpRequest } from '../NetworkConfig/Config';
import { Url, methods, headers } from '../NetworkConfig/ApiUrlConstatnts';
export const registrationStepOneBasicFunction = inpuData => {
  // console.log(inpuData, 'werew');
  return async dispatch => {
    dispatch(fetchingRegistrationStepOneBasicRequest());
    return httpRequest({
      method: methods.post,
      url: Url.RegistrationStepOneBasic,
      headers: headers.headFinancing,
      data: JSON.stringify(inpuData),
    }).then(JsonResponse => {
      if (JsonResponse != undefined) {
        if (JsonResponse.status == 200 || JsonResponse.status == 203) {
          let fetchedData =
            JsonResponse.data != undefined && JsonResponse.data != null && Object.keys(JsonResponse.data).length != 0
              ? JsonResponse.data
              : {};
          dispatch(fetchingRegistrationStepOneBasicSuccess(fetchedData));
        } else {
          dispatch(fetchingRegistrationStepOneBasicFailed());
        }
        return JsonResponse;
      } else {
        dispatch(fetchingRegistrationStepOneBasicFailed());
      }
    });
  };
};
export const fetchingRegistrationStepOneBasicRequest = () => ({
  type: types.FETCHING_REGIRSTRATION_STEP_ONE_BASIC_REQUEST,
});
export const fetchingRegistrationStepOneBasicSuccess = json => ({
  type: types.FETCHING_REGIRSTRATION_STEP_ONE_BASIC_SUCCESS,
  payload: json,
});
export const fetchingRegistrationStepOneBasicFailed = error => ({
  type: types.FETCHING_REGIRSTRATION_STEP_ONE_BASIC_FAILED,
  payload: error,
});
export const fetchingUserLogOutDetailsSuccess = () => ({
  type: types.FETCHING_USER_LOGOUT_DETAILS_SUCCESS,
});
