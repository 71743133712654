import * as types from '../actionTypes/types';
const initialState = {
    financingCreditability: {},
    isFetching: false,
    errorMessage: '',
};
const financingCreditabilityReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.FETCHING_FINANCING_CREDITABILITY_REQUEST:
            return { ...state, isFetching: true };
        case types.FETCHING_FINANCING_CREDITABILITY_FAILED:
            return {
                ...state,
                isFetching: false,
                errorMessage: action.payload,
            };
        case types.FETCHING_FINANCING_CREDITABILITY_SUCCESS:
            return {
                ...state,
                isFetching: false,
                financingCreditability: action.payload,
            };
        case types.FETCHING_USER_LOGOUT_DETAILS_SUCCESS:
            state = initialState;
            return state;
        default:
            return state;
    }
};
export default financingCreditabilityReducer;
