import React, { lazy, Suspense, useEffect, useRef } from 'react';
import { Redirect, Route, Switch } from 'react-router';
import PageLoader from '../../@jumbo/components/PageComponents/PageLoader';
export const useMounted = () => {
  const mounted = useRef(false);

  useEffect(() => {
    mounted.current = true;
    return () => {
      mounted.current = false;
    };
  }, []);

  return () => mounted.current;
};
const Dashboards = ({ match }) => {
  const requestedUrl = match.url.replace(/\/$/, '');
  // SignupBorrowerFinance;

  return (
    <Suspense fallback={<PageLoader />}>
      <Switch>
        <Redirect exact from={`${requestedUrl}/`} to={`${requestedUrl}/Dashboard`} />

        <Route path={`${requestedUrl}/Dashboard`} component={lazy(() => import('./Dashboard'))} />
        <Route path={`${requestedUrl}/MyFinanceRequest`} component={lazy(() => import('./MyFinanceRequest'))} />
        <Route
          path={`${requestedUrl}/applyforfinance/:loanUuid?/:purpose?/:kind?/:tenure?/:loanAmountQ?/:interestRate?/:flag?`}
          component={lazy(() => import('./ApplyForFinance'))}
        />
        <Route path={`${requestedUrl}/accountDetail`} component={lazy(() => import('./AccountDetail'))} />
        <Route path={`${requestedUrl}/changePassword`} component={lazy(() => import('./PasswordReset'))} />
        <Route path={`${requestedUrl}/LoanDetail/:id`} component={lazy(() => import('./LoanDetail'))} />
        <Route path={`${requestedUrl}/recentTransactions`} component={lazy(() => import('./RecentTransactions'))} />
        <Route path={`${requestedUrl}/sessionExpiry`} component={lazy(() => import('./SessionExpiry'))} />
        <Route component={lazy(() => import('../ExtraPages/404'))} />
      </Switch>
    </Suspense>
  );
};

export default Dashboards;
