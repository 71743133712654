import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';

import Common from './Common';
import Dashboard from './Dashboard';
import TaskList from './TaskList';
import MailApp from './MailApp';
import Chat from './Chat';
import ContactApp from './ContactApp';
import ProfileApp from './ProfileApp';
import WallApp from './WallApp';
import Auth from './Auth';
import allFinanceOfferReducer from './allFinanceOfferReducer';
import investorAllDetailReducer from './investorAllDetailReducer';
import tagNameReducer from './tagNameReducer';
import loanDetailIndividualreducer from './loanDetailIndividualreducer';
import allOffersReducer from './allOffersReducer';
import financedAmountReducer from './financedAmountReducer';
import transactionsReducer from './transactionsReducer';
import receivablesReducer from './receivablesReducer';
import financingCriteriaReducer from './financingCriteriaReducer';
import withdrawWalletBalanceReducer from './withdrawWalletBalanceReducer';
import changePasswordReducer from './changePasswordReducer';
import sendOtpMobileReducer from './sendOtpMobileReducer';
import emailActivationLInkSendReducer from './emailActivationLInkSendReducer';
import fundLoanApplicationReducer from './fundLoanApplicationReducer';
import addFinancingCriteriaReducer from './addFinancingCriteriaReducer';
import registrationStepOneBasicReducer from './registrationStepOneBasicReducer';
import registrationStepTwoIndividualReducer from './registrationStepTwoIndividualReducer';
import registrationStepThreeEmployeementReducer from './registrationStepThreeEmployeementReducer';
import registrationStepFourBankReducer from './registrationStepFourBankReducer';
import registrationStepFiveDeclarationReducer from './registrationStepFiveDeclarationReducer';
import declarationFileUploadReducer from './declarationFileUploadReducer';
import loginReducer from './loginReducer';
import sectorListReducer from './sectorListReducer';
import otpVerificationReducer from './otpVerificationReducer';
import financingCreditabilityReducer from './financingCreditabilityReducer';
import investorStatusDashboardReducer from './investorStatusDashboardReducer';
import financingCompanyReducer from './financingCompanyReducer';
import addCriteriaOtpReducer from './addCriteriaOtpReducer';
import updateCriteriaOtpReducer from './updateCriteriaOtpReducer';
import employmentDetailReducer from './employmentDetailReducer';
import investorMandatoryFiledsReducer from './investorMandatoryFiledsReducer';
import bankDetailsReducer from './bankDetailsReducer';
import getAlienInfoReducer from './getAlienInfoReducer';
import rayahSendOtpReducer from './rayahOtpSendReducer';
import loanPublishingSoonReducer from './loanPublishingSoonReducer';
import registrationBorrowerStepOneBasicReducer from './registrationBorrowerStepOneBasicReducer';
import registrationBorrowerStepTwoReducer from './registrationBorrowerStepTwoReducer';
import repaymentDetailReducer from './repaymentDetailReducer';
import declarationFileUploadBorrowerReducer from './declarationFileUploadBorrowerReducer';
import recentlyFullyFundedReducer from './recentlyFullyFundedReducer';
import profileUpdateReducer from './profileUpdateReducer';
import statusReducer from './statusReducer';
import logoutReducer from './logoutReducer';
import nafathIdVerificationReducer from './nafathIdVerificationReducer';
import statusDetailsReducer from './statusDetailsReducer';
import BorrowerAllDetailReducer from './borrower/BorrowerAllDetailReducer';
import BorrowerAnswerReducer from './borrower/BorrowerAnswerReducer';
import BorrowerAppliedLoansReducer from './borrower/BorrowerAppliedLoansReducer';
import BorrowerApplyLoanReducer from './borrower/BorrowerApplyLoanReducer';
import BorrowerDashReducer from './borrower/BorrowerDashReducer';
import BorrowerDocumentReducer from './borrower/BorrowerDocumentReducer';
import BorrowerFundingReducer from './borrower/BorrowerFundingReducer';
import BorrowerLoanApplicationDetailsReducer from './borrower/BorrowerLoanApplicationDetailsReducer';
import BorrowerLoanApplicationWithdrawReducer from './borrower/BorrowerLoanApplicationWithdrawReducer';
import BorrowerQuestionAddReducer from './borrower/BorrowerQuestionAddReducer';
import BorrowerTransactionsReducer from './borrower/BorrowerTransactionsReducer';
import BorrowerFinancedAmount from './borrower/BorrowerFinancedAmount';
export default history =>
  combineReducers({
    router: connectRouter(history),
    common: Common,
    taskList: TaskList,
    dashboard: Dashboard,
    mailApp: MailApp,
    chat: Chat,
    auth: Auth,
    contactApp: ContactApp,
    profileApp: ProfileApp,
    wallApp: WallApp,

    investorAllDetailReducer: investorAllDetailReducer,
    tagNameReducer: tagNameReducer,
    financingCompanyReducer: financingCompanyReducer,
    loanDetailIndividualreducer: loanDetailIndividualreducer,
    allOffersReducer: allOffersReducer,
    financedAmountReducer: financedAmountReducer,
    transactionsReducer: transactionsReducer,
    receivablesReducer: receivablesReducer,
    financingCriteriaReducer: financingCriteriaReducer,
    allFinanceOfferReducer: allFinanceOfferReducer,
    loginReducer: loginReducer,
    withdrawWalletBalanceReducer: withdrawWalletBalanceReducer,
    changePasswordReducer: changePasswordReducer,
    sendOtpMobileReducer: sendOtpMobileReducer,
    emailActivationLInkSendReducer: emailActivationLInkSendReducer,
    fundLoanApplicationReducer: fundLoanApplicationReducer,
    addFinancingCriteriaReducer: addFinancingCriteriaReducer,
    registrationStepOneBasicReducer: registrationStepOneBasicReducer,
    registrationStepTwoIndividualReducer: registrationStepTwoIndividualReducer,
    registrationStepThreeEmployeementReducer: registrationStepThreeEmployeementReducer,
    registrationStepFourBankReducer: registrationStepFourBankReducer,
    registrationStepFiveDeclarationReducer: registrationStepFiveDeclarationReducer,
    declarationFileUploadReducer: declarationFileUploadReducer,
    otpVerificationReducer: otpVerificationReducer,
    sectorListReducer: sectorListReducer,
    financingCreditabilityReducer: financingCreditabilityReducer,
    addCriteriaOtpReducer: addCriteriaOtpReducer,
    updateCriteriaOtpReducer: updateCriteriaOtpReducer,
    investorStatusDashboardReducer: investorStatusDashboardReducer,
    employmentDetailReducer: employmentDetailReducer,
    investorMandatoryFiledsReducer: investorMandatoryFiledsReducer,
    bankDetailsReducer: bankDetailsReducer,
    getAlienInfoReducer: getAlienInfoReducer,
    rayahSendOtpReducer: rayahSendOtpReducer,
    loanPublishingSoonReducer: loanPublishingSoonReducer,
    registrationBorrowerStepOneBasicReducer: registrationBorrowerStepOneBasicReducer,
    registrationBorrowerStepTwoReducer: registrationBorrowerStepTwoReducer,
    repaymentDetailReducer: repaymentDetailReducer,
    declarationFileUploadBorrowerReducer: declarationFileUploadBorrowerReducer,
    recentlyFullyFundedReducer: recentlyFullyFundedReducer,
    profileUpdateReducer: profileUpdateReducer,
    statusReducer: statusReducer,
    logoutReducer: logoutReducer,
    nafathIdVerificationReducer: nafathIdVerificationReducer,
    statusDetailsReducer: statusDetailsReducer,
    // borrower
    BorrowerAllDetailReducer: BorrowerAllDetailReducer,
    BorrowerAnswerReducer: BorrowerAnswerReducer,
    BorrowerAppliedLoansReducer: BorrowerAppliedLoansReducer,
    BorrowerApplyLoanReducer: BorrowerApplyLoanReducer,
    BorrowerDashReducer: BorrowerDashReducer,
    BorrowerDocumentReducer: BorrowerDocumentReducer,
    BorrowerFundingReducer: BorrowerFundingReducer,
    BorrowerLoanApplicationDetailsReducer: BorrowerLoanApplicationDetailsReducer,
    BorrowerLoanApplicationWithdrawReducer: BorrowerLoanApplicationWithdrawReducer,
    BorrowerQuestionAddReducer: BorrowerQuestionAddReducer,
    BorrowerTransactionsReducer: BorrowerTransactionsReducer,
    BorrowerFinancedAmount: BorrowerFinancedAmount,
  });
