import moment from 'moment';

export const getTodayDate = (format = 'DD.MM.YYYY') => {
  return moment().format(format);
};

export const getYesterdayDate = () => {
  return moment()
    .subtract(1, 'day')
    .format('DD.MM.YYYY');
};

export const timeFromNow = date => {
  const timestamp = moment(date).format('X');
  const newDate = moment.unix(timestamp);
  return moment(newDate).fromNow();
};

export const isToday = date => {
  return moment().isSame(date, 'day');
};

export const getNewDate = (noOfDays, format = 'DD MMM, YYYY') => {
  return moment()
    .add(noOfDays, 'days')
    .format(format);
};

export const getDateElements = date => {
  const dateString = moment(date).format('dddd, MMMM DD YYYY, hh:mm A');
  const dateSections = dateString.split(',');
  const day = dateSections[0];
  const time = dateSections[2];
  const datePart = dateSections[1].trim().split(' ');
  return {
    day,
    time,
    date: {
      dateString: dateSections[1],
      month: datePart[0],
      date: datePart[1],
      year: datePart[2],
    },
  };
};

export const getTime = date => {
  const dateObj = moment(date, 'dddd, MMMM DD YYYY, hh:mm a');
  return moment(dateObj).format('LT');
};

export const isDatesSame = (dateA, dateB) => {
  return moment(dateA).isSame(dateB, 'day');
};

export const isDateAfter = date => {
  const todayDate = getTodayDate('dddd, MMMM DD YYYY, hh:mm a');
  return moment(todayDate).isAfter(date);
};

export const getDateinDesiredFormat = (date, format) => {
  return moment(date).format(format);
};

export const makeJSDateObject = date => {
  if (date) {
    return new Date(date.getTime());
  }
  return date;
};

/**
 * Get Formatted Date
 * @param date
 * @param format
 * @returns {string}
 */
export const getFormattedDate = (date, format = 'YYYY-MM-DD') => {
  if (moment(date, 'YYYY-MM-DD').isValid()) {
    return moment(date).format(format);
  }

  return '';
};

/**
 * Check Is dateTime of Tomorrow
 * @param inputDateTime
 * @returns {boolean}
 */
export const isTomorrow = inputDateTime => {
  const tomorrow = moment()
    .add(1, 'days')
    .format('YYYY-MM-DD');

  return moment(inputDateTime).isSame(tomorrow, 'day');
};

/**
 * Check Is dateTime of Yesterday
 * @param inputDateTime
 * @returns {boolean}
 */
export const isYesterday = inputDateTime => {
  const yesterday = moment()
    .subtract(1, 'days')
    .format('YYYY-MM-DD');

  return moment(inputDateTime).isSame(yesterday, 'day');
};

/**
 * Get Custom Date Time
 * @param value
 * @param unit
 * @param format
 * @returns {string}
 */
export const getCustomDateTime = (value = 0, unit = 'days', format = 'YYYY-MM-DD') => {
  if (value === 0) {
    return moment().format(format);
  } else {
    return moment()
      .add(value, unit)
      .format(format);
  }
};

export const getDateText = date => {
  if (isToday(date)) {
    return 'Today';
  } else if (isYesterday(date)) {
    return 'Yesterday';
  } else if (isTomorrow(date)) {
    return 'Tomorrow';
  } else {
    return date;
  }
};

export const getTimeDiffString = date => {
  const postDate = moment(date, 'ddd MMM DD YYYY kk:mm:ss Z');
  const currentDate = moment(new Date());
  const duration = moment.duration(currentDate.diff(postDate));
  const minutes = duration.asMinutes() | 0;
  const hours = duration.asHours() | 0;

  switch (true) {
    case minutes === 0:
      return 'Just now';
    case minutes < 60:
      return `${minutes} min`;
    case hours < 24:
      return `${hours} hours`;
    default:
      return postDate.format('DD MMM, YYYY');
  }
};
export const datePicker = {
  year: [
    { value: '1360' },
    { value: '1361' },
    { value: '1362' },
    { value: '1363' },
    { value: '1364' },
    { value: '1365' },
    { value: '1366' },
    { value: '1367' },
    { value: '1368' },
    { value: '1369' },
    { value: '1370' },
    { value: '1371' },
    { value: '1372' },
    { value: '1373' },
    { value: '1374' },
    { value: '1375' },
    { value: '1376' },
    { value: '1377' },
    { value: '1378' },
    { value: '1379' },
    { value: '1380' },
    { value: '1381' },
    { value: '1382' },
    { value: '1383' },
    { value: '1384' },
    { value: '1385' },
    { value: '1386' },
    { value: '1387' },
    { value: '1388' },
    { value: '1389' },
    { value: '1390' },
    { value: '1391' },
    { value: '1392' },
    { value: '1393' },
    { value: '1394' },
    { value: '1395' },
    { value: '1396' },
    { value: '1397' },
    { value: '1398' },
    { value: '1399' },
    { value: '1400' },
    { value: '1401' },
    { value: '1402' },
    { value: '1403' },
    { value: '1404' },
    { value: '1405' },
    { value: '1406' },
    { value: '1407' },
    { value: '1408' },
    { value: '1409' },
    { value: '1410' },
    { value: '1411' },
    { value: '1412' },
    { value: '1413' },
    { value: '1414' },
    { value: '1415' },
    { value: '1416' },
    { value: '1417' },
    { value: '1418' },
    { value: '1419' },
    { value: '1420' },
    { value: '1421' },
    { value: '1422' },
    { value: '1423' },
    { value: '1424' },
    { value: '1425' },
    { value: '1426' },
    { value: '1427' },
    { value: '1428' },
    { value: '1429' },
    { value: '1430' },
    { value: '1431' },
    { value: '1432' },
    { value: '1433' },
    { value: '1434' },
    { value: '1435' },
    { value: '1436' },
    { value: '1437' },
    { value: '1438' },
    { value: '1439' },
    { value: '1440' },
    { value: '1441' },
    { value: '1442' },
    { value: '1443' },
    { value: '1444' },
    { value: '1445' },
    { value: '1446' },
    { value: '1447' },
    { value: '1448' },
    { value: '1449' },
    { value: '1450' },
  ],
  yearg: [
    { value: '1960' },
    { value: '1961' },
    { value: '1962' },
    { value: '1963' },
    { value: '1964' },
    { value: '1965' },
    { value: '1966' },
    { value: '1967' },
    { value: '1968' },
    { value: '1969' },
    { value: '1970' },
    { value: '1971' },
    { value: '1972' },
    { value: '1973' },
    { value: '1974' },
    { value: '1975' },
    { value: '1976' },
    { value: '1977' },
    { value: '1978' },
    { value: '1979' },
    { value: '1980' },
    { value: '1981' },
    { value: '1982' },
    { value: '1983' },
    { value: '1984' },
    { value: '1985' },
    { value: '1986' },
    { value: '1987' },
    { value: '1988' },
    { value: '1989' },
    { value: '1990' },
    { value: '1991' },
    { value: '1992' },
    { value: '1993' },
    { value: '1994' },
    { value: '1995' },
    { value: '1996' },
    { value: '1997' },
    { value: '1998' },
    { value: '1999' },
    { value: '2000' },
    { value: '2001' },
    { value: '2002' },
    { value: '2003' },
    { value: '2004' },
    { value: '2005' },
    { value: '2006' },
    { value: '2007' },
    { value: '2008' },
    { value: '2009' },
    { value: '2010' },
    { value: '2011' },
    { value: '2012' },
    { value: '2013' },
    { value: '2014' },
    { value: '2015' },
    { value: '2016' },
    { value: '2017' },
    { value: '2018' },
    { value: '2019' },
    { value: '2020' },
    { value: '2021' },
    { value: '2022' },
    { value: '2023' },
    { value: '2024' },
    { value: '2025' },
    { value: '2026' },
    { value: '2027' },
    { value: '2028' },
    { value: '2029' },
    { value: '2030' },
  ],
  day: [
    { value: '01' },
    { value: '02' },
    { value: '03' },
    { value: '04' },
    { value: '05' },
    { value: '06' },
    { value: '07' },
    { value: '08' },
    { value: '09' },
    { value: '10' },
    { value: '11' },
    { value: '12' },
    { value: '13' },
    { value: '14' },
    { value: '15' },
    { value: '16' },
    { value: '17' },
    { value: '18' },
    { value: '19' },
    { value: '20' },
    { value: '21' },
    { value: '22' },
    { value: '23' },
    { value: '24' },
    { value: '25' },
    { value: '26' },
    { value: '27' },
    { value: '28' },
    { value: '29' },
    { value: '30' },
    { value: '31' },
  ],
  month: [
    { value: '01' },
    { value: '02' },
    { value: '03' },
    { value: '04' },
    { value: '05' },
    { value: '06' },
    { value: '07' },
    { value: '08' },
    { value: '09' },
    { value: '10' },
    { value: '11' },
    { value: '12' },
  ],
};
