import React, { useEffect } from 'react';
import SidebarToggleHandler from '../../../../../@coremat/CmtLayouts/Vertical/SidebarToggleHandler';
import Toolbar from '@material-ui/core/Toolbar';
import { Box, fade, Typography, useMediaQuery, useTheme } from '@material-ui/core';
import LanguageSwitcher from '../LanguageSwitcher';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Logo from '../Logo';
import { useDispatch, useSelector } from 'react-redux';
import { investorAllDetailFunction } from 'redux/actions/investorAllDetail';
import IntlMessages from '@jumbo/utils/IntlMessages';
import { borrowerDashFunction } from 'redux/actions/borrower/borrowerDash';
import { logoutFunction } from 'redux/actions/logoutFuntion';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    paddingLeft: 16,
    paddingRight: 16,
    minHeight: 64,
    [theme.breakpoints.up('md')]: {
      minHeight: 72,
    },
    [theme.breakpoints.up('md')]: {
      paddingLeft: 24,
      paddingRight: 24,
    },
  },
  searchRoot: {
    position: 'relative',
    width: 260,
    [theme.breakpoints.up('md')]: {
      width: 350,
    },
    '& .MuiSvgIcon-root': {
      position: 'absolute',
      left: 18,
      top: '50%',
      transform: 'translateY(-50%)',
      zIndex: 1,
    },
    '& .MuiInputBase-root': {
      width: '100%',
    },
    '& .MuiInputBase-input': {
      height: 48,
      borderRadius: 30,
      backgroundColor: fade(theme.palette.common.dark, 0.38),
      color: fade(theme.palette.common.white, 0.7),
      boxSizing: 'border-box',
      padding: '5px 15px 5px 50px',
      transition: 'all 0.3s ease',
      '&:focus': {
        backgroundColor: fade(theme.palette.common.dark, 0.58),
        color: fade(theme.palette.common.white, 0.7),
      },
    },
  },
  langRoot: {
    borderLeft: `solid 1px ${fade(theme.palette.common.dark, 0.15)}`,
    // minHeight: 72,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    paddingLeft: 8,
    paddingRight: 8,
    position: 'relative',
    [theme.breakpoints.down('sm')]: {
      minHeight: 64,
    },
  },
  iconBtn: {
    color: fade(theme.palette.common.white, 0.38),
    '&:hover, &:focus': {
      color: theme.palette.common.white,
    },
  },
}));

const Header = () => {
  const classes = useStyles();
  const theme = useTheme();
  let user = localStorage.getItem('userDetails');
  let userDetail = JSON.parse(user);

  let userType = String(userDetail?.userType).toLowerCase();
  const veryWideScreen = useMediaQuery(theme.breakpoints.up('md'), {
    noSsr: true,
  });
  let investorAllDetail = useSelector(state => state.investorAllDetailReducer.investorAllDetail);
  let languageCheck = localStorage.getItem('languageCheck');
  if (!languageCheck) {
    languageCheck = 'en';
  }

  const dispatch = useDispatch();

  useEffect(() => {
    try {
      const inputdata = userDetail.access_token;
      // console.log(inputdata, 'alldetailres');
      if (userType === 'borrower') {
        dispatch(borrowerDashFunction(inputdata)).then(res => {
          console.log(res, 'alldetailres');
          if (res == undefined) {
            dispatch(logoutFunction(inputdata));

            localStorage.setItem('sessionExpired', 'TRUE');
            localStorage.removeItem('userDetails');
            localStorage.removeItem('investorAllDetail');
            localStorage.removeItem('dash');
            localStorage.removeItem('pr');
            localStorage.removeItem('tp');
            localStorage.removeItem('of');
            localStorage.removeItem('loanUuid');
            window.open('/signin?lang=' + `${languageCheck}`, '_self');

            return;
          }
        });
      } else {
        dispatch(investorAllDetailFunction(inputdata)).then(res => {
          // console.log(res, 'alldetailres');
          if (res == undefined) {
            dispatch(logoutFunction(inputdata));

            localStorage.setItem('sessionExpired', 'TRUE');
            localStorage.removeItem('userDetails');
            localStorage.removeItem('investorAllDetail');
            localStorage.removeItem('dash');
            localStorage.removeItem('pr');
            localStorage.removeItem('tp');
            localStorage.removeItem('of');
            localStorage.removeItem('loanUuid');
            window.open('/signin?lang=' + `${languageCheck}`, '_self');
            return;
          }
        });
      }
    } catch (error) {
      console.log(error, '');
    }
  }, []);
  let borrowerAllDetail = useSelector(state => state.BorrowerDashReducer.borrowerDash);

  return (
    <>
      <Toolbar className={classes.root}>
        <SidebarToggleHandler edge="start" color="inherit" aria-label="menu" />
        <Logo ml={2} color="white" />
        <Box flex={1} />
        <Box style={{ paddingInline: '15px' }}>
          <Typography style={{ fontSize: veryWideScreen ? '14px' : '8px' }}>
            <IntlMessages id="dashboard.walletbalance" /> :{' '}
            {userType === 'borrower'
              ? borrowerAllDetail?.availableCash || 'SAR0.00'
              : investorAllDetail?.availableCash || 'SAR0.00'}
          </Typography>
          <Typography style={{ fontSize: veryWideScreen ? '14px' : '8px' }}>
            <IntlMessages id="dashboard.profilestatus" /> :{' '}
            {userType === 'borrower' ? borrowerAllDetail?.borrowerProfileStatus : investorAllDetail?.investorProfileStatus}
          </Typography>
        </Box>
        <Box className={classes.langRoot}>
          <LanguageSwitcher />
        </Box>
      </Toolbar>
    </>
  );
};

export default Header;
