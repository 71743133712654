import * as types from '../../actionTypes/types';

const initialState = {
  BorrowerAllDetail: {},
  isFetching: false,
  errorMessage: '',
};

const BorrowerAllDetailReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.FETCHING_BORROWER_ALL_DETAILS_REQUEST:
      return { ...state, isFetching: true };
    case types.FETCHING_BORROWER_ALL_DETAILS_FAILED:
      return {
        ...state,
        isFetching: false,
        errorMessage: action.payload,
      };
    case types.FETCHING_BORROWER_ALL_DETAILS_SUCCESS:
      return {
        ...state,
        isFetching: false,
        BorrowerAllDetail: action.payload,
      };

    case types.FETCHING_USER_LOGOUT_DETAILS_SUCCESS:
      state = initialState;
      return state;

    default:
      return state;
  }
};
export default BorrowerAllDetailReducer;
