import * as types from '../actionTypes/types';
import { httpRequest } from '../NetworkConfig/Config';
import { Url, methods, headers } from '../NetworkConfig/ApiUrlConstatnts';
export const investorMandatoryFunction = inputData => {
  const url = Url.baseUrl + Url.InvestorMandatory + `?language=${inputData}`;
  // console.log(url);
  return async dispatch => {
    dispatch(fetchingInvestorMandatoryRequest());
    return httpRequest({
      method: methods.get,
      url: url,
      headers: headers.headFinancing,
    }).then(JsonResponse => {
      if (JsonResponse != undefined) {
        if (JsonResponse.status == 200 || JsonResponse.status == 203) {
          let fetchedData =
            JsonResponse.data != undefined && JsonResponse.data != null && Object.keys(JsonResponse.data).length != 0
              ? JsonResponse.data
              : {};
          dispatch(fetchingInvestorMandatorySuccess(fetchedData));
        } else {
          dispatch(fetchingInvestorMandatoryFailed());
        }
        return JsonResponse;
      } else {
        dispatch(fetchingInvestorMandatoryFailed());
      }
    });
  };
};
export const fetchingInvestorMandatoryRequest = () => ({
  type: types.FETCHING_INVESTOR_MANDATORY_FILEDS_REQUEST,
});
export const fetchingInvestorMandatorySuccess = json => ({
  type: types.FETCHING_INVESTOR_MANDATORY_FILEDS_SUCCESS,
  payload: json,
});
export const fetchingInvestorMandatoryFailed = error => ({
  type: types.FETCHING_INVESTOR_MANDATORY_FILEDS_FAILED,
  payload: error,
});
export const fetchingUserLogOutDetailsSuccess = () => ({
  type: types.FETCHING_USER_LOGOUT_DETAILS_SUCCESS,
});
