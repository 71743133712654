import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Box,
  fade,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Snackbar,
  SnackbarContent,
} from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Typography from '@material-ui/core/Typography';
import { NavLink } from 'react-router-dom';
import { CurrentAuthMethod } from '@jumbo/constants/AppConstants';
import IntlMessages from '@jumbo/utils/IntlMessages';
import ContentLoader from '@jumbo/components/ContentLoader';
import AuthWrapper from '@jumbo/components/Common/authComponents/AuthWrapper';
import CmtImage from '@coremat/CmtImage';
import { AuhMethods } from 'services/auth';
import { history } from 'redux/store';
import Axios from 'axios';
import { headers, Url } from '../../../redux/NetworkConfig/ApiUrlConstatnts';
import { datePicker } from '../../../@jumbo/utils/dateHelper';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormLabel from '@material-ui/core/FormLabel';
import { citizenInfoInvestorFunction } from '../../../redux/actions/citizenInfoInvestor';
import { citiesListFunction } from '../../../redux/actions/citiesList';
import { Dialog, DialogActions, DialogContent, DialogContentText } from '@material-ui/core';
import { registrationBorrowerStepTwoFunction } from '../../../redux/actions/registrationBorrowerStepTwo';
import HijriDate, { toHijri } from 'hijri-date/lib/safe';
import Swal from 'sweetalert2';
import Loader from 'routes/Components/pageLoaderCustom/loader';
import { investorMandatoryFunction } from '../../../redux/actions/investorMandotaryFields';
import { GetAlienInfoFunction } from 'redux/actions/getAlienInfo';
import { rayahSendOtpFunction } from 'redux/actions/rayahOtpSend';
import { rayahVerifyOtpFunction } from 'redux/actions/rayahOtpVerify';
import { citizenAddressInfoInvestorFunction } from 'redux/actions/citizenAddressInfoInvestor';
import { alienAddressInfoFunction } from 'redux/actions/alienAddressInfo';
import { Alert } from '@material-ui/lab';
import GridContainer from '@jumbo/components/GridContainer';
import { borrowerMandatoryFunction } from 'redux/actions/borrowerMandatoryFields';
import { registrationBorrowerStepOneBasicFunction } from 'redux/actions/registrationBorrowerStepOneBasic';
import SnackBarComponent from 'routes/Components/SnackBar';
import PageLoader from '@jumbo/components/PageComponents/PageLoader';

const useStyles = makeStyles(theme => ({
  authThumb: {
    backgroundColor: fade(theme.palette.primary.main, 0.12),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: 20,
    [theme.breakpoints.up('md')]: {
      width: '50%',
      order: 2,
    },
  },
  authContent: {
    padding: 30,
    [theme.breakpoints.up('md')]: {
      width: props => (props.variant === 'default' ? '50%' : '100%'),
      order: 1,
    },
    [theme.breakpoints.up('xl')]: {
      padding: 50,
    },
  },
  titleRoot: {
    marginBottom: 14,
    color: theme.palette.text.primary,
  },
  title: {
    fontSize: 14,
  },
  textFieldRoot: {
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: fade(theme.palette.common.dark, 0.12),
    },
  },
  textCapital: {
    textTransform: 'capitalize',
  },
  textAcc: {
    textAlign: 'center',
    '& a': {
      marginLeft: 4,
    },
  },
  alrTextRoot: {
    textAlign: 'center',
    [theme.breakpoints.up('sm')]: {
      textAlign: 'right',
    },
  },
}));

//variant = 'default', 'standard', 'bgColor'
const SignUpTwoBorrower = ({ method = CurrentAuthMethod, variant = 'standard', wrapperVariant = 'default' }) => {
  const dispatch = useDispatch();
  const classes = useStyles({ variant });
  const [otpModal, setOtpModal] = useState(false);
  const [showsnakbar, setshowsnakbar] = useState(false);
  const [otp, setOtp] = useState('');
  const [iqama, setIqama] = useState('');
  const [dateType, setDateType] = useState('');
  const [nameArabic, setNameArabic] = useState('');
  const [nameEnglish, setNameEnglish] = useState('');
  const [dateDay, setDateDay] = useState('');
  const [dateMonth, setDateMonth] = useState('');
  const [dateYear, setDateYear] = useState('');
  const [nationality, setNationality] = useState('');
  const [lenderCategory, setLenderCategory] = useState('');
  const [expiryDate, setExpiryDate] = useState('');

  const [cityArray, setCityArray] = useState([]);
  const [status, setStatus] = useState('');
  const [message, setMessage] = useState('');
  const [code, setCode] = useState('');
  const [mandatoryFields, setmandatoryFields] = useState({});
  const [showFields, setShowFields] = useState(false);

  const [grossIncome, setgrossIncome] = useState('');
  const [monthlyEmiOnOutstandingLoans, setmonthlyEmiOnOutstandingLoans] = useState('');
  const [purposeOfOutStandingEmi, setpurposeOfOutStandingEmi] = useState('');
  const [jobType, setjobType] = useState('');
  const [workExperience, setworkExperience] = useState('');
  const [employerType, setemployerType] = useState('');
  const [educationType, seteducationType] = useState('');
  const [residenceType, setresidenceType] = useState('');
  const [residentOwnershipType, setresidentOwnershipType] = useState('');
  const [homeExpenses, sethomeExpenses] = useState('');
  const [amountToInvest, setamountToInvest] = useState('');
  const [investmentPurpose, setinvestmentPurpose] = useState('');
  const [interestRate, setinterestRate] = useState('');
  const [durationForInvest, setdurationForInvest] = useState('');
  const [employmentCategory, setemploymentCategory] = useState('');
  const [passportNumber, setpassportNumber] = useState('');
  const [transportationExpenses, settransportationExpenses] = useState('');
  const [utilitiesExpenses, setutilitiesExpenses] = useState('');
  const [entertainmentExpenses, setentertainmentExpenses] = useState('');
  const [insuranceExpenses, setinsuranceExpenses] = useState('');
  const [otherExpenses, setotherExpenses] = useState('');
  const [creditCardExpenses, setcreditCardExpenses] = useState('');
  const [houseLoanExpenses, sethouseLoanExpenses] = useState('');
  const [monthlyRentPayable, setmonthlyRentPayable] = useState('');
  const [monthlyRentIncome, setmonthlyRentIncome] = useState('');

  const [middleName, setmiddleName] = useState('');
  const [gender, setgender] = useState('');
  const [maritalStatus, setmaritalStatus] = useState('');
  const [mobileNumber, setmobileNumber] = useState('');
  const [mobileNumberCountryCode, setmobileNumberCountryCode] = useState('');

  const [investorProfileDateVariable1, setinvestorProfileDateVariable1] = useState('');
  const [investorProfileTextVariable2, setinvestorProfileTextVariable2] = useState('');
  const [investorCategory, setinvestorCategory] = useState('');
  const [showAlert, setShowAlert] = useState(false);
  const [citizenShipArray, setCitizenShipArray] = useState([]);
  const [dateInput, setdateInput] = useState();
  const [response, setResponse] = useState(false);
  const [responseMessage, setResponseMessage] = useState('');
  const [responseStatus, setResponseStatus] = useState('');
  const [showAll, setShowAll] = useState(false);
  const [showAllone, setShowAllone] = useState(false);
  const [isFetching, setisFetching] = React.useState(false);
  var momentHijri = require('moment-hijri');

  const setHijriOrGregorian = val => {
    const value = val.slice(0, 1);
    // console.log(value, 'datetype');
    if (value == 1) {
      setDateType('hijri');
    } else {
      setDateType('gregorian');
    }
  };
  const lenderCategoryArr = [
    {
      id: 15,
      name: 'Individual Financer / ممول فردي ',
      uuid: 'eeba1b3a-57cc-4656-88c8-df2cb9edf9c0',
      type: 'INDIVIDUAL',
    },
    {
      id: 16,
      name: 'Qualified Individual Financer / ممول فردي مؤهل',
      uuid: '72f981d0-b1b5-49ac-ab5e-b01faa651bc9',
      type: 'INDIVIDUAL',
    },
  ];
  const fetchCitizenship = async () => {
    const url = Url.baseUrl + Url.CitizenShip;

    Axios.post(url)
      .then(function(response) {
        // handle success
        setCitizenShipArray(response.data);
        // console.log(response.data);
      })
      .catch(function(error) {
        alert(error);
      });
  };
  let languageCheck = localStorage.getItem('languageCheck');
  if (!languageCheck) {
    languageCheck = 'en';
  }
  // const dataAppend = mandatoryFields => {
  //   let append = '';
  //   console.log(Object.keys(mandatoryFields).length);
  //   for (const property in mandatoryFields) {
  //     append +=
  //       `const [set${mandatoryFields[property].variableName}, setset${mandatoryFields[property].variableName}] = useState('');` +
  //       '\n';
  //   }
  //   console.log(append, 'append');
  //   // return append;
  // };
  useEffect(() => {
    dispatch(borrowerMandatoryFunction(languageCheck)).then(res => {
      setmandatoryFields(res?.data);
      //   dataAppend(res?.data);
      setShowFields(true);
      getStepTwoDetails();
    });
  }, [languageCheck]);
  useEffect(() => {
    fetchCitizenship();
    dispatch(citiesListFunction()).then(res => {
      setCityArray(res.data);
    });
  }, []);

  const [stepTwoDetail, setstepTwoDetail] = useState('');
  const [firstName, setfirstName] = useState(stepTwoDetail?.firstName ? stepTwoDetail?.firstName : '');
  const [line1, setLine1] = useState(stepTwoDetail?.line1 ? stepTwoDetail?.line1 : '');
  const [line2, setLine2] = useState(stepTwoDetail?.line2 ? stepTwoDetail?.line2 : '');
  const [line3, setLine3] = useState(stepTwoDetail?.line3 ? stepTwoDetail?.line3 : '');
  const [city, setCity] = useState(stepTwoDetail?.city ? stepTwoDetail?.city : '');
  const [zipCode, setZipCode] = useState(stepTwoDetail?.zipCode ? stepTwoDetail?.zipCode : '');
  const [companyIdentityNumberOne, setcompanyIdentityNumberOne] = useState(
    stepTwoDetail?.companyIdentityNumberOne ? stepTwoDetail?.companyIdentityNumberOne : '',
  );
  const [dateOfBirth, setdateOfBirth] = useState(stepTwoDetail?.dateOfBirth ? stepTwoDetail?.dateOfBirth : '');
  const [citizenship, setcitizenship] = useState(stepTwoDetail?.citizenship ? stepTwoDetail?.citizenship : '');
  const [companyFullName, setcompanyFullName] = useState(
    stepTwoDetail?.companyFullName ? stepTwoDetail?.companyFullName : '',
  );
  const [companyFullNameArabic, setcompanyFullNameArabic] = useState(
    stepTwoDetail?.companyFullNameArabic ? stepTwoDetail?.companyFullNameArabic : '',
  );
  const [fetchingUser, setFetchingUser] = useState(false);
  const [seconds, setSeconds] = useState(60);
  const [resendButton, setResendButton] = useState(true);
  const [resendButtonCount, setResendButtonCount] = useState(3);
  const [timeLeft, setTimeLeft] = useState(seconds);
  const [disableVerify, setDisableVerify] = useState(false);
  useEffect(() => {
    if (!otpModal) {
      return;
    }
    if (timeLeft == 0) {
      setResendButton(false);
    }
    // exit early when we reach 0
    if (!timeLeft) return;
    // save intervalId to clear the interval when the
    // component re-renders
    const intervalId = setInterval(() => {
      setTimeLeft(timeLeft - 1);
    }, 1000);
    // clear interval on re-render to avoid memory leaks
    return () => {
      clearInterval(intervalId);
    };
    // add timeLeft as a dependency to re-rerun the effect
    // when we update it
  }, [timeLeft, otpModal]);
  const refreshButton = () => {
    setTimeLeft(seconds);
    setResendButton(true);
    setResendButtonCount(resendButtonCount - 1);
    let newUser = localStorage.getItem('newUser');
    checkValidId(newUser);
  };
  const citizenInfo = async () => {
    let newUser = localStorage.getItem('newUser');
    const hijri = require('hijri');
    setFetchingUser(true);

    let dateInput = '';
    let dayGreg = dateYear + dateMonth + dateDay;
    let dateArabic = dateYear + dateMonth + dateDay;
    {
      dateType === 'hijri' ? (dateInput = dateArabic) : (dateInput = dayGreg);
    }

    let inputData = '';
    let url = '';

    if (dateType === 'hijri') {
      url = Url.baseUrl + Url.GetCitizenAddressInfo;
      inputData = {
        access_token: newUser,
        nin: companyIdentityNumberOne,
        dateOfBirthH: dateInput,
        addressLanguage: 'a',
      };
      // console.log(inputData, 'hijriaddress');
      dispatch(citizenAddressInfoInvestorFunction(inputData)).then(res => {
        // console.log(res.data, 'citizenaddress');
        if (res?.data?.Status === 0) {
          setFetchingUser(false);

          setResponseMessage(<IntlMessages id="input.error" />);
          setResponse(true);
          setResponseStatus(500);
          return;
        } else {
          let addressInfo = res.data;
          let Result = addressInfo?.Result;
          let Address = Result?.addressListList;
          let addressLine1 = Address[0]?.buildingNumber + ',' + Address[0]?.streetName;
          let addressLine2 = Address[0]?.unitNumber + '+' + Address[0].district;
          let city = Address[0]?.city;
          let zipCode = Address[0]?.postCode;
          setLine1(addressLine1);
          setLine2(addressLine2);
          setLine3(addressLine2);
          setCity(city);
          setZipCode(String(zipCode));
          setShowAll(true);
          setShowAllone(true);
        }
      });
    } else {
      url = Url.baseUrl + Url.GetAlienAddressInfo;
      inputData = {
        access_token: newUser,
        iqamaNumber: companyIdentityNumberOne,
        dateOfBirthG: dateInput,
        addressLanguage: 'e',
      };
      dispatch(alienAddressInfoFunction(inputData)).then(res => {
        // console.log(res.data, 'alienaddress');
        if (res?.data?.Status === 0) {
          setFetchingUser(false);

          setResponseMessage(<IntlMessages id="input.error" />);
          setResponse(true);
          setResponseStatus(500);
          return;
        } else {
          setFetchingUser(false);
          let addressInfo = res.data;
          let Result = addressInfo?.Result;
          let Address = Result?.addressListList;
          let addressLine1 = Address[0]?.buildingNumber + ',' + Address[0]?.streetName;
          let addressLine2 = Address[0]?.unitNumber + '+' + Address[0].district;
          let city = Address[0]?.city;
          let zipCode = Address[0]?.postCode;
          setLine1(addressLine1);
          setLine2(addressLine2);
          setLine3(addressLine2);
          setCity(city);
          setZipCode(String(zipCode));
          setShowAll(true);
          setShowAllone(true);
        }
      });
    }

    // console.log(inputData);

    const addressurl = url;
    // Axios.post(addressurl, JSON.stringify(inputData), headers.head)
    //   .then(async response => {
    //     // handle success
    //     console.log(response.data);
    //     return;
    //     let addressInfo = response.data;
    //     let Result = addressInfo?.Result;
    //     let Address = Result?.addressListList;
    //     let addressLine1 = Address[0]?.buildingNumber + ',' + Address[0]?.streetName;
    //     let addressLine2 = Address[0]?.unitNumber + '+' + Address[0].district;
    //     let city = Address[0]?.city;
    //     let zipCode = Address[0]?.postCode;
    //     setLine1(addressLine1);
    //     setLine2(addressLine2);
    //     setLine3('addressLine2');
    //     setCity(city);
    //     setZipCode(String(zipCode));
    //   })
    //   .catch(function(error) {
    //     // handle error
    //     alert(error);
    //   });
    let input = '';
    setFetchingUser(false);
    if (dateType === 'hijri') {
      input = {
        access_token: newUser,
        nin: companyIdentityNumberOne,
        dateOfBirthH: dateInput,
      };
      // console.log(input, 'hijriinput');
      dispatch(citizenInfoInvestorFunction(input)).then(res => {
        if (res?.data?.Status === 0) {
          setFetchingUser(false);

          setResponseMessage(<IntlMessages id="input.error" />);
          setResponse(true);
          setResponseStatus(500);
          return;
        } else {
          // console.log(res.data, 'citizeninfo');
          setExpiryDate(res?.data?.Result?.idExpiryDate);
          setNationality('113');
          setNameArabic(
            res?.data?.Result?.firstName + ' ' + res?.data?.Result?.familyName + ' ' + res?.data?.Result?.fatherName,
          );
          setNameEnglish(
            res?.data?.Result?.englishFirstName +
              ' ' +
              res?.data?.Result?.englishSecondName +
              ' ' +
              res?.data.Result?.englishLastName,
          );
        }
      });
    } else {
      input = {
        access_token: newUser,
        iqamaNumber: companyIdentityNumberOne,
        dateOfBirthG: dateInput,
      };
      dispatch(GetAlienInfoFunction(input)).then(res => {
        if (res?.data?.Status === 0) {
          setFetchingUser(false);

          setResponseMessage(<IntlMessages id="input.error" />);
          setResponse(true);
          setResponseStatus(500);
          return;
        } else {
          // console.log(res?.data?.Result?.iqamaExpiryDateG);
          setExpiryDate(res?.data?.Result?.iqamaExpiryDateG);
          setNameArabic(res?.data?.Result?.firstName + ' ' + res?.data?.Result?.lastName);
          setNationality(res?.data?.Result?.nationalityCode);
          setNameEnglish(
            res?.data?.Result?.englishFirstName +
              ' ' +
              res?.data?.Result?.englishSecondName +
              ' ' +
              res?.data?.Result?.englishLastName,
          );
        }
      });
    }
  };
  const onSubmit = () => {
    let dateInput = '';

    if (dateType === 'hijri') {
      let dateArabic = dateYear + '/' + dateMonth + '/' + dateDay;
      // console.log(dateArabic);
      let date = momentHijri(dateArabic, 'iYYYY/iMM/iDD').format('DD/MM/YYYY');

      dateInput = date;
    } else {
      let dateOther = dateDay + '/' + dateMonth + '/' + dateYear;
      dateInput = dateOther;
    }
    let idExpiryDate = '';
    if (dateType === 'hijri') {
      idExpiryDate = momentHijri(expiryDate, 'iDD/iMM/iYYYY').format('DD/MM/YYYY');
    } else {
      idExpiryDate = expiryDate;
    }
    let inputData = {
      companyFullName: nameEnglish,
      companyFullNameArabic: nameArabic,
      line1: line1,
      line2: line2,
      line3: line3,
      city: city,
      zipCode: zipCode,
      companyIdentityNumberOne: idExpiryDate,
      // dateOfBirth: dateInput,
      companyCitizenship: nationality,
      companyDateOfBirth: dateInput,
      // investorCategory: lenderCategory,
    };
    let borrowerStepTwo = JSON.stringify(inputData);
    localStorage.setItem('borrowerStepTwo', borrowerStepTwo);
    history.push('/signupborrowerthree');
    // console.log(inputData);
  };
  const getStepTwoDetails = async () => {
    let stepTwoDetails = localStorage.getItem('borrowerStepTwo');
    // console.log(stepTwoDetails, 'stepTwoDetails');
    if (stepTwoDetails) {
      let value = JSON.parse(stepTwoDetails);
      setstepTwoDetail(value);
      setLine1(value.line1);
      setLine2(value.line2);
      setLine3(value.line3);
      setCity(value.city);
      setZipCode(value.zipCode);
      setcompanyIdentityNumberOne(value.companyIdentityNumberOne);
      setdateOfBirth(value.dateOfBirth);
      setcitizenship(value.citizenship);
      setcompanyFullName(value.companyFullName);
      setcompanyFullNameArabic(value.companyFullNameArabic);
    }
  };

  const checkMobileLinkedWithId = () => {
    let stepOneData = localStorage.getItem('borrowerStepOne');
    let finalData = JSON.parse(stepOneData);
    setisFetching(true);
    dispatch(registrationBorrowerStepOneBasicFunction(finalData)).then(res => {
      setisFetching(false);
      if (res.data.code === '200') {
        setResponseMessage(res.data.message);
        setResponse(true);
        setResponseStatus('200');
        setDisableVerify(true);
        localStorage.removeItem('borrowerStepOne');
        localStorage.removeItem('emailValidated');
        localStorage.removeItem('mobilevliadated');
        localStorage.setItem('newUser', res.data.access_token);
        setisFetching(false);
        checkValidId(res.data.access_token);
        localStorage.setItem('newUser', res.data.access_token);
      } else {
        setisFetching(false);
        setResponseMessage(res.data.message);
        setResponse(true);
        setResponseStatus(res.data.code);
        setTimeout(() => {
          history.push('/signupborrower');
        }, 2000);
      }
    });
  };
  const checkValidId = () => {
    let newUser = localStorage.getItem('newUser');
    let inputData = {
      access_token: newUser,
      recipientId: companyIdentityNumberOne,
    };
    // console.log(inputData);
    setisFetching(true);

    // const url = Url.baseUrl + Url.RayahSendOtp;
    dispatch(rayahSendOtpFunction(inputData)).then(res => {
      setisFetching(false);

      // console.log(res?.data?.message);
      if (res?.data?.status === '200') {
        setshowsnakbar(true);
        setOtpModal(true);
        setisFetching(false);
      } else {
        alert('Error');
        setisFetching(false);
      }
    });
  };

  const verifyOtp = async () => {
    let newUser = localStorage.getItem('newUser');
    let otpDetail = {
      access_token: newUser,
      otp: otp,
    };
    setisFetching(true);

    dispatch(rayahVerifyOtpFunction(otpDetail)).then(res => {
      // console.log(res?.data?.message);
      if (res?.data?.status === '200') {
        setisFetching(false);

        // Swal.fire('Success', 'OTP Verified Successfully', 'success');
        setResponseMessage(<IntlMessages id="accountdetail.otp.verify" />);
        setResponse(true);
        setResponseStatus('200');
        setOtp('');

        setOtpModal(false);
        setisFetching(false);
      } else {
        // Swal.fire('Error', 'OTP Verification Failed', 'error');
        setResponseMessage(<IntlMessages id="accountdetail.otp.verify.fail" />);
        setResponse(true);
        setResponseStatus('500');
        setOtp('');
        setOtpModal(false);
        setisFetching(false);
      }
    });
  };
  let isLoading = useSelector(state => state.registrationStepTwoIndividualReducer.isFetching);
  // console.log(expiryDate, 'baladate');
  // console.log(dateType, 'datetype');
  return (
    <AuthWrapper variant={'signup'}>
      {isFetching && (
        <div>
          <PageLoader />
        </div>
      )}
      <SnackBarComponent
        status={responseStatus}
        visible={response}
        setResponse={setResponse}
        message={responseMessage}
        setResponseStatus={setResponseStatus}
        setResponseMessage={setResponseMessage}
      />
      <Box className={classes.authContent}>
        <Loader isLoading={isLoading} />
        <Typography component="div" variant="h1" className={classes.titleRoot}>
          <IntlMessages id="registration.personaldetails" />
        </Typography>
        {showFields && (
          <form
            onSubmit={e => {
              onSubmit();
              e.preventDefault();
            }}>
            {mandatoryFields['GROSS_INCOME']?.showField && (
              <Box mb={2}>
                <TextField
                  label={mandatoryFields['GROSS_INCOME'].fieldLabel}
                  fullWidth
                  required
                  onChange={event => setgrossIncome(event.target.value)}
                  margin="normal"
                  variant="outlined"
                  className={classes.textFieldRoot}
                />
              </Box>
            )}
            {mandatoryFields['MONTHLY_EMI_ON_OUTSTANDING_LOAN']?.showField && (
              <Box mb={2}>
                <TextField
                  label={mandatoryFields['MONTHLY_EMI_ON_OUTSTANDING_LOAN'].fieldLabel}
                  fullWidth
                  required
                  onChange={event => setmonthlyEmiOnOutstandingLoans(event.target.value)}
                  margin="normal"
                  variant="outlined"
                  className={classes.textFieldRoot}
                />
              </Box>
            )}
            {mandatoryFields['PURPOSE_OF_OUT_STANDING_EMI']?.showField && (
              <Box mb={2}>
                <TextField
                  label={mandatoryFields['PURPOSE_OF_OUT_STANDING_EMI'].fieldLabel}
                  fullWidth
                  required
                  onChange={event => setpurposeOfOutStandingEmi(event.target.value)}
                  margin="normal"
                  variant="outlined"
                  className={classes.textFieldRoot}
                />
              </Box>
            )}
            {mandatoryFields['JOB_TYPE']?.showField && (
              <Box mb={2}>
                <TextField
                  label={mandatoryFields['JOB_TYPE'].fieldLabel}
                  fullWidth
                  required
                  onChange={event => setjobType(event.target.value)}
                  margin="normal"
                  variant="outlined"
                  className={classes.textFieldRoot}
                />
              </Box>
            )}
            {mandatoryFields['WORK_EXPERIENCE']?.showField && (
              <Box mb={2}>
                <TextField
                  label={mandatoryFields['WORK_EXPERIENCE'].fieldLabel}
                  fullWidth
                  required
                  onChange={event => setworkExperience(event.target.value)}
                  margin="normal"
                  variant="outlined"
                  className={classes.textFieldRoot}
                />
              </Box>
            )}
            {mandatoryFields['EMPLOYER_TYPE']?.showField && (
              <Box mb={2}>
                <TextField
                  label={mandatoryFields['EMPLOYER_TYPE'].fieldLabel}
                  fullWidth
                  required
                  onChange={event => setemployerType(event.target.value)}
                  margin="normal"
                  variant="outlined"
                  className={classes.textFieldRoot}
                />
              </Box>
            )}
            {mandatoryFields['EDUCATION_TYPE']?.showField && (
              <Box mb={2}>
                <TextField
                  label={mandatoryFields['EDUCATION_TYPE'].fieldLabel}
                  fullWidth
                  required
                  onChange={event => seteducationType(event.target.value)}
                  margin="normal"
                  variant="outlined"
                  className={classes.textFieldRoot}
                />
              </Box>
            )}
            {mandatoryFields['RESIDENT_TYPE']?.showField && (
              <Box mb={2}>
                <TextField
                  label={mandatoryFields['RESIDENT_TYPE'].fieldLabel}
                  fullWidth
                  required
                  onChange={event => setresidenceType(event.target.value)}
                  margin="normal"
                  variant="outlined"
                  className={classes.textFieldRoot}
                />
              </Box>
            )}
            {mandatoryFields['RESIDENT_OWNERSHIP_TYPE']?.showField && (
              <Box mb={2}>
                <TextField
                  label={mandatoryFields['RESIDENT_OWNERSHIP_TYPE'].fieldLabel}
                  fullWidth
                  required
                  onChange={event => setresidentOwnershipType(event.target.value)}
                  margin="normal"
                  variant="outlined"
                  className={classes.textFieldRoot}
                />
              </Box>
            )}
            {mandatoryFields['HOME_EXPENSES']?.showField && (
              <Box mb={2}>
                <TextField
                  label={mandatoryFields['HOME_EXPENSES'].fieldLabel}
                  fullWidth
                  required
                  onChange={event => sethomeExpenses(event.target.value)}
                  margin="normal"
                  variant="outlined"
                  className={classes.textFieldRoot}
                />
              </Box>
            )}
            {mandatoryFields['TRANSPORTATION_EXPENSES']?.showField && (
              <Box mb={2}>
                <TextField
                  label={mandatoryFields['TRANSPORTATION_EXPENSES'].fieldLabel}
                  fullWidth
                  required
                  onChange={event => settransportationExpenses(event.target.value)}
                  margin="normal"
                  variant="outlined"
                  className={classes.textFieldRoot}
                />
              </Box>
            )}
            {mandatoryFields['UTILITIES_EXPENSES']?.showField && (
              <Box mb={2}>
                <TextField
                  label={mandatoryFields['UTILITIES_EXPENSES'].fieldLabel}
                  fullWidth
                  required
                  onChange={event => setutilitiesExpenses(event.target.value)}
                  margin="normal"
                  variant="outlined"
                  className={classes.textFieldRoot}
                />
              </Box>
            )}
            {mandatoryFields['ENTERTAINMENT_EXPENSES']?.showField && (
              <Box mb={2}>
                <TextField
                  label={mandatoryFields['ENTERTAINMENT_EXPENSES'].fieldLabel}
                  fullWidth
                  required
                  onChange={event => setentertainmentExpenses(event.target.value)}
                  margin="normal"
                  variant="outlined"
                  className={classes.textFieldRoot}
                />
              </Box>
            )}
            {mandatoryFields['INSURANCE_EXPENSES']?.showField && (
              <Box mb={2}>
                <TextField
                  label={mandatoryFields['INSURANCE_EXPENSES'].fieldLabel}
                  fullWidth
                  required
                  onChange={event => setinsuranceExpenses(event.target.value)}
                  margin="normal"
                  variant="outlined"
                  className={classes.textFieldRoot}
                />
              </Box>
            )}
            {mandatoryFields['OTHER_EXPENSES']?.showField && (
              <Box mb={2}>
                <TextField
                  label={mandatoryFields['OTHER_EXPENSES'].fieldLabel}
                  fullWidth
                  required
                  onChange={event => setotherExpenses(event.target.value)}
                  margin="normal"
                  variant="outlined"
                  className={classes.textFieldRoot}
                />
              </Box>
            )}
            {mandatoryFields['CREDIT_CARD_EXPENSES']?.showField && (
              <Box mb={2}>
                <TextField
                  label={mandatoryFields['CREDIT_CARD_EXPENSES'].fieldLabel}
                  fullWidth
                  required
                  onChange={event => setcreditCardExpenses(event.target.value)}
                  margin="normal"
                  variant="outlined"
                  className={classes.textFieldRoot}
                />
              </Box>
            )}
            {mandatoryFields['HOUSE_LOAN_EXPENSES']?.showField && (
              <Box mb={2}>
                <TextField
                  label={mandatoryFields['HOUSE_LOAN_EXPENSES'].fieldLabel}
                  fullWidth
                  required
                  onChange={event => sethouseLoanExpenses(event.target.value)}
                  margin="normal"
                  variant="outlined"
                  className={classes.textFieldRoot}
                />
              </Box>
            )}
            {mandatoryFields['MONTHLY_RENT_PAYABLE']?.showField && (
              <Box mb={2}>
                <TextField
                  label={mandatoryFields['MONTHLY_RENT_PAYABLE'].fieldLabel}
                  fullWidth
                  required
                  onChange={event => setmonthlyRentPayable(event.target.value)}
                  margin="normal"
                  variant="outlined"
                  className={classes.textFieldRoot}
                />
              </Box>
            )}
            {mandatoryFields['MONTHLY_RENT_INCOME']?.showField && (
              <Box mb={2}>
                <TextField
                  label={mandatoryFields['MONTHLY_RENT_INCOME'].fieldLabel}
                  fullWidth
                  required
                  onChange={event => setmonthlyRentIncome(event.target.value)}
                  margin="normal"
                  variant="outlined"
                  className={classes.textFieldRoot}
                />
              </Box>
            )}
            {mandatoryFields['PERSONAL_FIRST_NAME']?.showField && (
              <Box mb={2}>
                <TextField
                  label={mandatoryFields['PERSONAL_FIRST_NAME'].fieldLabel}
                  fullWidth
                  required
                  onChange={event => setfirstName(event.target.value)}
                  margin="normal"
                  variant="outlined"
                  className={classes.textFieldRoot}
                />
              </Box>
            )}
            {mandatoryFields['CO_APPLICANT_MIDDLE_NAME']?.showField && (
              <Box mb={2}>
                <TextField
                  label={mandatoryFields['CO_APPLICANT_MIDDLE_NAME'].fieldLabel}
                  fullWidth
                  required
                  onChange={event => setmiddleName(event.target.value)}
                  margin="normal"
                  variant="outlined"
                  className={classes.textFieldRoot}
                />
              </Box>
            )}
            {mandatoryFields['CO_APPLICANT_GENDER']?.showField && (
              <Box mb={2}>
                <TextField
                  label={mandatoryFields['CO_APPLICANT_GENDER'].fieldLabel}
                  fullWidth
                  required
                  onChange={event => setgender(event.target.value)}
                  margin="normal"
                  variant="outlined"
                  className={classes.textFieldRoot}
                />
              </Box>
            )}
            {mandatoryFields['MARITAL_STATUS']?.showField && (
              <Box mb={2}>
                <TextField
                  label={mandatoryFields['MARITAL_STATUS'].fieldLabel}
                  fullWidth
                  required
                  onChange={event => setmaritalStatus(event.target.value)}
                  margin="normal"
                  variant="outlined"
                  className={classes.textFieldRoot}
                />
              </Box>
            )}
            {mandatoryFields['PERSONAL_MOBILE_NUMBER']?.showField && (
              <Box mb={2}>
                <TextField
                  label={mandatoryFields['PERSONAL_MOBILE_NUMBER'].fieldLabel}
                  fullWidth
                  required
                  onChange={event => setmobileNumber(event.target.value)}
                  margin="normal"
                  variant="outlined"
                  className={classes.textFieldRoot}
                />
              </Box>
            )}
            {mandatoryFields['CO_APPLICANT_MOBILE_NUMBER_COUNTRY_CODE']?.showField && (
              <Box mb={2}>
                <TextField
                  label={mandatoryFields['CO_APPLICANT_MOBILE_NUMBER_COUNTRY_CODE'].fieldLabel}
                  fullWidth
                  required
                  onChange={event => setmobileNumberCountryCode(event.target.value)}
                  margin="normal"
                  variant="outlined"
                  className={classes.textFieldRoot}
                />
              </Box>
            )}
            <GridContainer
              sm={12}
              md={12}
              lg={12}
              style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
              <Grid sm={12} md={10} lg={10}>
                {' '}
                <TextField
                  label={<IntlMessages id="registration.idiquama" />}
                  fullWidth
                  onChange={event => setcompanyIdentityNumberOne(event.target.value)}
                  // defaultValue={name}
                  value={companyIdentityNumberOne}
                  margin="normal"
                  disabled={showAll}
                  onBlur={event => setHijriOrGregorian(event.target.value)}
                  variant="outlined"
                  className={classes.textFieldRoot}
                />
              </Grid>
              <Grid style={{ padding: '18px' }} sm={12} md={2} lg={2}>
                <Button
                  disabled={disableVerify}
                  onClick={() => checkMobileLinkedWithId()}
                  variant="contained"
                  color="primary">
                  <IntlMessages id="registration.verify" />
                </Button>
              </Grid>
            </GridContainer>
            <GridContainer sm={12} md={12} lg={12} style={{ padding: '10px' }}>
              <FormControl component="fieldset">
                <FormLabel component="legend">
                  <IntlMessages id="registration.datetype" />
                </FormLabel>
                <RadioGroup row aria-label="position" name="position" defaultValue="top">
                  <FormControlLabel
                    checked={dateType == 'gregorian' ? true : false}
                    disabled
                    onClick={() => setDateType('gregorian')}
                    control={<Radio color="primary" />}
                    label={<IntlMessages id="registration.gregorian" />}
                  />
                  <FormControlLabel
                    checked={dateType == 'hijri' ? true : false}
                    onClick={() => setDateType('hijri')}
                    disabled
                    control={<Radio color="primary" />}
                    label={<IntlMessages id="registration.hijri" />}
                  />
                </RadioGroup>
              </FormControl>
            </GridContainer>
            <Grid container spacing={2}>
              <Grid item xs={6} md={4}>
                <Typography component="div" variant="h6" className={classes.titleRoot}>
                  <IntlMessages id="registration.dateofbirth" />
                </Typography>
                <FormControl fullWidth>
                  <InputLabel style={{ marginLeft: '10px' }} id="demo-simple-select-helper-label">
                    <IntlMessages id="registration.selectyear" />
                  </InputLabel>
                  <Select
                    required
                    disabled={showAllone}
                    labelId="demo-simple-select-helper-label"
                    id="demo-simple-select-helper"
                    variant="outlined"
                    value={dateYear}
                    onChange={event => setDateYear(event.target.value)}
                    label="Age">
                    <MenuItem value="">
                      <em>None</em>
                    </MenuItem>
                    {dateType === 'hijri'
                      ? datePicker.year.map(item => {
                          return <MenuItem value={item.value}>{item.value}</MenuItem>;
                        })
                      : datePicker.yearg.map(item => {
                          return <MenuItem value={item.value}>{item.value}</MenuItem>;
                        })}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={6} md={4}>
                <FormControl fullWidth style={{ marginTop: 36 }}>
                  <InputLabel style={{ marginLeft: '10px' }} id="demo-simple-select-helper-label">
                    <IntlMessages id="registration.selectmonth" />
                  </InputLabel>
                  <Select
                    required
                    disabled={showAllone}
                    labelId="demo-simple-select-helper-label"
                    id="demo-simple-select-helper"
                    variant="outlined"
                    value={dateMonth}
                    label="Age"
                    onChange={event => setDateMonth(event.target.value)}>
                    <MenuItem value="">
                      <em>None</em>
                    </MenuItem>
                    {datePicker.month.map(item => {
                      return <MenuItem value={item.value}>{item.value}</MenuItem>;
                    })}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={4}>
                <FormControl fullWidth style={{ marginTop: 36 }}>
                  <InputLabel style={{ marginLeft: '10px' }} id="demo-simple-select-helper-label">
                    <IntlMessages id="registration.selectdate" />
                  </InputLabel>
                  <Select
                    required
                    disabled={showAllone}
                    labelId="demo-simple-select-helper-label"
                    id="demo-simple-select-helper"
                    variant="outlined"
                    value={dateDay}
                    label="Age"
                    onChange={event => setDateDay(event.target.value)}>
                    <MenuItem value="">
                      <em>None</em>
                    </MenuItem>
                    {datePicker.day.map(item => {
                      return <MenuItem value={item.value}>{item.value}</MenuItem>;
                    })}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
            {showAll ? (
              <div>
                <GridContainer style={{ marginTop: 15, marginBottom: 15 }}>
                  <Grid item xs={12} sm={12} md={6} lg={6}>
                    <Typography className={classes.title} variant="h1" color="textprimary" gutterBottom>
                      <IntlMessages id="profile.name" />
                    </Typography>
                    <Typography className={classes.title} variant="h1" color="textSecondary" gutterBottom>
                      {nameEnglish}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={12} md={6} lg={6}>
                    <Typography className={classes.title} variant="h1" color="textprimary" gutterBottom>
                      <IntlMessages id="profile.arabic" />
                    </Typography>
                    <Typography className={classes.title} variant="h1" color="textSecondary" gutterBottom>
                      {nameArabic}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={12} md={6} lg={6}>
                    <Box mb={2}>
                      <FormControl fullWidth>
                        <InputLabel style={{ marginLeft: '10px' }} id="demo-simple-select-helper-label">
                          <IntlMessages id="registration.nationality" />
                        </InputLabel>
                        <Select
                          required
                          labelId="demo-simple-select-helper-label"
                          id="demo-simple-select-helper"
                          value={nationality}
                          variant="outlined"
                          disabled
                          onChange={event => setNationality(event.target.value)}
                          label="Age">
                          <MenuItem value="">
                            <em>None</em>
                          </MenuItem>
                          {citizenShipArray?.map(item => {
                            return <MenuItem value={item.value}>{item.name}</MenuItem>;
                          })}
                        </Select>
                      </FormControl>
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={12} md={6} lg={6}>
                    <Typography className={classes.title} variant="h1" color="textprimary" gutterBottom>
                      <IntlMessages id="registration.expirydate" />
                    </Typography>
                    <Typography className={classes.title} variant="h1" color="textSecondary" gutterBottom>
                      {expiryDate}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={12} md={6} lg={6}>
                    <Typography className={classes.title} variant="h1" color="textprimary" gutterBottom>
                      <IntlMessages id="registration.addressline1" />
                    </Typography>
                    <Typography className={classes.title} variant="h1" color="textSecondary" gutterBottom>
                      {line1}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={12} md={6} lg={6}>
                    <Typography className={classes.title} variant="h1" color="textprimary" gutterBottom>
                      <IntlMessages id="registration.addressline2" />
                    </Typography>
                    <Typography className={classes.title} variant="h1" color="textSecondary" gutterBottom>
                      {line2}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={12} md={6} lg={6}>
                    <Typography className={classes.title} variant="h1" color="textprimary" gutterBottom>
                      <IntlMessages id="registration.addressline3" />
                    </Typography>
                    <Typography className={classes.title} variant="h1" color="textSecondary" gutterBottom>
                      {line3}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={12} md={6} lg={6}>
                    <Typography className={classes.title} variant="h1" color="textprimary" gutterBottom>
                      <IntlMessages id="registration.zipcode" />
                    </Typography>
                    <Typography className={classes.title} variant="h1" color="textSecondary" gutterBottom>
                      {zipCode}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={12} md={6} lg={6}>
                    <Typography className={classes.title} variant="h1" color="textprimary" gutterBottom>
                      <IntlMessages id="registration.city" />
                    </Typography>
                    <Typography className={classes.title} variant="h1" color="textSecondary" gutterBottom>
                      {city}
                    </Typography>
                  </Grid>
                </GridContainer>

                {/* <Box mb={2}>
              <TextField
                label={<IntlMessages id="registration.companynameid" />}
                fullWidth
                onChange={event => setNameEnglish(event.target.value)}
                value={nameEnglish}
                margin="normal"
                variant="outlined"
                className={classes.textFieldRoot}
              />
            </Box>
            <Box mb={2}>
              <TextField
                label={<IntlMessages id="registration.companynameidarabic" />}
                fullWidth
                onChange={event => setNameArabic(event.target.value)}
                value={nameArabic}
                margin="normal"
                variant="outlined"
                className={classes.textFieldRoot}
              />
            </Box>
            <Box mb={2}>
              <TextField
                label={<IntlMessages id="registration.expirydate" />}
                fullWidth
                onChange={event => setExpiryDate(event.target.value)}
                value={expiryDate}
                margin="normal"
                variant="outlined"
                className={classes.textFieldRoot}
              />
            </Box>
            <Grid container spacing={2}>
              <Grid item xs={12} md={12}>
                <TextField
                  label={<IntlMessages id="registration.addressline1" />}
                  fullWidth
                  onChange={event => setLine1(event.target.value)}
                  value={line1}
                  margin="normal"
                  variant="outlined"
                  className={classes.textFieldRoot}
                />
              </Grid>
              <Grid item xs={12} md={12}>
                <Box>
                  <TextField
                    label={<IntlMessages id="registration.addressline2" />}
                    fullWidth
                    onChange={event => setLine2(event.target.value)}
                    value={line2}
                    margin="normal"
                    variant="outlined"
                    // className={classes.textFieldRoot}
                  />
                </Box>
              </Grid>
              <Grid item xs={12} md={12}>
                <Box>
                  <TextField
                    label={<IntlMessages id="registration.addressline3" />}
                    fullWidth
                    onChange={event => setLine3(event.target.value)}
                    value={line3}
                    margin="normal"
                    variant="outlined"
                    // className={classes.textFieldRoot}
                  />
                </Box>
              </Grid>
              <Grid item xs={6} md={6}>
                <Box>
                  <TextField
                    label={<IntlMessages id="registration.zipcode" />}
                    fullWidth
                    onChange={event => setZipCode(event.target.value)}
                    value={zipCode}
                    margin="normal"
                    variant="outlined"
                    // className={classes.textFieldRoot}
                  />
                </Box>
              </Grid>
              <Grid item xs={6} md={6}>
                <Box>
                  <TextField
                    label={<IntlMessages id="registration.city" />}
                    fullWidth
                    onChange={event => setCity(event.target.value)}
                    value={city}
                    margin="normal"
                    variant="outlined"
                    // className={classes.textFieldRoot}
                  />
                </Box>
              </Grid>
            </Grid> */}
                {/* <Box mb={2}>
              <FormControl sx={{ m: 1, minWidth: 120 }} style={{ width: 500, marginTop: 55 }}>
                <InputLabel id="demo-simple-select-helper-label">
                  <IntlMessages id="registration.city" />
                </InputLabel>
                <Select
                required
                  labelId="demo-simple-select-helper-label"
                  id="demo-simple-select-helper"
                  variant="outlined"
                  value={city}
                  label="city"
                  onChange={event => setCity(event.target.value)}>
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                  {cityArray.map(item => {
                    return <MenuItem value={item.name}>{item.name}</MenuItem>;
                  })}
                </Select>
              </FormControl>
            </Box> */}
                <Box
                  display="flex"
                  flexDirection={{ xs: 'column', sm: 'row' }}
                  alignItems={{ sm: 'center' }}
                  justifyContent={{ sm: 'space-between' }}
                  mb={3}>
                  <Box mb={{ xs: 2, sm: 0 }}>
                    <Button type="submit" variant="contained" color="primary">
                      <IntlMessages id="appModule.next" />
                    </Button>
                  </Box>
                </Box>
              </div>
            ) : (
              <Grid item xs={12} md={12}>
                <Button
                  disabled={showAllone}
                  style={{ marginTop: 20 }}
                  fullWidth
                  onClick={() => citizenInfo()}
                  variant="contained"
                  color="primary">
                  <IntlMessages id="registration.citizeninfo" />
                </Button>
              </Grid>
            )}
          </form>
        )}
        <div>
          <Dialog open={showAlert} fullWidth>
            <DialogContent>
              <DialogContentText>{status}</DialogContentText>

              <DialogContentText>{message}</DialogContentText>
            </DialogContent>

            <DialogActions>
              <Button
                onClick={() => {
                  setShowAlert(false);
                }}>
                Cancel
              </Button>
              <Button onClick={() => (code === '200' ? history.push('/signupborrowerthree') : setShowAlert(false))}>
                Okay
              </Button>
            </DialogActions>
          </Dialog>
        </div>
        <div>
          <Dialog fullWidth open={otpModal}>
            <DialogContent>
              <DialogContentText>
                <IntlMessages id="registration.otpverifiy" />
              </DialogContentText>
              <TextField
                label=""
                autoComplete="off"
                fullWidth
                onChange={event => setOtp(event.target.value)}
                // defaultValue={password}
                margin="normal"
                variant="outlined"
                // className={classes.textFieldRoot}
              />
              <Snackbar open={showsnakbar} autoHideDuration={6000} onClose={() => setshowsnakbar(false)}>
                <Alert onClose={() => setshowsnakbar(false)} severity="success">
                  <IntlMessages id="registration.otpsend" />
                </Alert>
              </Snackbar>
            </DialogContent>

            <DialogActions>
              <Button onClick={() => setOtpModal(false)}>
                <IntlMessages id="registration.cancel" />
              </Button>
              {resendButton == true ? (
                <Button onClick={() => verifyOtp()}>
                  <IntlMessages id="registration.submit" />
                </Button>
              ) : (
                <Button
                  onClick={() => {
                    refreshButton();
                  }}>
                  <IntlMessages id="registration.resend" />
                </Button>
              )}
            </DialogActions>
            {timeLeft != 0 ? (
              <DialogContentText style={{ textAlign: 'center' }}>
                <IntlMessages id="registration.resendotp" /> {timeLeft}s
              </DialogContentText>
            ) : null}
          </Dialog>
        </div>
        {/* <Box mb={3}>{dispatch(AuhMethods[method].getSocialMediaIcons())}</Box>

        <ContentLoader /> */}
      </Box>
    </AuthWrapper>
  );
};

export default SignUpTwoBorrower;
