export const FETCHING_USER_LOGIN_DETAILS_REQUEST = 'FETCHING_USER_LOGIN_DETAILS_REQUEST';
export const FETCHING_USER_LOGIN_DETAILS_SUCCESS = 'FETCHING_USER_LOGIN_DETAILS_SUCCESS';
export const FETCHING_USER_LOGIN_DETAILS_FAILED = 'FETCHING_USER_LOGIN_DETAILS_FAILED';
export const FETCHING_USER_LOGOUT_DETAILS_REQUEST = 'FETCHING_USER_LOGOUT_DETAILS_REQUEST';
export const FETCHING_USER_LOGOUT_DETAILS_SUCCESS = 'FETCHING_USER_LOGOUT_DETAILS_SUCCESS';
export const FETCHING_USER_LOGOUT_DETAILS_FAILED = 'FETCHING_USER_LOGOUT_DETAILS_FAILED';
export const FETCHING_INVESTOR_ALL_DETAIL_REQUEST = 'FETCHING_INVESTOR_ALL_DETAIL_REQUEST';
export const FETCHING_INVESTOR_ALL_DETAIL_SUCCESS = 'FETCHING_INVESTOR_ALL_DETAIL_SUCCESS';
export const FETCHING_INVESTOR_ALL_DETAIL_FAILED = 'FETCHING_INVESTOR_ALL_DETAIL_FAILED';
export const FETCHING_ALL_FINANCE_OFFERS_REQUEST = 'FETCHING_ALL_FINANCE_OFFERS_REQUEST';
export const FETCHING_ALL_FINANCE_OFFERS_SUCCESS = 'FETCHING_ALL_FINANCE_OFFERS_SUCCESS';
export const FETCHING_ALL_FINANCE_OFFERS_FAILED = 'FETCHING_ALL_FINANCE_OFFERS_FAILED';
export const FETCHING_TAG_NAME_REQUEST = 'FETCHING_TAG_NAME_REQUEST';
export const FETCHING_TAG_NAME_SUCCESS = 'FETCHING_TAG_NAME_SUCCESS';
export const FETCHING_TAG_NAME_FAILED = 'FETCHING_TAG_NAME_FAILED';
export const FETCHING_LOAN_DETAIL_INDIVIDUAL_REQUEST = 'FETCHING_LOAN_DETAIL_INDIVIDUAL_REQUEST';
export const FETCHING_LOAN_DETAIL_INDIVIDUAL_SUCCESS = 'FETCHING_LOAN_DETAIL_INDIVIDUAL_SUCCESS';
export const FETCHING_LOAN_DETAIL_INDIVIDUAL_FAILED = 'FETCHING_LOAN_DETAIL_INDIVIDUAL_FAILED';
export const FETCHING_ALL_OFFERS_REQUEST = 'FETCHING_ALL_OFFERS_REQUEST';
export const FETCHING_ALL_OFFERS_SUCCESS = 'FETCHING_ALL_OFFERS_SUCCESS';
export const FETCHING_ALL_OFFERS_FAILED = 'FETCHING_ALL_OFFERS_FAILED';
export const FETCHING_FINANCED_AMOUNT_REQUEST = 'FETCHING_FINANCED_AMOUNT_REQUEST';
export const FETCHING_FINANCED_AMOUNT_SUCCESS = 'FETCHING_FINANCED_AMOUNT_SUCCESS';
export const FETCHING_FINANCED_AMOUNT_FAILED = 'FETCHING_FINANCED_AMOUNT_FAILED';
export const FETCHING_TRANSACTIONS_REQUEST = 'FETCHING_TRANSACTIONS_REQUEST';
export const FETCHING_TRANSACTIONS_SUCCESS = 'FETCHING_TRANSACTIONS_SUCCESS';
export const FETCHING_TRANSACTIONS_FAILED = 'FETCHING_TRANSACTIONS_FAILED';
export const FETCHING_RECEIVABLES_REQUEST = 'FETCHING_RECEIVABLES_REQUEST';
export const FETCHING_RECEIVABLES_SUCCESS = 'FETCHING_RECEIVABLES_SUCCESS';
export const FETCHING_RECEIVABLES_FAILED = 'FETCHING_RECEIVABLES_FAILED';
export const FETCHING_FINANCING_CRITERIA_REQUEST = 'FETCHING_FINANCING_CRITERIA_REQUEST';
export const FETCHING_FINANCING_CRITERIA_SUCCESS = 'FETCHING_FINANCING_CRITERIA_SUCCESS';
export const FETCHING_FINANCING_CRITERIA_FAILED = 'FETCHING_FINANCING_CRITERIA_FAILED';
export const FETCHING_WITHDRAW_WALLET_REQUEST = 'FETCHING_WITHDRAW_WALLET_REQUEST';
export const FETCHING_WITHDRAW_WALLET_SUCCESS = 'FETCHING_WITHDRAW_WALLET_SUCCESS';
export const FETCHING_WITHDRAW_WALLET_FAILED = 'FETCHING_WITHDRAW_WALLET_FAILED';
export const FETCHING_CHANGE_PASSWORD_REQUEST = 'FETCHING_CHANGE_PASSWORD_REQUEST';
export const FETCHING_CHANGE_PASSWORD_SUCCESS = 'FETCHING_CHANGE_PASSWORD_SUCCESS';
export const FETCHING_CHANGE_PASSWORD_FAILED = 'FETCHING_CHANGE_PASSWORD_FAILED';
export const FETCHING_SEND_OTP_MOBILE_REQUEST = 'FETCHING_SEND_OTP_MOBILE_REQUEST';
export const FETCHING_SEND_OTP_MOBILE_SUCCESS = 'FETCHING_SEND_OTP_MOBILE_SUCCESS';
export const FETCHING_SEND_OTP_MOBILE_FAILED = 'FETCHING_SEND_OTP_MOBILE_FAILED';
export const FETCHING_VERIFY_OTP_MOBILE_REQUEST = 'FETCHING_VERIFY_OTP_MOBILE_REQUEST';
export const FETCHING_VERIFY_OTP_MOBILE_SUCCESS = 'FETCHING_VERIFY_OTP_MOBILE_SUCCESS';
export const FETCHING_VERIFY_OTP_MOBILE_FAILED = 'FETCHING_VERIFY_OTP_MOBILE_FAILED';
export const FETCHING_EMAIL_ACTIVATION_LINK_SEND_REQUEST = 'FETCHING_EMAIL_ACTIVATION_LINK_SEND_REQUEST';
export const FETCHING_EMAIL_ACTIVATION_LINK_SEND_SUCCESS = 'FETCHING_EMAIL_ACTIVATION_LINK_SEND_SUCCESS';
export const FETCHING_EMAIL_ACTIVATION_LINK_SEND_FAILED = 'FETCHING_EMAIL_ACTIVATION_LINK_SEND_FAILED';
export const FETCHING_FUND_LOAN_APPLICATION_REQUEST = 'FETCHING_FUND_LOAN_APPLICATION_REQUEST';
export const FETCHING_FUND_LOAN_APPLICATION_SUCCESS = 'FETCHING_FUND_LOAN_APPLICATION_SUCCESS';
export const FETCHING_FUND_LOAN_APPLICATION_FAILED = 'FETCHING_FUND_LOAN_APPLICATION_FAILED';
export const FETCHING_ADD_FINANCING_CRITERIA_REQUEST = 'FETCHING_ADD_FINANCING_CRITERIA_REQUEST';
export const FETCHING_ADD_FINANCING_CRITERIA_SUCCESS = 'FETCHING_ADD_FINANCING_CRITERIA_SUCCESS';
export const FETCHING_ADD_FINANCING_CRITERIA_FAILED = 'FETCHING_ADD_FINANCING_CRITERIA_FAILED';
// Registration
export const FETCHING_REGIRSTRATION_STEP_ONE_BASIC_REQUEST = 'FETCHING_REGIRSTRATION_STEP_ONE_BASIC_REQUEST';
export const FETCHING_REGIRSTRATION_STEP_ONE_BASIC_SUCCESS = 'FETCHING_REGIRSTRATION_STEP_ONE_BASIC_SUCCESS';
export const FETCHING_REGIRSTRATION_STEP_ONE_BASIC_FAILED = 'FETCHING_REGIRSTRATION_STEP_ONE_BASIC_FAILED';
export const FETCHING_REGIRSTRATION_STEP_TWO_INDIVIDUAL_REQUEST = 'FETCHING_REGIRSTRATION_STEP_TWO_INDIVIDUAL_REQUEST';
export const FETCHING_REGIRSTRATION_STEP_TWO_INDIVIDUAL_SUCCESS = 'FETCHING_REGIRSTRATION_STEP_TWO_INDIVIDUAL_SUCCESS';
export const FETCHING_REGIRSTRATION_STEP_TWO_INDIVIDUAL_FAILED = 'FETCHING_REGIRSTRATION_STEP_TWO_INDIVIDUAL_FAILED';
export const FETCHING_REGIRSTRATION_STEP_THREE_EMPLOYEEMENT_REQUEST =
  'FETCHING_REGIRSTRATION_STEP_THREE_EMPLOYEEMENT_REQUEST';
export const FETCHING_REGIRSTRATION_STEP_THREE_EMPLOYEEMENT_SUCCESS =
  'FETCHING_REGIRSTRATION_STEP_THREE_EMPLOYEEMENT_SUCCESS';
export const FETCHING_REGIRSTRATION_STEP_THREE_EMPLOYEEMENT_FAILED = 'FETCHING_REGIRSTRATION_STEP_THREE_EMPLOYEEMENT_FAILED';
export const FETCHING_REGIRSTRATION_STEP_FOUR_BANK_REQUEST = 'FETCHING_REGIRSTRATION_STEP_FOUR_BANK_REQUEST';
export const FETCHING_REGIRSTRATION_STEP_FOUR_BANK_SUCCESS = 'FETCHING_REGIRSTRATION_STEP_FOUR_BANK_SUCCESS';
export const FETCHING_REGIRSTRATION_STEP_FOUR_BANK_FAILED = 'FETCHING_REGIRSTRATION_STEP_FOUR_BANK_FAILED';
export const FETCHING_REGIRSTRATION_STEP_FIVE_DECLARATION_REQUEST = 'FETCHING_REGIRSTRATION_STEP_FIVE_DECLARATION_REQUEST';
export const FETCHING_REGIRSTRATION_STEP_FIVE_DECLARATION_SUCCESS = 'FETCHING_REGIRSTRATION_STEP_FIVE_DECLARATION_SUCCESS';
export const FETCHING_REGIRSTRATION_STEP_FIVE_DECLARATION_FAILED = 'FETCHING_REGIRSTRATION_STEP_FIVE_DECLARATION_FAILED';

//borrowerRegistration
export const FETCHING_REGIRSTRATION_BORROWER_STEP_ONE_BASIC_REQUEST =
  'FETCHING_REGIRSTRATION_BORROWER_STEP_ONE_BASIC_REQUEST';
export const FETCHING_REGIRSTRATION_BORROWER_STEP_ONE_BASIC_SUCCESS =
  'FETCHING_REGIRSTRATION_BORROWER_STEP_ONE_BASIC_SUCCESS';
export const FETCHING_REGIRSTRATION_BORROWER_STEP_ONE_BASIC_FAILED = 'FETCHING_REGIRSTRATION_BORROWER_STEP_ONE_BASIC_FAILED';

export const FETCHING_REGIRSTRATION_STEP_TWO_BORROWER_REQUEST = 'FETCHING_REGIRSTRATION_STEP_TWO_BORROWER_REQUEST';
export const FETCHING_REGIRSTRATION_STEP_TWO_BORROWER_SUCCESS = 'FETCHING_REGIRSTRATION_STEP_TWO_BORROWER_SUCCESS';
export const FETCHING_REGIRSTRATION_STEP_TWO_BORROWER_FAILED = 'FETCHING_REGIRSTRATION_STEP_TWO_BORROWER_FAILED';

export const FETCHING_CITIZEN_INFO_REQUEST = 'FETCHING_CITIZEN_INFO_REQUEST';
export const FETCHING_CITIZEN_INFO_SUCCESS = 'FETCHING_CITIZEN_INFO_SUCCESS';
export const FETCHING_CITIZEN_INFO_FAILED = 'FETCHING_CITIZEN_INFO_FAILED';

export const FETCHING_DECLARATION_FILE_UPLOAD_REQUEST = 'FETCHING_DECLARATION_FILE_UPLOAD_REQUEST';
export const FETCHING_DECLARATION_FILE_UPLOAD_SUCCESS = 'FETCHING_DECLARATION_FILE_UPLOAD_SUCCESS';
export const FETCHING_DECLARATION_FILE_UPLOAD_FAILED = 'FETCHING_DECLARATION_FILE_UPLOAD_FAILED';

export const FETCHING_DECLARATION_FILE_UPLOAD_BORROWER_REQUEST = 'FETCHING_DECLARATION_FILE_UPLOAD_BORROWER_REQUEST';
export const FETCHING_DECLARATION_FILE_UPLOAD_BORROWER_SUCCESS = 'FETCHING_DECLARATION_FILE_UPLOAD_BORROWER_SUCCESS';
export const FETCHING_DECLARATION_FILE_UPLOAD_BORROWER_FAILED = 'FETCHING_DECLARATION_FILE_UPLOAD_BORROWER_FAILED';

export const FETCHING_CITIES_LIST_REQUEST = 'FETCHING_CITIES_LIST_REQUEST';
export const FETCHING_CITIES_LIST_SUCCESS = 'FETCHING_CITIES_LIST_SUCCESS';
export const FETCHING_CITIES_LIST_FAILED = 'FETCHING_CITIES_LIST_FAILED';

export const FETCHING_CITIZENSHIP_REQUEST = 'FETCHING_CITIZENSHIP_REQUEST';
export const FETCHING_CITIZENSHIP_SUCCESS = 'FETCHING_CITIZENSHIP_SUCCESS';
export const FETCHING_CITIZENSHIP_FAILED = 'FETCHING_CITIZENSHIP_FAILED';

export const FETCHING_EMPLOYMENT_STATUS_REQUEST = 'FETCHING_EMPLOYMENT_STATUS_REQUEST';
export const FETCHING_EMPLOYMENT_STATUS_SUCCESS = 'FETCHING_EMPLOYMENT_STATUS_SUCCESS';
export const FETCHING_EMPLOYMENT_STATUS_FAILED = 'FETCHING_EMPLOYEMENT_STATUS_FAILED';

export const FETCHING_INDUSTRY_REQUEST = 'FETCHING_INDUSTRY_REQUEST';
export const FETCHING_INDUSTRY_SUCCESS = 'FETCHING_INDUSTRY_SUCCESS';
export const FETCHING_INDUSTRY_FAILED = 'FETCHING_INDUSTRY_FAILED';

export const FETCHING_COMPANY_REQUEST = 'FETCHING_COMPANY_REQUEST';
export const FETCHING_COMPANY_SUCCESS = 'FETCHING_COMPANY_SUCCESS';
export const FETCHING_COMPANY_FAILED = 'FETCHING_COMPANY_FAILED';

export const FETCHING_BANK_NAME_REQUEST = 'FETCHING_BANK_NAME_REQUEST';
export const FETCHING_BANK_NAME_SUCCESS = 'FETCHING_BANK_NAME_SUCCESS';
export const FETCHING_BANK_NAME_FAILED = 'FETCHING_BANK_NAME_FAILED';

export const FETCHING_FORGOT_PASSWORD_REQUEST = 'FETCHING_FORGOT_PASSWORD_REQUEST';
export const FETCHING_FORGOT_PASSWORD_SUCCESS = 'FETCHING_FORGOT_PASSWORD_SUCCESS';
export const FETCHING_FORGOT_PASSWORD_FAILED = 'FETCHING_FORGOT_PASSWORD_FAILED';

export const FETCHING_LOAN_PUBLISHING_SOON_REQUEST = 'FETCHING_LOAN_PUBLISHING_SOON_REQUEST';
export const FETCHING_LOAN_PUBLISHING_SOON_SUCCESS = 'FETCHING_LOAN_PUBLISHING_SOON_SUCCESS';
export const FETCHING_LOAN_PUBLISHING_SOON_FAILED = 'FETCHING_LOAN_PUBLISHING_SOON_FAILED';

export const FETCHING_DELETE_FINANCING_CRITERIA_REQUEST = 'FETCHING_DELETE_FINANCING_CRITERIA_REQUEST';
export const FETCHING_DELETE_FINANCING_CRITERIA_SUCCESS = 'FETCHING_DELETE_FINANCING_CRITERIA_SUCCESS';
export const FETCHING_DELETE_FINANCING_CRITERIA_FAILED = 'FETCHING_DELETE_FINANCING_CRITERIA_FAILED';
export const FETCHING_FINANCING_COMPANY_REQUEST = 'FETCHING_FINANCING_COMPANY_REQUEST';
export const FETCHING_FINANCING_COMPANY_SUCCESS = 'FETCHING_FINANCING_COMPANY_SUCCESS';
export const FETCHING_FINANCING_COMPANY_FAILED = 'FETCHING_FINANCING_COMPANY_FAILED';
export const FETCHING_BANK_DETAILS_REQUEST = 'FETCHING_BANK_DETAILS_REQUEST';
export const FETCHING_BANK_DETAILS_SUCCESS = 'FETCHING_BANK_DETAILS_SUCCESS';
export const FETCHING_BANK_DETAILS_FAILED = 'FETCHING_BANK_DETAILS_FAILED';

export const FETCHING_LENDER_CATEGORY_REQUEST = 'FETCHING_LENDER_CATEGORY_REQUEST';
export const FETCHING_LENDER_CATEGORY_SUCCESS = 'FETCHING_LENDER_CATEGORY_SUCCESS';
export const FETCHING_LENDER_CATEGORY_FAILED = 'FETCHING_LENDER_CATEGORY_FAILED';

export const FETCHING_IS_LOGIN_REQUEST = ' FETCHING_IS_LOGIN_REQUEST';
export const FETCHING_IS_LOGIN_SUCCESS = ' FETCHING_IS_LOGIN_SUCCESS';
export const FETCHING_IS_LOGIN_FAILED = ' FETCHING_IS_LOGIN_FAILED';

export const FETCHING_LOGOUT_REQUEST = ' FETCHING_LOGOUT_REQUEST';
export const FETCHING_LOGOUT_SUCCESS = ' FETCHING_LOGOUT_SUCCESS';
export const FETCHING_LOGOUT_FAILED = ' FETCHING_LOGOUT_FAILED';

export const FETCHING_OTP_VERIFICATION_REQUEST = ' FETCHING_OTP_VERIFICATION_REQUEST';
export const FETCHING_OTP_VERIFICATION_SUCCESS = ' FETCHING_OTP_VERIFICATION_SUCCESS';
export const FETCHING_OTP_VERIFICATION_FAILED = ' FETCHING_OTP_VERIFICATION_FAILED';

export const FETCHING_SECTORLIST_REQUEST = ' FETCHING_SECTORLIST_REQUEST';
export const FETCHING_SECTORLIST_SUCCESS = ' FETCHING_SECTORLIST_SUCCESS';
export const FETCHING_SECTORLIST_FAILED = ' FETCHING_SECTORLIST_FAILED';

export const FETCHING_UPDATE_FINANCING_CRITERIA_REQUEST = ' FETCHING_UPDATE_FINANCING_CRITERIA_REQUEST';
export const FETCHING_UPDATE_FINANCING_CRITERIA_SUCCESS = ' FETCHING_UPDATE_FINANCING_CRITERIA_SUCCESS';
export const FETCHING_UPDATE_FINANCING_CRITERIA_FAILED = ' FETCHING_UPDATE_FINANCING_CRITERIA_FAILED';

export const FETCHING_STATUS_REQUEST = ' FETCHING_STATUS_REQUEST';
export const FETCHING_STATUS_SUCCESS = ' FETCHING_STATUS_SUCCESS';
export const FETCHING_STATUS_FAILED = ' FETCHING_STATUS_FAILED';

export const FETCHING_ADD_OTP_FINANCING_REQUEST = ' FETCHING_ADD_OTP_FINANCING_REQUEST';
export const FETCHING_ADD_OTP_FINANCING_SUCCESS = ' FETCHING_ADD_OTP_FINANCING_SUCCESS';
export const FETCHING_ADD_OTP_FINANCING_FAILED = ' FETCHING_ADD_OTP_FINANCING_FAILED';
export const FETCHING_UPDATE_OTP_FINANCING_REQUEST = ' FETCHING_UPDATE_OTP_FINANCING_REQUEST';
export const FETCHING_UPDATE_OTP_FINANCING_SUCCESS = ' FETCHING_UPDATE_OTP_FINANCING_SUCCESS';
export const FETCHING_UPDATE_OTP_FINANCING_FAILED = ' FETCHING_UPDATE_OTP_FINANCING_FAILED';
export const FETCHING_FINANCING_CREDITABILITY_REQUEST = 'FETCHING_FINANCING_CREDITABILITY_REQUEST';
export const FETCHING_FINANCING_CREDITABILITY_SUCCESS = 'FETCHING_FINANCING_CREDITABILITY_SUCCESS';
export const FETCHING_FINANCING_CREDITABILITY_FAILED = 'FETCHING_FINANCING_CREDITABILITY_FAILED';

export const FETCHING_INVESTOR_STATUS_REQUEST = 'FETCHING_INVESTOR_STATUS_REQUEST';
export const FETCHING_INVESTOR_STATUS_SUCCESS = 'FETCHING_INVESTOR_STATUS_SUCCESS';
export const FETCHING_INVESTOR_STATUS_FAILED = 'FETCHING_INVESTOR_STATUS_FAILED';

export const FETCHING_COMAPANY_REGISTRATION_CHECK_REQUEST = 'FETCHING_COMAPANY_REGISTRATION_CHECK_REQUEST';
export const FETCHING_COMAPANY_REGISTRATION_CHECK_SUCCESS = 'FETCHING_COMAPANY_REGISTRATION_CHECK_SUCCESS';
export const FETCHING_COMAPANY_REGISTRATION_CHECK_FAILED = 'FETCHING_COMAPANY_REGISTRATION_CHECK_FAILED';

export const FETCHING_EMPLOYMENT_DETAIL_REQUEST = 'FETCHING_EMPLOYMENT_DETAIL_REQUEST';
export const FETCHING_EMPLOYMENT_DETAIL_SUCCESS = 'FETCHING_EMPLOYMENT_DETAIL_SUCCESS';
export const FETCHING_EMPLOYMENT_DETAIL_FAILED = 'FETCHING_EMPLOYEMENT_DETAIL_FAILED';

export const FETCHING_INVESTOR_MANDATORY_FILEDS_REQUEST = 'FETCHING_INVESTOR_MANDATORY_FILEDS_REQUEST';
export const FETCHING_INVESTOR_MANDATORY_FILEDS_SUCCESS = 'FETCHING_INVESTOR_MANDATORY_FILEDS_SUCCESS';
export const FETCHING_INVESTOR_MANDATORY_FILEDS_FAILED = 'FETCHING_INVESTOR_MANDATORY_FILEDS_FAILED';

export const FETCHING_BORROWER_MANDATORY_FILEDS_REQUEST = 'FETCHING_BORROWER_MANDATORY_FILEDS_REQUEST';
export const FETCHING_BORROWER_MANDATORY_FILEDS_SUCCESS = 'FETCHING_BORROWER_MANDATORY_FILEDS_SUCCESS';
export const FETCHING_BORROWER_MANDATORY_FILEDS_FAILED = 'FETCHING_BORROWER_MANDATORY_FILEDS_FAILED';

export const FETCHING_GET_ALIEN_INFO_REQUEST = 'FETCHING_GET_ALIEN_INFO_REQUEST';
export const FETCHING_GET_ALIEN_INFO_SUCCESS = 'FETCHING_GET_ALIEN_INFO_SUCCESS';
export const FETCHING_GET_ALIEN_INFO_FAILED = 'FETCHING_GET_ALIEN_INFO_FAILED';

export const FETCHING_RAYAH_OTP_SEND_REQUEST = 'FETCHING_RAYAH_OTP_SEND_REQUEST';
export const FETCHING_RAYAH_OTP_SEND_SUCCESS = 'FETCHING_RAYAH_OTP_SEND_SUCCESS';
export const FETCHING_RAYAH_OTP_SEND_FAILED = 'FETCHING_RAYAH_OTP_SEND_FAILED';

export const FETCHING_RAYAH_OTP_VERIFY_REQUEST = 'FETCHING_RAYAH_OTP_VERIFY_REQUEST';
export const FETCHING_RAYAH_OTP_VERIFY_SUCCESS = 'FETCHING_RAYAH_OTP_VERIFY_SUCCESS';
export const FETCHING_RAYAH_OTP_VERIFY_FAILED = 'FETCHING_RAYAH_OTP_VERIFY_FAILED';

export const FETCHING_CITIZEN_ADDRESS_INFO_REQUEST = 'FETCHING_CITIZEN_ADDRESS_INFO_REQUEST';
export const FETCHING_CITIZEN_ADDRESS_INFO_SUCCESS = 'FETCHING_CITIZEN_ADDRESS_INFO_SUCCESS';
export const FETCHING_CITIZEN_ADDRESS_INFO_FAILED = 'FETCHING_CITIZEN_ADDRESS_INFO_FAILED';

export const FETCHING_ALIEN_ADDRESS_INFO_REQUEST = 'FETCHING_ALIEN_ADDRESS_INFO_REQUEST';
export const FETCHING_ALIEN_ADDRESS_INFO_SUCCESS = 'FETCHING_ALIEN_ADDRESS_INFO_SUCCESS';
export const FETCHING_ALIEN_ADDRESS_INFO_FAILED = 'FETCHING_ALIEN_ADDRESS_INFO_FAILED';

export const FETCHING_LOAN_PUBLSIHING_SOON_REQUEST = 'FETCHING_LOAN_PUBLSIHING_SOON_REQUEST';
export const FETCHING_LOAN_PUBLSIHING_SOON_SUCCESS = 'FETCHING_LOAN_PUBLSIHING_SOON_SUCCESS';
export const FETCHING_LOAN_PUBLSIHING_SOON_FAILED = 'FETCHING_LOAN_PUBLSIHING_SOON_FAILED';

export const FETCHING_RECENTLY_FULLY_FUNDED_REQUEST = 'FETCHING_RECENTLY_FULLY_FUNDED_REQUEST';
export const FETCHING_RECENTLY_FULLY_FUNDED_SUCCESS = 'FETCHING_RECENTLY_FULLY_FUNDED_SUCCESS';
export const FETCHING_RECENTLY_FULLY_FUNDED_FAILED = 'FETCHING_RECENTLY_FULLY_FUNDED_FAILED';

export const FETCHING_REPAYMENT_DETAIL_REQUEST = 'FETCHING_REPAYMENT_DETAIL_REQUEST';
export const FETCHING_REPAYMENT_DETAIL_SUCCESS = 'FETCHING_REPAYMENT_DETAIL_SUCCESS';
export const FETCHING_REPAYMENT_DETAIL_FAILED = 'FETCHING_REPAYMENT_DETAIL_FAILED';

export const FETCHING_PROFILE_UPDATE_REQUEST = 'FETCHING_PROFILE_UPDATE_REQUEST';
export const FETCHING_PROFILE_UPDATE_SUCCESS = 'FETCHING_PROFILE_UPDATE_SUCCESS';
export const FETCHING_PROFILE_UPDATE_FAILED = 'FETCHING_PROFILE_UPDATE_FAILED';

export const FETCHING_NAFATH_ID_VERIFY_REQUEST = 'FETCHING_NAFATH_ID_VERIFY_REQUEST';
export const FETCHING_NAFATH_ID_VERIFY_SUCCESS = 'FETCHING_NAFATH_ID_VERIFY_SUCCESS';
export const FETCHING_NAFATH_ID_VERIFY_FAILED = 'FETCHING_NAFATH_ID_VERIFY_FAILED';

export const FETCHING_STATUS_DETAILS_REQUEST = 'FETCHING_STATUS_DETAILS_REQUEST';
export const FETCHING_STATUS_DETAILS_SUCCESS = 'FETCHING_STATUS_DETAILS_SUCCESS';
export const FETCHING_STATUS_DETAILS_FAILED = 'FETCHING_STATUS_DETAILS_FAILED';

// borrower

export const FETCHING_BORROWER_DASH_REQUEST = 'FETCHING_BORROWER_DASH_REQUEST';
export const FETCHING_BORROWER_DASH_SUCCESS = 'FETCHING_BORROWER_DASH_SUCCESS';
export const FETCHING_BORROWER_DASH_FAILED = 'FETCHING_BORROWER_DASH_FAILED';

export const FETCHING_BORROWER_ALL_DETAILS_REQUEST = 'FETCHING_BORROWER_ALL_DETAILS_REQUEST';
export const FETCHING_BORROWER_ALL_DETAILS_SUCCESS = 'FETCHING_BORROWER_ALL_DETAILS_SUCCESS';
export const FETCHING_BORROWER_ALL_DETAILS_FAILED = 'FETCHING_BORROWER_ALL_DETAILS_FAILED';

export const FETCHING_BORROWER_APPLIED_LOAN_REQUEST = 'FETCHING_BORROWER_APPLIED_LOAN_REQUEST';
export const FETCHING_BORROWER_APPLIED_LOAN_SUCCESS = 'FETCHING_BORROWER_APPLIED_LOAN_SUCCESS';
export const FETCHING_BORROWER_APPLIED_LOAN_FAILED = 'FETCHING_BORROWER_APPLIED_LOAN_FAILED';

export const FETCHING_BORROWER_LOAN_APPLICATION_WITHDRAW_REQUEST = 'FETCHING_BORROWER_LOAN_APPLICATION_WITHDRAW_REQUEST';
export const FETCHING_BORROWER_LOAN_APPLICATION_WITHDRAW_SUCCESS = 'FETCHING_BORROWER_LOAN_APPLICATION_WITHDRAW_SUCCESS';
export const FETCHING_BORROWER_LOAN_APPLICATION_WITHDRAW_FAILED = 'FETCHING_BORROWER_LOAN_APPLICATION_WITHDRAW_FAILED';

export const FETCHING_BORROWER_LOAN_APPLICATION_DETAILS_REQUEST = 'FETCHING_BORROWER_LOAN_APPLICATION_DETAILS_REQUEST';
export const FETCHING_BORROWER_LOAN_APPLICATION_DETAILS_SUCCESS = 'FETCHING_BORROWER_LOAN_APPLICATION_DETAILS_SUCCESS';
export const FETCHING_BORROWER_LOAN_APPLICATION_DETAILS_FAILED = 'FETCHING_BORROWER_LOAN_APPLICATION_DETAILS_FAILED';

export const FETCHING_BORROWER_QUESTION_ADD_REQUEST = 'FETCHING_BORROWER_QUESTION_ADD_REQUEST';
export const FETCHING_BORROWER_QUESTION_ADD_SUCCESS = 'FETCHING_BORROWER_QUESTION_ADD_SUCCESS';
export const FETCHING_BORROWER_QUESTION_ADD_FAILED = 'FETCHING_BORROWER_QUESTION_ADD_FAILED';

export const FETCHING_BORROWER_ANSWER_REQUEST = 'FETCHING_BORROWER_ANSWER_REQUEST';
export const FETCHING_BORROWER_ANSWER_SUCCESS = 'FETCHING_BORROWER_ANSWER_SUCCESS';
export const FETCHING_BORROWER_ANSWER_FAILED = 'FETCHING_BORROWER_ANSWER_FAILED';

export const FETCHING_BORROWER_APPLY_LOAN_REQUEST = 'FETCHING_BORROWER_APPLY_LOAN_REQUEST';
export const FETCHING_BORROWER_APPLY_LOAN_SUCCESS = 'FETCHING_BORROWER_APPLY_LOAN_SUCCESS';
export const FETCHING_BORROWER_APPLY_LOAN_FAILED = 'FETCHING_BORROWER_APPLY_LOAN_FAILED';

export const FETCHING_BORROWER_FUNDING_REQUEST = 'FETCHING_BORROWER_FUNDING_REQUEST';
export const FETCHING_BORROWER_FUNDING_SUCCESS = 'FETCHING_BORROWER_FUNDING_SUCCESS';
export const FETCHING_BORROWER_FUNDING_FAILED = 'FETCHING_BORROWER_FUNDING_FAILED';

export const FETCHING_BORROWER_DOCUMENT_REQUEST = 'FETCHING_BORROWER_DOCUMENT_REQUEST';
export const FETCHING_BORROWER_DOCUMENT_SUCCESS = 'FETCHING_BORROWER_DOCUMENT_SUCCESS';
export const FETCHING_BORROWER_DOCUMENT_FAILED = 'FETCHING_BORROWER_DOCUMENT_FAILED';

export const FETCHING_BORROWER_TRANSACTIONS_REQUEST = 'FETCHING_BORROWER_TRANSACTIONS_REQUEST';
export const FETCHING_BORROWER_TRANSACTIONS_SUCCESS = 'FETCHING_BORROWER_TRANSACTIONS_SUCCESS';
export const FETCHING_BORROWER_TRANSACTIONS_FAILED = 'FETCHING_BORROWER_TRANSACTIONS_FAILED';

export const FETCHING_BORROWER_FINANCED_AMOUNT_REQUEST = 'FETCHING_BORROWER_FINANCED_AMOUNT_REQUEST';
export const FETCHING_BORROWER_FINANCED_AMOUNT_SUCCESS = 'FETCHING_BORROWER_FINANCED_AMOUNT_SUCCESS';
export const FETCHING_BORROWER_FINANCED_AMOUNT_FAILED = 'FETCHING_BORROWER_FINANCED_AMOUNT_FAILED';
