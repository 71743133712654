import React, { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink, useHistory } from 'react-router-dom';

import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { Box, Collapse, Dialog, DialogActions, DialogContent, DialogTitle, fade, IconButton, Link } from '@material-ui/core';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Typography from '@material-ui/core/Typography';
import { Alert } from '@material-ui/lab';
import CloseIcon from '@material-ui/icons/Close';

import CmtImage from '../../../../@coremat/CmtImage';

import IntlMessages from '../../../utils/IntlMessages';
import { AuhMethods } from '../../../../services/auth';
import ContentLoader from '../../ContentLoader';
import { CurrentAuthMethod } from '../../../constants/AppConstants';
import AuthWrapper from './AuthWrapper';
import { setForgetPassMailSent } from '../../../../redux/actions/Auth';
import { forgotPasswordFunction } from '../../../../redux/actions/forgotPassword';
import { Url } from 'redux/NetworkConfig/ApiUrlConstatnts';
import Axios from 'axios';
import Swal from 'sweetalert2';
import SnackBarComponent from 'routes/Components/SnackBar';
import AppContext from '../../contextProvider/AppContextProvider/AppContext';

const useStyles = makeStyles(theme => ({
  authThumb: {
    backgroundColor: fade(theme.palette.primary.main, 0.12),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: 20,
    [theme.breakpoints.up('md')]: {
      width: '50%',
      order: 2,
    },
  },
  authContent: {
    padding: 30,
    [theme.breakpoints.up('md')]: {
      order: 1,
      width: props => (props.variant === 'default' ? '50%' : '100%'),
    },
    [theme.breakpoints.up('xl')]: {
      padding: 50,
    },
  },
  titleRoot: {
    marginBottom: 14,
    color: theme.palette.text.primary,
  },
  textFieldRoot: {
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: fade(theme.palette.common.dark, 0.12),
    },
  },
  alertRoot: {
    marginBottom: 10,
  },
}));

//variant = 'default', 'standard', 'bgColor'
const ResetPassword = ({ method = CurrentAuthMethod, variant = 'default', wrapperVariant = 'default' }) => {
  const { send_forget_password_email } = useSelector(({ auth }) => auth);
  const [open, setOpen] = React.useState(false);
  const [email, setEmail] = useState('');
  const dispatch = useDispatch();
  const classes = useStyles({ variant });
  const history = useHistory();
  const [otp, setOtp] = useState(false);
  const [otpModal, setOtpModal] = useState(false);
  const [userUuid, setUserUuid] = useState('');
  const [response, setResponse] = useState(false);
  const [responseMessage, setResponseMessage] = useState('');
  const [responseStatus, setResponseStatus] = useState('');
  const [newPassword, setNewpassword] = useState('');
  const [confirmNewPassword, setConfirmNewpassword] = useState('');
  const { locale, setLocale } = useContext(AppContext);
  let languageCheck = localStorage.getItem('languageCheck');
  if (!languageCheck) {
    languageCheck = 'en';
  }
  const query = new URLSearchParams(window.location.search);
  const token = query.get('lang');
  const id = query.get('id');
  const switchLanguage = async item => {
    await setLocale(item);
    if (item.locale === 'en') {
      // setDirection('ltr');
      localStorage.setItem('languageCheck', 'en');
      window.location.reload();
    } else {
      // setDirection('rtl');
      localStorage.setItem('languageCheck', 'ar_JO');
      window.location.reload();
    }
    // handleClose();
  };
  let languagedata = [
    {
      languageId: 'english',
      locale: 'en',
      name: 'English',
      icon: 'us',
    },
    {
      languageId: 'saudi-arabia',
      locale: 'ar',
      name: 'عربى',
      icon: 'sa',
    },
  ];
  useEffect(() => {
    if (token !== null) {
      if (token.includes('en') || token.includes('ar')) {
        if (languageCheck.includes(token)) {
          return;
        } else if (token == 'en') {
          switchLanguage(languagedata[0]);
        } else {
          switchLanguage(languagedata[1]);
        }
      }
    }
  }, []);
  const forgotPassword = () => {
    const url = Url.baseUrl + Url.ResetPassword;

    const formData = new FormData();
    formData.append('id', id);
    formData.append('password', newPassword);
    formData.append('confirmPassword', confirmNewPassword);
    Axios({
      method: 'post',
      url: url,
      data: formData,
      headers: { 'Content-Type': 'multipart/form-data' },
    })
      .then(function(response) {
        //handle success

        if (response.data.code == '200') {
          // alert(response.data.status + ' ' + response.data.message);
          Swal.fire({
            text: (languageCheck = 'en' ? 'Password Reset Done' : 'تمت إعادة تعيين كلمة المرور'),
            showDenyButton: false,
            showCancelButton: false,
            icon: 'success',
            confirmButtonText: 'Okay',
          }).then(result => {
            /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed) {
              history.push('/signin');
            } else if (result.isDenied) {
              return;
            }
          });
        } else {
          // alert(response.data.status + ' ' + response.data.message);
          Swal.fire('Failure', (languageCheck = 'en' ? 'Password Reset Failed' : 'فشل إعادة تعيين كلمة المرور'), 'error');
        }
      })
      .catch(function(response) {
        //handle error
        alert(response);
        // Swal.fire(response.);
      });
  };

  useEffect(() => {
    let timeOutStopper = null;
    if (send_forget_password_email) {
      setOpen(true);

      timeOutStopper = setTimeout(() => {
        dispatch(setForgetPassMailSent(false));
        history.push('/');
      }, 1500);
    }

    return () => {
      if (timeOutStopper) clearTimeout(timeOutStopper);
    };
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [send_forget_password_email]);

  const onSubmit = () => {
    dispatch(AuhMethods[method].onForgotPassword({ email }));
  };

  return (
    <AuthWrapper variant={wrapperVariant}>
      <SnackBarComponent
        status={responseStatus}
        visible={response}
        setResponse={setResponse}
        message={responseMessage}
        setResponseStatus={setResponseStatus}
        setResponseMessage={setResponseMessage}
      />
      {variant === 'default' ? (
        <div className={classes.authThumb}>
          <CmtImage src={'/images/auth/forgot-img.png'} />
        </div>
      ) : null}
      <div className={classes.authContent}>
        <Typography component="div" variant="h1" className={classes.titleRoot}>
          <IntlMessages id="appModule.resetPassword" />
        </Typography>
        <Collapse in={open}>
          <Alert
            variant="outlined"
            severity="success"
            className={classes.alertRoot}
            action={
              <IconButton
                aria-label="close"
                color="inherit"
                size="small"
                onClick={() => {
                  setOpen(false);
                }}>
                <CloseIcon fontSize="inherit" />
              </IconButton>
            }>
            A mail has been sent on your email address with reset password link.
          </Alert>
        </Collapse>
        <form>
          <div className={'mb-5'}>
            <TextField
              label={<IntlMessages id="dashboard.newpassword" />}
              fullWidth
              onChange={event => setNewpassword(event.target.value)}
              defaultValue={newPassword}
              margin="normal"
              variant="outlined"
              type={'password'}
              className={classes.textFieldRoot}
            />
            <TextField
              label={<IntlMessages id="dashboard.renewpassword" />}
              fullWidth
              onChange={event => setConfirmNewpassword(event.target.value)}
              defaultValue={confirmNewPassword}
              margin="normal"
              type={'password'}
              variant="outlined"
              className={classes.textFieldRoot}
            />
          </div>
          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', flexDirection: 'row' }}>
            <div className={'mb-5'}>
              <Button onClick={() => forgotPassword()} variant="contained" color="primary">
                <IntlMessages id="appModule.resetPassword" />
              </Button>
            </div>
            <div>
              <Box component="p" fontSize={{ xs: 12, sm: 16 }}>
                <NavLink to="/">
                  <IntlMessages id="profile.backtosignin" />
                </NavLink>
              </Box>
            </div>
          </div>
        </form>
        <ContentLoader />
      </div>
    </AuthWrapper>
  );
};

export default ResetPassword;
