import React, { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink, useHistory } from 'react-router-dom';

import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { Box, Collapse, Dialog, DialogActions, DialogContent, DialogTitle, fade, IconButton, Link } from '@material-ui/core';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Typography from '@material-ui/core/Typography';
import { Alert } from '@material-ui/lab';
import CloseIcon from '@material-ui/icons/Close';

import CmtImage from '../../../../@coremat/CmtImage';

import IntlMessages from '../../../utils/IntlMessages';
import { AuhMethods } from '../../../../services/auth';
import ContentLoader from '../../ContentLoader';
import { CurrentAuthMethod } from '../../../constants/AppConstants';
import AuthWrapper from './AuthWrapper';
import { setForgetPassMailSent } from '../../../../redux/actions/Auth';
import { forgotPasswordFunction } from '../../../../redux/actions/forgotPassword';
import { Url } from 'redux/NetworkConfig/ApiUrlConstatnts';
import Axios from 'axios';
import Swal from 'sweetalert2';
import SnackBarComponent from 'routes/Components/SnackBar';
import AppContext from '../../contextProvider/AppContextProvider/AppContext';

const useStyles = makeStyles(theme => ({
  authThumb: {
    backgroundColor: fade(theme.palette.primary.main, 0.12),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: 20,
    [theme.breakpoints.up('md')]: {
      width: '50%',
      order: 2,
    },
  },
  authContent: {
    padding: 30,
    [theme.breakpoints.up('md')]: {
      order: 1,
      width: props => (props.variant === 'default' ? '50%' : '100%'),
    },
    [theme.breakpoints.up('xl')]: {
      padding: 50,
    },
  },
  titleRoot: {
    marginBottom: 14,
    color: theme.palette.text.primary,
  },
  textFieldRoot: {
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: fade(theme.palette.common.dark, 0.12),
    },
  },
  alertRoot: {
    marginBottom: 10,
  },
}));

//variant = 'default', 'standard', 'bgColor'
const Activation = ({ method = CurrentAuthMethod, variant = 'default', wrapperVariant = 'default' }) => {
  const { send_forget_password_email } = useSelector(({ auth }) => auth);
  const [open, setOpen] = React.useState(false);
  const [email, setEmail] = useState('');
  const dispatch = useDispatch();
  const classes = useStyles({ variant });
  const history = useHistory();
  const [otp, setOtp] = useState(false);
  const [otpModal, setOtpModal] = useState(false);
  const [userUuid, setUserUuid] = useState('');
  const [response, setResponse] = useState(false);
  const [responseMessage, setResponseMessage] = useState('');
  const [responseStatus, setResponseStatus] = useState('');
  const [newPassword, setNewpassword] = useState('');
  const [confirmNewPassword, setConfirmNewpassword] = useState('');
  const { locale, setLocale } = useContext(AppContext);
  let languageCheck = localStorage.getItem('languageCheck');
  if (!languageCheck) {
    languageCheck = 'en';
  }
  const query = new URLSearchParams(window.location.search);
  const token = query.get('lang');
  const id = query.get('verificationToken');
  // console.log(id, 'verification');
  const switchLanguage = async item => {
    await setLocale(item);
    if (item.locale === 'en') {
      // setDirection('ltr');
      localStorage.setItem('languageCheck', 'en');
      window.location.reload();
    } else {
      // setDirection('rtl');
      localStorage.setItem('languageCheck', 'ar_JO');
      window.location.reload();
    }
    // handleClose();
  };
  let languagedata = [
    {
      languageId: 'english',
      locale: 'en',
      name: 'English',
      icon: 'us',
    },
    {
      languageId: 'saudi-arabia',
      locale: 'ar',
      name: 'عربى',
      icon: 'sa',
    },
  ];
  useEffect(() => {
    if (token !== null) {
      if (token.includes('en') || token.includes('ar')) {
        if (languageCheck.includes(token)) {
          return;
        } else if (token == 'en') {
          switchLanguage(languagedata[0]);
        } else {
          switchLanguage(languagedata[1]);
        }
      }
    }
    forgotPassword();
  }, []);

  const forgotPassword = () => {
    const url = Url.baseUrl + Url.Activation + '=' + id;

    Axios({
      method: 'get',
      url: url,
      headers: { 'Content-Type': 'multipart/form-data' },
    })
      .then(function(response) {
        //handle success

        if (response.data == 'Either the token is invalid or it has expired.') {
          // alert(response.data.status + ' ' + response.data.message);
          Swal.fire({
            text: (languageCheck = 'en'
              ? 'The token is invalid or expired. Please log in and revalidate your email'
              : 'رابط التحقق من البريد الإلكتروني قد إنتهى، الرجاء الدخول إلى الموقع لإرسال رابط جديد'),
            showDenyButton: false,
            showCancelButton: false,
            allowOutsideClick: false,
            icon: 'error',
            confirmButtonText: 'Okay',
          }).then(result => {
            /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed) {
              history.push('/signin');
            } else if (result.isDenied) {
              return;
            }
          });
        } else {
          // alert(response.data.status + ' ' + response.data.message);
          Swal.fire({
            text: (languageCheck = 'en'
              ? 'Your account is activated successfully. Please login to our system'
              : 'تم التحقق من بريدك الإلكتروني بنجاح'),
            showDenyButton: false,
            showCancelButton: false,
            allowOutsideClick: false,
            icon: 'success',
            confirmButtonText: 'Okay',
          }).then(result => {
            /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed) {
              history.push('/signin');
            } else if (result.isDenied) {
              return;
            }
          });
        }
      })
      .catch(function(response) {
        //handle error
        alert(response);
        // Swal.fire(response.);
      });
  };

  useEffect(() => {
    let timeOutStopper = null;
    if (send_forget_password_email) {
      setOpen(true);

      timeOutStopper = setTimeout(() => {
        dispatch(setForgetPassMailSent(false));
        history.push('/');
      }, 1500);
    }

    return () => {
      if (timeOutStopper) clearTimeout(timeOutStopper);
    };
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [send_forget_password_email]);

  const onSubmit = () => {
    dispatch(AuhMethods[method].onForgotPassword({ email }));
  };

  return <AuthWrapper variant={wrapperVariant}></AuthWrapper>;
};

export default Activation;
