// import {ToastAndroid} from 'react-native';
import Axios from 'axios';
import { Url } from './ApiUrlConstatnts';

// import {store} from '../storeConfig/storeConfig';
// const {dispatch} = store;

const client = Axios.create({
  baseURL: Url.baseUrl,
  responseType: 'json',
  timeout: 2 * 60 * 1000,
});
/**
 * Request Wrapper with default success/error actions
 */
client.interceptors.response.use(
  function(response) {
    // Do something with response data

    return response;
  },
  function(error) {
    // Do something with response error

    // console.log('Status1:', error.response.status);
    // console.log('Data1:', error.response.data);
    // console.log('Headers1:', error.response.headers);
    // console.log('Code Error1', error.response.code);

    const status = error.status || error.response.status;
    if (status === 400) {
      // constant.toastAlert('400', ToastAndroid.LONG);
      // dispatch(autoLogout());
    }
    return Promise.reject(error);
  },
);

const httpRequest = function(options) {
  // console.log('###REQUEST', options);

  const onSuccess = function(response) {
    // console.log('###RESPONSE', response);
    // console.debug('Request Successful!', response);

    return response;
  };

  const onError = function(error) {
    if (error.response) {
      // console.log('Status:', error.response.status);
      // console.log('Data:', error.response.data);
      // console.log('Headers:', error.response.headers);
      // console.log('Code Error', error.response.code);
      // console.log('Error Req', options);
    } else if (error.request) {
      // console.log(error.request);
    } else {
      // Something else happened while setting up the request
      // triggered the error
      // console.log('Error Message:', error.message);
    }

    return error.response;
  };

  return client(options)
    .then(onSuccess)
    .catch(onError);
};
export { httpRequest };
