import createBreakpoints from '@material-ui/core/styles/createBreakpoints';
import AmpleSoftPro from './fonts/AmpleSoftPro-Regular.ttf';
import Tajawal from './fonts/Tajawal-Regular.ttf';

const breakpoints = createBreakpoints({
  values: {
    xs: 0,
    sm: 600,
    md: 960,
    lg: 1280,
    xl: 1920,
  },
});

let languageCheck = localStorage.getItem('languageCheck');
if (!languageCheck) {
  languageCheck = 'en';
}

const AmpleSoftProRegular = {
  fontFamily: '"AmpleSoftPro-Regular"',
  src: `local("AmpleSoftPro"), 
  local("AmpleSoftPro-Regular.ttf"), 
  url(${AmpleSoftPro}) format("opentype")`,
};

const TajawalRegular = {
  fontFamily: '"Tajawal-Regular"',
  src: `local("Tajawal"), 
  local("Tajawal-Regular.ttf"), 
  url(${Tajawal}) format("opentype")`,
};
export const fontSizeratio = 0;
const defaultTheme = {
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1280,
      xl: 1920,
    },
  },
  spacing: 4,
  direction: languageCheck === 'en' ? 'ltr' : 'rtl',
  palette: {
    type: 'light',
    common: {
      black: '#000',
      white: '#fff',
      dark: '#020202',
    },
    primary: {
      main: '#37AA57',
      dark: '#28A4DB',
      contrastText: '#fff',
    },
    secondary: {
      main: '#03DAC5',
      dark: '#28A4DB',
      contrastText: '#fff',
    },
    success: {
      light: '#D7F5B1',
      main: '#8DCD03',
      dark: '#5D9405',
    },
    info: {
      light: '#9BE7FD',
      main: '#0795F4',
      dark: '#0356AF',
    },
    warning: {
      light: '#FFDE99',
      main: '#FF8C00',
      dark: '#D36F1A',
    },
    error: {
      light: '#FFC7D1',
      main: '#E00930',
      dark: '#87061E',
    },
    sidebar: {
      bgColor: '#fff',
      textColor: 'rgba(0, 0, 0, 0.6)',
      textDarkColor: 'rgba(0, 0, 0, 0.87)',
      textActiveColor: '#37AA57',
      navHoverBgColor: 'rgb(229, 229, 229)',
      navActiveBgColor: 'rgb(239, 229, 253)',
      borderColor: 'rgba(33, 33, 33, 0.08)',
    },
    horizontalNav: {
      navigationColor: 'rgba(255, 255, 255, 0.74)',
      navigationActiveColor: 'rgba(255, 255, 255, 1)',
      textColor: 'rgba(0, 0, 0, 0.6)',
      textDarkColor: 'rgba(0, 0, 0, 0.87)',
      textActiveColor: '#37AA57',
      menuHoverBgColor: 'rgb(229, 229, 229)',
      menuActiveBgColor: 'rgb(239, 229, 253)',
    },
    background: {
      paper: '#FFFFFF',
      default: '#f4f4f7',
    },
    text: {
      primary: 'rgba(0, 0, 0, 0.87)',
      secondary: 'rgba(0, 0, 0, 0.6)',
      disabled: 'rgba(0, 0, 0, 0.38)',
      hint: 'rgba(0, 0, 0, 0.3)',
      white: '#fff',
    },
    btn: {
      hover: 'rgba(0, 0, 0, 0.08)',
    },
    lightBtn: {
      bgColor: '#f5f5f5',
      textColor: 'rgba(0, 0, 0, 0.38)',
    },
    borderColor: {
      main: 'rgba(0, 0, 0, 0.06)',
      dark: 'rgba(0, 0, 0, 0.12)',
    },
    popupColor: {
      main: '#fff',
    },
  },
  status: {
    danger: 'orange',
  },
  typography: {
    fontFamily: languageCheck == 'en' ? 'AmpleSoftPro-Regular' : 'Tajawal-Regular',
    fontWeightExtraLight: 200,
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightBold: 'bold',
    fontWeightExtraBold: 800,
  },
  overrides: {
    MuiCssBaseline: {
      '@global': {
        '@font-face': languageCheck === 'en' ? AmpleSoftProRegular : TajawalRegular,
        html: {
          fontSize: 13,
          [breakpoints.up('md')]: {
            fontSize: 14,
          },
          [breakpoints.up('md')]: {
            fontSize: 15,
          },
          [breakpoints.up('lg')]: {
            fontSize: 16,
          },
        },
      },
    },

    MuiTypography: {
      h1: {
        fontFamily: languageCheck == 'en' ? 'AmpleSoftPro-Regular' : 'Tajawal-Regular',
        fontSize: fontSizeratio + 20,
        fontWeight: 'bold',
        [breakpoints.up('md')]: {
          fontSize: fontSizeratio + 22,
        },
      },
      h2: {
        fontFamily: languageCheck == 'en' ? 'AmpleSoftPro-Regular' : 'Tajawal-Regular',
        fontSize: fontSizeratio + 18,
        fontWeight: 'bold',
        [breakpoints.up('md')]: {
          fontSize: fontSizeratio + 20,
        },
      },
      h3: {
        fontFamily: languageCheck == 'en' ? 'AmpleSoftPro-Regular' : 'Tajawal-Regular',
        fontSize: fontSizeratio + 16,
        fontWeight: 'bold',
        [breakpoints.up('md')]: {
          fontSize: fontSizeratio + 18,
        },
      },
      h4: {
        fontFamily: languageCheck == 'en' ? 'AmpleSoftPro-Regular' : 'Tajawal-Regular',
        fontSize: fontSizeratio + 16,
        fontWeight: 'bold',
      },
      h5: {
        fontFamily: languageCheck == 'en' ? 'AmpleSoftPro-Regular' : 'Tajawal-Regular',
        fontSize: fontSizeratio + 14,
        fontWeight: 400,
      },
      h6: {
        fontFamily: languageCheck == 'en' ? 'AmpleSoftPro-Regular' : 'Tajawal-Regular',
        fontSize: fontSizeratio + 14,
        fontWeight: 'bold',
        letterSpacing: 0.5,
      },
      subtitle1: {
        fontFamily: languageCheck == 'en' ? 'AmpleSoftPro-Regular' : 'Tajawal-Regular',
        fontSize: fontSizeratio + 16,
        fontWeight: 400,
        letterSpacing: 0.15,
      },
      subtitle2: {
        fontFamily: languageCheck == 'en' ? 'AmpleSoftPro-Regular' : 'Tajawal-Regular',
        fontSize: fontSizeratio + 14,
        fontWeight: 'bold',
        letterSpacing: 0.1,
      },
      body1: {
        fontFamily: languageCheck == 'en' ? 'AmpleSoftPro-Regular' : 'Tajawal-Regular',
        fontSize: fontSizeratio + 16,
        fontWeight: 400,
        letterSpacing: 0.5,
      },
      body2: {
        fontFamily: languageCheck == 'en' ? 'AmpleSoftPro-Regular' : 'Tajawal-Regular',
        fontSize: fontSizeratio + 14,
        fontWeight: 400,
        letterSpacing: 0.25,
      },
    },
    MuiButton: {
      root: {
        fontFamily: languageCheck == 'en' ? 'AmpleSoftPro-Regular' : 'Tajawal-Regular',
        fontWeight: 'bold',
        letterSpacing: 1.25,
        fontSize: fontSizeratio + 13,
      },
    },
    MuiToggleButton: {
      root: {
        borderRadius: 4,
      },
    },
    MuiCardLg: {
      root: {
        borderRadius: 10,
      },
    },
    MuiCard: {
      root: {
        borderRadius: 4,
        boxShadow: '0px 1px 3px rgba(0, 0, 0, 0.2), 0px 2px 1px rgba(0, 0, 0, 0.12), 0px 1px 1px rgba(0, 0, 0, 0.14)',
      },
    },
    MuiTab: {
      textColorPrimary: {
        color: 'rgba(0, 0, 0, 0.87)',
      },
    },
    MuiPopover: {
      paper: {
        backgroundColor: '#FFFFFF',
      },
    },
    MuiDialog: {
      paper: {
        backgroundColor: '#FFFFFF',
      },
    },
  },
};
export default defaultTheme;
