/* eslint-disable no-unused-vars */
/* eslint-disable react/jsx-no-target-blank */
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Grid, Box, Paper, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import InstagramIcon from '@material-ui/icons/Instagram';
import TwitterIcon from '@material-ui/icons/Twitter';
import FacebookIcon from '@material-ui/icons/Facebook';
import LinkedInIcon from '@material-ui/icons/LinkedIn';
import IntlMessages from '@jumbo/utils/IntlMessages';
import { websiteUrl } from '../Header/Header';

const Apple = 'https://raqamyah.com/assetsNew/images/apple-icon.png';
const Google = 'https://raqamyah.com/assetsNew/images/google-icon.png';
let selected = localStorage.getItem('i18nextLng')?.includes('en') ? 'en' : 'ar';
if (selected === undefined || selected === null) {
  selected = 'en';
}
let languageCheck = localStorage.getItem('languageCheck');
if (!languageCheck) {
  languageCheck = 'en';
}
const lang = languageCheck == 'en' ? 'en' : 'ar';

const styles = theme => ({
  icon: {
    width: '25px',
    height: '25px',
    background: '#3BB257',
    borderRadius: '20px',
    padding: '5px',
    margin: '5px',
    color: '#ffff',
  },
  footer_container: {
    padding: '5px',
  },

  footer_text: {
    color: '#fff',
    fontWeight: 'bold',
    marginTop: '10px',
    transform: 'none',
    overflow: 'hidden',

    textDecoration: 'none !important',
    '&:hover,&:focus': {
      color: '#fff',
      textDecoration: 'none',
    },
  },

  footer_pra: {
    fontSize: '14px !important',
    marginTop: '10px',

    color: '#fff',
    textDecoration: 'none',
    '&:hover,&:focus': {
      color: '#fff',
      textDecoration: 'none',
    },
  },
  footer_box: {
    padding: '25px 50px 25px 50px',
    backgroundColor: '#1E396C',
    color: '#fff',
    [theme.breakpoints.down('sm')]: {
      padding: '25px 50px 25px 50px',
      backgroundColor: '#1E396C',
    },
  },
  footerIconsContainer: {
    marginTop: 35,
    display: 'flex',
    [theme.breakpoints.down('sm')]: {
      marginTop: 10,
      width: 'fit-content',
      display: 'flex',
      flexWrap: 'initial',
    },
    [theme.breakpoints.down('xs')]: {
      marginTop: 10,
      display: 'flex',
      flexWrap: 'initial',
    },
  },
  footerIcons: {
    display: 'flex',
    margin: 5,
    [theme.breakpoints.down('sm')]: {
      display: 'flex',
      margin: 5,
      flexWrap: 'initial',
    },
    [theme.breakpoints.down('xs')]: {
      margin: 5,
      display: 'flex',
      flexWrap: 'initial',
    },
  },
  footer_praClick: {
    fontSize: '14px !important',
    marginTop: '10px',

    color: '#37A753',
    textDecoration: 'none',
    '&:hover,&:focus': {
      color: '#37A753',
      textDecoration: 'none',
    },
  },
});

const useStyles = makeStyles(styles);

export default function FooterTop() {
  const logo = 'https://d1rfd0ppcouvna.cloudfront.net/public/assetsNew/images/logo/logoFooter.webp';
  const Apple = "https://d1rfd0ppcouvna.cloudfront.net/public/assetsNew/images/mobilePromotion/ap.webp";
  const Google = "https://d1rfd0ppcouvna.cloudfront.net/public/assetsNew/images/mobilePromotion/gp.webp";
  const consumerprotectionEn = "https://d1rfd0ppcouvna.cloudfront.net/public/assetsNew/images/pdf/Consumer_Protection_EN.pdf";
  const consumerprotectionAr = "https://d1rfd0ppcouvna.cloudfront.net/public/assetsNew/images/pdf/حماية%20العملاء.pdf"
  const classes = useStyles();
  const { t } = useTranslation();
  const [isVisible, setIsVisible] = useState(false);

  // Top: 0 takes us all the way back to the top of the page
  // Behavior: smooth keeps it smooth!
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  useEffect(() => {
    // Button is displayed after scrolling for 500 pixels
    const toggleVisibility = () => {
      if (window.pageYOffset > 500) {
        setIsVisible(true);
      } else {
        setIsVisible(false);
      }
    };

    window.addEventListener('scroll', toggleVisibility);

    return () => window.removeEventListener('scroll', toggleVisibility);
  }, []);
  return (
    <footer>
      <Box className={classes.footer_box}>
        <Grid container direction="row">
          <Grid item xs={12} md={4} container direction="column" className={classes.footer_container}>
            <Link href="#">
              <img src={logo} alt="Logo" style={{ width: '130px', height: '60px' }} />
            </Link>
            <div className={classes.right}>
              <a target="_blank" href="https://www.facebook.com/raqamyahksa/">
                <FacebookIcon className={classes.icon} />
              </a>
              <a target="_blank" href="https://twitter.com/raqamyah">
                <TwitterIcon className={classes.icon} />
              </a>
              <a target="_blank" href="https://www.linkedin.com/company/raqamyah/?viewAsMember=true">
                <LinkedInIcon className={classes.icon} />
              </a>
              <a target="_blank" href="https://www.instagram.com/raqamyahksa/">
                <InstagramIcon className={classes.icon} />
              </a>
            </div>
            <Typography className={classes.footer_pra}>{<IntlMessages id="Footer Address1" />}</Typography>
            <Typography className={classes.footer_pra}>{<IntlMessages id="Footer Address2" />}</Typography>
            <Typography className={classes.footer_pra}>{<IntlMessages id="Footer Address3" />}</Typography>
          </Grid>
          <Grid item xs={12} md={2} container direction="column" className={classes.footer_container}>
            <a className={classes.footer_text} onClick={scrollToTop} href={websiteUrl + 'About'}>
              {' '}
              {<IntlMessages id="About" />}
            </a>
            <a className={classes.footer_text} onClick={scrollToTop} href={websiteUrl + 'Get_financed'}>
              {' '}
              {<IntlMessages id="Get Financed" />}
            </a>
            <a className={classes.footer_text} onClick={scrollToTop} href={websiteUrl + 'Finance'}>
              {' '}
              {<IntlMessages id="Finance" />}
            </a>
            <a className={classes.footer_text} onClick={scrollToTop} href={websiteUrl + 'posFinance'}>
              {' '}
              {<IntlMessages id="Pos Finance" />}
            </a>
            <a className={classes.footer_text} onClick={scrollToTop} href={websiteUrl + 'Howitwork'}>
              {' '}
              {<IntlMessages id="How It Works" />}
            </a>
            <a className={classes.footer_text} onClick={scrollToTop} href={websiteUrl + 'Contact'}>
              {' '}
              {<IntlMessages id="Contact" />}
            </a>

          </Grid>
          <Grid item xs={12} md={3} container direction="column" className={classes.footer_container}>
            <a className={classes.footer_text} onClick={scrollToTop} href={websiteUrl + 'Legal'}>
              {<IntlMessages id="Legal" />}
            </a>
            <a className={classes.footer_text} onClick={scrollToTop} href={websiteUrl + 'LearnMore'}>
              {<IntlMessages id="Learn more as a Financer" />}
            </a>
            <a className={classes.footer_text} onClick={scrollToTop} href={websiteUrl + 'LearnMoreFinancee'}>
              {<IntlMessages id="Learn more as a Financee (Debtor)" />}
            </a>
            <a className={classes.footer_text} onClick={scrollToTop} href={websiteUrl + 'Faq'}>
              {<IntlMessages id="FAQ" />}
            </a>
            <a className={classes.footer_text} href={languageCheck === 'en' ? consumerprotectionEn: consumerprotectionAr} target="_blank" ><IntlMessages id="Consumer protection" /></a>
            <a className={classes.footer_text} href={websiteUrl + 'consumerProtection'}><IntlMessages id="Complains and suggestions" /></a>
            {/* <Grid className={classes.footerIconsContainer} container>
              <Grid item xs={12} md={6}>
                <img className={classes.footerIcons} style={{ width: '125px', height: '40px' }} src={Apple} />
              </Grid>
              <Grid item xs={12} md={6}>
                <img className={classes.footerIcons} style={{ width: '125px', height: '40px' }} src={Google} />
              </Grid>
            </Grid> */}
          </Grid>
          <Grid item xs={12} md={3} container direction="column" className={classes.footer_container}>
            <Typography className={classes.footer_pra}>{<IntlMessages id="Footer Discription1" />}</Typography>
            <Typography className={classes.footer_pra}>{<IntlMessages id="Footer Discription2" />}</Typography>
            <Typography className={classes.footer_pra}>
              {<IntlMessages id="Footer Discription3" />}
              <span>
                {' '}
                <a
                  className={classes.footer_praClick}
                  target="_blank"
                  href="https://www.sama.gov.sa/ar-sa/-SandBox/Pages/Permitted-Fintechs.aspx">
                  {<IntlMessages id="Footer Discription4" />}
                </a>
              </span>
            </Typography>
            <Grid className={classes.footerIconsContainer}>
              <Grid item xs={12} md={6}>
                <img style={{cursor:'pointer'}} alt="Apple" className={classes.footerIcons} width={120} height={39} onClick={() =>
                  window.open(
                    "https://apps.apple.com/sa/app/raqamyah-%D8%B1%D9%82%D9%85%D9%8A%D8%A9/id6443498463",
                    "_blank")} src={Apple} />
              </Grid>
              <Grid item xs={12} md={6}>
                <img alt="google" style={{cursor:'pointer'}} className={classes.footerIcons} width={120} height={39} onClick={() =>
                  window.open(
                    "https://play.google.com/store/apps/details?id=com.raqamyah",
                    "_blank"
                  )} src={Google} />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </footer>
  );
}
