import * as types from '../actionTypes/types';

const initialState = {
  isFetching: false,
  errorMessage: '',
};

const logoutReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.FETCHING_USER_LOGOUT_DETAILS_REQUEST:
      return { ...state, isFetching: true };
    case types.FETCHING_USER_LOGOUT_DETAILS_FAILED:
      return {
        ...state,
        isFetching: false,
      };
    case types.FETCHING_USER_LOGOUT_DETAILS_SUCCESS:
      state = initialState;
      return state;

    default:
      return state;
  }
};
export default logoutReducer;
