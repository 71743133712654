import React from 'react';
import Hidden from '@material-ui/core/Hidden';
import { Box } from '@material-ui/core';
import { NavLink } from 'react-router-dom';
import CmtImage from '../../../../@coremat/CmtImage';

const Logo = ({ color, ...props }) => {
  let languageCheck = localStorage.getItem('languageCheck');
  if (!languageCheck) {
    languageCheck = 'en';
  }
  const logoUrl = languageCheck === 'en' ? '/images/Raqamyah_white_Logo.png' : '/images/Raqamyah_white_Logo.png';
  const logoSymbolUrl = languageCheck === 'en' ? '/images/Raqamyah_white_Logo.png' : '/images/Raqamyah_white_Logo.png';

  return (
    <Box className="pointer" {...props}>
      <Hidden xsDown>
        <CmtImage style={{ height: '58px' }} src={logoUrl} alt="Raqmyah Platform" />
      </Hidden>
      <Hidden smUp>
        <CmtImage style={{ height: '58px' }} src={logoSymbolUrl} alt="Raqamyah Platform" />
      </Hidden>
    </Box>
  );
};

export default Logo;
