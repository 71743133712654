import React, { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, fade, FormControl, FormHelperText, Grid, InputLabel, MenuItem, Select, Snackbar } from '@material-ui/core';
import MuiAlert from '@material-ui/lab/Alert';
import IntlMessages from '../../../utils/IntlMessages';
import { AuhMethods } from '../../../../services/auth';
import ContentLoader from '../../ContentLoader';
import makeStyles from '@material-ui/core/styles/makeStyles';
import CmtImage from '../../../../@coremat/CmtImage';
import Typography from '@material-ui/core/Typography';
import { CurrentAuthMethod } from '../../../constants/AppConstants';
import AuthWrapper from './AuthWrapper';
import { NavLink } from 'react-router-dom';
import { history } from 'redux/store';
import { registrationStepOneBasicFunction } from 'redux/actions/registrationStepOneBasic';
// import Alert from 'routes/Components/MuiComponents/Alert';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField } from '@material-ui/core';
import Swal from 'sweetalert2';
import Loader from 'routes/Components/pageLoaderCustom/loader';
import { investorMandatoryFunction } from '../../../../redux/actions/investorMandotaryFields';
import Axios from 'axios';
import { headers, Url } from '../../../../redux/NetworkConfig/ApiUrlConstatnts';
import SnackBarComponent from 'routes/Components/SnackBar';
import PageLoader from '@jumbo/components/PageComponents/PageLoader';
import AppContext from '@jumbo/components/contextProvider/AppContextProvider/AppContext';

const useStyles = makeStyles(theme => ({
  authThumb: {
    backgroundColor: fade(theme.palette.primary.main, 0.12),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: 20,
    [theme.breakpoints.up('md')]: {
      width: '50%',
      order: 2,
    },
  },
  authContent: {
    padding: 30,
    [theme.breakpoints.up('md')]: {
      width: props => (props.variant === 'default' ? '50%' : '100%'),
      order: 1,
    },
    [theme.breakpoints.up('xl')]: {
      padding: 50,
    },
  },
  titleRoot: {
    marginBottom: 14,
    color: theme.palette.text.primary,
  },
  textFieldRoot: {
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: fade(theme.palette.common.dark, 0.12),
    },
  },
  textCapital: {
    textTransform: 'capitalize',
  },
  textAcc: {
    textAlign: 'center',
    '& a': {
      marginLeft: 4,
    },
  },
  alrTextRoot: {
    textAlign: 'center',
    [theme.breakpoints.up('sm')]: {
      textAlign: 'right',
    },
  },
}));
//variant = 'default', 'standard', 'bgColor'
const SignUp = ({ method = CurrentAuthMethod, variant = 'default', wrapperVariant = 'default' }) => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [confirmEmail, setConfirmEmail] = useState('');
  // const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [mobile, setMobile] = useState('');
  const [status, setStatus] = useState('');
  const [message, setMessage] = useState('');
  const [code, setCode] = useState('');
  const [showFields, setShowFields] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const dispatch = useDispatch();
  const classes = useStyles({ variant });
  const [mandatoryFields, setmandatoryFields] = useState({});
  const [otpModal, setOtpModal] = useState(false);
  const [otp, setOtp] = useState('');
  const [uniqueEmail, setUniqueEmail] = useState(false);
  const [uniqueEmailMessage, setUniqueEmailMessage] = useState('');
  const [uniqueMobile, setUniqueMobile] = useState(false);
  const [uniqueMobileMessage, setUniqueMobileMessage] = useState('');
  const [showsnakbar, setshowsnakbar] = useState('');
  const [response, setResponse] = useState(false);
  const [responseMessage, setResponseMessage] = useState('');
  const [responseStatus, setResponseStatus] = useState('');
  let alreadyvalidEmail = localStorage.getItem('emailValidated');
  let alreadyvalidmMobile = localStorage.getItem('mobilevliadated');

  const [emailValidated, setEmailValidated] = useState(alreadyvalidEmail || false);
  const [mobilevliadated, setMobileValidated] = useState(alreadyvalidmMobile || false);
  const [isFetching, setisFetching] = React.useState(false);
  const [errorMsg, setErrorMsg] = useState([]);
  const { locale, setLocale } = useContext(AppContext);
  let languageCheck = localStorage.getItem('languageCheck');
  if (!languageCheck) {
    languageCheck = 'en';
  }
  const query = new URLSearchParams(window.location.search);
  const token = query.get('lang');
  const switchLanguage = async item => {
    await setLocale(item);
    if (item.locale === 'en') {
      // setDirection('ltr');
      localStorage.setItem('languageCheck', 'en');
      window.location.reload();
    } else {
      // setDirection('rtl');
      localStorage.setItem('languageCheck', 'ar_JO');
      window.location.reload();
    }
    // handleClose();
  };
  let languagedata = [
    {
      languageId: 'english',
      locale: 'en',
      name: 'English',
      icon: 'us',
    },
    {
      languageId: 'saudi-arabia',
      locale: 'ar',
      name: 'عربى',
      icon: 'sa',
    },
  ];
  useEffect(() => {
    if (token !== null) {
      if (token.includes('en') || token.includes('ar')) {
        if (languageCheck.includes(token)) {
          return;
        } else if (token == 'en') {
          switchLanguage(languagedata[0]);
        } else {
          switchLanguage(languagedata[1]);
        }
      }
    }
  }, []);
  const handlePassword = val => {
    var p = val;
    let errors = [];
    if (p.length < 8) {
      errors.push(<IntlMessages id="error.passwordcharacter" />);
    }
    if (p.search(/[a-z]/i) < 0) {
      errors.push(<IntlMessages id="error.passwordletter" />);
    }
    if (!/[A-Z]/.test(p)) {
      errors.push(<IntlMessages id="error.passworduppercase" />);
    }
    if (p.search(/[0-9]/) < 0) {
      errors.push(<IntlMessages id="error.passworddigits" />);
    }
    if (p.search(/.*[\W_]/) < 0) {
      errors.push(<IntlMessages id="error.passwordspecialchar" />);
    }

    if (errors.length > 0) {
      setErrorMsg(errors);

      return false;
    } else {
      setErrorMsg([]);
      setpassword(p);
    }

    return true;
  };
  const checkUniqueEmail = async () => {
    let inputData = {
      email: userName,
    };
    const url = Url.baseUrl + Url.CheckUniqueEmail;

    // console.log(inputData, 'inputData');
    var config = {
      method: 'post',
      url: url,
      headers: headers.headFinancing,
      data: inputData,
    };
    const res = await Axios(config);
    // console.log(res.data, 'uniqueemail');
    if (res.data.status == '200') {
      setEmailValidated(true);
    } else {
      setEmailValidated(false);
    }
    setUniqueEmailMessage(res?.data);
    setUniqueEmail(true);
  };

  function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }
  const checkUniqueMobile = async () => {
    const url = Url.baseUrl + Url.CheckUniqueMobile + `${contact}`;
    const res = await Axios.get(url);
    // console.log(res?.data, 'uniquemobile');
    if (res.data.status == '200') {
      setMobileValidated(true);
    } else {
      setMobileValidated(false);
    }
    setUniqueMobileMessage(res?.data);
    setUniqueMobile(true);
  };
  function checkPassword(str) {
    var re = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[#$@!%&*?])[A-Za-z\d#$@!%&*?]{8,20}$/;
    return re.test(str);
  }
  const sendOtp = () => {
    if (userName.length <= 0) {
      setResponseMessage(<IntlMessages id="error.emailmsg" />);
      setResponse(true);
      setResponseStatus(500);
      return;
    }
    if (userName != confirmUserName) {
      setResponseMessage(<IntlMessages id="error.emailmatch" />);
      setResponse(true);
      setResponseStatus(500);
      return;
    }
    if (password != retypePassword) {
      setResponseMessage(<IntlMessages id="error.passwordmatch" />);
      setResponse(true);
      setResponseStatus(500);
      return;
    }
    if (!checkPassword(password)) {
      Swal.fire({
        title: 'Error/خطأ',
        text: `
      Min 1 uppercase letter.
      Min 1 lowercase letter.
      Min 1 special character.
      Min 1 number.
      Min 8 characters.
      Max 20 characters.`,
        showDenyButton: false,
        showCancelButton: false,
        icon: 'failed',
        confirmButtonText: 'Okay',
      });
      return;
    }

    const url = Url.baseUrl + Url.MobileNumberOtp;
    const inputData = {
      phoneNumber: `${contactCountryCode}` + `${contact}`,
    };
    // console.log(url, 'otpurl');
    // console.log(inputData, 'otpurl');
    var data = JSON.stringify({
      phoneNumber: `${contactCountryCode}` + `${contact}`,
    });
    var config = {
      method: 'post',
      url: url,
      headers: {
        'Content-Type': 'application/json',
      },
      data: data,
    };
    if (!emailValidated) {
      setResponseMessage(<IntlMessages id="error.emailexists" />);
      setResponse(true);
      setResponseStatus(500);
      return;
    }
    if (!mobilevliadated) {
      setResponseMessage(<IntlMessages id="error.mobileexists" />);
      setResponse(true);
      setResponseStatus(500);
      return;
    }
    setisFetching(true);

    // if
    // console.log(emailValidated, mobilevliadated, 'validation');
    Axios(config)
      .then(function(response) {
        // handle success
        // console.log(response.data, 'otpurl');
        if (response.data.code === '200') {
          setisFetching(false);
          setshowsnakbar(true);
          setOtpModal(true);
        } else {
          setResponseMessage(response.data.message);
          setResponse(true);
          setisFetching(false);
          setResponseStatus(response.data.status);
        }
      })
      .catch(function(error) {
        setResponseMessage(error.message);
        setResponse(true);
        setisFetching(false);
        setResponseStatus('500');
      });
  };
  const verifyOtp = () => {
    setisFetching(true);
    const url = Url.baseUrl + Url.MobileNumberVerifyOtp;
    var data = JSON.stringify({
      otp: otp,
      phoneNumber: `${contactCountryCode}` + `${contact}`,
    });

    var config = {
      method: 'post',
      url: url,
      headers: {
        'Content-Type': 'application/json',
      },
      data: data,
    };

    Axios(config)
      .then(response => {
        if (response.data.code == '200') {
          // console.log(response.data, 'otpurl');
          setResponseMessage(response.data.message);
          setResponse(true);
          setisFetching(false);
          setResponseStatus(response.data.code);
          setOtp('');
          setOtpModal(false);
          history.push('/signuptwo');
          let inputs = {
            lastName: lastName,
            userName: userName,
            confirmUserName: confirmUserName,
            password: password,
            retypePassword: retypePassword,
            contact: contact,
            contactCountryCode: '+966',
            investorType: 'INDIVIDUAL',
            referralSource: referralSource,
            religion: religion,
            gender: gender,
            maritalStatus: maritalStatus,
            referralCode: referralCode,
            secondLastName: secondLastName,
            alternativeNumber: alternativeNumber,
            alternativeNumberCountryCode: alternativeNumberCountryCode,
            landLineNumber: landLineNumber,
            placeOfBirthState: placeOfBirthState,
            placeOfBirthCity: placeOfBirthCity,
            identityNumberOne: identityNumberOne,
            identityNumberTwo: identityNumberTwo,
            alternativeUsername: alternativeUsername,
            casteCategory: casteCategory,
            identityNumberThree: identityNumberThree,
            identityNumberFour: identityNumberFour,
            isPersonWithDisability: isPersonWithDisability,
            title: title,
            firstSchoolName: firstSchoolName,
          };
          let investorStepOne = JSON.stringify(inputs);
          localStorage.setItem('investorStepOne', investorStepOne);
          localStorage.setItem('emailValidated', emailValidated);
          localStorage.setItem('mobilevliadated', mobilevliadated);
          // console.log(investorStepOne.count, 'count');
        } else {
          setResponseMessage(response.data.message);
          setResponse(true);
          setResponseStatus(response.data.code);
          setOtp('');
        }
      })
      .catch(function(error) {
        // handle error
        setResponseMessage(error.message);
        setResponse(true);
        setResponseStatus('500');
      });
  };
  window.onunload = function() {
    localStorage.removeItem('investorStepOne');
  };

  // const dataAppend = mandatoryFields => {
  //   let append = '';
  //   console.log(Object.keys(mandatoryFields).length);
  //   for (const property in mandatoryFields) {
  //     append +=
  //       `const [set${mandatoryFields[property].variableName}, setset${mandatoryFields[property].variableName}] = useState('');` +
  //       '\n';
  //   }
  //   console.log(append, 'append');
  //   // return append;
  // };
  const [stepOneDetail, setstepOneDetail] = useState('');
  useEffect(() => {
    dispatch(investorMandatoryFunction(languageCheck)).then(res => {
      setmandatoryFields(res?.data);
      // dataAppend(res?.data);
      // console.log(res?.data, 'mandatory');
      setShowFields(true);
      getStepOneDetails();
    });
  }, [languageCheck]);
  const [lastName, setlastName] = useState(stepOneDetail?.lastName ? stepOneDetail?.lastName : '');
  const [userName, setuserName] = useState(stepOneDetail?.userName ? stepOneDetail?.userName : '');
  const [confirmUserName, setconfirmUserName] = useState(
    stepOneDetail?.confirmUserName ? stepOneDetail?.confirmUserName : '',
  );
  const [password, setpassword] = useState(stepOneDetail?.password ? stepOneDetail?.password : '');
  const [retypePassword, setretypePassword] = useState(stepOneDetail?.retypePassword ? stepOneDetail?.retypePassword : '');
  const [contact, setcontact] = useState(stepOneDetail?.contact ? stepOneDetail?.contact : '');
  const [contactCountryCode, setcontactCountryCode] = useState('+966');
  const [investorType, setinvestorType] = useState('INDIVIDUAL');
  const [referralSource, setreferralSource] = useState(stepOneDetail?.referralSource ? stepOneDetail?.referralSource : '');
  const [religion, setreligion] = useState(stepOneDetail?.religion ? stepOneDetail?.religion : '');
  const [gender, setgender] = useState(stepOneDetail?.gender ? stepOneDetail?.gender : '');
  const [maritalStatus, setmaritalStatus] = useState(stepOneDetail?.maritalStatus ? stepOneDetail?.maritalStatus : '');
  const [referralCode, setreferralCode] = useState(stepOneDetail?.referralCode ? stepOneDetail?.referralCode : '');
  const [secondLastName, setsecondLastName] = useState(stepOneDetail?.secondLastName ? stepOneDetail?.secondLastName : '');
  const [alternativeNumber, setalternativeNumber] = useState(
    stepOneDetail?.alternativeNumber ? stepOneDetail?.alternativeNumber : '',
  );
  const [alternativeNumberCountryCode, setalternativeNumberCountryCode] = useState(
    stepOneDetail?.alternativeNumberCountryCode ? stepOneDetail?.alternativeNumberCountryCode : '',
  );
  const [landLineNumber, setlandLineNumber] = useState(stepOneDetail?.landLineNumber ? stepOneDetail?.landLineNumber : '');
  const [placeOfBirthState, setplaceOfBirthState] = useState(
    stepOneDetail?.placeOfBirthState ? stepOneDetail?.placeOfBirthState : '',
  );
  const [placeOfBirthCity, setplaceOfBirthCity] = useState(
    stepOneDetail?.placeOfBirthCity ? stepOneDetail?.placeOfBirthCity : '',
  );
  const [identityNumberOne, setidentityNumberOne] = useState(
    stepOneDetail?.identityNumberOne ? stepOneDetail?.identityNumberOne : '',
  );
  const [identityNumberTwo, setidentityNumberTwo] = useState(
    stepOneDetail?.identityNumberTwo ? stepOneDetail?.identityNumberTwo : '',
  );
  const [alternativeUsername, setalternativeUsername] = useState(
    stepOneDetail?.alternativeUsername ? stepOneDetail?.alternativeUsername : '',
  );
  const [casteCategory, setcasteCategory] = useState(stepOneDetail?.casteCategory ? stepOneDetail?.casteCategory : '');
  const [identityNumberThree, setidentityNumberThree] = useState(
    stepOneDetail?.identityNumberThree ? stepOneDetail?.identityNumberThree : '',
  );
  const [identityNumberFour, setidentityNumberFour] = useState(
    stepOneDetail?.identityNumberFour ? stepOneDetail?.identityNumberFour : '',
  );
  const [isPersonWithDisability, setisPersonWithDisability] = useState(
    stepOneDetail?.isPersonWithDisability ? stepOneDetail?.isPersonWithDisability : '',
  );
  const [title, settitle] = useState(stepOneDetail?.title ? stepOneDetail?.title : '');
  const [firstSchoolName, setfirstSchoolName] = useState(
    stepOneDetail?.firstSchoolName ? stepOneDetail?.firstSchoolName : '',
  );

  const getStepOneDetails = async () => {
    let stepOneDetails = localStorage.getItem('investorStepOne');
    let emailValidated = localStorage.getItem('emailValidated');
    let mobilevliadated = localStorage.getItem('mobilevliadated');
    // console.log(stepOneDetails, 'stepOneDetails');
    if (stepOneDetails) {
      let value = JSON.parse(stepOneDetails);
      setstepOneDetail(value);
      setlastName(value.lastName);
      setuserName(value.userName);
      setconfirmUserName(value.confirmUserName);
      setpassword(value.password);
      setretypePassword(value.retypePassword);
      setcontact(value.contact);
      setcontactCountryCode(value.contactCountryCode);
      setinvestorType(value.investorType);
      setreferralSource(value.referralSource);
      setreligion(value.religion);
      setgender(value.gender);
      setmaritalStatus(value.maritalStatus);
      setreferralCode(value.referralCode);
      setsecondLastName(value.secondLastName);
      setalternativeNumber(value.alternativeNumber);
      setalternativeNumberCountryCode(value.alternativeNumberCountryCode);
      setlandLineNumber(value.landLineNumber);
      setplaceOfBirthState(value.placeOfBirthState);
      setplaceOfBirthCity(value.placeOfBirthCity);
      setidentityNumberOne(value.identityNumberOne);
      setidentityNumberTwo(value.identityNumberTwo);
      setalternativeUsername(value.alternativeUsername);
      setidentityNumberThree(value.identityNumberThree);
      setidentityNumberFour(value.identityNumberFour);
      setisPersonWithDisability(value.isPersonWithDisability);
      settitle(value.title);
      setfirstSchoolName(value.firstSchoolName);
      setMobileValidated(mobilevliadated);
      setEmailValidated(emailValidated);
    }
  };
  // console.log(stepOneDetail?.lastName, 'mandatory');
  // const Register_step1 = () => {
  //   let inputs = {
  //     lastName: lastName,
  //     userName: userName,
  //     confirmUserName: confirmUserName,
  //     password: password,
  //     retypePassword: retypePassword,
  //     contact: contact,
  //     contactCountryCode: '+966',
  //     referralSource: referralSource,
  //     religion: religion,
  //     gender: gender,
  //     maritalStatus: maritalStatus,
  //     referralCode: referralCode,
  //     secondLastName: secondLastName,
  //     alternativeNumber: alternativeNumber,
  //     alternativeNumberCountryCode: alternativeNumberCountryCode,
  //     landLineNumber: landLineNumber,
  //     placeOfBirthState: placeOfBirthState,
  //     placeOfBirthCity: placeOfBirthCity,
  //     identityNumberOne: identityNumberOne,
  //     identityNumberTwo: identityNumberTwo,
  //     alternativeUsername: alternativeUsername,
  //     casteCategory: casteCategory,
  //     identityNumberThree: identityNumberThree,
  //     identityNumberFour: identityNumberFour,
  //     isPersonWithDisability: isPersonWithDisability,
  //     title: title,
  //     firstSchoolName: firstSchoolName,
  //   };
  //   let investorStepOne = JSON.stringify(inputs);
  //   localStorage.setItem('investorStepOne', investorStepOne);
  //   console.log(investorStepOne.count, 'count');
  //   history.push('/signuptwo');
  //   return;
  //   // let inputData = inputs;
  //   // return;
  //   console.log(inputs, 'inputs');
  //   // return;
  //   dispatch(registrationStepOneBasicFunction(inputs)).then(res => {
  //     console.log(res.data);
  //     if (res.data.code === '200') {
  //       Swal.fire({
  //         title: res.data.status,
  //         text: res.data.message,
  //         showDenyButton: false,
  //         showCancelButton: false,
  //         icon: 'success',
  //         confirmButtonText: 'Okay',
  //       }).then(result => {
  //         /* Read more about isConfirmed, isDenied below */
  //         if (result.isConfirmed) {
  //           history.push('/signuptwo');
  //         } else if (result.isDenied) {
  //           return;
  //         }
  //       });
  //       localStorage.setItem('newUser', res.data.access_token);
  //     } else {
  //       Swal.fire({
  //         title: res.data.status,
  //         text: res.data.message,
  //         icon: 'info',
  //         confirmButtonText: 'Okay',
  //       });
  //     }
  //   });
  // };
  let isLoading = useSelector(state => state.registrationStepOneBasicReducer.isFetching);
  const [seconds, setSeconds] = useState(60);
  const [resendButton, setResendButton] = useState(true);
  const [resendButtonCount, setResendButtonCount] = useState(3);
  const [timeLeft, setTimeLeft] = useState(seconds);
  useEffect(() => {
    if (!otpModal) {
      return;
    }
    if (timeLeft == 0) {
      setResendButton(false);
    }
    // exit early when we reach 0
    if (!timeLeft) return;
    // save intervalId to clear the interval when the
    // component re-renders
    const intervalId = setInterval(() => {
      setTimeLeft(timeLeft - 1);
    }, 1000);
    // clear interval on re-render to avoid memory leaks
    return () => {
      clearInterval(intervalId);
    };
    // add timeLeft as a dependency to re-rerun the effect
    // when we update it
  }, [timeLeft, otpModal]);
  const refreshButton = () => {
    setTimeLeft(seconds);
    setResendButton(true);
    setResendButtonCount(resendButtonCount - 1);
    sendOtp();
  };
  // console.log(password, 'password');
  return (
    <AuthWrapper variant={'signup'}>
      {isFetching && (
        <div>
          <PageLoader />
        </div>
      )}
      <SnackBarComponent
        status={responseStatus}
        visible={response}
        setResponse={setResponse}
        message={responseMessage}
        setResponseStatus={setResponseStatus}
        setResponseMessage={setResponseMessage}
      />
      {variant === 'default' ? (
        <>
          <Box className={classes.authThumb}>
            <CmtImage src={'/images/auth/sign-up-img.png'} />
          </Box>
        </>
      ) : null}
      <Box className={classes.authContent}>
        <Loader isLoading={isLoading} />
        <Typography component="div" variant="h1" className={classes.titleRoot}>
          <IntlMessages id="registration.financierregistration" />
        </Typography>

        {showFields && (
          <>
            <form>
              {mandatoryFields['TITLE']?.showField && (
                <Box mb={2}>
                  <TextField
                    label={mandatoryFields['TITLE'].fieldLabel}
                    fullWidth
                    onChange={event => settitle(event.target.value)}
                    value={title}
                    margin="normal"
                    variant="outlined"
                    className={classes.textFieldRoot}
                  />
                </Box>
              )}
              {/* {mandatoryFields['SECOND_LAST_NAME']?.showField && (
                <Box mb={2}>
                  <TextField
                    label={mandatoryFields['SECOND_LAST_NAME'].fieldLabel}
                    fullWidth
                    onChange={event => setsecondLastName(event.target.value)}
                    value={secondLastName}
                    margin="normal"
                    variant="outlined"
                    className={classes.textFieldRoot}
                  />
                </Box>
              )} */}
              {mandatoryFields['LAST_NAME']?.showField && (
                <Box mb={2}>
                  <TextField
                    label={languageCheck == 'en' ? 'Full Name' : 'الاسم الكامل'}
                    fullWidth
                    onChange={event => setlastName(event.target.value)}
                    value={lastName}
                    margin="normal"
                    variant="outlined"
                    className={classes.textFieldRoot}
                  />
                </Box>
              )}
              {mandatoryFields['EMAIL_ADDRESS']?.showField && (
                <Box mb={2}>
                  <TextField
                    label={mandatoryFields['EMAIL_ADDRESS'].fieldLabel}
                    fullWidth
                    onBlur={() => checkUniqueEmail()}
                    onChange={event => setuserName(event.target.value)}
                    value={userName}
                    margin="normal"
                    variant="outlined"
                    className={classes.textFieldRoot}
                  />
                </Box>
              )}
              <Snackbar open={uniqueEmail} autoHideDuration={2000} onClose={() => setUniqueEmail(false)}>
                <Alert
                  onClose={() => setUniqueEmail()}
                  severity={uniqueEmailMessage?.status === '200' ? 'success' : 'error'}>
                  {uniqueEmailMessage?.message}
                </Alert>
              </Snackbar>

              {mandatoryFields['CONFIRM_EMAIL_ADDRESS']?.showField && (
                <Box mb={2}>
                  <TextField
                    label={mandatoryFields['CONFIRM_EMAIL_ADDRESS'].fieldLabel}
                    fullWidth
                    onChange={event => setconfirmUserName(event.target.value)}
                    value={confirmUserName}
                    margin="normal"
                    variant="outlined"
                    className={classes.textFieldRoot}
                  />
                </Box>
              )}
              <Grid container spacing={12}>
                {mandatoryFields['CONTACT_NO_COUNTRY_CODE']?.showField && (
                  <Grid item xs={3} md={2} lg={2}>
                    <Box>
                      <TextField
                        fullWidth
                        onChange={event => setcontactCountryCode(event.target.value)}
                        // defaultValue={name}
                        margin="normal"
                        value={'+966'}
                        disabled
                        variant="outlined"
                        className={classes.textFieldRoot}
                      />
                    </Box>
                  </Grid>
                )}
                {mandatoryFields['CONTACT_NO']?.showField && (
                  <Grid item xs={9} md={10} lg={10}>
                    <Box xs={6} md={10} lg={10}>
                      <TextField
                        label={mandatoryFields['CONTACT_NO'].fieldLabel}
                        fullWidth
                        onBlur={() => checkUniqueMobile()}
                        onChange={event => setcontact(event.target.value)}
                        value={contact}
                        margin="normal"
                        variant="outlined"
                        className={classes.textFieldRoot}
                      />
                    </Box>
                  </Grid>
                )}
                <Snackbar open={uniqueMobile} autoHideDuration={2000} onClose={() => setUniqueMobile(false)}>
                  <Alert
                    onClose={() => setUniqueMobile()}
                    severity={uniqueMobileMessage?.status === '200' ? 'success' : 'error'}>
                    {uniqueMobileMessage?.message}
                  </Alert>
                </Snackbar>
              </Grid>
              {mandatoryFields['PASSWORD']?.showField && (
                <Box mb={2}>
                  <TextField
                    label={mandatoryFields['PASSWORD'].fieldLabel}
                    fullWidth
                    type="password"
                    onChange={event => handlePassword(event.target.value)}
                    // value={password}
                    margin="normal"
                    variant="outlined"
                    className={classes.textFieldRoot}
                  />
                </Box>
              )}
              {errorMsg.length > 0 && (
                <Box mb={2}>
                  {errorMsg[0] && <Typography variant="caption">{errorMsg[0]}</Typography>}
                  <br />
                  {errorMsg[1] && <Typography variant="caption">{errorMsg[1]}</Typography>}
                  <br />
                  {errorMsg[2] && <Typography variant="caption">{errorMsg[2]}</Typography>}
                  <br />
                  {errorMsg[3] && <Typography variant="caption">{errorMsg[3]}</Typography>}
                </Box>
              )}
              {mandatoryFields['CONFIRM_PASSWORD']?.showField && (
                <Box mb={2}>
                  <TextField
                    label={mandatoryFields['CONFIRM_PASSWORD'].fieldLabel}
                    fullWidth
                    type="password"
                    onChange={event => setretypePassword(event.target.value)}
                    // value={retypePassword}
                    margin="normal"
                    variant="outlined"
                    className={classes.textFieldRoot}
                  />
                </Box>
              )}

              {mandatoryFields['REFERRAL_SOURCE']?.showField && (
                <Box mb={2}>
                  <TextField
                    label={mandatoryFields['REFERRAL_SOURCE'].fieldLabel}
                    fullWidth
                    onChange={event => setreferralSource(event.target.value)}
                    value={referralSource}
                    margin="normal"
                    variant="outlined"
                    className={classes.textFieldRoot}
                  />
                </Box>
              )}
              {mandatoryFields['RELIGION_TYPE']?.showField && (
                <Box mb={2}>
                  <TextField
                    label={mandatoryFields['RELIGION_TYPE'].fieldLabel}
                    fullWidth
                    onChange={event => setreligion(event.target.value)}
                    value={religion}
                    margin="normal"
                    variant="outlined"
                    className={classes.textFieldRoot}
                  />
                </Box>
              )}
              {mandatoryFields['GENDER']?.showField && (
                <Box mb={2}>
                  <TextField
                    label={mandatoryFields['GENDER'].fieldLabel}
                    fullWidth
                    onChange={event => setgender(event.target.value)}
                    value={gender}
                    margin="normal"
                    variant="outlined"
                    className={classes.textFieldRoot}
                  />
                </Box>
              )}
              {mandatoryFields['MARITAL_STATUS']?.showField && (
                <Box mb={2}>
                  <TextField
                    label={mandatoryFields['MARITAL_STATUS'].fieldLabel}
                    fullWidth
                    onChange={event => setmaritalStatus(event.target.value)}
                    value={maritalStatus}
                    margin="normal"
                    variant="outlined"
                    className={classes.textFieldRoot}
                  />
                </Box>
              )}
              {mandatoryFields['REFERRAL_CODE']?.showField && (
                <Box mb={2}>
                  <TextField
                    label={mandatoryFields['REFERRAL_CODE'].fieldLabel}
                    fullWidth
                    onChange={event => setreferralCode(event.target.value)}
                    value={referralCode}
                    margin="normal"
                    variant="outlined"
                    className={classes.textFieldRoot}
                  />
                </Box>
              )}

              {mandatoryFields['ALTERNATIVE_NO']?.showField && (
                <Box mb={2}>
                  <TextField
                    label={mandatoryFields['ALTERNATIVE_NO'].fieldLabel}
                    fullWidth
                    onChange={event => setalternativeNumber(event.target.value)}
                    value={alternativeNumber}
                    margin="normal"
                    variant="outlined"
                    className={classes.textFieldRoot}
                  />
                </Box>
              )}
              {mandatoryFields['ALTERNATIVE_NO_COUNTRY_CODE']?.showField && (
                <Box mb={2}>
                  <TextField
                    label={mandatoryFields['ALTERNATIVE_NO_COUNTRY_CODE'].fieldLabel}
                    fullWidth
                    onChange={event => setalternativeNumberCountryCode(event.target.value)}
                    value={alternativeNumberCountryCode}
                    margin="normal"
                    variant="outlined"
                    className={classes.textFieldRoot}
                  />
                </Box>
              )}
              {mandatoryFields['LANDLINE_NO']?.showField && (
                <Box mb={2}>
                  <TextField
                    label={mandatoryFields['LANDLINE_NO'].fieldLabel}
                    fullWidth
                    onChange={event => setlandLineNumber(event.target.value)}
                    value={landLineNumber}
                    margin="normal"
                    variant="outlined"
                    className={classes.textFieldRoot}
                  />
                </Box>
              )}
              {mandatoryFields['PLACE_OF_BIRTH_STATE']?.showField && (
                <Box mb={2}>
                  <TextField
                    label={mandatoryFields['PLACE_OF_BIRTH_STATE'].fieldLabel}
                    fullWidth
                    onChange={event => setplaceOfBirthState(event.target.value)}
                    value={placeOfBirthState}
                    margin="normal"
                    variant="outlined"
                    className={classes.textFieldRoot}
                  />
                </Box>
              )}
              {mandatoryFields['PLACE_OF_BIRTH']?.showField && (
                <Box mb={2}>
                  <TextField
                    label={mandatoryFields['PLACE_OF_BIRTH'].fieldLabel}
                    fullWidth
                    onChange={event => setplaceOfBirthCity(event.target.value)}
                    value={placeOfBirthCity}
                    margin="normal"
                    variant="outlined"
                    className={classes.textFieldRoot}
                  />
                </Box>
              )}
              {mandatoryFields['IDENTITY_NUMBER_ONE']?.showField && (
                <Box mb={2}>
                  <TextField
                    label={mandatoryFields['IDENTITY_NUMBER_ONE'].fieldLabel}
                    fullWidth
                    onChange={event => setidentityNumberOne(event.target.value)}
                    value={identityNumberOne}
                    margin="normal"
                    variant="outlined"
                    className={classes.textFieldRoot}
                  />
                </Box>
              )}
              {mandatoryFields['IDENTITY_NUMBER_TWO']?.showField && (
                <Box mb={2}>
                  <TextField
                    label={mandatoryFields['IDENTITY_NUMBER_TWO'].fieldLabel}
                    fullWidth
                    onChange={event => setidentityNumberTwo(event.target.value)}
                    value={identityNumberTwo}
                    margin="normal"
                    variant="outlined"
                    className={classes.textFieldRoot}
                  />
                </Box>
              )}
              {mandatoryFields['ALTERNATIVE_USERNAME']?.showField && (
                <Box mb={2}>
                  <TextField
                    label={mandatoryFields['ALTERNATIVE_USERNAME'].fieldLabel}
                    fullWidth
                    onChange={event => setalternativeUsername(event.target.value)}
                    value={alternativeUsername}
                    margin="normal"
                    variant="outlined"
                    className={classes.textFieldRoot}
                  />
                </Box>
              )}
              {mandatoryFields['CASTE_CATEGORY']?.showField && (
                <Box mb={2}>
                  <TextField
                    label={mandatoryFields['CASTE_CATEGORY'].fieldLabel}
                    fullWidth
                    onChange={event => setcasteCategory(event.target.value)}
                    value={casteCategory}
                    margin="normal"
                    variant="outlined"
                    className={classes.textFieldRoot}
                  />
                </Box>
              )}
              {mandatoryFields['IDENTITY_NUMBER_THREE']?.showField && (
                <Box mb={2}>
                  <TextField
                    label={mandatoryFields['IDENTITY_NUMBER_THREE'].fieldLabel}
                    fullWidth
                    onChange={event => setidentityNumberThree(event.target.value)}
                    value={identityNumberThree}
                    margin="normal"
                    variant="outlined"
                    className={classes.textFieldRoot}
                  />
                </Box>
              )}
              {mandatoryFields['IDENTITY_NUMBER_FOUR']?.showField && (
                <Box mb={2}>
                  <TextField
                    label={mandatoryFields['IDENTITY_NUMBER_FOUR'].fieldLabel}
                    fullWidth
                    onChange={event => setidentityNumberFour(event.target.value)}
                    value={identityNumberFour}
                    margin="normal"
                    variant="outlined"
                    className={classes.textFieldRoot}
                  />
                </Box>
              )}
              {mandatoryFields['PERSON_WITH_DISABILITY']?.showField && (
                <Box mb={2}>
                  <TextField
                    label={mandatoryFields['PERSON_WITH_DISABILITY'].fieldLabel}
                    fullWidth
                    onChange={event => setisPersonWithDisability(event.target.value)}
                    value={isPersonWithDisability}
                    margin="normal"
                    variant="outlined"
                    className={classes.textFieldRoot}
                  />
                </Box>
              )}
              {mandatoryFields['FIRST_SCHOOL_NAME']?.showField && (
                <Box mb={2}>
                  <TextField
                    label={mandatoryFields['FIRST_SCHOOL_NAME'].fieldLabel}
                    fullWidth
                    onChange={event => setfirstSchoolName(event.target.value)}
                    value={firstSchoolName}
                    margin="normal"
                    variant="outlined"
                    className={classes.textFieldRoot}
                  />
                </Box>
              )}
              <Box
                display="flex"
                flexDirection={{ xs: 'column', sm: 'row' }}
                alignItems={{ sm: 'center' }}
                justifyContent={{ sm: 'space-between' }}
                mb={3}>
                <Box mb={{ xs: 2, sm: 0 }}>
                  <Button onClick={() => sendOtp()} variant="contained" color="primary">
                    <IntlMessages id="appModule.next" />
                  </Button>
                </Box>
              </Box>
            </form>
            <Typography className={classes.textAcc}>
              <IntlMessages id="registration.haveanaccount" />
              <NavLink to="/signin">
                <IntlMessages id="registration.signin" />
              </NavLink>
              {/* <NavLink to="/signuptwo">Step two</NavLink> */}
            </Typography>
          </>
        )}
        <div>
          <Dialog open={showAlert}>
            <DialogContent>
              <DialogContentText>{status}</DialogContentText>

              <DialogContentText>{message}</DialogContentText>
            </DialogContent>

            <DialogActions>
              <Button onClick={() => setShowAlert(false)}>Cancel</Button>
              <Button onClick={() => (code === '200' ? history.push('/signuptwo') : setShowAlert(false))}>Ok</Button>
            </DialogActions>
          </Dialog>
        </div>
        <div>
          <Dialog fullWidth open={otpModal}>
            <DialogContent>
              <DialogContentText>
                <IntlMessages id="registration.otpverifiy" />
              </DialogContentText>
              <TextField
                label=""
                autoComplete="off"
                fullWidth
                onChange={event => setOtp(event.target.value)}
                // defaultValue={password}
                margin="normal"
                variant="outlined"
                // className={classes.textFieldRoot}
              />
              <Snackbar open={showsnakbar} autoHideDuration={6000} onClose={() => setshowsnakbar(false)}>
                <Alert onClose={() => setshowsnakbar(false)} severity="success">
                  <IntlMessages id="registration.otpsend" />
                </Alert>
              </Snackbar>
            </DialogContent>

            <DialogActions>
              <Button onClick={() => setOtpModal(false)}>
                <IntlMessages id="registration.cancel" />
              </Button>
              {resendButton == true ? (
                <Button onClick={() => verifyOtp()}>
                  <IntlMessages id="registration.submit" />
                </Button>
              ) : (
                <Button
                  onClick={() => {
                    refreshButton();
                  }}>
                  <IntlMessages id="registration.resend" />
                </Button>
              )}
            </DialogActions>
            {timeLeft != 0 ? (
              <DialogContentText style={{ textAlign: 'center' }}>
                <IntlMessages id="registration.resendotp" /> {timeLeft}s
              </DialogContentText>
            ) : null}
          </Dialog>
        </div>
      </Box>
    </AuthWrapper>
  );
};

export default SignUp;
