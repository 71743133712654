import * as types from '../actionTypes/types';
import { httpRequest } from '../NetworkConfig/Config';
import { Url, methods, headers } from '../NetworkConfig/ApiUrlConstatnts';
export const verifyOtpMobileFunction = inpuData => {
  return async dispatch => {
    dispatch(fetchingVerifyOtpMobileRequest());
    return httpRequest({
      method: methods.post,
      url: Url.VerifyOtpMobile,
      headers: headers.headFinancing,
      data: JSON.stringify(inpuData),
    }).then(JsonResponse => {
      if (JsonResponse !== undefined) {
        if (JsonResponse.status == 200 || JsonResponse.status == 203) {
          let fetchedData =
            JsonResponse.data !== undefined && JsonResponse.data !== null && Object.keys(JsonResponse.data).length !== 0
              ? JsonResponse.data
              : {};
          dispatch(fetchingVerifyOtpMobileSuccess(fetchedData));
        } else {
          dispatch(fetchingVerifyOtpMobileFailed());
        }
        return JsonResponse;
      } else {
        dispatch(fetchingVerifyOtpMobileFailed());
      }
    });
  };
};
export const fetchingVerifyOtpMobileRequest = () => ({
  type: types.FETCHING_VERIFY_OTP_MOBILE_REQUEST,
});
export const fetchingVerifyOtpMobileSuccess = json => ({
  type: types.FETCHING_VERIFY_OTP_MOBILE_SUCCESS,
  payload: json,
});
export const fetchingVerifyOtpMobileFailed = error => ({
  type: types.FETCHING_VERIFY_OTP_MOBILE_FAILED,
  payload: error,
});
export const fetchingUserLogOutDetailsSuccess = () => ({
  type: types.FETCHING_USER_LOGOUT_DETAILS_SUCCESS,
});
