import * as types from '../../actionTypes/types';
import { httpRequest } from '../../NetworkConfig/Config';
import { Url, methods, headers } from '../../NetworkConfig/ApiUrlConstatnts';
export const borrowerDashFunction = inputData => {
  let formData = new FormData();
  formData.append('access_token', inputData);
  return async dispatch => {
    dispatch(fetchingBorrowerDashRequest());
    return httpRequest({
      method: methods.post,
      url: Url.BorrowerDash,
      headers: headers.headwithMultipart,
      data: formData,
    }).then(JsonResponse => {
      if (JsonResponse !== undefined) {
        if (JsonResponse.status == 200 || JsonResponse.status == 203) {
          let fetchedData =
            JsonResponse.data !== undefined && JsonResponse.data !== null && Object.keys(JsonResponse.data).length !== 0
              ? JsonResponse.data
              : {};
          dispatch(fetchingBorrowerDashSuccess(fetchedData?.borrowerDetail));
        } else {
          dispatch(fetchingBorrowerDashFailed());
        }
        return JsonResponse;
      } else {
        dispatch(fetchingBorrowerDashFailed());
      }
    });
  };
};
export const fetchingBorrowerDashRequest = () => ({
  type: types.FETCHING_BORROWER_DASH_REQUEST,
});
export const fetchingBorrowerDashSuccess = json => ({
  type: types.FETCHING_BORROWER_DASH_SUCCESS,
  payload: json,
});
export const fetchingBorrowerDashFailed = error => ({
  type: types.FETCHING_BORROWER_DASH_FAILED,
  payload: error,
});
export const fetchingUserLogOutDetailsSuccess = () => ({
  type: types.FETCHING_USER_LOGOUT_DETAILS_SUCCESS,
});
