import Footer from '@jumbo/components/Footer/Footer';
import FooterTop from '@jumbo/components/Footer/FooterTop';
import Header from '@jumbo/components/Header/Header';
import React from 'react';
import SignIn from '../../@jumbo/components/Common/authComponents/SignIn';

const Login = () => (
  <div style={{ flex: 1 }}>
    <Header />
    <SignIn variant="standard" wrapperVariant="bgColor" />
    <FooterTop />
    <Footer />
  </div>
);

export default Login;
