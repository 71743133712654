import * as types from '../actionTypes/types';
import { httpRequest } from '../NetworkConfig/Config';
import { Url, methods, headers } from '../NetworkConfig/ApiUrlConstatnts';

export const declarationFileUploadFunction = inputData => {
  const formData = new FormData();

  return async dispatch => {
    dispatch(declarationFileUploadRequest());
    return httpRequest({
      method: methods.post,
      url: Url.DeclarationFileUpload,
      headers: headers.headwithMultipart,
      data: inputData,
    }).then(JsonResponse => {
      if (JsonResponse !== undefined) {
        if (JsonResponse.status == 200 || JsonResponse.status == 203) {
          let fetchedData =
            JsonResponse.data !== undefined && JsonResponse.data !== null && Object.keys(JsonResponse.data).length !== 0
              ? JsonResponse.data
              : {};

          dispatch(declarationFileUploadSuccess(fetchedData));
        } else {
          dispatch(declarationFileUploadFailed());
        }
        return JsonResponse;
      } else {
        dispatch(declarationFileUploadFailed());
      }
    });
  };
};
export const declarationFileUploadRequest = () => ({
  type: types.FETCHING_DECLARATION_FILE_UPLOAD_REQUEST,
});
export const declarationFileUploadSuccess = json => ({
  type: types.FETCHING_DECLARATION_FILE_UPLOAD_SUCCESS,
  payload: json,
});
export const declarationFileUploadFailed = error => ({
  type: types.FETCHING_DECLARATION_FILE_UPLOAD_FAILED,
  payload: error,
});
export const fetchingUserLogOutDetailsSuccess = () => ({
  type: types.FETCHING_USER_LOGOUT_DETAILS_SUCCESS,
});
