/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Box, fade, FormControl, Grid, InputLabel, MenuItem, Select } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Typography from '@material-ui/core/Typography';
import { CurrentAuthMethod } from '@jumbo/constants/AppConstants';
import IntlMessages from '@jumbo/utils/IntlMessages';
import AuthWrapper from '@jumbo/components/Common/authComponents/AuthWrapper';
import { history } from 'redux/store';
import Axios from 'axios';
import { headers, Url } from '../../../redux/NetworkConfig/ApiUrlConstatnts';
import { citiesListFunction } from '../../../redux/actions/citiesList';
import { companyListFunction } from '../../../redux/actions/company';
import { Dialog, DialogActions, DialogContent, DialogContentText } from '@material-ui/core';
import Swal from 'sweetalert2';
import SnackBarComponent from 'routes/Components/SnackBar';
import PageLoader from '@jumbo/components/PageComponents/PageLoader';

const useStyles = makeStyles(theme => ({
  authThumb: {
    backgroundColor: fade(theme.palette.primary.main, 0.12),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: 20,
    [theme.breakpoints.up('md')]: {
      width: '50%',
      order: 2,
    },
  },
  authContent: {
    padding: 30,
    [theme.breakpoints.up('md')]: {
      width: props => (props.variant === 'default' ? '50%' : '100%'),
      order: 1,
    },
    [theme.breakpoints.up('xl')]: {
      padding: 50,
    },
  },
  titleRoot: {
    marginBottom: 14,
    color: theme.palette.text.primary,
  },
  textFieldRoot: {
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: fade(theme.palette.common.dark, 0.12),
    },
  },
  textCapital: {
    textTransform: 'capitalize',
  },
  textAcc: {
    textAlign: 'center',
    '& a': {
      marginLeft: 4,
    },
  },
  alrTextRoot: {
    textAlign: 'center',
    [theme.breakpoints.up('sm')]: {
      textAlign: 'right',
    },
  },
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    width: '100%',
  },
}));

//variant = 'default', 'standard', 'bgColor'
const SignupBorrowerFinance = ({ method = CurrentAuthMethod, variant = 'standard', wrapperVariant = 'default' }) => {
  const dispatch = useDispatch();
  const classes = useStyles({ variant });
  const [isFetching, setIsFetching] = useState(false);
  const [companyName, setcompanyName] = useState('');
  const [companySignupAllowed, setcompanySignupAllowed] = useState('');
  const [companyRegistrationCode, setcompanyRegistrationCode] = useState('');
  const [companyTurnover, setcompanyTurnover] = useState('');
  const [companyBalance, setcompanyBalance] = useState('');
  const [dateOfArticle, setdateOfArticle] = useState('');
  const [companyAddress, setcompanyAddress] = useState('');
  const [companyIndustry, setcompanyIndustry] = useState('');
  const [companyCategory, setcompanyCategory] = useState('');
  const [companyDescription, setcompanyDescription] = useState('');
  const [identityNumberOneIssueDate, setidentityNumberOneIssueDate] = useState('');
  const [identityNumberTwoIssueDate, setidentityNumberTwoIssueDate] = useState('');
  const [employeesCount, setemployeesCount] = useState([]);
  const [positionInCompany, setpositionInCompany] = useState('');
  const [faxNumber, setfaxNumber] = useState('');
  const [businessType, setbusinessType] = useState('');
  const [companyIdentityNumberOne, setcompanyIdentityNumberOne] = useState('');
  const [companyIdentityNumberTwo, setcompanyIdentityNumberTwo] = useState('');
  const [companyIdentityNumberThree, setcompanyIdentityNumberThree] = useState('');
  const [companyIdentityNumberFour, setcompanyIdentityNumberFour] = useState('');
  const [companyIdentityNumberFive, setcompanyIdentityNumberFive] = useState('');
  const [companyIdentityNumberSix, setcompanyIdentityNumberSix] = useState('');
  const [faxNumberCountryCode, setfaxNumberCountryCode] = useState('');
  const [companyIdentityNumberOneIssueDate, setcompanyIdentityNumberOneIssueDate] = useState('');
  const [companyIdentityNumberTwoIssueDate, setcompanyIdentityNumberTwoIssueDate] = useState('');
  const [investorEmploymentDetailsTextVariable1, setinvestorEmploymentDetailsTextVariable1] = useState('');

  const [retypePassword, setretypePassword] = useState('');
  const [line1, setline1] = useState('');
  const [line2, setline2] = useState('');
  const [line3, setline3] = useState('');
  const [city, setcity] = useState('');
  const [state, setstate] = useState('');
  const [zipCode, setzipCode] = useState('');
  const [businessVintage, setbusinessVintage] = useState('');
  const [dateYear, setDateYear] = useState('');
  const [dateMonth, setDateMonth] = useState('');
  const [dateDay, setDateDay] = useState('');
  const [dateYearExpiry, setDateYearExpiry] = useState('');
  const [dateMonthExpiry, setDateMonthExpiry] = useState('');
  const [dateDayExpiry, setDateDayExpiry] = useState('');
  const [cityList, setCityList] = useState([]);
  const [companyList, setCompanyList] = useState([]);
  const [loanPurposeUuid, setloanPurposeUuid] = useState('');
  const [loanAmount, setloanAmount] = useState('');
  const [description, setdescription] = useState('');
  const [loanTenure, setloanTenure] = useState('');
  const [response, setResponse] = useState(false);
  const [responseMessage, setResponseMessage] = useState('');
  const [responseStatus, setResponseStatus] = useState('');
  const [amountError, setamountError] = useState(false);
  let languageCheck = localStorage.getItem('languageCheck');
  if (!languageCheck) {
    languageCheck = 'en';
  }
  const Register_step3 = () => {
    let newUser = localStorage.getItem('newUser');
    let amount = loanAmount.toString().replace(/,/g, '');
    // console.log(amount, 'loanamount');
    if (amount > 7500000) {
      setamountError(true);
      setResponseMessage(
        languageCheck === 'en'
          ? 'You can’t apply for more than SAR 7.5 million finance'
          : 'لايمكنك تقديم طلب تمويل بأكثر من 7.5 مليون ريال سعودي',
      );
      return;
    } else {
      setamountError(false);
    }
    let inputs = {
      access_token: newUser,
      loanPurposeUuid: loanPurposeUuid,
      loanAmount: loanAmount,
      description: description,
      loanTenure: loanTenure,
    };
    // console.log(JSON.stringify(inputs));
    setIsFetching(true);
    Axios({
      method: 'post',
      url: Url.baseUrl + Url.RegistrationBorrowerApplyLoan,
      headers: headers.headFinancing,
      data: JSON.stringify(inputs),
    })
      .then(function(res) {
        if (res.data.code == '200') {
          setResponseMessage(res.data.message);
          setResponse(true);
          setResponseStatus('200');
          setIsFetching(true);

          setTimeout(() => {
            history.push('/signupborrowerseven');
          }, 2000);
        } else {
          setIsFetching(false);

          alert(res.data.status);
          Swal.fire('Alert', res.data.status, 'info');
        }
      })
      .catch(function(error) {
        // handle error
        setIsFetching(false);
        setResponseMessage(error.message);
        setResponse(true);
        setResponseStatus('500');
        setTimeout(() => {
          window.location.reload();
        }, 1500);
      });
  };

  useEffect(() => {
    dispatch(citiesListFunction()).then(res => {
      setCityList(res?.data);
    });

    dispatch(companyListFunction()).then(res => {
      setCompanyList(res?.data);
    });
  }, []);

  const riskType = [
    { name: 'Inventory Finance / تمويل المخزون', uuid: 'cdd0f38d-5969-4f84-ad2d-b405fe5d1704' },
    { name: 'Invoice Finance / تمويل الفواتير', uuid: '82741d68-2d9b-4ace-8908-cfbde4801de5' },
    { name: 'Term Finance / التمويل لأجل', uuid: '3f8e1bb3-5fb2-40e3-aa5d-2dab62ca8131' },
    { name: 'POS Lending / تمويل نقاط البيع', uuid: 'c3e5dbae-e5c8-4588-bec5-d5254f7724f2' },
  ];

  const addCommas = num => num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  const removeNonNumeric = num => num.toString().replace(/[^0-9]/g, '');

  return (
    <AuthWrapper variant={wrapperVariant}>
      {isFetching && (
        <div>
          <PageLoader />
        </div>
      )}
      <SnackBarComponent
        status={responseStatus}
        visible={response}
        setResponse={setResponse}
        message={responseMessage}
        setResponseStatus={setResponseStatus}
        setResponseMessage={setResponseMessage}
      />
      <Box className={classes.authContent}>
        <Typography component="div" variant="h1" className={classes.titleRoot}>
          <IntlMessages id="registration.companydetails" />
        </Typography>
        <form
          onSubmit={e => {
            Register_step3();
            e.preventDefault();
          }}>
          <Grid xs={12} md={12} lg={12}>
            <FormControl fullWidth style={{ marginTop: 20 }}>
              <InputLabel style={{ marginLeft: '10px' }} id="demo-simple-select-helper-label">
                <IntlMessages id="registration.kindoffinance" />
              </InputLabel>
              <Select
                labelId="demo-simple-select-helper-label"
                id="demo-simple-select-helper"
                variant="outlined"
                required
                onChange={event => setloanPurposeUuid(event.target.value)}
                // value={loanPurposeUuid}
                label="Age">
                {riskType?.map(item => {
                  return (
                    <MenuItem value={item.uuid}>
                      <em>{item.name}</em>
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
            <TextField
              label={<IntlMessages id="registration.amountrequired" />}
              fullWidth
              required
              // defaultValue={name}
              margin="normal"
              variant="outlined"
              className={classes.textFieldRoot}
              value={loanAmount}
              onChange={event => setloanAmount(addCommas(removeNonNumeric(event.target.value)))}
            />
            {amountError && (
              <Typography variant="caption" style={{ color: 'red' }}>
                {responseMessage}
              </Typography>
            )}
          </Grid>

          <Grid md={12}>
            <TextField
              label={<IntlMessages id="financerequest.purpose" />}
              fullWidth
              required
              multiline
              rows={5}
              // value={companyName}
              // defaultValue={com}
              margin="normal"
              variant="outlined"
              className={classes.textFieldRoot}
              onChange={event => setdescription(event.target.value)}
            />
          </Grid>
          <Grid md={12}>
            <FormControl fullWidth style={{ marginTop: 20 }}>
              <InputLabel style={{ marginLeft: '10px' }} id="demo-simple-select-helper-label">
                <IntlMessages id="offers.periodoffinance" />
              </InputLabel>
              <Select
                labelId="demo-simple-select-helper-label"
                id="demo-simple-select-helper"
                required
                variant="outlined"
                onChange={event => setloanTenure(event.target.value)}
                value={loanTenure}
                label="Age">
                <MenuItem value="">- Select One -</MenuItem>
                <MenuItem value="1">1 month/شهر</MenuItem>
                <MenuItem value="2">2 months/شهرين</MenuItem>
                <MenuItem value="3">3 months/ثلاثة أشهر</MenuItem>
                <MenuItem value="4">4 months/أربع أشهر</MenuItem>
                <MenuItem value="5">5 months/خمس أشهر</MenuItem>
                <MenuItem value="6">6 months/ستة أشهر</MenuItem>
                <MenuItem value="7">7 months/سبعة أشهر</MenuItem>
                <MenuItem value="8">8 months/ثمان شهور</MenuItem>
                <MenuItem value="9">9 months/تسع شهور</MenuItem>
                <MenuItem value="10">10 months/عشر شهور</MenuItem>
                <MenuItem value="11">11 months/أحد عشر شهر</MenuItem>
                <MenuItem value="12">12 months/إثنى عشرة شهر</MenuItem>
                <MenuItem value="13">13 months/ثلاث عشرة شهر</MenuItem>
                <MenuItem value="14">14 months/أربع عشرة شهر</MenuItem>
                <MenuItem value="15">15 months/خمس عشرة شهر</MenuItem>
                <MenuItem value="16">16 months/ستة عشرة شهر</MenuItem>
                <MenuItem value="17">17 months/سبع عشرة شهر</MenuItem>
                <MenuItem value="18">18 months/ثمان عشرة شهر</MenuItem>
                <MenuItem value="19">19 months/تسع عشرة شهر</MenuItem>
                <MenuItem value="20">20 months/عشرون شهر</MenuItem>
                <MenuItem value="21">21 months/واحد وعشرون شهر</MenuItem>
                <MenuItem value="22">22 months/إثنان وعشرون شهر</MenuItem>
                <MenuItem value="23">23 months/ثلاث وعشرون شهر</MenuItem>
                <MenuItem value="24">24 months/أربع وعشرون شهر</MenuItem>
                <MenuItem value="25">25 months/خمس وعشرون شهر</MenuItem>
                <MenuItem value="26">26 months/ستةوعشرون شهر</MenuItem>
                <MenuItem value="27">27 months/سبع عشرون شهر</MenuItem>
                <MenuItem value="28">28 months/ثمان وعشرون شهر</MenuItem>
                <MenuItem value="29">29 months/تسع وعشرون شهر</MenuItem>
                <MenuItem value="30">30 months/ثلاثون شهر</MenuItem>
                <MenuItem value="31">31 months/واحد وثلاثون شهر</MenuItem>
                <MenuItem value="32">32 months/إثنان وثلاثون شهر</MenuItem>
                <MenuItem value="33">33 months/ثلاثة وثلاثون شهر</MenuItem>
                <MenuItem value="34">34 months/أربع وثلاثون شهر</MenuItem>
                <MenuItem value="35">35 months/خمس وثلاثون شهر</MenuItem>
                <MenuItem value="36">36 months/ستة وثلاثون شهر</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid
            style={{ marginTop: '10px' }}
            display="flex"
            flexDirection={{ xs: 'column', sm: 'row' }}
            alignItems={{ sm: 'center' }}
            justifyContent={{ sm: 'space-between' }}
            mb={3}>
            <Grid mb={{ xs: 2, sm: 0 }}>
              <Button variant="contained" color="primary" type="submit">
                <IntlMessages id="appModule.next" />
              </Button>
            </Grid>
          </Grid>
        </form>
        <div>
          <Dialog>
            <DialogContent>
              <DialogContentText></DialogContentText>

              <DialogContentText></DialogContentText>
            </DialogContent>

            <DialogActions>
              <Button>Cancel</Button>
              <Button>Okay</Button>
            </DialogActions>
          </Dialog>
        </div>
        <div>
          <Dialog>
            <DialogContent>
              <DialogContentText>Otp verfication</DialogContentText>
              <TextField
                label=""
                fullWidth
                // defaultValue={password}
                margin="normal"
                variant="outlined"
                // className={classes.textFieldRoot}
              />
            </DialogContent>

            <DialogActions>
              <Button>Cancel</Button>
              <Button>Okay</Button>
            </DialogActions>
          </Dialog>
        </div>
        {/* <Box mb={3}>{dispatch(AuhMethods[method].getSocialMediaIcons())}</Box>

        <ContentLoader /> */}
      </Box>
    </AuthWrapper>
  );
};

export default SignupBorrowerFinance;
