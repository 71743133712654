import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  fade,
  Button,
  Input,
  TextField,
  Paper,
  Grid,
  DialogTitle,
} from '@material-ui/core';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Typography from '@material-ui/core/Typography';
import { CurrentAuthMethod } from '@jumbo/constants/AppConstants';
import IntlMessages from '@jumbo/utils/IntlMessages';
import ContentLoader from '@jumbo/components/ContentLoader';
import AuthWrapper from '@jumbo/components/Common/authComponents/AuthWrapper';
import { AuhMethods } from 'services/auth';
import { Checkbox } from '@material-ui/core';
import { Url } from '../../../redux/NetworkConfig/ApiUrlConstatnts';
import Axios from 'axios';
import { history } from 'redux/store';
import AttachmentIcon from '@material-ui/icons/Attachment';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { declarationFileUploadFunction } from '../../../redux/actions/declarationFIleUpload';
import PageLoader from '../../../@jumbo/components/PageComponents/PageLoader';
import colors from 'theme/Color';
import Swal from 'sweetalert2';
import Loader from 'routes/Components/pageLoaderCustom/loader';
import SnackBarComponent from 'routes/Components/SnackBar';
import { sendOtpMobileFunction } from 'redux/actions/sendOtpMobile';
import { verifyOtpMobileFunction } from 'redux/actions/verifyOtpMobile';
import { investorAllDetailFunction } from 'redux/actions/investorAllDetail';
import { websiteUrl } from '@jumbo/components/Header/Header';
const useStyles = makeStyles(theme => ({
  authThumb: {
    backgroundColor: fade(theme.palette.primary.main, 0.12),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: 20,
    [theme.breakpoints.up('md')]: {
      width: '50%',
      order: 2,
    },
  },
  authContent: {
    padding: 30,
    [theme.breakpoints.up('md')]: {
      width: props => (props.variant === 'default' ? '70%' : '100%'),
      order: 1,
    },
    [theme.breakpoints.up('xl')]: {
      padding: 50,
    },
  },
  titleRoot: {
    // marginBottom: 14,
    color: theme.palette.text.primary,
  },
  textFieldRoot: {
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: fade(theme.palette.common.dark, 0.12),
    },
  },
  textCapital: {
    textTransform: 'capitalize',
  },
  textAcc: {
    textAlign: 'center',
    '& a': {
      marginLeft: 4,
    },
  },
  alrTextRoot: {
    textAlign: 'center',
    [theme.breakpoints.up('sm')]: {
      textAlign: 'right',
    },
  },
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
  decHeader: {
    textAlign: 'center',
    fontWeight: 'bold',
    fontSize: '16px',
    color: colors.gray,
    borderTopColor: colors.gray,
    borderTopWidth: 0.5,
    paddingTop: 20,
    paddingBottom: 10,
  },
  decText: {
    textAlign: 'justify',
    fontWeight: '300',
    paddingHorizontal: 5,
    fontSize: '12px',
    // lineHeight: 2,
    paddingBottom: 10,
    color: colors.black,
  },
}));

//variant = 'default', 'standard', 'bgColor'
const SignUpFive = ({ method = CurrentAuthMethod, variant = 'standard', wrapperVariant = 'default' }) => {
  const [checkedOne, setCheckedOne] = React.useState(false);
  const [checkedTwo, setCheckedTwo] = React.useState(false);
  const [checkedThree, setCheckedThree] = React.useState(false);
  const [checkedFour, setCheckedFour] = React.useState(false);
  const [checkedFive, setCheckedFive] = React.useState(false);
  const [terms, setTerms] = React.useState(false);
  const [declarationFile, setDeclarationFile] = useState(null);
  const [declarationFileShow, setDeclarationFileShow] = useState(true);
  const [fileLoading, setFileLoading] = useState(false);
  const [commercialRegistrationFile, setCommercialRegistrationFile] = useState(null);
  const [commercialRegistrationFileShow, setCommercialRegistrationFileShow] = useState(true);
  const [articlesOfAssociationFile, setArticlesOfAssociationFile] = useState(null);
  const [articlesOfAssociationShow, setArticlesOfAssociationShow] = useState(true);
  const [boardResolutionFile, setBoardResolutionFile] = useState(null);
  const [boardResolutionShow, setBoardResolutionShow] = useState(true);
  const [response, setResponse] = useState(false);
  const [responseMessage, setResponseMessage] = useState('');
  const [responseStatus, setResponseStatus] = useState('');
  const [file2, setFile2] = useState(null);
  const [file3, setFile3] = useState(null);
  const [file4, setFile4] = useState(null);
  const [verifyModal, setVerfiyModal] = useState(false);
  const dispatch = useDispatch();
  const styles = useStyles({ variant });
  const [otp, setOtp] = useState('');
  const [seconds, setSeconds] = useState(60);
  const [resendButton, setResendButton] = useState(true);
  const [resendButtonCount, setResendButtonCount] = useState(3);
  const [timeLeft, setTimeLeft] = useState(seconds);
  const [verifymessage, setVerify] = useState('');
  const [disOpen, setdisOpen] = React.useState(false);

  const [open, setOpen] = React.useState(false);
  const mobileVerification = isopen => {
    if (isopen == true) {
      setVerfiyModal(isopen);
    }
  };
  let newUser = localStorage.getItem('newUser');
  let userid = localStorage.getItem('userid');
  let stepOneDetails = localStorage.getItem('investorStepOne');
  let value = JSON.parse(stepOneDetails);
  let mobile = value?.contact;
  // let mobile = '90908888';
  // console.log(mobile, 'mobileno');
  // console.log(userid, 'mobileno');
  let languageCheck = localStorage.getItem('languageCheck');
  if (!languageCheck) {
    languageCheck = 'en';
  }
  const refreshButton = () => {
    setTimeLeft(seconds);
    setResendButton(true);
    setResendButtonCount(resendButtonCount - 1);
    sendOtpMobile(false);
  };
  useEffect(() => {
    if (!verifyModal) {
      return;
    }
    if (timeLeft == 0) {
      setResendButton(false);
    }
    // exit early when we reach 0
    if (!timeLeft) return;
    // save intervalId to clear the interval when the
    // component re-renders
    const intervalId = setInterval(() => {
      setTimeLeft(timeLeft - 1);
    }, 1000);
    // clear interval on re-render to avoid memory leaks
    return () => {
      clearInterval(intervalId);
    };
    // add timeLeft as a dependency to re-rerun the effect
    // when we update it
  }, [timeLeft, verifyModal]);
  const sendOtpMobile = isopen => {
    setdisOpen(false);
    setVerfiyModal(true);
    let inputDataOtp = {
      access_token: newUser,
      userId: userid,
      phoneNumber: mobile,
    };

    // console.log(isopen, 'isopen');
    dispatch(sendOtpMobileFunction(inputDataOtp)).then(res => {
      if (res.data.code === '200') {
        mobileVerification(isopen);
      } else {
        Swal.fire('INFO', res.data.message, 'error');
        return;
      }
    });
  };

  useEffect(() => {
    const inputData = {
      access_token: newUser,
    };
    dispatch(investorAllDetailFunction(newUser));
  }, []);
  let investorAllDetail = useSelector(state => state.investorAllDetailReducer.investorAllDetail);
  // console.log(investorAllDetail, 'investorAllDetail');

  const Register_step5 = () => {
    const url = Url.baseUrl + Url.RegistrationStepFiveDeclaration + `?access_token=${newUser}`;
    Axios.post(url)
      .then(function(response) {
        // console.log(response);
        if (response.data.code == '200') {
          Swal.fire(
            languageCheck === 'en' ? 'Success' : 'النجاح',
            languageCheck === 'en'
              ? 'Registration process completed you are redirected to login!'
              : 'اكتملت عملية التسجيل سيُعاد توجيهك لتسجيل الدخول',
            'success',
          );
          history.push('/');
          localStorage.clear();
          setOtp('');
        } else {
          // alert('Failed', 'Error saving details', [{ text: 'Okay' }]);
          // Swal.fire('Failed', 'Error saving details', 'error');
          setResponseMessage('Error saving details');
          setResponse(true);
          setResponseStatus('error');
        }
      })
      .catch(function(error) {
        // handle error
        // alert(error.message);
        // Swal.fire('Error', error.message, 'info');
        setResponseMessage(error.message);
        setResponse(true);
        setResponseStatus('error');
      });
  };

  const sendEmail = () => {
    let emailInput = {
      key: '74854414785455',
      mailto: investorAllDetail?.email,
      mailcc: '',
      name_english: investorAllDetail?.investorProfileTextVariable3,
      name_arabic: investorAllDetail?.investorProfileTextVariable4,
      iqama_number_english: investorAllDetail?.investorProfileTextVariable2,
      iqama_number_arabic: investorAllDetail?.investorProfileTextVariable2,
      date_english: '',
      date_arabic: '',
    };
    // console.log(emailInput, 'email');
    const urlMasters = 'https://raqamyah.net:5000/raqamyah/investor/';
    // dispatch(sendMasterAgreementFunction(emailInput))
    //   .then(res => {
    //     console.log(res.data);
    //   })
    Axios.post(urlMasters, emailInput)
      .then(function async(response) {
        // console.log(response.data, 'sendEmail res');
        if (response.data.error == '0') {
          Register_step5();
        } else {
          Swal.fire(
            'Error/خطأ',
            languageCheck == 'en'
              ? 'Terms and conditions Submission has failed, please try again'
              : 'حدث خطأ في قبول الشروط والأحكام، الرجاء المحاولة مرة أخرى',
            'error',
          );

          history.push('/');
          localStorage.clear();
        }
      })
      .catch(function(error) {
        // handle error
        console.log(error);
      });
  };

  const verifyMobileOtp = () => {
    let inputData = {
      access_token: newUser,
      otp: otp,
    };
    // return;
    dispatch(verifyOtpMobileFunction(inputData)).then(res => {
      if (res.data.code === '200') {
        setVerfiyModal(false);
        sendEmail();
      } else {
        Swal.fire(
          languageCheck === 'en' ? 'Success' : 'النجاح',
          languageCheck === 'en'
            ? 'Registration process is completed. You are going to be redirected to login page!'
            : 'اكتملت عملية التسجيل سيُعاد توجيهك لصفحة تسجيل الدخول',
          'success',
        );
        history.push('/');
        localStorage.clear();
      }
    });
  };

  const onSubmit = () => {
    if (checkedOne && checkedTwo && checkedThree && checkedFour && checkedFive && terms) {
      // sendOtpMobile(true);
      setdisOpen(true);
    } else {
      // alert('Please accept all declartaions and accept terms & condition');
      // Swal.fire('Alert', 'Please accept all declartaions and accept terms & condition', 'info');
      setResponseMessage(<IntlMessages id="registration.terms" />);
      setResponse(true);
      setResponseStatus('error');
    }
  };
  const declarationFileRef = useRef(null);
  const commercialregistrationFileRef = useRef(null);
  const articlesOfAssociationsFileRef = useRef(null);
  const boardResolutionRef = useRef(null);
  const declarationFileChoose = () => {
    declarationFileRef.current.click();
  };
  const commercialRegistrationChoose = () => {
    commercialregistrationFileRef.current.click();
  };
  const articlesOfAssociationChoose = () => {
    articlesOfAssociationsFileRef.current.click();
  };
  const boardResolutionChoose = () => {
    boardResolutionRef.current.click();
  };
  const selectDeclaration = file => {
    setDeclarationFile(file);
  };
  const selectCommercialRegistration = file => {
    setCommercialRegistrationFile(file);
  };
  const selectArticlesOfAssociations = file => {
    setArticlesOfAssociationFile(file);
  };
  const selectBoardResolution = file => {
    setBoardResolutionFile(file);
  };
  const uploadDeclarationFile = () => {
    let newUser = localStorage.getItem('newUser');
    // console.log(newUser);
    setFileLoading(true);
    // Check if any file is selected or not
    if (declarationFile != null) {
      // If file selected then create FormData
      const fileToUpload = declarationFile;
      const data = new FormData();
      data.append('access_token', newUser);
      data.append('documentCategoryUuid', '3f7100af-e459-474f-8d0a-38478ec60d88');
      data.append('document', fileToUpload);
      // console.log(fileToUpload);
      // return;
      dispatch(declarationFileUploadFunction(data)).then(res => {
        // console.log(res.data);
        if (res.data.code == '200') {
          // alert(res.data.status + '  ' + res.data.message);
          // Swal.fire(res.data.status, res.data.message, 'success');
          setResponseMessage(res.data.message);
          setResponse(true);
          setResponseStatus(res.data.status);
          setDeclarationFile(null);
          setDeclarationFileShow(false);
          setFileLoading(false);
        } else {
          // alert(res.data.status + '  ' + res.data.message);
          // Swal.fire(res.data.status, res.data.message, 'info');
          setResponseMessage(res.data.message);
          setResponse(true);
          setResponseStatus(res.data.status);
          setDeclarationFile(null);
          setFileLoading(false);
        }
      });
    } else {
      // If no file selected the show alert
      // alert(<IntlMessages id='registration.files'/>);
      // Swal.fire('Alert', <IntlMessages id='registration.files'/>, 'info');
      setResponseMessage(<IntlMessages id="registration.files" />);
      setResponse(true);
      setResponseStatus('error');
      setFileLoading(false);
    }
  };
  const uploadCommercialRegistrationFile = () => {
    let newUser = localStorage.getItem('newUser');
    // console.log(newUser);
    setFileLoading(true);
    // Check if any file is selected or not
    if (commercialRegistrationFile != null) {
      // If file selected then create FormData
      const fileToUpload = commercialRegistrationFile;
      const data = new FormData();
      data.append('access_token', newUser);
      data.append('documentCategoryUuid', 'ce862cf2-62bb-4be1-a5b8-9ebeb9c6900d');
      data.append('document', fileToUpload);
      // console.log(fileToUpload);
      // return;
      dispatch(declarationFileUploadFunction(data)).then(res => {
        // console.log(res.data);
        if (res.data.code == '200') {
          // alert(res.data.status + '  ' + res.data.message);
          // Swal.fire(res.data.status, res.data.message, 'success');
          setResponseMessage(res.data.message);
          setResponse(true);
          setResponseStatus(res.data.status);
          setCommercialRegistrationFile(null);
          setCommercialRegistrationFileShow(false);
          setFileLoading(false);
        } else {
          // alert(res.data.status + '  ' + res.data.message);
          // Swal.fire(res.data.status, res.data.message, 'info');
          setResponseMessage(res.data.message);
          setResponse(true);
          setResponseStatus(res.data.status);
          setCommercialRegistrationFile(null);
          setFileLoading(false);
        }
      });
    } else {
      // If no file selected the show alert
      // alert(<IntlMessages id='registration.files'/>);
      // Swal.fire('Alert', <IntlMessages id='registration.files'/>, 'info');
      setResponseMessage(<IntlMessages id="registration.files" />);
      setResponse(true);
      setResponseStatus('error');
      setFileLoading(false);
    }
  };
  const uploadArticlesOfAssociation = () => {
    let newUser = localStorage.getItem('newUser');
    // console.log(newUser);
    setFileLoading(true);
    // Check if any file is selected or not
    if (articlesOfAssociationFile != null) {
      // If file selected then create FormData
      const fileToUpload = articlesOfAssociationFile;
      const data = new FormData();
      data.append('access_token', newUser);
      data.append('documentCategoryUuid', '557a9d43-5dc5-48bf-ad8e-a3657715f670');
      data.append('document', fileToUpload);
      // console.log(fileToUpload);
      // return;
      dispatch(declarationFileUploadFunction(data)).then(res => {
        if (res.data.code == '200') {
          // alert(res.data.status + '  ' + res.data.message);
          // Swal.fire(res.data.status, res.data.message, 'success');
          setResponseMessage(res.data.message);
          setResponse(true);
          setResponseStatus(res.data.status);
          setArticlesOfAssociationFile(null);
          setArticlesOfAssociationShow(false);
          setFileLoading(false);
        } else {
          // alert(res.data.status + '  ' + res.data.message);
          // Swal.fire(res.data.status, res.data.message, 'info');
          setResponseMessage(res.data.message);
          setResponse(true);
          setResponseStatus(res.data.status);
          setArticlesOfAssociationFile(null);
          setFileLoading(false);
        }
      });
    } else {
      // If no file selected the show alert
      // alert(<IntlMessages id='registration.files'/>);
      // Swal.fire('Alert', <IntlMessages id='registration.files'/>, 'info');
      setResponseMessage(<IntlMessages id="registration.files" />);
      setResponse(true);
      setResponseStatus('error');
      setFileLoading(false);
    }
  };
  const uploadBoardResolution = () => {
    let newUser = localStorage.getItem('newUser');
    // console.log(newUser);
    setFileLoading(true);
    // Check if any file is selected or not
    if (boardResolutionFile != null) {
      // If file selected then create FormData
      const fileToUpload = boardResolutionFile;
      const data = new FormData();
      data.append('access_token', newUser);
      data.append('documentCategoryUuid', 'e18c785d-8f7c-471f-be88-c27814affb69');
      data.append('document', fileToUpload);
      // console.log(fileToUpload);
      // return;
      dispatch(declarationFileUploadFunction(data)).then(res => {
        if (res.data.code == '200') {
          // alert(res.data.status + '  ' + res.data.message);
          // Swal.fire(res.data.status, res.data.message, 'success');
          setResponseMessage(res.data.message);
          setResponse(true);
          setResponseStatus(res.data.status);
          setBoardResolutionFile(null);
          setBoardResolutionShow(false);
          setFileLoading(false);
        } else {
          // alert(res.data.status + '  ' + res.data.message);
          // Swal.fire(res.data.status, res.data.message, 'info');
          setResponseMessage(res.data.message);
          setResponse(true);
          setResponseStatus(res.data.status);
          setBoardResolutionFile(null);
          setFileLoading(false);
        }
      });
    } else {
      // If no file selected the show alert
      // alert(<IntlMessages id='registration.files'/>);
      // Swal.fire('Alert', <IntlMessages id='registration.files'/>, 'info');
      setResponseMessage(<IntlMessages id="registration.files" />);
      setResponse(true);
      setResponseStatus('error');
      setFileLoading(false);
    }
  };
  const UploadIcon = () => {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" height="100" viewBox="0 0 100 100" width="100">
        <path d="M0 0h24v24H0z" fill="none" />
        <path d="M9 16h6v-6h4l-7-7-7 7h4zm-4 2h14v2H5z" />
      </svg>
    );
  };
  const registrationType = localStorage.getItem('registrationType');
  let isLoading = useSelector(state => state.registrationStepFiveDeclarationReducer.isFetching);
  // console.log(wrapperVariant, 'variant');
  return (
    <AuthWrapper variant={'stepfive'}>
      <SnackBarComponent
        status={responseStatus}
        visible={response}
        setResponse={setResponse}
        message={responseMessage}
        setResponseStatus={setResponseStatus}
        setResponseMessage={setResponseMessage}
      />
      <Box className={styles.authContent}>
        <Loader isLoading={isLoading} />
        <Typography style={{ marginBottom: 20 }} component="div" variant="h1" className={styles.titleRoot}>
          <IntlMessages id="registration.declaration" />
        </Typography>
        <div className={styles.root}>
          <Grid container spacing={3}>
            {/* <Grid item xs={6} sm={3} md={6}>
              <Paper className={styles.paper}>
                <div style={{ alignItems: 'center', display: 'flex' }}>
                  <input
                    type="file"
                    id="file"
                    ref={declarationFileRef}
                    onChange={event => selectDeclaration(event.target.files[0])}
                    style={{ display: 'none' }}
                  />

                  <Typography component="div" variant="h6" className={styles.titleRoot}>
                    <IntlMessages id="registration.declarationstatement" />
                  </Typography>
                </div>
                {declarationFileShow ? (
                  <div>
                    <Button onClick={() => declarationFileChoose()}>
                      <AttachmentIcon fontSize="200px" />
                      {'  choose file'}
                    </Button>
                    <Typography component="div" variant="h6" className={styles.titleRoot}>
                      {declarationFile?.name}
                    </Typography>
                    {declarationFile && (
                      <Button style={{ marginLeft: '3px' }} onClick={() => uploadDeclarationFile()}>
                        <IntlMessages id="registration.upload" />
                      </Button>
                    )}
                  </div>
                ) : (
                  <>
                    <CheckCircleIcon color={colors.green} />
                    <Typography component="div" variant="h6" className={styles.titleRoot}>
                      <IntlMessages id="registration.statementuploaded" />
                    </Typography>
                  </>
                )}
              </Paper>
            </Grid> */}
            {registrationType === 'company' && (
              <>
                <Grid item xs={6} sm={3} md={6}>
                  <Paper className={styles.paper}>
                    {' '}
                    <div style={{ alignItems: 'center', display: 'flex' }}>
                      <input
                        type="file"
                        id="file"
                        ref={commercialregistrationFileRef}
                        onChange={event => selectCommercialRegistration(event.target.files[0])}
                        style={{ display: 'none' }}
                      />

                      <Typography component="div" variant="h6" className={styles.titleRoot}>
                        <IntlMessages id="registration.commercialregistration" />
                      </Typography>
                    </div>
                    {commercialRegistrationFileShow ? (
                      <div>
                        <Button onClick={() => commercialRegistrationChoose()}>
                          <AttachmentIcon fontSize="200px" />
                          {'  choose file'}
                        </Button>
                        <Typography component="div" variant="h6" className={styles.titleRoot}>
                          {commercialRegistrationFile?.name}
                        </Typography>
                        {commercialRegistrationFile && (
                          <Button style={{ marginLeft: '3px' }} onClick={() => uploadCommercialRegistrationFile()}>
                            <IntlMessages id="registration.upload" />
                          </Button>
                        )}
                      </div>
                    ) : (
                      <>
                        <CheckCircleIcon color={colors.green} />
                        <Typography
                          component="div"
                          variant="h6"
                          className={styles.titleRoot}
                          style={{ textAlign: 'center', display: 'flex', alignItems: 'center' }}>
                          <IntlMessages id="registration.commercialregistrationupload" />
                        </Typography>
                      </>
                    )}
                  </Paper>
                </Grid>
                <Grid item xs={6} sm={3} md={6}>
                  <Paper className={styles.paper}>
                    <div style={{ alignItems: 'center', display: 'flex' }}>
                      <input
                        type="file"
                        id="file"
                        ref={articlesOfAssociationsFileRef}
                        onChange={event => selectArticlesOfAssociations(event.target.files[0])}
                        style={{ display: 'none' }}
                      />

                      <Typography component="div" variant="h6" className={styles.titleRoot}>
                        <IntlMessages id="registration.articlesofassociation" />
                      </Typography>
                    </div>
                    {articlesOfAssociationShow ? (
                      <div>
                        <Button onClick={() => articlesOfAssociationChoose()}>
                          <AttachmentIcon fontSize="200px" />
                          {'  choose file'}
                        </Button>
                        <Typography component="div" variant="h6" className={styles.titleRoot}>
                          {articlesOfAssociationFile?.name}
                        </Typography>
                        {articlesOfAssociationFile && (
                          <Button style={{ marginLeft: '3px' }} onClick={() => uploadArticlesOfAssociation()}>
                            <IntlMessages id="registration.upload" />
                          </Button>
                        )}
                      </div>
                    ) : (
                      <>
                        <CheckCircleIcon color={colors.green} />
                        <Typography component="div" variant="h6" className={styles.titleRoot}>
                          <IntlMessages id="registration.articlesofassociationupload" />
                        </Typography>
                      </>
                    )}
                  </Paper>
                </Grid>
                <Grid item xs={6} sm={3} md={6}>
                  <Paper className={styles.paper}>
                    <div style={{ alignItems: 'center', display: 'flex' }}>
                      <input
                        type="file"
                        id="file"
                        ref={boardResolutionRef}
                        onChange={event => selectBoardResolution(event.target.files[0])}
                        style={{ display: 'none' }}
                      />

                      <Typography component="div" variant="h6" className={styles.titleRoot}>
                        <IntlMessages id="registration.boardingresolutions" />
                      </Typography>
                    </div>
                    {boardResolutionShow ? (
                      <div>
                        <Button onClick={() => boardResolutionChoose()}>
                          <AttachmentIcon fontSize="200px" />
                          {'  choose file'}
                        </Button>
                        <Typography component="div" variant="h6" className={styles.titleRoot}>
                          {boardResolutionFile?.name}
                        </Typography>
                        {boardResolutionFile && (
                          <Button style={{ marginLeft: '3px' }} onClick={() => uploadBoardResolution()}>
                            <IntlMessages id="registration.upload" />
                          </Button>
                        )}
                      </div>
                    ) : (
                      <>
                        <CheckCircleIcon color={colors.green} />
                        <Typography component="div" variant="h6" className={styles.titleRoot}>
                          <IntlMessages id="registration.boardingresolutionsupload" />
                        </Typography>
                      </>
                    )}
                  </Paper>
                </Grid>
              </>
            )}
          </Grid>
        </div>

        {fileLoading && <PageLoader />}
        <form>
          <Box mb={2}>
            <Typography style={{ textAlign: 'justify' }}>
              <Checkbox onClick={() => setCheckedOne(true)} value={'one'} /> <IntlMessages id="registration.declaration1" />
            </Typography>
          </Box>
          <Box mb={2}>
            <Typography style={{ textAlign: 'justify' }}>
              <Checkbox onClick={() => setCheckedTwo(true)} value={'two'} />
              <IntlMessages id="registration.declaration2" />
            </Typography>
          </Box>
          <Box mb={2}>
            <Typography style={{ textAlign: 'justify' }}>
              <Checkbox onClick={() => setCheckedThree(true)} value={'three'} />
              <IntlMessages id="registration.declaration3" />
            </Typography>
          </Box>
          <Box mb={2}>
            <Typography style={{ textAlign: 'justify' }}>
              <Checkbox onClick={() => setCheckedFour(true)} value={'four'} />
              <IntlMessages id="registration.declaration4" />
            </Typography>
          </Box>
          <Box mb={2}>
            <Typography style={{ textAlign: 'justify' }}>
              <Checkbox onClick={() => setCheckedFive(true)} value={'five'} />
              <IntlMessages id="registration.declaration5" />
            </Typography>
          </Box>
          <Box mb={2}>
            <Typography style={{ textAlign: 'justify' }}>
              <Checkbox onClick={() => setTerms(true)} value={'terms'} />
              <IntlMessages id="registration.click" />{' '}
              <a href={websiteUrl + 'Terms'} target="_blank">
                <IntlMessages id="registration.conditions" />
              </a>
            </Typography>
          </Box>

          <Box
            display="flex"
            flexDirection={{ xs: 'column', sm: 'row' }}
            alignItems={{ sm: 'center' }}
            justifyContent={{ sm: 'space-between' }}
            mb={3}>
            <Box mb={{ xs: 2, sm: 0 }}>
              <Button onClick={onSubmit} variant="contained" color="primary">
                <IntlMessages id="appModule.submit" />
              </Button>
            </Box>
          </Box>
        </form>

        <Box mb={3}>{dispatch(AuhMethods[method].getSocialMediaIcons())}</Box>

        <ContentLoader />
      </Box>
      <Dialog fullWidth maxWidth={'xs'} open={verifyModal}>
        <DialogTitle>
          <IntlMessages id="dashboard.mobilenotification" />
        </DialogTitle>
        <DialogContent>
          <TextField
            fullWidth
            autoComplete="off"
            label={<IntlMessages id="registration.enterotp" />}
            onChange={event => setOtp(event.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setVerfiyModal(false)}>
            <IntlMessages id="dashboard.close" />
          </Button>
          {resendButton == true ? (
            <Button onClick={() => verifyMobileOtp()}>
              <IntlMessages id="registration.submit" />
            </Button>
          ) : (
            <Button
              onClick={() => {
                refreshButton();
              }}>
              <IntlMessages id="registration.resend" />
            </Button>
          )}
        </DialogActions>
        {timeLeft != 0 ? (
          <DialogContentText style={{ textAlign: 'center' }}>
            <IntlMessages id="registration.resendotp" /> {timeLeft}s
          </DialogContentText>
        ) : null}
        <DialogContentText>
          <Typography style={{ marginLeft: 10, marginRight: 10 }}>{verifymessage}</Typography>
        </DialogContentText>
      </Dialog>
      <Dialog
        open={disOpen}
        style={{ height: 'auto' }}
        fullWidth
        classes={{
          paperFullWidth: styles.paperFullWidth,
        }}>
        <DialogContent style={{ paddingTop: '20px' }}>
          <Box
            style={{ borderStyle: 'solid', borderWidth: '1px', borderColor: 'green', padding: 'inherit' }}
            className={styles.authContent}>
            {languageCheck == 'en' ? (
              <>
                <Typography className={styles.decHeader}>MASTER SERVICES AGREEMENT</Typography>
                <Typography className={styles.decHeader}>BETWEEN</Typography>
                <Typography className={styles.decText}>1. THE FINANCER; and</Typography>
                <Typography className={styles.decText}>
                  2. RAQAMYAH PLATFORM FOR INFORMATION TECHNOLOGY, a limited liability company incorporated under the laws of
                  the Kingdom of Saudi Arabia, with Commercial Registration number 1010449309 and having its registered Head
                  Office at 2908, Prince Mohammad Abdulaziz Road, Centria Mall, Third Floor, Office 307, Olaya, Riyadh
                  12241-6055, Kingdom of Saudi Arabia ( "Raqamyah").
                </Typography>
                <Typography className={styles.decHeader}>BACKGROUND</Typography>

                <Typography className={styles.decText}>
                  1. Raqamyah provides an online peer-to-peer lending service platform (the "Platform") through which
                  eligible participants can lend money (the "Financers") to eligible third party borrowers (the "Financees").
                </Typography>
                <Typography className={styles.decText}>
                  2. The Financer intends to register itself as a lender on the Platform after accepting Raqamyah's terms and
                  conditions for Financers as stated herein, which are to be read in conjunction with Raqamyah's General
                  Terms and Conditions, FAQ's and Privacy Policy (collectively the "Terms").
                </Typography>
                <Typography className={styles.decHeader}>TERMS AGREED</Typography>
                <Typography className={styles.decHeader}>1. DEFINITIONS AND INTERPRETATION</Typography>
                <Typography className={styles.decText}>
                  1. The following terms have the meanings indicated below: "Automatic Financing has the meaning given to
                  such term in Clause a);
                  {<br />} Collection Agent has the meaning given to such term in Clause 14.2;
                  {<br />} Financees has the meaning given to such term in Recital
                  {<br />} Financers has the meaning given to such term in Recital
                  {<br />} Financer Account means the online account that the Financer will open with Raqamyah (upon its
                  successful registration as a lender) through which the Financer will carry out all transactions on the
                  Platform;
                  {<br />}Guarantor" means a person who is acting as a guarantor for the obligations of a Financee pursuant
                  to the Murabaha Agreement;
                  {<br />} Individual Financer has the meaning given to such term in paragraph a) of Clause 3.2;
                  {<br />} Institutional Financer has the meaning given to such term in paragraph 0 of Clause 3.2;
                  {<br />} Intermediary Services has the meaning given to such term in Clause 10.2;
                  {<br />} Management Fee has the meaning given to such term in Clause 11.1;
                  {<br />}Manual Financing" has the meaning given to such term in Clause b);
                  {<br />} Matching refers to the process of matching a Financee with one or more Financers through the
                  execution of a Murabaha Agreement by a Financer and the terms Match, Matches, or Matched shall be construed
                  accordingly;
                  {<br />} Murabaha Agreement means the lending agreement between the Financee and Raqamyah (acting as an
                  agent on behalf of the undisclosed Financer(s)) pursuant to which funds will be lent to a Financee;
                  {<br />} Omnibus Account means a segregated omnibus bank account with a Saudi bank licensed by SAMA which
                  will be used to facilitate the transfer of funds between the Financers and the Financees and vice versa in
                  order for the parties to transact through the Platform;
                  {<br />} Platform has the meaning given to such term in Recital
                  {<br />} Qualified Individual Financer has the meaning given to such term in paragraph 0 of Clause 3.2;
                  {<br />}Security Agent" means a third-party security agent that may be appointed by Raqamyah to hold any
                  security provided by a Financee;
                  {<br />} Services has the meaning given to such term in Clause 10.1;
                  {<br />} Terms has the meaning given to such term in Recital B; and
                  {<br />}Website means the Raqamyah website
                  <a className={styles.decText} href={websiteUrl} target="_blank">
                    {' '}
                    www.raqamyah.com
                  </a>
                </Typography>
                <Typography className={styles.decText}>
                  2. The headings in this Agreement are for convenience only and shall not affect its interpretation.
                </Typography>
                <Typography className={styles.decText}>
                  3. Each of the schedules shall have effect as if set out in this Agreement.
                </Typography>
                <Typography className={styles.decText}>
                  4. References to any statute or statutory provision include a reference to that statute or statutory
                  provision as amended, consolidated or replaced from time to time (whether before or after the date of this
                  Agreement) and include any subordinate legislation made under the relevant statute or statutory provision.
                </Typography>
                <Typography className={styles.decText}>
                  5. Words in the singular include the plural and vice versa and words importing any gender include every
                  gender.
                </Typography>
                <Typography className={styles.decText}>
                  6. References to times of day are, unless the context requires otherwise, to KSA time and references to a
                  day are to a period of 24 hours running from midnight on the previous day.
                </Typography>
                <Typography className={styles.decText}>
                  7. References to day, month, year and any other references in time shall be construed by reference to the
                  Gregorian calendar.
                </Typography>

                <Typography className={styles.decText}>
                  8. References to persons include individuals, firms, sole proprietorships, partnerships, limited liability
                  partnerships, companies, bodies corporate, corporations, unincorporated associations, governments,
                  authorities, agencies and trusts (in each case, whether or not having separate legal personality) wherever
                  situated and references to an individual or natural person include his estate and personal representatives.
                </Typography>
                <Typography className={styles.decText}>
                  9.Any phrase introduced by the term )including, include, in particular or any similar expression( shall be
                  construed as illustrative and shall not limit the sense of the words preceding that term.
                </Typography>

                <Typography className={styles.decHeader}>2. SCOPE OF THE CONTRACT</Typography>

                <Typography className={styles.decText}>
                  This Agreement and the Terms constitute the entire terms and conditions under which the Financer agrees to
                  be engaged in peer-to-peer lending through the Platform.
                </Typography>
                <Typography className={styles.decHeader}>3. REGISTRATION OF FINANCER</Typography>

                <Typography className={styles.decText}>
                  1. The Financer shall create an account and register itself on the Platform. The Financer acknowledges and
                  agrees that it will be on-boarded as a Financer subject to fulfilling all the eligibility conditions of the
                  Terms and providing Raqamyah with all the relevant information and documents as and when requested.
                </Typography>

                <Typography className={styles.decText}>
                  In order to register, a Financer must classify as: {<br />}
                  An individual Financer who must: {<br />}
                  (i) be a natural person; {<br />}
                  (ii) be aged twenty-one (21) years or over; {<br />}
                  (iii) provide his/her current and valid National Identification Card details (as a Saudi Arabian national)
                  or Iqama (Resident identity, as a foreign national); and {<br />}
                  (iv) provide his/her national address details (the "Individual Financer"). {<br />}
                  The Individual Financer hereby agrees that Raqamyah shall access the Yakeen service to validate his/her
                  National Identification Card or Iqama (Resident Identity); or {<br />}
                </Typography>
                <Typography className={styles.decText}>
                  2. A qualified individual Financer who must:{<br />}
                  (i) be a natural person;{<br />}
                  (ii) be aged twenty-one (21) years or over;{<br />}
                  (iii) provide his/her National Identification Card details (as a Saudi Arabian national) or his/her Iqama
                  (Resident Identity, as a foreign national); and{<br />}
                  (iv) fulfil at least one of the following criteria (by signing a declaration on the Website stating the
                  same) (the "Qualified Individual Financer"):{<br />}
                  (A) own net assets with a value not less than three (3) Million Saudi Riyals;{<br />}
                  (B) work or have worked for at least three (3) years in the financial sector in a position related to
                  finance or investment;
                  {<br />}
                  (C) hold a professional certificate in finance or investment is approved by an internationally or national
                  recognized establishment; or{<br />}
                  (D) have an annual income that is not less than six hundred thousand Saudi Riyals (SAR600,000) in the two
                  most recent years.
                  {<br />}
                  The Individual Financer hereby agrees that Raqamyah shall access the Yakeen service to validate his/her
                  National Identification Card or Iqama (Resident Identity); or{<br />}
                </Typography>
                <Typography className={styles.decText}>
                  3. An institutional Financer who must be:{<br />}
                  (i) an entity licensed by the Saudi SAMA; or{<br />}
                  (ii) an entity or an investment fund licensed by the Capital Market Authority; or{<br />}
                  (iii) a legal entity incorporated under the laws of the Kingdom of Saudi Arabia; or{<br />}
                  (iv) a governmental or semi-governmental office or entity of the Kingdom of Saudi Arabia (the
                  "Institutional Financer"). An Institutional Financer must provide:{<br />}
                  (A) its current and valid Commercial Registration certificate issued by the Ministry of Commerce (if
                  applicable); and{<br />}
                  (B) a copy of the power of attorney or the authorisation letter appointing its authorised representative
                  unless{<br />}
                  (C) such authorised representative has been specifically appointed in a board or shareholders' resolution
                  permitting the Institutional Financer to carry out the necessary Platform activities; or{<br />}
                  (D) such authorised representative has the existing authority under the Institutional Financer's articles
                  of association to carry out the necessary Platform activities.{<br />}
                  The Institutional Financer hereby agrees that Raqamyah shall access the Thiqah service to validate its
                  Commercial Registration certificate (if applicable).{<br />}
                </Typography>
                <Typography className={styles.decText}>
                  3. Raqamyah shall not carry out any independent inquiries to verify that any corporate authority or
                  authorisation, licence or registration, information and documentation provided pursuant to Clause 3.2 is
                  correct, true, up to date and in place. The Financer is obliged to immediately provide Raqamyah with any
                  changes to the information and documentation provided.
                </Typography>
                <Typography className={styles.decText}>
                  4. Raqamyah shall carry out "know your customer" procedures and checks pursuant to its internal
                  requirements and in accordance with the applicable laws of the Kingdom of Saudi Arabia. Raqamyah may
                  request the Financer to provide additional documentation for "know your customer" checks and any failure to
                  provide such documentation shall result in the application of potential Financer being rejected, with such
                  decision being final and binding.
                </Typography>
                <Typography className={styles.decText}>
                  5. The eligibility criteria listed in Clause 3.2 is non-exhaustive and Raqamyah reserves the right to amend
                  the eligibility criteria at any time on its Website, without any notification to the Financer. Raqamyah
                  further reserves the right to accept or reject any Financer application at its sole discretion, even if the
                  Financer satisfies the eligibility criteria. All decisions made by Raqamyah shall be final and binding and
                  shall not be subject to appeal.
                </Typography>
                <Typography className={styles.decText}>
                  6. The Financer must furnish Raqamyah with all information and documents that Raqamyah may reasonably
                  request from time to time and in relation to the Financer's financial condition or business, in order to
                  enable Raqamyah to fulfil its legal obligations and to ensure the proper operation of Platform.
                </Typography>
                <Typography className={styles.decText}>
                  7. Raqamyah shall have the right, acting in its sole discretion, to waive the requirement for any document
                  that must be provided by an Institutional Financer, without prejudice to its right to request these or
                  additional documents at any time.
                </Typography>

                <Typography className={styles.decHeader}>4 .CREATING A FINANCER ACCOUNT</Typography>
                <Typography className={styles.decText}>
                  1. Upon the provision by the Financer of the relevant documentation matching its eligibility criteria and
                  satisfying Raqaymah's "know your customer" checks and Anti-Money Laundering and Counter-Terrorism Financing
                  procedures, Raqamyah shall within three (3) business days notify the Financer by email or SMS if its
                  application to become a Financer is successful. If the application is successful, Raqamyah shall provide
                  the Financer with a unique IBAN number for its Finance Account. The Financer must use this IBAN number for
                  all transactions it carried out on the platform.
                </Typography>
                <Typography className={styles.decText}>
                  2. The Financer is prohibited from using its Financer Account to make any transfer to third parties or
                  accept any transfer from third parties without Raqamyah's prior written approval. The Financer must
                  immediately notify Raqamyah if it suspects that a third party has accessed the Financer Account or if it
                  suspects a security breach has occurred.
                </Typography>
                <Typography className={styles.decText}>
                  3. Raqamyah reserves the right not to comply with the Financer's instructions and to suspend the Financer's
                  Account if it suspects a security breach, any illegal or fraudulent activity in connection with the
                  Financer Account or the unauthorised use of the Financer Account.
                </Typography>
                <Typography className={styles.decText}>
                  4. Raqamyah reserves the right to suspend the Financer Account if the Financer has not updated any
                  information provided to Raqamyah in connection with creating the Financer Account. In the event Raqamyah
                  requests for updated information and if the Financer does not respond to such queries the Financer Account
                  shall be suspended. During the period of suspension the Financer shall only be able to request withdrawals
                  and will not be permitted to carry out any financing activities. The Financer Account will be activated
                  once the required documentation has been provided to Raqamyah.
                </Typography>
                <Typography className={styles.decText}>
                  5. The Financer shall use the Financer Account to credit funds to the Omnibus Account by means of wire
                  transfer. Cash deposits into the Omnibus Account will not be permitted.
                </Typography>
                <Typography className={styles.decText}>
                  6. Upon Raqamyah receiving funds in the Omnibus Account, the Financer may engage in financing. Any
                  applicable fees in connection with the financing may be deducted from time to time from the balance of the
                  funds held on behalf of the Financer. Such funds shall be held on behalf of the Financer until they are
                  withdrawn by the Financer, applied towards any applicable fees or transferred to a Financee.
                </Typography>
                <Typography className={styles.decText}>
                  7. Upon the allocation of any of the Financer's funds to a specific finance request, the Financer must wait
                  until the finance request is fully repaid by the Financee as Raqamyah does not offer a secondary market
                  option.
                </Typography>
                <Typography className={styles.decHeader}>5. FINANCER REPRESENTATIONS AND WARRANTIES</Typography>
                <Typography className={styles.decText}>
                  1. The Financer represents and warrants that:{<br />}
                  1. it has read, understood and agreed to be bound by this Agreement and the Terms, as they may be amended
                  from time to time. Raqamyah has the right to amend this Agreement and the Terms at any time, (whether to
                  reflect any changes in laws and regulations or to change its existing services due to changes in technology
                  and systems) without any notification to or consent from the Financers. By continuing to access and use the
                  Platform after any amendments to this Agreement or the Terms the Financer accepts and agrees to be bound by
                  the amendments;{<br />}
                  2. if it any time does not agree with any amendments made by Raqamyah to this Agreement or the Terms it may
                  close its Financer Account by contacting Raqamyah by email at contactus@raqamyah.com or by telephone at
                  +920004032.{<br />}
                  3. all information that it has provided to Raqmyah in connection with its application to become a Financer
                  is accurate, true and up to date;{<br />}
                  4. as an Institutional Financer, the person acting on its behalf is duly authorised to act and shall remain
                  authorised to act on its behalf. Furthermore, such authorised representative shall have the necessary
                  authority to enter into this Agreement on behalf of the Institutional Financer and the Institutional
                  Financer has the authority to enter into this Agreement. Raqamyah shall not be liable in any way whatsoever
                  as a result of the actions of any authorised representative who does not have the due authority to enter
                  into this Agreement on behalf of an Institutional Financer; and{<br />}
                  5. as an Institutional Financer, it has a permanent place of business.{<br />}
                </Typography>
                <Typography className={styles.decText}>
                  2. The Financer further represents and warrants that as long as it maintains a Financer Account with
                  Raqamyah:{<br />}
                  1. it shall continue to satisfy the Financer eligibility criteria laid out in this Agreement;{<br />}
                  2. all information and documentation it has provided is accurate, true, up to date and not misleading;
                  {<br />}
                  3. it has disclosed and shall continue to disclose all circumstances and factors which may materially or
                  adversely change its financial condition, such as legal claims, any liabilities, litigation or alleged
                  investigations which are threatened, pending or current against it;{<br />}
                  4. it shall keep its unique IBAN number secure and confidential;
                  {<br />}
                  5. no unauthorised person will be able to access the Financer Account and only authorised persons are
                  permitted to access the Financer Account. In the event such authorised persons are changed, the Financer
                  warrants that such persons are duly authorised; and
                  {<br />}
                  6. it shall remain authorised to credit funds to the unique IBAN.
                  {<br />}
                </Typography>

                <Typography className={styles.decHeader}>6.RISK ACKNOWLEDGEMENT</Typography>
                <Typography className={styles.decText}>
                  1. The Financer acknowledges and confirms that:{<br />}
                  1. it has carefully read and considered the risks involved in financing through the Platform and that it
                  has assessed that it has the appropriate risk appetite to undertake the transactions contemplated herein.
                  {<br />}
                  2. by accepting the provisions of this Agreement and the Terms it is engaging in peer-to-peer lending and
                  that at no time will the Financer be deemed to be financing Raqamyah.{<br />}
                  3. it understands the risks associated with peer-to-peer lending and may lose all its money or part thereof
                  if a Financee with whom the Financer has been Matched defaults on repayments and Raqamyah has no liability
                  for any such loss.{<br />}
                  4. that it has made its own independent decision to become a Financer on the Platform and to be Matched to
                  Financees and that has not relied on any statement, promise, representation, assurance or warranty made or
                  given by or on behalf of Raqamyah, which is not set out in this Agreement and the Terms.{<br />}
                  5. it will not be paid any repayment of its funds until a finance request has been repaid in full by the
                  relevant Financee. The Financer's liquidity shall be affected during the duration of the relevant Murabaha
                  Agreement unless there is an early repayment or a default by the Financees.{<br />}
                  6. the provision by Raqamyah of the Services and the Intermediary Services (each as defined below) is not
                  intended to constitute or be construed as advice, recommendations or a guarantee by Raqamyah as to the
                  creditworthiness of any Financee, the amount of repayments the Financer might receive, or the likelihood of
                  payment defaults that may occur, in relation to any Murabaha Agreement.{<br />}
                  7. Raqamyah is neither licensed nor authorised to grant financing advice. No information contained on the
                  Platform or on the Website constitutes or shall be construed to constitute financing advice, investment
                  advice or a recommendation to any Financer to lend money.
                  {<br />}
                </Typography>
                <Typography className={styles.decHeader}>7. FINANCING PROCESS</Typography>

                <Typography className={styles.decText}>
                  1. A Financer can use the Platform to provide financing to Financees in the following two ways:{<br />}
                  1. Automatic Financing This type of financing uses a tool called Automatic Financing to automatically
                  select the Financee that Financers are willing to finance based on pre-selected criteria. Financers can
                  select their own custom mix based on the following:
                  {<br />}
                  2. maximum amount for each finance request;{<br />}
                  3. sector(s) that the Financer wants to finance; and{<br />}
                  4. risk grades that the Financer wants to finance.{<br />}A Financer that chooses to use the automated tool
                  function acknowledges and agrees that its funds shall be automatically allocated to different finance
                  requests from different Financees based on the pre-selected criteria the Financer has set up. If the
                  Financer uses the automated financing tool and sets it criteria to be automatically Matched to Financees it
                  can stop using the automated tool at any time by switching it off.{<br />}
                  1. Manual Financing: is the process whereby the Financer manually selects Financees that it may be
                  interested in financing by using the Raqamyah search tool and bidding for funding such Financees through
                  Raqamyah's online bidding on the Website. Financers can browse available finance requests and compare
                  finance requests details such as score, finance request purpose, finance request type, duration, profit
                  rate, and Financee information. The funding process for every finance request is open for up to thirty (30)
                  days, and the funding period will close once it is fully committed. Orders are made on a first come first
                  serve basis.{<br />}
                  2. In accordance with its acceptance of the Terms and this Agreement, the Financer shall be entitled to
                  select whether it chooses to provide financing through Automatic Financing or Manual Financing.{<br />}
                  3. Financers will be able to monitor the following when using the Platform:{<br />}
                  1. the amount financed;{<br />}
                  2. earned profits;{<br />}
                  3. receivable profits;{<br />}
                  4. the amount received;{<br />}
                  5. the annual specialised commission rate;{<br />}
                  6. the funded finance requests;{<br />}
                  7. the transaction history;{<br />}
                  8. the status of financed amounts;{<br />}
                  9. the repayment schedule;{<br />}
                  10. the detailed profile of each Financee; and{<br />}
                  11. the portfolio composition.{<br />}
                  4. It is the Financer's sole decision whether or not to provide finance to Financees on the Platform.
                  Raqamyah is not authorised to grant any financing advice. No information contained on the Platform or on
                  the Website constitutes or shall be construed to constitute financing advice, investment advice or a
                  recommendation to finance money.{<br />}
                  5. The Financer confirms that it has sought independent financial, legal, Zakat, tax or other professional
                  advice prior to financing through the Platform.{<br />} 6. Once a Financer has made a financing bid,
                  whether by means of Automated Financing or Manual Financing, it shall have two days to withdraw its
                  financing bid provided that the funding period is open. All financing bids, whether by means of Automated
                  Financing or Manual Financing, shall be binding on the Financer once the funding period for a particular
                  transaction is closed and such bid may not be revoked by the Financer for any reason whatsoever.{<br />}
                  7. In the event a finance amount requested by any Financee is not fully covered by the closing of thirty
                  (30) days period, Raqamyah reserves the right to further extend the bidding period for an additional thirty
                  (30) days. If after the additional period, the finance amount requested is still not covered in full by all
                  the bids received from the Financers, then Raqamyah shall close the funding period and return the funds
                  provided by each of the Financers in relation to such finance request.{<br />}
                  8. Upon repayment by the Financee into the Omnibus Account, Raqamyah shall, by wire transfer, transfer the
                  Financer's portion of the repayment into the Financer's Account.{<br />}
                </Typography>
                <Typography className={styles.decHeader}>8. FUNDING LIMITS</Typography>

                <Typography className={styles.decText}>
                  Depending upon the classification of the Financer in accordance with the eligibility criteria set out in
                  the Terms, the Financer will be limited to transferring the following amounts in any one calendar year:
                  {<br />}
                  1. Individual Financer – maximum amount that may be transferred annualy to Raqamyah is SAR 200,000 and the
                  maximum exposure to one finance request is SAR 50,000 shall not exceed 25% of the finance amount requested.
                  {<br />}
                  2. Qualified Individual Financer – no restrictions.{<br />}
                  3. Institutional Financer – no restrictions.{<br />}
                </Typography>
                <Typography className={styles.decHeader}>9. OMNIBUS ACCOUNT</Typography>

                <Typography className={styles.decText}>
                  1. The Financer agrees and acknowledges that all funds provided by it shall be held in the Omnibus Account.
                  {<br />}
                  2. The Financer further acknowledges and agrees that Raqamyah will hold all the funds provided by all
                  Financers in the same Omnibus Account and that all the Financers' funds will be co-mingled.{<br />}
                  3. Raqamyah shall act as agent in relation to such Omnibus Account and in its capacity as an agent it
                  represents and warrants:{<br />}
                  4. the funds received from the Financer will be co-mingled with other funds received from other Financers
                  but shall not be co-mingled with funds belonging to Raqamyah; and{<br />}
                  5. in the event of Raqamyah's insolvency, winding up or any other bankruptcy event, the funds received from
                  the Financer shall be separate to and segregated from the funds belonging to Raqamyah and only the Raqamyah
                  funds shall be subject to the distribution rules contained in the bankruptcy rules and regulations of the
                  Kingdom of Saudi Arabia.{<br />}
                  6. Withdrawals of funds from the Omnibus Account are permitted provided that all fees in connection with a
                  particular financing have been paid in full. Raqamyah has the authority to suspend or place limitations the
                  Omnibus Account from time to time if this is necessary to comply with any legal or regulatory requirements.
                  {<br />}
                  7. The Omnibus Account shall not accrue any interest or profit to be paid to the Financer.{<br />}
                </Typography>
                <Typography className={styles.decHeader}>10. SERVICES</Typography>

                <Typography className={styles.decText}>
                  1. Raqamyah shall provide the following services (the "Services") to the Financer:{<br />}
                  1. operate and manage the Platform and the Website;{<br />}
                  2. Match Financers with the Financees;{<br />}
                  3. facilitate the Murabaha financing transactions between Financers and the Financees; and{<br />}
                  4. perform Intermediary Services (as defined below) as Financing Agent in relation to the Murabaha
                  transactions.{<br />}
                  2. Raqamyah shall provide or arrange for the provision of the following intermediary services (the
                  "Intermediary Services"):
                  {<br />}
                  1. assessing the creditworthiness of Financees;{<br />}
                  2. preparing the Murabaha Agreement for Financers and Financees and acting as Financing Agent in relation
                  thereto;{<br />}
                  3. providing Financees with the necessary pre-contractual, contractual and post-contractual documentation
                  and information;
                  {<br />}
                  4. collecting and distributing payments due to the Financer and received by Raqamyah from Financees (and
                  Guarantors if any) through the use of the Omnibus Account; and{<br />}
                  5. entering into an Omnibus Protocol Agreement with Saudi Banks.
                  {<br />}
                  The Financer hereby authorises Raqamyah to act as its agent in respect of the Intermediary Services.
                  {<br />}
                  1. The provision of Services by Raqamyah (and specifically the Intermediary Services (including the credit
                  assessment of Financees) is not intended to constitute or be construed as advice, recommendation or a
                  guarantee by Raqamyah as to the:
                  {<br />}
                  2. creditworthiness of any Financee;{<br />}
                  3. amount of repayments that the Financer may receive; or{<br />}
                  likelihood of payment defaults that may occur, in relation to any Murabaha Agreement.{<br />}
                </Typography>
                <Typography className={styles.decHeader}>11. FEES</Typography>
                <Typography className={styles.decText}>
                  1. For the provision of the Intermediary Services, Raqamyah shall charge a management fee which shall be
                  calculated at two percent (2%) per annum on outstanding financed amounts (the "Management Fee"). For the
                  avoidance of doubt, Raqamyah shall not charge any fees for creating a Financer Account.{<br />}
                  2. In the event Raqamyah incurs any further reasonable expenses in the provision of the Intermediary
                  Services, it shall notify the Financer in writing and the Financer shall reimburse Raqamyah for such
                  expenses. To facilitate such reimbursement, the Financer hereby expressly authorises Raqamyah to access and
                  withdraw such amount from the Financer Account, or otherwise recover such expenses from any payments due to
                  the Financer or request payment directly from the Financer in relation to such expenses.{<br />}
                  3. Raqamyah reserves all its rights to change or waive any applicable Management Fee from time to time at
                  its sole discretion.
                  {<br />}
                </Typography>

                <Typography className={styles.decHeader}>
                  12. PROVISION OF SECURITY AND APPOINTMENT OF SECURITY AGENT
                </Typography>
                <Typography className={styles.decText}>
                  1. If any form of security has been created by a Financee or a Guarantor, their obligations to the Financer
                  under the Murabaha Agreement and the Guarantee will be secured by that security.
                  {<br />}
                  2. Raqamyah reserves the right to enter into an agreement with a Security Agent to hold any security on
                  behalf of the Financer in relation to any Murabaha Agreement and Guarantee. Raqamyah shall authorise the
                  Security Agent to exercise the rights, powers, authorities and discretions specifically granted to the
                  Security Agent under any separate security agreement. The Security Agent's authorities are purely
                  administrative and shall be governed by the security agreement, this Agreement and applicable laws. If
                  there is any inconsistency between the security agreement and this Agreement, this Agreement shall prevail.
                  {<br />}
                  3. The Security Agent is entitled to rely on any representation, notice or document believed by it to be
                  authorised, genuine, correct and may rely on any statement made by a director, authorised person or
                  employee of any person regarding any matters which may reasonably be assumed to be within their knowledge
                  or power to verify. The Security Agent shall have the discretion to exercise all of its rights and
                  authorities and it shall have no liability save for any action caused by its own negligence or misconduct.
                  {<br />}
                  4. The Financer agrees, accepts and acknowledges that:{<br />}
                  1. it is not entitled to take any action to enforce any security against the Financee or the Guarantor
                  directly and it hereby authorises Raqamyah to take all actions necessary in relation to any enforcement
                  through the Security Agent.{<br />}
                  2.the existence of security does not mean that a Financee or a Guarantor shall fully fulfill its repayment
                  obligations under a Murabaha Agreement or a Guarantee and does not constitute a guarantee of full repayment
                  by the Financee or the Guarantor.
                  {<br />}
                  3. the enforceability of any security is subject to the normal risks and limitations under applicable law
                  and that the proceeds received following enforcement may not be sufficient to discharge all of the
                  obligations owed by the Financee and any Guarantor to the Financer. There may be other creditors of the
                  Financee and the Guarantor that have claims that may be recovered in priority to those of the Security
                  Agent acting on the Financer's behalf.{<br />}
                  4. the Security Agent shall not be liable for any loss suffered by the Financer unless the loss is caused
                  by the Security Agent's negligence or misconduct.{<br />}
                  5. If a Financee or a Guarantor fail to fulfill any repayment obligation when due or if an event of default
                  occurs under the Murabaha Agreement, the Security Agent shall be entitled to enforce the security. A
                  default notice shall be sent to the Financee and the Guarantor together with a demand for repayment of the
                  amounts due and any applicable fees and costs.{<br />}
                  6. If the security becomes enforceable, the Security Agent shall enforce the security in accordance with
                  the security agreement in the interests of the Financer and shall be entitled to make the distributions in
                  accordance with the security agreement, this Agreement and the Terms.{<br />}
                  7. Following the enforcement of security, any amounts received or recovered by the Security Agent shall be
                  applied in the following order of priority:{<br />}
                  1. towards payment or reimbursement of all costs, expenses, losses or liabilities incurred by the Security
                  Agent;{<br />}
                  2. payments of all amounts due to the Financer in accordance with this Agreement, the Terms and the
                  relevant Murabaha Agreement; and
                  {<br />}
                  3. payment of any surplus amounts to the Financee, the Guarantor or any other person entitled to receive
                  the same under applicable law.
                  {<br />}
                </Typography>

                <Typography className={styles.decHeader}>13. DEFAULT BY A FINANCE</Typography>
                <Typography className={styles.decText}>
                  1. If the Financee defaults or misses a repayment under the relevant Murabaha Agreement, Raqamyah shall
                  take administrative steps to attempt to recover the payment which may include discussions with the Financee
                  and/or restructuring the finance request in order to attempt to recover the outstanding sums due from them,
                  as Raqamyah deems in the best interests of the Financer as a whole. Raqamyah may also recover its
                  reasonable costs and expenses as a priority from any amounts recovered from the Financee in this manner.
                  {<br />}
                  2. In the event a Financee faces a temporary economic issue such as a liquidity issue or a decrease in
                  sales revenue or an unforeseen event, it may default on its repayment obligations under the relevant
                  Murabaha Agreement and the Financer hereby authorises Raqamyah to determine on its behalf whether to agree
                  to a request from the Financee to restructure the finance request by:{<br />}
                  1. reducing repayment amounts by extending the repayment period and increasing the number of repayments; or
                  {<br />}
                  2. suspending or reducing repayment amounts during a given period and then increasing the amount of the
                  monthly instalments.{<br />}
                  3. The Financer agrees and acknowledges that the restructuring of a finance request does not guarantee that
                  the Financee will meet its future repayment obligations and it may still be necessary to appoint a
                  Collection Agent.{<br />}
                </Typography>
                <Typography className={styles.decHeader}>13. APPOINTMENT OF COLLECTION AGENT</Typography>

                <Typography className={styles.decText}>
                  1. In the event Raqamyah are unable to recover the amounts outstanding in accordance with Clause 13 above
                  or through the enforcement of any security pursuant to Clause 12, the Financer hereby authorises Raqamyah
                  to take the necessary administrative and legal steps acting on its behalf and acting in its best interests.
                  The Financer permits Raqamyah to deduct all legal and administrative costs and expenses (including
                  reasonable legal expenses) incurred in taking any such action, from the amounts recovered pursuant to this
                  Clause 14.{<br />}
                  2. The Financer hereby authorises Raqamyah to appoint a third party collection agent (the "Collection
                  Agent") to collect and recover any outstanding sums due under the Murabaha Agreement on the Financer's
                  behalf if it deems necessary in the event of any default on the repayment of any finance request that the
                  Financer has provided. Raqamyah shall keep the Financer duly informed of all actions taken by the
                  Collection Agent including the progress of all efforts and actions taken to collect missed payments and
                  recover outstanding sums, the status of which the Financer will be able to view through the Financer
                  Account.
                  {<br />}
                  3. The Financer agrees that the Collection Agent shall be authorised to take all actions as are reasonable
                  to protect the rights of the Financer, which may include enforcing guarantees, corporate or personal
                  promissory notes and other forms of security as provided by the Financees and taking any other remedy or
                  action as may be available from time to time in accordance with the applicable law.
                  {<br />}
                  4. The Collection Agent may charge a collection fee which shall be payable by the Financee and which shall
                  be deducted from the overall amount which the Collection Agent is seeking to collect and recover from the
                  Financee. For the avoidance of doubt the collection fee shall be deducted as a priority over any
                  distributions to be made to the Financer. The legal and administrative costs and expenses of the Collection
                  Agent shall also be deducted from all amounts claimed from the Financee. The Collection Agent may deduct
                  any amount that represents market practice of the amount claimed and recovered from the Financee, and the
                  remainder of such amount that shall be distributed to the Financer.{<br />}
                  5. In the event that court proceedings are required, Raqamyah shall have the right to request the Financer
                  to provide a power of attorney for litigation purposes (if required) and the Financer hereby undertakes to
                  comply with this request.{<br />}
                  6. The Financer agrees and accepts that:{<br />}
                  1. Raqamyah cannot guarantee that any court proceedings taken on the Financer's behalf shall be successful;
                  {<br />}
                  2. there is a risk that any court proceedings may result in the Financer paying damages;{<br />}
                  3. there may be other creditors of the Financee with claims that may be recovered in priority to the
                  Financer's claim.{<br />}
                  4. only Raqamyah, the Security Agent or the Collection Agent can engage with the Financee and any Guarantor
                  to recover outstanding sums on the Financer's behalf. The Financer is not permitted to undertake any
                  individual action in this regard or to engage with the Financee or the Guarantor unless Raqamyah agrees
                  otherwise in writing.{<br />}
                </Typography>
                <Typography className={styles.decHeader}>14. CESSATION OF BUSINESS BY RAQAMYAH</Typography>
                <Typography className={styles.decText}>
                  1. In the event Raqamyah plans to cease its operations for any reason it shall inform the Financer through
                  the Website and to ensure minimal impact and disruption to the Financer. Raqamyah shall continue its
                  operations for a further three (3) months (which may be extended for another three (3) month period). All
                  available balances from the Financer in the Omnibus Account shall be reimbursed to the bank account
                  registered on the platform. A statement of outstanding payments and a repayment schedule shall be emailed
                  to the Financer within ten (10) business days.{<br />}
                  2. During the period prescribed in Clause ‎15.1, Raqamyah may:
                  {<br />}
                  1. sell the Financer's financed amount portfolio to a bank or a financial institution licensed by SAMA in
                  order to refund all outstanding financed amounts due to the Financer; or{<br />}
                  2. seek early repayment of the outstanding financed amounts and assist the Financee in having the
                  outstanding financed amounts refinanced in the event that the Financee is not able to achieve the early
                  repayment of the financed amount.{<br />}
                  In the event Raqamyah is unable to achieve either of the above, it shall appoint a Collection Agent to
                  recover the outstanding financed amounts. All outstanding financed amounts shall continue to be valid,
                  legally binding and enforceable and shall continue to be collected as they fall due. All payments shall
                  continue to be made to the Collection Agent who shall, after the deduction of the Collection Fee, make
                  regular repayments to the Financer.{<br />}
                  1. Should Raqamyah appoint a Collection Agent pursuant to Clause 15.2, it shall inform the Financer of the
                  situation and of all actions taken to preserve the Financer's rights and to ensure the repayment of the
                  principal and profit amounts due to the Financer under each relevant Murabaha Agreement Contract.{<br />}
                </Typography>
                <Typography className={styles.decHeader}>16. EXCLUSION AND LIABILITY AND INDEMNITY</Typography>
                <Typography className={styles.decText}>
                  1. Raqamyah makes no representations and gives no warranties as to the ability of any Financee to fulfill
                  their repayment obligations in relation to any type of funding method and Raqamyah shall not be liable for
                  the failure of any Financee to fulfill their repayment obligations. The Financer agrees and acknowledges
                  that it is providing funding to any Financee at its own risk.{<br />}
                  2. Where a particular Financee has provided a personal guarantee from an individual shareholder or a
                  director therein, the Financer accepts that the enforceability of such personal guarantee is subject to any
                  qualifications or reservations under applicable law and that Raqamyah is not under any obligation to ensure
                  that the issuer of such personal guarantee is of sound mind and has the capacity to enter into such
                  personal guarantee. Such personal guarantee can be open to challenges and the Financer accepts that the
                  personal guarantor or a third-party may challenge such personal guarantee on the basis of capacity.
                  {<br />}
                  3. The Financer hereby agrees to defend, indemnify and hold harmless Raqamyah, its subsidiaries,
                  affiliates, officers, directors, agents, employees, representatives, successors and assigns from and
                  against any and all actions, claims, suits, demands, judgments, losses, costs, expenses, regulatory fines
                  and damages (including legal fees and expenses), arising out of:
                  {<br />}
                  1. any breach by the Financer of this Agreement, the Terms or any Murabaha Agreement; and/or{<br />}
                  2. any third-party claims arising out of any breach by the Financer of this Agreement, the Terms or any
                  Murabaha Agreement; and/or{<br />}
                  3. any failure to comply with applicable law.{<br />}
                  4. This Clause 16 shall survive the termination or expiry of this Agreement, the Terms and any Murabaha
                  Agreement for any reason whatsoever.{<br />}
                </Typography>
                <Typography className={styles.decHeader}>17. TERM AND TERMINATION</Typography>
                <Typography className={styles.decText}>
                  1. This Agreement shall become effective upon the Financer accepting all the provisions of this Agreement
                  and the Terms and shall remain in force unless terminated by either Raqamyah or the Financer in accordance
                  with this Clause 17.1.{<br />}
                  2. The Financer may terminate this Agreement by notifying Raqamyah at any time if it no longer wishes to be
                  a Financer provided that, at the time of termination it has:{<br />}
                  1. no Murabaha Agreement in force;{<br />}
                  2. no outstanding amounts in its Financer Account; and{<br />}
                  3. no amounts owing and payable to Raqamyah or the Security Agent (as applicable).{<br />}
                  Upon notification, Raqamyah shall promptly close the Financer's Account.{<br />}
                  3. In the event the Financer wishes to close its Financer Account but at the time of termination:{<br />}
                  1. it is a party to a Murabaha Agreement which is in force; or
                  {<br />}
                  2. it has funds which are allocated for financing to Financees; or
                  {<br />}
                  3. it still owe amounts to Raqamyah or the Security Agent,{<br />}
                  then the Financer must either:{<br />}
                  1. wait for the underlying finance requests to be repaid in full; or
                  {<br />}
                  2. pay any owed amounts to Raqamyah or the Security Agent (as applicable).{<br />}
                  Once either the finance requests have been repaid in full or the Financer has paid any amounts owed to
                  Raqamyah or the Security Agent (as applicable), all the funds in the Financer Account shall be fully
                  available for withdrawal and the Financer may transfer such funds to its nominated bank account. Raqamyah
                  shall then close the Financer Account.{<br />}
                  4. Raqamyah may close the Financer Account at any time by giving the Financer fifteen (15) business days'
                  prior notice if:{<br />}
                  1. the Financer breaches any term of this Agreement, the Terms or the Privacy Policy;{<br />}
                  2. the Financer ceases to be an eligible Financer because it ceases to satisfy the eligibility criteria
                  laid out in the Terms;{<br />}
                  3. the Financer does not comply with Raqamyah's reasonable requests for information; 4. Raqamyah suspects
                  that the Financer has been involved in fraud, money laundering or other criminal activities or Raqamyah
                  discovers that any information provided to it is false, misleading or materially incorrect;{<br />}
                  5. the Financer uses the Platform for a purpose for which it was not intended;{<br />}
                  6. the Financer undertakes direct action to contact or engage directly with a Financee to recover
                  outstanding payments; or
                  {<br />}
                  7. the Financer Account is not funded for a period of six (6) months from the date on which the Financer
                  has created a Financer Account.
                  {<br />}
                  5. The Financer hereby authorises Raqamyah, upon receipt of the notice pursuant to Clause 17.4, to:
                  {<br />}
                  1. transfer any outstanding Murabaha Agreement to another financer in order to recover the principal amount
                  that the Financer has financed under such outstanding Murabaha Agreement;
                  {<br />}
                  2. remit any funds remaining to which the Financer is entitled to, to its nominated bank account after
                  deduction of amounts owed to Raqamyah or the Security Agent (as applicable),{<br />}
                  and then close the Financer Account.{<br />}
                  6. Raqamyah may also terminate this Agreement pursuant to Clause 15.
                  {<br />}
                </Typography>
                <Typography className={styles.decHeader}>18. NOTICES</Typography>
                <Typography className={styles.decText}>
                  1. The Platform is an online service and all documents and notices to be sent by Raqamyah to the Financer
                  shall be delivered through the Website or through the email address the Financer has registered with
                  Raqamyah.{<br />}
                  2. All instructions and notices to be sent by the Financer to Raqamyah, (including any notice in accordance
                  with Clause 17.2) shall only be binding provided they are delivered through the Website or are delivered by
                  email to contactus@raqamyah.com.
                  {<br />}
                </Typography>
                <Typography className={styles.decHeader}>19. GOVERNING LAW AND JURISDICTION</Typography>

                <Typography className={styles.decText}>
                  1. This Agreement shall be governed by and construed in accordance with the laws of the Kingdom of Saudi
                  Arabia.{<br />}
                  2. All disputes and/or claims arising out of or in connection with this Agreement shall be referred to and
                  finally resolved by and subject to the exclusive jurisdiction of the competent courts of the Kingdom of
                  Saudi Arabia.{<br />}
                </Typography>
                <Typography className={styles.decHeader}>20. MISCELLANEOUS</Typography>

                <Typography className={styles.decText}>
                  1. Regulatory Oversight: The Platform is subject to a SAMA Fintech Sandbox application and shall operate
                  under the SAMA Fintech Sandbox regulatory environment.{<br />}
                  2. Complaints: In the event the Financer has a complaint, it should promptly write to Raqamyah providing
                  clear details of the complaint. The Financer should send the complaint by e-mail to contactus@raqamyah.com.
                  Raqamyah will endeavour to acknowledge the complaint within five days of receiving it and will endeavour to
                  provide a response to the complaint within fifteen days of receiving it.{<br />}
                  3. Severability: If any provision of this Agreement is found to be illegal, invalid or unenforceable by any
                  court of competent jurisdiction, the validity and enforceability of the remaining provisions shall not be
                  affected.{<br />}
                  4. Remedies: Raqamyah's rights under this Agreement, the Terms and the Privacy Policy are cumulative and
                  not exclusive of any further rights and remedies that are provided under applicable law. Delay in the
                  exercise of any of Raqamyah's rights whether under this Agreement, the Terms, the Privacy Policy, or under
                  applicable law, shall not operate as a waiver of such rights.
                  {<br />}
                  5. Assignment: Raqamyah and the Security Agent (if applicable) may assign or transfer all or part of their
                  respective rights and obligations under this Agreement, the Terms or the security agreement to any
                  third-party and are permitted to exercise any of their rights through a third-party service provider. The
                  Financer is not permitted to assign or transfer any of its rights and obligations under this Agreement, the
                  Terms and the Privacy Policy.
                  {<br />}
                  6. Entire Agreement: The following documents constitute the entire agreement between Raqamyah and the
                  Financer:{<br />}
                  1. this Agreement;{<br />}
                  2. the Terms; and{<br />}
                  3. any amendments or supplements to any of the foregoing from time to time.{<br />}
                  In the event of any conflict between the terms of any of the foregoing documents, they shall have the same
                  order of priority in which they appear above subject to any amendments referred to in paragraph (c) above.
                  {<br />}
                  7. Acceptance by Financer: Before registering, the Financer must read this Agreement and the Terms. If the
                  Financer does not agree or does not wish to comply with the provisions of this Agreement or the Terms, then
                  it should not proceed to register as a Financer.{<br />}
                </Typography>
              </>
            ) : (
              <>
                <Typography className={styles.decHeader}>
                  أبرمت هذه الاتفاقية (المشار إليها فيما يلي بـ "الاتفاقية") بين كل من
                </Typography>
                <Typography className={styles.decText}>
                  (1) المُمَوِل (المشار إليه فيما يلي بـ "المُمَوِل")، و{<br />}
                  (2) منصة رقمية لتقنية المعلومات، شركة ذات مسئولية محدودة، تأسست بموجب أنظمة المملكة العربية السعودية، سجل
                  تجاري رقم 1010449309، وعنوانها مقرها المسجل هو 2908، طريق الأمير محمد بن عبد العزيز، مول سنتريا، الطابق
                  الثالث، مكتب 307، العليا، الرياض 12241-6055، المملكة العربية السعودية (المشار إليها فيما يلي بـ رقمية)
                </Typography>
                <Typography className={styles.decHeader}>نبذة</Typography>
                <Typography className={styles.decText}>
                  (أ‌) تقدم رقمية منصة خدمة تمويل من نظير إلى نظير عبر الإنترنت (المشار إليها فيما يلي بـ "المنصة ") يمكن من
                  خلالها للمشاركين المؤهلين من تمويل المال ("المُمَوِلون") إلى طالبي التمويل المؤهلين لذلك (المشار إليهم فيما
                  يلي بـ "الحاصلون على التمويل ").{<br />}
                  (ب‌) يعتزم المُمَوِل تسجيل نفسه على المنصة كممول بعد قبوله الشروط والأحكام الرقمية لـ المُمَوِلين وفقًا
                  للمنصوص عليه في هذه الاتفاقية، وهي الشروط والأحكام التي تتم قراءتها جنبًا إلى جنب مع الشروط والأحكام
                  العامة، والأسئلة الشائعة وسياسة الخصوصية الخاصة بـ منصة رقمية (المشار إليها مجتمعة بـ "الشروط ").{<br />}
                  (ت‌) تقدم رقمية خدمات معينة لـ المُمَوِل (عند تسجيله على المنصة وقبوله لهذه الاتفاقية والشروط) ويوافق
                  المُمَوِل على التمويل من خلال المنصة إلى طالبي التمويل وفقًا لشروط وأحكام هذه الاتفاقية.
                  {<br />}
                </Typography>

                <Typography className={styles.decHeader}>الشروط المتفق عليها</Typography>
                <Typography className={styles.decHeader}>1. التعريفات والتفسير</Typography>
                <Typography className={styles.decText}>
                  1.1 تحمل المصطلحات التالية المعاني المشار إليها أدناه:{<br />}
                  "التمويل الألي": يحمل المعنى المحدد لهذا المصطلح في البند رقم 7.1 (أ)، و{<br />}
                  "وكيل التحصيل": يحمل المعنى المحدد لهذا المصطلح في البند رقم 14.2، و{<br />}
                  "الحاصلون على التمويل": يحمل المعنى المحدد لهذا المصطلح في البند أ، و{<br />}
                  "المُمَوِلون": يحمل المعنى المحدد لهذا المصطلح في البند أ، و{<br />}
                  "حساب المُمَوِل": يعني الحساب الذي سيفتحه المُمَوِل لدى رقمية عبر الإنترنت (عند تسجيله بنجاح كممول) الذي من
                  خلاله سينفذ المُمَوِل جميع المعاملات على المنصة، و{<br />}
                  "الضامن": يعني الشخص الذي يعمل كضامن لالتزامات الحاصل على التمويل بموجب اتفاقية المرابحة، و{<br />}
                  "المُمَوِل الفردي": يحمل المعنى المحدد لهذا المصطلح في الفقرة (أ) من البند رقم 3.2، و{<br />}
                  "المُمَوِل المؤسساتي": يحمل المعنى المحدد لهذا المصطلح في البند في الفقرة ت من البند 3.2، و{<br />}
                  "خدمات الوسيط": تحمل المعنى المحدد لهذا المصطلح في البند رقم 10.2، و{<br />}
                  "أتعاب الإدارة": تحمل المعنى المحدد لهذا المصطلح في البند رقم 11.1، و{<br />}
                  "التمويل اليدوي": يحمل المعنى المحدد لهذا المصطلح في البند رقم 7.1 (ب)، و{<br />}
                  "التوافق": يشير إلى عملية توافق حاصل على التمويل مع واحد أو أكثر من المُمَوِلين عبر قيام المٌمَوَل بتنفيذ
                  اتفاقية مرابحة، ويتم تفسير المصطلحات "توافُق" أو "يتوافق" أو "متوافق" وفقًا لذلك،
                  {<br />}
                  "اتفاقية المرابحة": تعني اتفاقية التمويل بين الحاصل على التمويل ورقمية (التي تعمل كوكيل نيابة عن المُمَوِل)
                  والتي بموجبها سيتم التمويل لطالب التمويل ، و{<br />}
                  "الحساب التجميعي": يعني حساب بنكي منفصل عن حسابات رقمية مع أي بنك سعودي مرخص من البنك المركزي السعودي والذي
                  سيتم استخدامه لتسهيل تحويل الأموال بين المُمَوِلين وطالبي التمويل والعكس بالعكس حتى يتمكن الأطراف من
                  التعامل من خلال المنصة، و{<br />}
                  "المنصة": تحمل المعنى المحدد لهذا المصطلح في البند أ، و{<br />}
                  "المُمَوِل الفردي المؤهل": يحمل المعنى المحدد لهذا المصطلح في الفقرة ب من البند 3.2،
                  {<br />}
                  "وكيل الضمان": يعني وكيل حفظقد تعينه رقمية للاحتفاظ بأي ضمان يقدمه طالب التمويل، و{<br />}
                  "الخدمات": تحمل المعنى المحدد لهذا المصطلح في البند 10.1، و{<br />}
                  "الشروط": تحمل المعنى المحدد لهذا المصطلح في البند ب، و{<br />}
                  "الموقع الإلكتروني": يعني الموقع الإلكتروني لـ منصة رقمية:
                  <a className={styles.decText} href={websiteUrl} target="_blank">
                    {' '}
                    www.raqamyah.com
                  </a>
                  {<br />}
                  1.2 تم إيراد عناوين ورؤوس المواد في هذه الاتفاقية لسهولة المرجعية فقط ولا تؤثر على تفسير هذه الاتفاقية.
                  {<br />}
                  1.3 يسري العمل بكل جدول من الجداول الواردة كما لو كان قد تم تحديده في هذه الاتفاقية.
                  {<br />}
                  1.4 الإشارات إلى أي تشريع أو حكم تشريعي هي إشارة إلى ذلك التشريع أو الحكم التشريعي، وفقًا لما يرد عليه من
                  تعديلات أو دمج أو استبدال، من آن لآخر (سواء قبل تاريخ هذه الاتفاقية أو بعدها) وتتضمن أي تشريع ثانوي صادر
                  بموجب النظام أو الحكم التشريعي ذي الصلة.
                  {<br />}
                  1.5 الكلمات الواردة بصيغة المفرد تشمل الجمع والعكس بالعكس والكلمات التي تشير إلى أي جنس تشمل كل جنس.
                  {<br />}
                  1.6 ما لم يقتض السياق خلاف ذلك، فإن الإشارات إلى أوقات اليوم، تعني إشارة إلى توقيت المملكة العربية السعودية
                  بينما الإشارات إلى يوم ما هي فترة 24 ساعة تبدأ من منتصف ليل اليوم السابق. 1.7 يتم تفسير الإشارات إلى اليوم
                  والشهر والسنة وأية إشارات أخرى، في الوقت المناسب، بالرجوع إلى التقويم الميلادي.
                  {<br />}
                  1.8 تشمل الإشارات إلى الأشخاص الأفراد والشركات والمؤسسات الفردية والشركات العامة والشركات ذات المسؤولية
                  المحدودة والشركات والهيئات الاعتبارية والشركات والجمعيات غير المسجلة والحكومات والسلطات والوكالات والصناديق
                  الاستثمارية (في كل حالة، سواء كانت تتمتع بشخصية نظامية منفصلة أم لا) أينما وجدت وتشمل الإشارات إلى فرد أو
                  شخص طبيعي إشارة إلى تركاته وممتلكاته وممثليه الشخصيين.
                  {<br />}
                  1.9 أية عبارة يسبقها المصطلح (بما في ذلك، بما يشمل، على وجه الخصوص أو أي تعبير مشابه) يتم تفسيرها على أنها
                  عبارة واردة للإيضاح فقط ولا تحد من معنى الكلمات التي تسبق هذا المصطلح.
                </Typography>
                <Typography className={styles.decHeader}>2. نطاق العقد</Typography>

                <Typography className={styles.decText}>
                  تشكل هذه الاتفاقية مع ما ورد من الشروط والأحكام كامل الشروط والأحكام التي يوافق بموجبها المُمَوِل على
                  المشاركة في التمويل من نظير إلى نظير من خلال المنصة.
                </Typography>
                <Typography className={styles.decHeader}>3. تسجيل المُمَوِل</Typography>

                <Typography className={styles.decText}>
                  3.1 يقوم المُمَوِل بإنشاء حساب وتسجيل نفسه على المنصة. يقر المُمَوِل ويوافق على أنه سيتم ضمه كممول خاضع
                  لاستيفاء جميع متطلبات الأهلية والجدارة المحددة بموجب الشروط مع تزويد رقمية بكافة المعلومات والوثائق ذات
                  الصلة، عند الطلب.
                  {<br />}
                  3.2 لغرض التسجيل، يتم تصنيف المُمَوِل على أنه:
                  {<br />}
                  أ‌) مُمَوِل فردي يلزمه:
                  {<br />}
                  1) أن يكون شخصًا طبيعيًا، و{<br />}
                  2) أن يكون عمره واحدًا وعشرون (21) عامًا أو أكثر، و{<br />}
                  3) تقديم تفاصيل بطاقة الهوية الوطنية الخاصة به (كمواطن سعودي) أو الإقامة (تصريح الإقامة، كمواطن أجنبي)، و
                  {<br />}
                  4) تقديم تفاصيل عنوانه الوطني ("المُمَوِل الفردي").
                  {<br />}
                  يوافق المُمَوِل الفردي بموجبه على أن تحصل رقمية على خدمة يقين للتحقق من بطاقة الهوية الوطنية أو الإقامة
                  (تصريح الإقامة)، أو
                  {<br />}
                  ب‌) مُمَوِل فردي مؤهل يلزمه:
                  {<br />}
                  1) أن يكون شخصًا طبيعيًا، و{<br />}
                  2) أن يكون عمره واحدًا وعشرون (21) عامًا أو أكثر، و{<br />}
                  3) تقديم تفاصيل بطاقة الهوية الوطنية الخاصة به (كمواطن سعودي) أو الإقامة (تصريح الإقامة، كمواطن أجنبي)،
                  {<br />}
                  4) استيفاء واحد على الأقل من المعايير التالية (من خلال توقيع إقرار على أحد مواقع الإنترنت التي تنص على ذلك)
                  ("المُمَوِل الفردي المؤهل"):
                  {<br />}
                  (أ‌) حيازة صافي أصول لا تقل قيمتها عن ثلاثة (3) ملايين ريال سعودي، و{<br />}
                  (ب‌) العمل حاليًا أو سابقًا لمدة ثلاث (3) سنوات على الأقل في القطاع المالي في وظيفة تتعلق بالتمويل أو
                  الاستثمار ، و{<br />}
                  (ت‌) الحصول على شهادة مهنية تتعلق بالتمويل أو الاستثمار ومعتمدة من مؤسسة معترف بها دوليًا، أو
                  {<br />}
                  (ث‌) لا يقل دخله السنوي عن ستمائة ألف (600.000) ريال سعودي في آخر سنتين.
                  {<br />}
                  يوافق المُمَوِل الفردي المؤهل بموجبه على أن تصل رقمية إلى خدمة يقين للتحقق من بطاقة الهوية الوطنية أو
                  الإقامة (تصريح الإقامة)،
                  {<br />}
                  أو
                  {<br />}
                  ت‌) مُمَوِل مؤسساتي يلزمه:
                  {<br />}
                  1) أن يكون كيانًا مرخصًا من قبل البنك المركزي السعودي. أو
                  {<br />}
                  2) أن يكون كيانًا أو صندوق استثمار مرخصًا من قبل هيئة السوق المالية. أو
                  {<br />}
                  3) أن يكون كيانًا نظاميًا مؤسس بموجب أنظمة المملكة العربية السعودية، أو
                  {<br />}
                  4) مكتب أو كيان حكومي أو شبه حكومي في المملكة العربية السعودية ("المُمَوِل المؤسسي").
                  {<br />}
                  على المُمَوِل المؤسساتي تقديم ما يلي:
                  {<br />}
                  (أ‌) شهادة السجل التجاري الحالية والصالحة الصادرة عن وزارة التجارة (إن وجدت)، أو
                  {<br />}
                  (ب‌) صورة من التوكيل الرسمي أو خطاب التفويض بتعيين ممثله المعتمد ما لم:
                  {<br />}
                  (ت‌) يكن قد تم تعيين هذا الممثل المعتمد على وجه التحديد بموجب قرار من مجلس الإدارة أو المساهمين الذي يسمح
                  لـ المُمَوِل المؤسساتي بتنفيذ أنشطة المنصة الضرورية، أو
                  {<br />}
                  (ث‌) يتمتع هذا الممثل المعتمد بالسلطة الحالية بموجب النظام الأساسي لـ المُمَوِل المؤسساتي لتنفيذ أنشطة
                  المنصة الضرورية.
                  {<br />}
                  يوافق المُمَوِل المؤسساتي بموجبه على دخول رقمية إلى خدمة "ثقة" للتحقق من شهادة السجل التجاري الخاصة به (إن
                  كان ذلك مطبقًا).
                  {<br />}
                  3.3 لا تقوم رقمية بإجراء أية استفسارات مستقلة للتحقق من أن أية سلطة مؤسسية أو تفويض أو ترخيص أو تسجيل مؤسسي
                  ومعلومات ووثائق مقدمة بموجب الفقرة 3.2 صحيحة وحقيقية ومحدثة وموجودة بحيث يمكن التحقق منها بصفة دائمة. يلتزم
                  المُمَوِل بتقديم أية تغييرات على المعلومات والوثائق المقدمة إلى رقمية على الفور.
                  {<br />}
                  3.4 يتعين على رقمية تنفيذ إجراءات وفحوصات "اعرف عميلك" حسب متطلباتها الداخلية ووفقًا للأنظمة المعمول بها في
                  المملكة العربية السعودية. يجوز لـ رقمية أن تطلب من المُمَوِل تقديم وثائق إضافية لاستكمال "اعرف عميلك" وأي
                  فشل في تقديم مثل هذه الوثائق سيؤدي إلى رفض طلب المُمَوِل المحتمل، مع كون هذا القرار نهائيًا وملزمًا.
                  {<br />}
                  3.5 معايير الأهلية المدرجة في البند 3.2 غير شاملة وتحتفظ رقمية بالحق في تعديل معايير الأهلية في أي وقت على
                  موقعها الإلكتروني، دون أي إخطار لـ المُمَوِل. كما تحتفظ رقمية بالحق في قبول أو رفض أي طلب ممول وفقًا
                  لتقديرها الخاص، حتى لو استوفى المُمَوِل معايير الأهلية. جميع القرارات التي تتخذها الرقمية نهائية وملزمة
                  وغير قابلة للاستئناف.
                  {<br />}
                  3.6 يجب على المُمَوِل تزويد رقمية بجميع المعلومات والمستندات التي قد تطلبها رقمية بشكل معقول من وقت لآخر
                  وفيما يتعلق بالوضع المالي لـ المُمَوِل أو أعماله، من أجل تمكين رقمية من الوفاء بالتزاماتها النظامية ولضمان
                  التشغيل السليم للمنصة.
                  {<br />}
                  3.7 يحق لـ رقمية، وفقًا لتقديرها الخاص، التنازل عن شرطها بشأن إلزام المُمَوِل المؤسساتي بتقديم أي مستند أو
                  أية وثيقة، دون المساس بحقها في طلب هذه المستندات أو الوثائق الإضافية في أي وقت.
                </Typography>
                <Typography className={styles.decHeader}>4. إنشاء حساب ممول</Typography>
                <Typography className={styles.decText}>
                  4.1 عند قيام المُمَوِل بتوفير الوثائق ذات الصلة المطابقة لمعايير الأهلية الخاصة به والوفاء بشيكات "اعرف
                  عميلك" وإجراءات مكافحة غسل الأموال وتمويل الإرهاب ، يتعين على رقمية في غضون ثلاثة (3) أيام إخطار المُمَوِل
                  عن طريق البريد الإلكتروني أو إرسال رسالة نصية قصيرة إذا كان تطبيقه ليصبح ممولًا ناجحًا. في حالة نجاح الطلب،
                  يجب على رقمية تزويد المُمَوِل برقم آيبان مميز لحساب التمويل الخاص به. يجب على المُمَوِل استخدام رقم آيبان
                  هذا لجميع المعاملات التي يقوم بها على المنصة.
                  {<br />}
                  4.2 يحظر على المُمَوِل استخدام حساب المُمَوِل الخاص به لإجراء أي تحويل إلى أطراف ثالثة أو قبول أي تحويل من
                  أطراف ثالثة دون موافقة خطية مسبقة من رقمية. يجب على المُمَوِل إخطار رقمية على الفور إذا اشتبه في أن طرفًا
                  ثالثًا قد وصل إلى حساب المُمَوِل أو إذا اشتبه في حدوث خرق أمني.
                  {<br />}
                  4.3 تحتفظ رقمية بالحق في عدم الامتثال لتعليمات المُمَوِل وتعليق حساب المُمَوِل إذا اشتبهت في وجود خرق أمني
                  أو أي نشاط غير نظامي أو احتيالي فيما يتعلق بحساب المُمَوِل أو الاستخدام غير المصرح به لحساب المُمَوِل.
                  {<br />}
                  4.4 تحتفظ رقمية بالحق في تعليق حساب المُمَوِل إذا لم يقم المُمَوِل بتحديث أي معلومات مقدمة إلى رقمية فيما
                  يتعلق بإنشاء حساب المُمَوِل. في حالة طلب رقمية للحصول على معلومات محدثة، وإذا لم يرد المُمَوِل على هذه
                  الاستفسارات، فسيتم تعليق حساب المُمَوِل. خلال فترة التعليق، يكون المُمَوِل قادرًا فقط على طلب عمليات السحب
                  ولن يُسمح له بالقيام بأي أنشطة تمويلية. سيتم تفعيل حساب المُمَوِل بمجرد تقديم الوثائق المطلوبة إلى رقمية.
                  {<br />}
                  4.5 يجب على المُمَوِل استخدام حساب المُمَوِل لإيداع الأموال في الحساب التجميعي عن طريق التحويل البنكي. لن
                  يُسمح بالإيداعات النقدية في حساب تجميعي.
                  {<br />}
                  4.6 عند استلام رقمية للأموال في الحساب التجميعي، يجوز لـ المُمَوِل المشاركة في التمويل. قد يتم خصم أي رسوم
                  مطبقة فيما يتعلق بالتمويل من وقت لآخر من رصيد الأموال المحتفظ بها نيابة عن المُمَوِل. يجب الاحتفاظ بهذه
                  الأموال نيابة عن المُمَوِل حتى يتم سحبها من قبل المُمَوِل، أو تطبيقها على أي رسوم مطبقة أو تحويلها إلى طالب
                  التمويل.
                  {<br />}
                  4.7 عند تخصيص أي من أموال المُمَوِل لطلب تمويل محدد، يجب على المُمَوِل الانتظار إلى أن يتم سداد طلب التمويل
                  بالكامل من قبل طالب التمويل حيث لا تقدم رقمية خيار سوق ثانوي.
                </Typography>

                <Typography className={styles.decHeader}>5. إقرارات وضمانات المُمَوِل</Typography>

                <Typography className={styles.decText}>
                  5.1 يقر المُمَوِل بما يلي ويضمنه:
                  {<br />}
                  (أ‌) أنه قد قرأ هذه الاتفاقية والشروط المصاحبة لها وفهمها ووافقا على الالتزام بها، حيث يمكن تعديلها من وقت
                  لآخر. يحق لـ منصة رقمية تعديل هذه الاتفاقية والشروط في أي وقت، (سواء لتعكس أي تغييرات في الأنظمة والقوانين
                  واللوائح أو لتغيير خدماتها الحالية بسبب التغييرات في التقنية والنظم) دون أي إخطار أو موافقة من المُمَوِلين.
                  من خلال الاستمرار في الوصول إلى المنصة واستخدامها بعد أي تعديلات على هذه الاتفاقية أو الشروط، يقبل
                  المُمَوِل التعديلات ويوافق على الالتزام بها، و{<br />}
                  (ب‌) إذا كان لا يوافق في أي وقت على أية تعديلات أجرتها رقمية على هذه الاتفاقية أو الشروط، يجوز له إغلاق
                  حساب المُمَوِل الخاص بها عن طريق الاتصال بـ رقمية عبر البريد الإلكتروني التالي: contactus@raqamyah.com أو
                  عبر الهاتف على رقم: 920004032+، و{<br />}
                  (ت‌) أن جميع المعلومات التي قدمها إلى رقمية فيما يتعلق بطلبه ليصبح ممولًا هي معلومات دقيقة وصحيحة وحديثة، و
                  {<br />}
                  (ث‌) بصفته ممولًا مؤسسيًا، يكون الشخص الذي يتصرف نيابة عنها مخولًا حسب الأصول للتصرف ويظل مخولًا بالتصرف
                  نيابة عنها. علاوة على ذلك، يجب أن يتمتع هذا الممثل المعتمد بالسلطة اللازمة للدخول في هذه الاتفاقية نيابة عن
                  المُمَوِل المؤسساتي ويمتلك المُمَوِل المؤسساتي سلطة الدخول في هذه الاتفاقية. لن تكون رقمية مسؤولة بأي شكل
                  من الأشكال نتيجة تصرفات أي ممثل معتمد ليس لديه السلطة الواجبة للدخول في هذه الاتفاقية نيابة عن ممول مؤسسي،
                  و{<br />}
                  (ج‌) كممول مؤسساتي، فإن لديه مكان عمل دائم.
                  {<br />}
                  5.2 كذلك، يقر المُمَوِل ويضمن أنه طالما يحتفظ بحساب ممول لدى رقمية:
                  {<br />}
                  (أ‌) إنه مستمر في تلبية معايير الأهلية لـ المُمَوِل المنصوص عليها في هذه الاتفاقية،
                  {<br />}
                  (ب‌) إن جميع المعلومات والوثائق التي قدمها دقيقة وصحيحة وحديثة وغير مضللة،
                  {<br />}
                  (ت‌) إنه أفصح وسيواصل الإفصاح عن جميع الظروف والعوامل التي قد تغير حالته المالية بشكل جوهري أو سلبي، مثل
                  المطالبات النظامية أو أية التزامات أو دعاوى أو تحقيقات والتي تكون مهددة أو معلقة أو قائمة ضده،
                  {<br />}
                  (ث‌) أن يحتفظ برقم آيبان المميز الخاص به آمنًا وسريًا،
                  {<br />}
                  (ج‌) فلن يتمكن أي شخص غير مصرح له من الوصول إلى حساب المُمَوِل ولا يُسمح إلا للأشخاص المصرح لهم بالوصول إلى
                  حساب المُمَوِل. في حالة تغيير هؤلاء الأشخاص المصرح لهم، يضمن المُمَوِل أن هؤلاء الأشخاص مفوضون حسب الأصول،
                  و{<br />}
                  (ح‌) أن يظل مصرحًا له بإيداع الأموال في حساب الأيبان الذي تم تزويده به
                </Typography>
                <Typography className={styles.decHeader}>6. إقرار بالمخاطر</Typography>

                <Typography className={styles.decText}>
                  6.1 يقر المُمَوِل ويؤكد ما يلي:
                  {<br />}
                  (أ‌) أنه قد قرأ وراعى بكل عناية المخاطر التي تنطوي عليها التمويل من خلال المنصة وأنه قد أعرب عن أن لديه
                  الرغبة المناسبة في المخاطرة لإجراء المعاملات الواردة في هذه الاتفاقية.
                  {<br />}
                  (ب‌) من خلال قبول أحكام هذه الاتفاقية والشروط، فإنه شارك في التمويل من نظير إلى نظير ولن يتم اعتبار
                  المُمَوِل في أي وقت من الأوقات على أنه قام بالتمويل لرقمية.
                  {<br />}
                  (ت‌) يتفهم المخاطر المرتبطة بالتمويل من نظير إلى نظير وأنه قد يخسر كل أمواله أو جزء منها إذا تخلف طالب
                  التمويل الذي تم التوافق بينه وبين المُمَوِل عن سداد أقساط السداد ولا تتحمل رقمية أية مسؤولية عن أية خسارة
                  من هذا القبيل.
                  {<br />}
                  (ث‌) أنه اتخذ قراره المستقل بأن يصبح ممولًا على المنصة وأن يكون متوافقًا مع الحاصلين على التمويل ولم يعتمد
                  على أي بيان أو وعد أو إقرار أو ضمان أو تعهد تم تقديمه من قبل رقمية أو بالنيابة عنرقمية مما هو غير منصوص
                  عليه في هذه الاتفاقية والشروط.
                  {<br />}
                  (ج‌) لن يتم سداد أية مدفوعات له من أمواله حتى يتم سداد طلب التمويل بالكامل من قبل طالب التمويل المعني.
                  تتأثر سيولة المُمَوِل خلال مدة اتفاقية المرابحة ذات الصلة ما لم يكن هناك سداد مبكر أو أو تعثر من قبل طالبي
                  التمويل.
                  {<br />}
                  (ح‌) لا يُقصد من توفير رقمية للخدمات وخدمات الوسيط (كل على النحو المحدد أدناه) أن يشكل أو يتم تفسيره على
                  أنه نصيحة أو توصيات أو ضمان من قبل رقمية فيما يتعلق بالجدارة الائتمانية لأي طالب تمويل، ومقدار المدفوعات
                  التي قد يحصل عليها المُمَوِل، أو احتمال حدوث حالات تعثر في السداد، فيما يتعلق بأية اتفاقية مرابحة.
                  {<br />}
                  (خ‌) رقمية ليست مرخصة أو مخولة لتقديم الاستشارات المالية. لا تشكل أية معلومات واردة في المنصة أو على الموقع
                  الإلكتروني أو يجب تفسيرها على أنها تشكل نصيحة تمويلية أو نصيحة استثمارية أو توصية لأي ممول لإقراض المال.
                </Typography>

                <Typography className={styles.decHeader}>7. عملية التمويل</Typography>
                <Typography className={styles.decText}>
                  7.1 يمكن لـ المُمَوِل استخدام المنصة لتقديم التمويل لـ طالبي التمويل بالطريقتين التاليتين:
                  {<br />}
                  (أ‌) التمويل الآلي: يستخدم هذا النوع من التمويل أداة تسمى التمويل الآلي للاختيار الآلي طالبي التمويل الذي
                  يرغب المُمَوِلين في تمويله بناءً على معايير محددة مسبقًا. يمكن لـ المُمَوِلين اختيار مزيجهم المخصص بناءً
                  على ما يلي:
                  {<br />}
                  (1) الحد الأقصى للمبلغ لكل طلب تمويل، و{<br />}
                  (2) القطاع (القطاعات) التي يرغب المُمَوِل في تمويلها، و{<br />}
                  (3) درجات المخاطر التي يريد المُمَوِل تمويلها.
                  {<br />}
                  يقر المُمَوِل الذي يختار استخدام وظيفة الأداة الآلية ويوافق على تخصيص أمواله تلقائيًا لطلبات التمويل
                  المختلفة من طالبي تمويل مختلفين بناءً على المعايير المحددة مسبقًا التي وضعها المُمَوِل. إذا كان الممول
                  يستخدم أداة التمويل الآلي ويضع معاييرها بحيث تتوافق تلقائيًا مع طالبي التمويل ، فيمكنه التوقف عن استخدام
                  الأداة الآلية في أي وقت عن طريق إيقاف تشغيلها.
                  {<br />}
                  (ب) التمويل اليدوي: هي العملية التي يقوم بموجبها الممول بتحديد طالبي التمويل يدويًا الذين قد يرغب المُمَوِل
                  في تمويلها ومن ثم تقديم عطاءات لتمويل تلك الطلبات يدويا عبر منصة رقمية حيث تسمى هذه العملية بالتمويل
                  اليدوي. يمكن لـ المُمَوِلين تصفح طلبات التمويل المتاحة ومقارنة تفاصيل طلبات التمويل مثل التصنيف، والغرض من
                  طلب التمويل، ونوع طلب التمويل، والمدة، وهامش الربح ومعلومات طالب التمويل. عملية التمويل لكل طلب تمويل
                  مفتوحة لمدة تصل إلى ثلاثين (30) يومًا، وستنتهي فترة التمويل بمجرد اكتمال المبغ المطلوب. يتم تنفيذ الطلبات
                  على أساس الأول قدومًا هو الأول تنفيذًا.
                  {<br />}
                  7.2 وفقًا لقبوله الشروط جنبًا إلى جنب مع الموافقة على هذه الاتفاقية، يحق لـ المُمَوِل تحديد ما إذا كان
                  سيختار تقديم التمويل من خلال التمويل التلقائي أو التمويل اليدوي. إذا استخدم المُمَوِل أداة التمويل الآلي
                  وحدد معاييرها بحيث تتوافق تلقائيًا مع طلبات التمويل القائمة، فيمكنه التوقف عن استخدام التمويل الآلي في أي
                  وقت عن طريق إيقاف تشغيلها.
                  {<br />}
                  7.3 سيتمكن المُمَوِلون من مراقبة ما يلي عند استخدام المنصة:
                  {<br />}
                  (أ‌) مبالغ التمويل، و{<br />}
                  (ب‌) الأرباح التي تحصل عليها، و{<br />}
                  (ت‌) الأرباح المستحقة، و{<br />}
                  (ث‌) مبالغ التمويل المقبوضة، و{<br />}
                  (ج‌) هامش المرابحة السنوي، و{<br />}
                  (ح‌) طلبات التمويل الممولة، و{<br />}
                  (خ‌) سجل العمليات اللمنفذة من خلال المنصة، و{<br />}
                  (د‌) حالة مبالغ التمويل، و{<br />}
                  (ذ‌) جدول السداد، و{<br />}
                  (ر‌) الملف التفصيلي لكل طالب تمويل، و (ز‌) مكونات المحفظة التمويلية.
                  {<br />}
                  7.4 إن قرار ما إذا كان سيقدم المُمَوِل تمويلا من عدمه لطالبي التمويل على المنصة هو قرار منوط بالمُمَوِل
                  وحده، وفقًا لما يراه من وجهة نظره وحسب تقديره الخاص. رقمية غير مصرح لها بمنح أو تقديم أية استشارة تمويلية.
                  لا تشكل أي معلومات واردة في المنصة أو على الموقع الإلكتروني أو يجب تفسيرها على أنها تشكل نصيحة مالية أو
                  نصيحة استثمارية أو توصية لتمويل الأموال.
                  {<br />}
                  7.5 يؤكد المُمَوِل أنه سعي للحصول على استشارة مالية أو نظامية أو زكوية أو ضريبية أو غيرها من الاستشارات
                  المهنية المستقلة قبل التمويل من خلال المنصة.
                  {<br />}
                  7.6 بمجرد قيام المُمَوِل بتقديم عرض تمويل ، سواء عن طريق التمويل الآلي أو التمويل اليدوي ، يكون أمامه يومان
                  لسحب عرض التمويل الخاص به بشرط أن تكون مازالت مدة التمويل مفتوحة ولم يتم اكتمالها. إن جميع عروض التمويل ،
                  سواء عن طريق التمويل الآلي أو التمويل اليدوي ، ملزمة للممول متى تم إغلاق فترة التمويل لتمويل معين ولا يجوز
                  للممول الرجوع عن هذا العرض لأي سبب من الأسباب.
                  {<br />}
                  7.7 في حالة عدم تغطية مبلغ التمويل المطلوب من قبل أي طالب للتمويل خلال فترة 30 يوم، تحتفظ رقمية بالحق في
                  تمديد فترة المزايدة لمدة ثلاثين (30) يومًا إضافية. إذا لم يتم تغطية مبلغ التمويل المطلوب بالكامل بعد الفترة
                  الإضافية من قبل جميع العروض الواردة من المُمَوِلين ، فيجب على "رقميه" إغلاق طلب التمويل وإعادة الأموال التي
                  قدمها المُمَوِلين فيما يتعلق بطلب التمويل هذا.
                  {<br />}
                  7.8 عند السداد من قبل طالب التمويل وايداع المستحقات في الحساب التجميعي، ستقوم "رقمية" بإيداع المبلغ المسدد
                  في حسابات الممولين في المنصة.
                  {<br />}
                </Typography>

                <Typography className={styles.decHeader}>8. حدود التمويل</Typography>

                <Typography className={styles.decText}>
                  اعتمادًا على تصنيف المُمَوِل وفقًا لمعايير الجدارة والأهلية المنصوص عليها في الشروط، سيقتصر المُمَوِل على
                  تحويل المبالغ التالية في أي سنة تقويمية واحدة:
                  {<br />}
                  (أ‌) المُمَوِل الفردي - الحد الأقصى للمبلغ الذي يمكن تحويله إلى رقمية سنويا هو مائتين ألف (200,000) ريال
                  سعودي والحد الأقصى للتعرض لطلب تمويل واحد هو خمسون ألف (50,000) ريال سعودي بما لايتجاوز 25% من قيمة التمويل
                  المطلوب
                  {<br />}
                  (ب‌) ممول فردي مؤهل - لايوجد قيود.
                  {<br />}
                  (ت‌) ممول المؤسساتي – لايوجد قيود
                </Typography>
                <Typography className={styles.decHeader}>9. الحساب التجميعي</Typography>
                <Typography className={styles.decText}>
                  9.1 يوافق المُمَوِل ويقر بأن جميع المبالغ المقدمة من قبله سيتم الاحتفاظ بها في الحساب التجميعي.
                  {<br />}
                  9.2 كذلك، يقر المُمَوِل ويوافق على أن رقمية ستحتفظ بجميع المبالغ المقدمة من جميع المُمَوِلين في نفس الحساب
                  التجميعي وأن جميع أموال المُمَوِلين ستكون مختلطة.
                  {<br />}
                  9.3 تتصرف رقمية كوكيل فيما يتعلق بهذا الحساب التجميعي وبصفتها وكيلًا، فإنها تتعهد وتضمن ما يلي:
                  {<br />}
                  (أ‌) ستختلط المبالغ المستلمة من المُمَوِل بالمبالغ الأخرى المستلمة من ممولين آخرين ولكن لن تختلط مع أموال
                  وأصول رقمية، و{<br />}
                  (ب‌) في حالة إفلاس رقمية أو حلها أو أي حالة إفلاس أخرى، ستكون المبالغ المستلمة من المُمَوِلين منفصلة وبمعزل
                  عن الأموال والأصول العائدة لـ رقمية، وتخضع أموال وأصول رقمية فقط لقواعد التوزيع الواردة في قواعد الإفلاس.
                  وأنظمة المملكة العربية السعودية.
                  {<br />}
                  (ت‌) لا يراكم الحساب التجميعي أية فائدة أو أي ربح يتم دفعه لـ المُمَوِل.
                  {<br />}
                  9.4 يُسمح بسحب الأموال من الحساب التجميعي شريطة أن يتم سداد جميع الرسوم المتعلقة بتمويل معين بالكامل. تمتلك
                  رقمية سلطة تعليق أو وضع قيود على الحساب التجميعي من وقت لآخر إذا كان ذلك ضروريًا للامتثال لأية متطلبات
                  نظامية أو تنظيمية
                </Typography>
                <Typography className={styles.decHeader}>10. الخدمات</Typography>

                <Typography className={styles.decText}>
                  10.1تقدم رقمية الخدمات التالية ("الخدمات") لـ المُمَوِل:
                  {<br />}
                  (أ‌) تشغيل وإدارة المنصة والموقع الإلكتروني، و{<br />}
                  (ب‌) التوفيق بين المُمَوِلين مع طالبي التمويل، و{<br />}
                  (ت‌) تسهيل معاملات تمويل المرابحة بين المُمَوِلين وطالبي التمويل، و{<br />}
                  (ث‌) أداء خدمات الوسيط (على النحو المحدد أدناه) كوكيل تمويل فيما يتعلق بمعاملات المرابحة.
                  {<br />}
                  10.2توفر رقمية أو ترتب لتوفير خدمات الوسيط التالية (المشار إليها بـ "خدمات الوسيط"):
                  {<br />}
                  (أ‌) تقييم الجدارة الائتمانية لـ طالبي التمويل، و{<br />}
                  (ب‌) إعداد اتفاقيات المرابحة مع وطالبي التمويل والعمل كوكيل تمويل فيما يتعلق بها، و{<br />}
                  (ت‌) تزويد طالبي التمويل بما يلزم من عقود ومعلومات المرحلة السابقة للتعاقد ومرحلة التعاقد ومرحلة ما بعد
                  التعاقد، و{<br />}
                  (ث‌) تحصيل وتوزيع المدفوعات المستحقة للمولين والتي تتلقاها رقمية من طالبي التمويل (والضامنين إن وجدوا) من
                  خلال استخدام الحساب التجميعي، و{<br />}
                  (ج‌) إبرام اتفاقيات مع البنوك السعودية المرخصة من البنك المركزي لفتح وتشغيل الحسابات التجميعية. .{<br />}
                  يفوض المُمَوِل ، بموجبه، رقمية للعمل كوكيل له فيما يتعلق بخدمات الوسيط.
                  {<br />}
                  10.3لا يُقصد من تقديم الخدمات من قبل رقمية (لا سيما خدمات الوسيط (بما في ذلك التقييم الائتماني للحاصلين على
                  التمويل) أن يمثل أو يفسر على أنه يمثل نصيحة أو توصيات أو ضمان من قبل رقمية فيما يتعلق بما يلي:
                  {<br />}
                  (أ‌) الجدارة الائتمانية لأي طالب تمويل، أو
                  {<br />}
                  (ب‌) قيمة المدفوعات التي قد يتلقاها المُمَوِل، أو
                  {<br />}
                  (ت‌) احتمال حدوث حالات تخلف عن السداد فيما يتعلق بأي اتفاقية مرابحة.
                  {<br />}
                </Typography>
                <Typography className={styles.decHeader}>11. الرسوم</Typography>

                <Typography className={styles.decText}>
                  11.1لتقديم خدمات الوسيط، تفرض رقمية رسومًا إدارية يتم احتسابها بنسبة اثنين بالمائة (2٪) سنويًا على المبالغ
                  الممولة غير المسددة ("رسوم الإدارة"). لتجنب الشك، لن تتقاضى رقمية أية رسوم لإنشاء حساب ممول.
                  {<br />}
                  11.2في حالة تكبد رقمية أية مصروفات أو نفقات أخرى معقولة في سبيل تقديم خدمات الوسيط، عليها إخطار المُمَوِل
                  كتابيًا الذي، بدوره، سيدفع لـ رقمية هذه المصروفات والنفقات. لتسهيل هذا السداد، يسمح المُمَوِل بموجبه صراحةً
                  لـ "رقمية" بالوصول إلى هذا المبلغ وسحبه من حساب المُمَوِل، أو استرداد تلك المصروفات والنفقات بطريقة أخرى من
                  أية مدفوعات مستحقة لـ المُمَوِل أو طلب السداد مباشرة من المُمَوِل فيما يتعلق بهذه النفقات.
                  {<br />}
                  11.3تحتفظ رقمية بكافة حقوقها في تغيير أو التنازل عن أية رسوم إدارة سارية من وقت لآخر، وفقًا لتقديرها الخاص.
                  {<br />}
                </Typography>
                <Typography className={styles.decHeader}>12. تقديم خدمات الحفظ وتعيين وكيل ضمان</Typography>

                <Typography className={styles.decText}>
                  12.1إذا أنشأ طالب التمويل أو الكفيل أي شكل من أشكال الضمان تكون إلتزاماتهم تجاه المُمَوِل بموجب عقد تمويل
                  المرابحة والكفالة مضمونة ومكفولة بموجب ذلك الضمان.
                  {<br />}
                  12.2تحتفظ رقمية بالحق في إبرام اتفاقية مع وكيل الضمان للاحتفاظ بأي ضمان نيابة عن المُمَوِل فيما يتعلق بأية
                  اتفاقية مرابحة وضمان. تفوض رقمية وكيل الضمان ​​بممارسة الحقوق والصلاحيات والسلطات والتقديرات الممنوحة لوكيل
                  الضمان، على وجه التحديد، ​​بموجب أية اتفاقية ضمان منفصلة. سلطات وكيل الضمان إدارية بحتة وتحكمها اتفاقية
                  الضمان وهذه الاتفاقية والأنظمة المعمول بها. إذا كان هناك أي تعارض بين اتفاقية الضمان وهذه الاتفاقية، تسود
                  هذه الاتفاقية.
                  {<br />}
                  12.3يحق لوكيل الضمان الاعتماد على أي تعهد أو إشعار أو مستند يعتقد أنه مصرح به وحقيقي وصحيح ويمكنه الاعتماد
                  على أي بيان صادر عن مدير أو شخص مخول أو موظف لدى أي شخص فيما يتعلق بأي أمور يمكن افتراضها بشكل معقول أن
                  تكون في حدود معرفتهم أو قدرتهم على التحقق. يجب أن يكون لوكيل الضمان السلطة التقديرية لممارسة جميع حقوقه
                  وصلاحياته ولن يتحمل أية مسؤولية باستثناء أي إجراء ناتج عن إهماله أو سوء سلوكه.
                  {<br />}
                  12.4يوافق المُمَوِل على ما يلي ويقبله ويقر به:
                  {<br />}
                  (أ‌) لا يحق له اتخاذ أي إجراء لتنفيذ ـو تحصيل أي ضمان ضد طالب التمويل أو الضامن مباشرة ويفوض رقمية، بموجبه،
                  في اتخاذ جميع الإجراءات اللازمة فيما يتعلق بأي إنفاذ من خلال وكيل الضمان.
                  {<br />}
                  (ب‌) إن وجود الضمان لا يعني أن طالب التمويل أو الضامن يمكنه أن يفي بالكامل بالتزامات السداد بموجب اتفاقية
                  مرابحة أو ضمان ولا يشكل ضمانًا للسداد الكامل من قبل طالب التمويل أو الضامن.
                  {<br />}
                  (ت‌) تخضع قابلية إنفاذ أية ورقة مالية للمخاطر وقد لا تكون القيود العادية المفروضة بموجب النظام المعمول به
                  وأن العائدات المستلمة بعد التنفيذ قد لا تكون كافية للوفاء بجميع الالتزامات المستحقة على طالب التمويل وأي
                  ضامن لـ المُمَوِل. قد يكون هناك دائنون آخرون لـ طالب التمويل والضامن الذين لديهم مطالبات يمكن استردادها في
                  الأولوية على مطالبات وكيل الضمان الذي يتصرف نيابةً عن المُمَوِل ..
                  {<br />}
                  (ث‌) لن يكون وكيل الضمان مسؤولاً عن أية خسارة يتكبدها المُمَوِل ما لم تكن الخسارة ناجمة عن إهمال وكيل
                  الضمان أو سوء سلوكه.
                  {<br />}
                  12.5إذا فشل طالب التمويل أو الضامن في الوفاء بأي التزام بالسداد عند استحقاقه أو في حالة حدوث حالة تقصير
                  بموجب اتفاقية المرابحة، يحق لوكيل الضمان إنفاذ الضمان. يتم إرسال إشعار التخلف عن السداد إلى طالب التمويل
                  والضامن مع طلب سداد المبالغ المستحقة وأي رسوم وتكاليف مفروضة.
                  {<br />}
                  12.6إذا أصبح الضمان قابلاً للتنفيذ، يجب على وكيل الضمان إنفاذ الضمان وفقًا لاتفاقية الضمان لصالح المُمَوِل
                  ويحق له إجراء التوزيعات وفقًا لاتفاقية الضمان وهذه الاتفاقية والشروط.
                  {<br />}
                  12.7بعد إنفاذ الضمان، يجب توزيع واستخدام أي مبالغ يتلقاها أو يستردها وكيل الضمان حسب ترتيب الأولوية التالي:
                  {<br />}
                  (أ‌) لدفع أو سداد جميع التكاليف أو النفقات أو الخسائر أو الالتزامات التي يتكبدها وكيل الضمان، و{<br />}
                  (ب‌) سداد جميع المبالغ المستحقة لـ المُمَوِل وفقًا لهذه الاتفاقية والشروط واتفاقية المرابحة ذات الصلة، و
                  {<br />}
                  (ت‌) دفع أية مبالغ فائضة إلى طالب التمويل أو الضامن أو أي شخص آخر يحق له الحصول عليها بموجب النظام المعمول
                  به.
                </Typography>
                <Typography className={styles.decHeader}>13. التأخر أو التعثر في السداد من قبل طالب التمويل</Typography>

                <Typography className={styles.decText}>
                  13.1في حالة تأخر طالب التمويل أو تخلفه عن السداد بموجب اتفاقية المرابحة ذات الصلة، تقوم رقمية باتخاذ خطوات
                  إدارية لمحاولة استرداد الدفعة التي قد تتضمن مناقشات مع طالب التمويل و / أو إعادة هيكلة طلب التمويل لمحاولة
                  استرداد المبالغ المستحقة من عليها، كما تراها رقمية في مصلحة المُمَوِل ككل. قد تسترد رقمية أيضًا تكاليفها
                  ومصاريفها المعقولة كأولوية من أي مبالغ مستردة من طالب التمويل بهذه الطريقة.
                  {<br />}
                  13.2إذا تعرض طالب التمويل لمشكلة اقتصادية مؤقتة مثل مشكلة سيولة أو انخفاض في إيرادات المبيعات أو حدث غير
                  متوقع، فقد يتخلف عن الوفاء بالتزاماته في السداد بموجب اتفاقية المرابحة ذات الصلة ويفوض المُمَوِل بموجب هذا
                  الأمر لـ رقمية أن تحدد نيابة عنه ما إذا كان سيتم الموافقة على طلب من طالب التمويل لإعادة هيكلة طلب التمويل
                  عن طريق:
                  {<br />}
                  (أ‌) تقليل مبالغ السداد عبر تمديد فترة السداد وزيادة عدد عمليات السداد، أو
                  {<br />}
                  (ب‌) تعليق أو تقليص مبالغ السداد خلال فترة معينة ثم زيادة مبلغ الأقساط الشهرية.
                  {<br />}
                  13.3يوافق المُمَوِل ويقر بأن إعادة هيكلة التمويل لا يضمن أن طالب التمويل سوف يفي بالتزامات السداد
                  المستقبلية وقد يكون من الضروري تعيين وكيل تحصيل.
                </Typography>
                <Typography className={styles.decHeader}>14. تعيين وكيل تحصيل</Typography>
                <Typography className={styles.decText}>
                  {<br />}
                  14.1في حالة عدم قدرة رقمية على استرداد المبالغ المستحقة وفقًا للبند 13 أعلاه أو من خلال إنفاذ أي ضمان وفقًا
                  للبند 12، يصرح المُمَوِل بموجبه لـ منصة رقمية باتخاذ الإجراءات الإدارية والقانونية اللازمة بالنيابة عنها
                  والتصرف في مصلحتها. يسمح المُمَوِل لرقمية بخصم جميع التكاليف والمصاريف النظامية والإدارية (بما في ذلك
                  المصاريف النظامية المعقولة) المتكبدة في اتخاذ أي إجراء من هذا القبيل، من المبالغ المستردة بموجب هذا البند
                  رقم 14.
                  {<br />}
                  14.2يخول المُمَوِل بموجب هذه الاتفاقية تعيين وكيل تحصيل لطرف ثالث ("وكيل التحصيل") لتحصيل واسترداد أي مبالغ
                  مستحقة بموجب اتفاقية المرابحة نيابة عن المُمَوِل إذا رأى ذلك ضروريًا في حالة حدوث أي تقصير في سداد أي تمويل
                  قدمه المُمَوِل. يجب أن تبقي رقمية المُمَوِل على اطلاع على النحو الواجب بجميع الإجراءات التي يتخذها وكيل
                  التحصيل بما في ذلك التقدم المحرز في جميع الجهود والإجراءات المتخذة لتحصيل المدفوعات المتأخرة واسترداد
                  المبالغ المستحقة، والتي سيتمكن المُمَوِل من الاطلاع عليها من خلال حساب المُمَوِل.
                  {<br />}
                  14.3يوافق المُمَوِل على أن يكون وكيل التحصيل مفوضًا باتخاذ جميع الإجراءات المعقولة لحماية حقوق المُمَوِل،
                  والتي قد تشمل إنفاذ الضمانات، وسندات الأمر الخاصة بالشركة أو الشخصية وغيرها من أشكال الضمان على النحو
                  المنصوص عليه من قبل طالبي التمويل واتخاذ أية إجراءات أخرى قد تكون متاحة من وقت لآخر وفقًا للنظام المعمول
                  به.
                  {<br />}
                  14.4يجوز لوكيل التحصيل أن يفرض رسوم تحصيل يدفعها طالب التمويل ويتم خصمها من المبلغ الإجمالي الذي يسعى وكيل
                  التحصيل لتحصيله واسترداده من طالب التمويل. لتجنب الشك، يتم خصم رسوم التحصيل كأولوية على أي توزيعات يتم
                  إجراؤها على المُمَوِل. يجب أيضًا خصم التكاليف والنفقات النظامية والإدارية لوكيل التحصيل من جميع المبالغ
                  المطالب بها من قبل طالب التمويل. يجوز لوكيل التحصيل خصم أي مبلغ يمثل ممارسة السوق من المبلغ المطالب به
                  والمسترد من قبل طالب التمويل، والباقي من هذا المبلغ الذي يتم توزيعه على المُمَوِل.
                  {<br />}
                  14.5في حالة الحاجة إلى اتخاذ إجراءات قضائية، يحق لـ رقمية أن تطلب من المُمَوِل تقديم توكيل رسمي لأغراض
                  التقاضي (إذا لزم الأمر) ويتعهد المُمَوِل بموجبه بالامتثال لهذا الطلب.
                  {<br />}
                  14.6يوافق المُمَوِل ما يلي ويقبله:
                  {<br />}
                  (أ‌) لا يمكن لـ رقمية أن تضمن نجاح أية إجراءات قضائية يتم اتخاذها نيابة عن المُمَوِل،
                  {<br />}
                  (ب‌) هناك خطر من أن تؤدي أية إجراءات قضائية إلى دفع المُمَوِل لتعويضات،
                  {<br />}
                  (ت‌) قد يكون هناك دائنون آخرون لـ طالب التمويل لديهم مطالبات يمكن استردادها على سبيل الأولوية من مطالبة
                  المُمَوِل.
                  {<br />}
                  (ث‌) يمكن فقط لـ رقمية أو وكيل الضمان أو وكيل التحصيل التفاوض والتواصل مع طالب التمويل وأي ضامن لاسترداد
                  المبالغ المستحقة نيابة عن المُمَوِل. لا يجوز لـ المُمَوِل اتخاذ أي إجراء فردي في هذا الصدد أو التفاوض أو
                  التواصل مع طالب التمويل أو الضامن ما لم توافق رقمية على خلاف ذلك كتابةً.
                </Typography>

                <Typography className={styles.decHeader}>15. توقف أعمال رقمية</Typography>
                <Typography className={styles.decText}>
                  15.1إذا قامت رقمية بالتخطيط لإيقاف عملياتها لأي سبب من الأسباب، يجب عليها إبلاغ المُمَوِل من خلال الموقع
                  الإلكتروني ولضمان الحد الأدنى من التأثير والاضطراب للممول ، ستستمر رقمية في عملياتها لمدة ثلاثة (3) أشهر
                  أخرى (ويمكن تمديدها لمدة ثلاثة أشهر ـأخرى). ستقوم رقمية بسداد جميع الأرصدة المتاحة للمُمَوِل في الحساب
                  التجميعي إلى حساب بنكي المسجل في المنصة . كما سيتم إرسال بيان بالدفعات المستحقة وجدول السداد بالبريد
                  الإلكتروني إلى المُمَوِل في غضون عشرة (10) أيام عمل.
                  {<br />}
                  15.2خلال الفترة المنصوص عليها في البند 15.1، يجوز لـ رقمية:
                  {<br />}
                  (أ‌) بيع محفظة المبالغ الممولة من المُمَوِل إلى بنك أو مؤسسة مالية مصرحة من البنك المركزي السعودي من أجل
                  استرداد جميع مبالغ التمويل المستحقة لـ المُمَوِل، أو
                  {<br />}
                  (ب‌) طلب السداد المبكر لمبالغ التمويل المستحقة ومساعدة طالب التمويل في إعادة تمويل المبالغ الممولة المستحقة
                  في حالة عدم تمكن طالب التمويل من السداد المبكر للمبلغ المُمَوِل.
                  {<br />}
                  في حالة عدم تمكن رقمية من تحقيق أي مما سبق، فإنها ستقوم بتعيين وكيل تحصيل لاسترداد مبالغ التمويل المستحقة.
                  ستظل جميع المبالغ الممولة غير المسددة سارية المفعول وملزمة نظاما وقابلة للتنفيذ وسيستمر تحصيلها عند
                  استحقاقها. يستمر سداد جميع الدفعات إلى وكيل التحصيل الذي يلتزم بالقيام بسداد دفعات دورية الى المُمَوِل وذلك
                  بعد خصم رسوم التحصيل.
                  {<br />}
                  15.3في حالة تعيين رقمية لوكيل تحصيل بموجب البند 15.2، فإنه يتعين عليها إبلاغ المُمَوِل بالوضع وبجميع
                  الإجراءات المتخذة للحفاظ على حقوق المُمَوِل ولضمان سداد أصل المبلغ ومبالغ الربح المستحقة لـ المُمَوِل بموجب
                  كل اتفاقية مرابحة ذات صلة.
                </Typography>

                <Typography className={styles.decHeader}>16. الاستثناء والمسؤولية والتعويض</Typography>
                <Typography className={styles.decText}>
                  16.1لا تقدم رقمية أي تعهد أو ضمان فيما يتعلق بقدرة أي طالب تمويل على الوفاء بالتزامات السداد الخاصة به فيما
                  يتعلق بأي نوع من طرق التمويل ولن تكون رقمية مسؤولة عن فشل أي طالب للتمويل في الوفاء بالتزامات السداد الخاصة
                  به. يوافق المُمَوِل ويقر بأنه يقدم التمويل لأي طالب للتمويل على مسؤوليته الخاصة.
                  {<br />}
                  16.2إذا قدم مُمَوَّل ( طالب تمويل) بعينه كفالة شخصية من شريك فرد أو عضو مجلس إدارة لديه، يوافق المُمَوِل
                  ويقبل بأن قابلية التنفيذ على تلك الكفالة الشخصية تخضع لأي شروط أو قيود منصوص عليها في النظام المعمول به،
                  وأن رقمية ليست ملزمة بضمان أن يكون مُصدر تلك الكفالة الشخصية هو شخص يتمتع بكامل قواه العقلية ولديه الأهلية
                  المعتبرة شرعاً لإبرام تلك الكفالة الشخصية . ويجوز الطعن في صحة تلك الكفالة الشخصية ويوافق المُمَوِل على أنه
                  يجوز للكفيل الشخصي أو أي طرف آخر الطعن في صحة تلك الكفالة الشخصية مستنداً على مدى الأهلية المعتبرة شرعاً في
                  إبرام الضمان
                  {<br />}
                  16.3يوافق المُمَوِل بموجبه على أن يدافع ويعوض ويبرئ ذمة رقمية والشركات التابعة لها وشركاتها الشقيقة
                  والزميلة ومسئوليها ومديريها ووكلائها ومنسوبيها وممثليها والخلفاء والمتنازل لهم من وضد أي وجميع الإجراءات
                  والمطالبات والدعاوى والمطالب والأحكام والخسائر والتكاليف والنفقات والغرامات والأضرار التنظيمية (بما في ذلك
                  الرسوم والمصاريف النظامية) الناشئة عن:
                  {<br />}
                  (أ‌) أي خرق من قبل المُمَوِل لهذه الاتفاقية أو الشروط أو أية اتفاقية مرابحة، و / أو
                  {<br />}
                  (ب‌) أية مطالبات من طرف ثالث تنشأ عن أي خرق من قبل المُمَوِل لهذه الاتفاقية أو الشروط أو أي اتفاقية مرابحة،
                  و / أو
                  {<br />}
                  (ت‌) أي حالة عدم االإمتثال للنظام المعمول به.
                  {<br />}
                  16.4يظل هذا البند 16 قيد السريان والفعالية إلى ما بعد إنهاء أو انتهاء هذه الاتفاقية والشروط وأية اتفاقية
                  مرابحة لأي سبب من الأسباب.
                </Typography>
                <Typography className={styles.decHeader}>17. المدة والإنهاء</Typography>

                <Typography className={styles.decText}>
                  17.1يسري العمل بهذه الاتفاقية بمجرد قبول المُمَوِل لجميع أحكام هذه الاتفاقية والشروط وتظل سارية المفعول ما
                  لم يتم إنهاؤها من قبل رقمية أو المُمَوِل وفقًا لهذا البند .{<br />}
                  17.2يجوز لـ المُمَوِل إنهاء هذه الاتفاقية عبر إخطار رقمية في أي وقت إذا لم تعد لديه الرغبة في أن يكون
                  ممولًا، شريطة أنه في وقت الإنهاء:
                  {<br />}
                  (أ‌) لا توجد اتفاقية مرابحة سارية.
                  {<br />}
                  (ب‌) لا تكون هناك مبالغ مستحقة عليك في حساب المُمَوِل الخاص بك ، و{<br />}
                  (ت‌) لا تكون هناك مبالغ مستحقة وواجبة الدفع لـ رقمية أو وكيل الضمان (حسبما يلزم).
                  {<br />}
                  عند الإخطار، تقوم رقمية بإغلاق حساب المُمَوِل على الفور.
                  {<br />}
                  17.3في حالة رغبة المُمَوِل في إغلاق حساب المُمَوِل الخاص به ولكنه في وقت الإنهاء:
                  {<br />}
                  (أ‌) كان طرفا في اتفاقية مرابحة سارية. أو
                  {<br />}
                  (ب‌) كانت لديه أموال مخصصة لتمويل طالبي التمويل، أو
                  {<br />}
                  (ت‌) لا يزال مدينًا لـ رقمية أو وكيل الضمان،
                  {<br />}
                  حينئذِ، يجب على المُمَوِل القيام بأي مما يلي:
                  {<br />}
                  (1) الانتظار حتى يتم سداد مبالغ التمويل بالكامل، أو
                  {<br />}
                  (2) دفع أية مبالغ مستحقة لـ منصة رقمية أو وكيل الضمان (حسبما يلزم).
                  {<br />}
                  بمجرد سداد طلبات التمويل بالكامل أو دفع المُمَوِل أية مبالغ مستحقة لـ رقمية أو وكيل الضمان (حسبما يلزم)،
                  ستكون جميع الأموال في حساب المُمَوِل متاحة بالكامل للسحب ويمكن لـ المُمَوِل تحويل هذه الأموال إلى حسابه
                  البنكي المسجل في منصة رقمية. بعد ذلك، تقوم رقمية بإغلاق حساب المُمَوِل.
                  {<br />}
                  17.4يجوز لـ رقمية أن تغلق حساب المُمَوِل في أي وقت عن طريق إرسال إشعار ، إلى المُمَوِل، قبل خمسة عشر (15)
                  يوم عمل إذا:
                  {<br />}
                  (أ‌) خرق المُمَوِل أي بند من بنود هذه الاتفاقية أو الشروط أو سياسة الخصوصية،
                  {<br />}
                  (ب‌) لم يعد المُمَوِل ممولاً مؤهلاً لأنه توقف عن الوفاء بمعايير الأهلية المنصوص عليها في الشروط، أو
                  {<br />}
                  (ت‌) لم يمتثل المُمَوِل لطلبات رقمية المعقولة للمعلومات، أو
                  {<br />}
                  (ث‌) اشتبهت رقمية في تورط المُمَوِل في عمليات احتيال أو غسيل أموال أو أنشطة إجرامية أخرى أو اكتشاف رقمية أن
                  أية معلومات مقدمة إليها كانت خاطئة أو مضللة أو غير صحيحة من الناحية المادية، أو
                  {<br />}
                  (ج‌) استخدم المُمَوِل المنصة لغرض لم تكن المنصة مخصصة له، أو
                  {<br />}
                  (ح‌) قيام المُمَوِل بإتخاذ إجراء مباشر للإتصال أو التعاقد مباشرةً مع المُمَوَّل (طالب التمويل) لإسترداد
                  الدفعات المستحقة؛ أو
                  {<br />}
                  (خ‌) لم يتم إيداع أموال في حساب المُمَوِل لمدة (6) ستة أشهر من تاريخ إنشاء المُمَوِل لحساب المُمَوِل..
                  {<br />}
                  17.5يفوض المُمَوِل رقمية، بموجبه، عند استلام الإشعار وفقًا للبند 17.4 من أجل:
                  {<br />}
                  (أ‌) نقل أية اتفاقية مرابحة معلقة إلى ممول آخر لاسترداد المبلغ الأصلي الذي موله المُمَوِل بموجب اتفاقية
                  المرابحة المعلقة تلك،
                  {<br />}
                  (ب‌) تحويل أية أموال متبقية يحق لـ المُمَوِل الحصول عليها إلى حسابه البنكي المحدد بعد خصم المبالغ المستحقة
                  لـ منصة رقمية أو وكيل الضمان (حسبما يلزم)،
                  {<br />}
                  ومن ثم إغلاق حساب المُمَوِل الخاص بك. 17.6كذلك، يجوز لـ رقمية إنهاء هذه الاتفاقية وفقًا للبند .
                </Typography>
                <Typography className={styles.decHeader}>18. الإشعارات</Typography>
                <Typography className={styles.decText}>
                  18.1المنصة عبارة عن خدمة عبر الإنترنت وجميع المستندات والإشعارات التي ترسلها منصة رقمية إلى المُمَوِل يتم
                  تسليمها من خلال الموقع الإلكتروني أو من خلال عنوان البريد الإلكتروني الذي سجله المُمَوِل في رقمية.
                  {<br />}
                  18.2جميع التعليمات والإشعارات التي يرسلها المُمَوِل إلى رقمية، (بما في ذلك أي إشعار وفقًا للبند 17.2) تكون
                  ملزمة فقط بشرط أن يتم تسليمها من خلال الموقع الإلكتروني أو يتم تسليمها عبر البريد الإلكتروني إلى
                  contactus@raqamyah.com.
                </Typography>
                <Typography className={styles.decHeader}>19. النظام المعمول به وجهة الاختصاص القضائي</Typography>
                <Typography className={styles.decText}>
                  19.1تخضع هذه الاتفاقية للحكم والتفسير وفقًا لأنظمة المملكة العربية السعودية.
                  {<br />}
                  19.2تتم إحالة جميع النزاعات و / أو المطالبات الناشئة عن هذه الاتفاقية أو فيما يتعلق بها للتسوية والحل
                  النهائي من قبل وتخضع للاختصاص القضائي الحصري للمحاكم المختصة في المملكة العربية السعودية.
                </Typography>

                <Typography className={styles.decHeader}>20. شروط وأحكام منوعة</Typography>

                <Typography className={styles.decText}>
                  20.1الرقابة التنظيمية: تخضع المنصة لتنظيم البنك المركزي السعودي ضمن البيئة التجريبية التشريعية SAMA Fintech
                  Sandbox والمنصة مصرحة من قبل البنك المركزي السعودي لتجربة منتجاتها الابتكارية في البيئة التجريبيةالتشريعية
                  (Sandbox)
                  {<br />}
                  20.2الشكاوى: في حالة وجود شكوى لدى المُمَوِل ، يجب أن يكتب على الفور إلى رقمية لتقديم تفاصيل واضحة عن
                  الشكوى. يجب على المُمَوِل إرسال الشكوى عبر البريد الإلكتروني إلى contactus@raqamyah.com. ستسعى رقمية إلى
                  الإقرار بالشكوى في غضون خمسة أيام عمل من استلامها وستسعى إلى الرد على الشكوى في غضون خمسة عشر يوم عمل من
                  استلامها.
                  {<br />}
                  20.3استقلالية الشروط: إذا تبين لأية محكمة مختصة بطلان أو عدم سريان أي من شروط أو أحكام هذه الاتفاقية أو عدم
                  قابليته للتنفيذ قضائي، فسيظل ما تبقى من شروط وأحكام في كامل السريان والصلاحية دون تأثر بالشرط غير الساري.
                  {<br />}
                  20.4سبل الانتصاف: حقوق رقمية بموجب هذه الاتفاقية والشروط وسياسة الخصوصية تراكمية وليست مستثناة من أية حقوق
                  وسبل انتصاف أخرى منصوص عليها في النظام المعمول به. التأخير في ممارسة أي من حقوق رقمية، سواء بموجب هذه
                  الاتفاقية أو الشروط أو سياسة الخصوصية أو بموجب النظام المعمول به، لا يعتبر تنازلًا عن هذه الحقوق.
                  {<br />}
                  20.5التنازل: يجوز لـ رقمية ووكيل الضمان (إن أمكن) نقل أو التنازل لأي طرف ثالث عن كل أو جزء من حقوقهما
                  والتزاماتهما بموجب هذه الاتفاقية أو بموجب الشروط أو اتفاقية الضمان إلى أي طرف ثالث ويسمح لهما بممارسة أي من
                  حقوقهما من خلال طرف ثالث مزود خدمات. لا يحق لـ المُمَوِل نقل أو التنازل عن أي من حقوقه والتزاماته بموجب هذه
                  الاتفاقية والشروط وسياسة الخصوصية.
                  {<br />}
                  20.6الاتفاقية الكاملة تشكل الوثائق التالية الاتفاقية الكاملة بين رقمية والمُمَوِل:
                  {<br />}
                  (أ‌) هذه الاتفاقية، و{<br />}
                  (ب‌) الشروط، و{<br />}
                  (ت‌) أي تعديلات أو إضافات على أي مما سبق ذكره من آن لآخر.
                  {<br />}
                  في حالة وجود أي تعارض بين شروط أي من الوثائق السابقة، فإنه يتم وضعها بنفس ترتيب الأولوية الذي تظهر فيه
                  أعلاه مع مراعاة أي تعديلات مشار إليها في الفقرة (ت) أعلاه.
                  {<br />}
                  20.7قبول المُمَوِل: قبل التسجيل، على المُمَوِل قراءة هذه الاتفاقية والشروط. إذا لم يوافق المُمَوِل أو لم
                  يرغب في الامتثال لأحكام هذه الاتفاقية أو الشروط، فلا ينبغي له استمرار الشروع في التسجيل كممول.
                </Typography>
              </>
            )}
          </Box>
        </DialogContent>
        <DialogActions style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <Button variant="contained" style={{ backgroundColor: 'red', color: 'white' }} onClick={() => setdisOpen(false)}>
            <IntlMessages id="button.disagree" />
          </Button>
          <Button variant="contained" color="primary" onClick={() => sendOtpMobile()}>
            <IntlMessages id="button.agree" />
          </Button>
        </DialogActions>
      </Dialog>
    </AuthWrapper>
  );
};

export default SignUpFive;
