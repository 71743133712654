import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import {
  Box,
  fade,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Text,
  Grid,
  InputLabel,
  MenuItem,
  Select,
} from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Typography from '@material-ui/core/Typography';
import { NavLink } from 'react-router-dom';
import { CurrentAuthMethod } from '@jumbo/constants/AppConstants';
import IntlMessages from '@jumbo/utils/IntlMessages';
import ContentLoader from '@jumbo/components/ContentLoader';
import AuthWrapper from '@jumbo/components/Common/authComponents/AuthWrapper';
import CmtImage from '@coremat/CmtImage';
import { AuhMethods } from 'services/auth';
import { history } from 'redux/store';
import Axios from 'axios';
import { headers, Url } from '../../../redux/NetworkConfig/ApiUrlConstatnts';
import { datePicker } from '../../../@jumbo/utils/dateHelper';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormLabel from '@material-ui/core/FormLabel';
import { citizenInfoInvestorFunction } from '../../../redux/actions/citizenInfoInvestor';
import { citiesListFunction } from '../../../redux/actions/citiesList';
import { industryListFunction } from '../../../redux/actions/industry';
import { companyRegistrationCheckFunction } from '../../../redux/actions/companyRegistrationCheck';
import { Dialog, DialogActions, DialogContent, DialogContentText } from '@material-ui/core';
import { registrationStepTwoIndividualFunction } from '../../../redux/actions/registrationStepTwoIndividual';
import HijriDate, { toHijri } from 'hijri-date/lib/safe';
import Swal from 'sweetalert2';
import GridContainer from '@jumbo/components/GridContainer';

const useStyles = makeStyles(theme => ({
  authThumb: {
    backgroundColor: fade(theme.palette.primary.main, 0.12),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: 20,
    [theme.breakpoints.up('md')]: {
      width: '50%',
      order: 2,
    },
  },
  authContent: {
    padding: 30,
    [theme.breakpoints.up('md')]: {
      width: props => (props.variant === 'default' ? '50%' : '100%'),
      order: 1,
    },
    [theme.breakpoints.up('xl')]: {
      padding: 50,
    },
  },
  titleRoot: {
    marginBottom: 14,
    color: theme.palette.text.primary,
  },
  textFieldRoot: {
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: fade(theme.palette.common.dark, 0.12),
    },
  },
  textCapital: {
    textTransform: 'capitalize',
  },
  textAcc: {
    textAlign: 'center',
    '& a': {
      marginLeft: 4,
    },
  },
  alrTextRoot: {
    textAlign: 'center',
    [theme.breakpoints.up('sm')]: {
      textAlign: 'right',
    },
  },
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    width: '100%',
  },
}));

//variant = 'default', 'standard', 'bgColor'
const SignupCompany = ({ method = CurrentAuthMethod, variant = 'standard', wrapperVariant = 'default' }) => {
  const dispatch = useDispatch();
  const classes = useStyles({ variant });
  const [isFetching, setIsFetching] = useState(false);
  const [companyName, setcompanyName] = useState('');
  const [companySignupAllowed, setcompanySignupAllowed] = useState('');
  const [companyRegistrationCode, setcompanyRegistrationCode] = useState('');
  const [companyTurnover, setcompanyTurnover] = useState('');
  const [companyBalance, setcompanyBalance] = useState('');
  const [dateOfArticle, setdateOfArticle] = useState('');
  const [companyAddress, setcompanyAddress] = useState('');
  const [companyIndustry, setcompanyIndustry] = useState('');
  const [companyCategory, setcompanyCategory] = useState('');
  const [companyDescription, setcompanyDescription] = useState('');
  const [identityNumberOneIssueDate, setidentityNumberOneIssueDate] = useState('');
  const [identityNumberTwoIssueDate, setidentityNumberTwoIssueDate] = useState('');
  const [employeesCount, setemployeesCount] = useState('');
  const [positionInCompany, setpositionInCompany] = useState('');
  const [faxNumber, setfaxNumber] = useState('');
  const [businessType, setbusinessType] = useState('');
  const [companyIdentityNumberOne, setcompanyIdentityNumberOne] = useState('');
  const [companyIdentityNumberTwo, setcompanyIdentityNumberTwo] = useState('');
  const [companyIdentityNumberThree, setcompanyIdentityNumberThree] = useState('');
  const [companyIdentityNumberFour, setcompanyIdentityNumberFour] = useState('');
  const [companyIdentityNumberFive, setcompanyIdentityNumberFive] = useState('');
  const [companyIdentityNumberSix, setcompanyIdentityNumberSix] = useState('');
  const [faxNumberCountryCode, setfaxNumberCountryCode] = useState('');
  const [companyIdentityNumberOneIssueDate, setcompanyIdentityNumberOneIssueDate] = useState('');
  const [companyIdentityNumberTwoIssueDate, setcompanyIdentityNumberTwoIssueDate] = useState('');
  const [investorEmploymentDetailsTextVariable1, setinvestorEmploymentDetailsTextVariable1] = useState('');

  const [retypePassword, setretypePassword] = useState('');
  const [line1, setline1] = useState('');
  const [line2, setline2] = useState('');
  const [line3, setline3] = useState('');
  const [city, setcity] = useState('');
  const [state, setstate] = useState('');
  const [zipCode, setzipCode] = useState('');
  const [businessVintage, setbusinessVintage] = useState('');
  const [dateYear, setDateYear] = useState('');
  const [dateMonth, setDateMonth] = useState('');
  const [dateDay, setDateDay] = useState('');
  const [dateYearExpiry, setDateYearExpiry] = useState('');
  const [dateMonthExpiry, setDateMonthExpiry] = useState('');
  const [dateDayExpiry, setDateDayExpiry] = useState('');
  const [cityList, setCityList] = useState([]);
  const [industryList, setIndustryList] = useState([]);
  const Register_step3 = () => {
    let newUser = localStorage.getItem('newUser');

    let inputs = {
      access_token: newUser,
      companyName: companyName,
      companyRegistrationCode: companyRegistrationCode,
      companyTurnover: companyTurnover,
      companyBalance: companyBalance,
      dateOfArticle: dateOfArticle,
      companyIndustry: companyIndustry,
      companyDescription: companyDescription,
      employeesCount: employeesCount,
      positionInCompany: positionInCompany,
      faxNumber: faxNumber,
      faxNumberCountryCode: faxNumberCountryCode,
      businessType: businessType,
      companyIdentityNumberOne: companyIdentityNumberOne,
      companyIdentityNumberTwo: companyIdentityNumberTwo,
      companyIdentityNumberThree: companyIdentityNumberThree,
      companyIdentityNumberFour: companyIdentityNumberFour,
      companyIdentityNumberOneIssueDate: companyIdentityNumberOneIssueDate,
      companyIdentityNumberTwoIssueDate: companyIdentityNumberTwoIssueDate,
      businessVintage: businessVintage,
      line1: line1,
      line2: line2,
      line3: line3,
      city: city,
      state: state,
      zipCode: zipCode,
    };
    // console.log(JSON.stringify(inputs));
    setIsFetching(true);
    Axios({
      method: 'post',
      url: Url.baseUrl + Url.RegistrationStepTwoCompany,
      headers: headers.headFinancing,
      data: JSON.stringify(inputs),
    })
      .then(function(res) {
        if (res.data.code == '200') {
          Swal.fire({
            title: 'Success',
            text: res.data.message,
            showDenyButton: false,
            showCancelButton: false,
            icon: 'success',
            confirmButtonText: 'Okay',
          }).then(result => {
            /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed) {
              history.push('/signupfour');
            } else if (result.isDenied) {
              return;
            }
          });
        } else {
          setIsFetching(false);

          alert(res.data.status);
          Swal.fire('Alert', res.data.status, 'info');
        }
      })
      .catch(function(error) {
        // handle error
        alert(error);
      });
  };

  const checkCompanyRegistration = () => {
    let newUser = localStorage.getItem('newUser');
    let input = {
      access_token: newUser,
      registrationNumber: companyRegistrationCode,
    };
    // {"companyDetails": {"businessName": "شركة منصة رقميه لتقنية المعلومات", "dateOfRegistration": "1439/08/26",
    // "description": "null", "expiryDate": "1444/08/26"}, "message": "Commercial Data Fetched", "status": 200}
    dispatch(companyRegistrationCheckFunction(input)).then(res => {
      // console.log(res.data);
      // console.log(res?.data?.message);
      if (res?.data?.status === 200) {
        setcompanyName(res?.data?.companyDetails?.businessName);
        setcompanyDescription(res?.data?.companyDetails?.description);
        setidentityNumberOneIssueDate(res?.data?.companyDetails?.dateOfRegistration);
        setidentityNumberTwoIssueDate(res?.data?.companyDetails?.expiryDate);
      } else {
        Swal.fire('Info', res?.data?.message, 'info');
      }
    });
  };
  useEffect(() => {
    dispatch(citiesListFunction()).then(res => {
      setCityList(res?.data);
    });
    const url = Url.IndustryList;

    dispatch(industryListFunction(url)).then(res => {
      setIndustryList(res?.data);
    });
  }, []);

  return (
    <AuthWrapper variant={wrapperVariant}>
      <Box className={classes.authContent}>
        <Typography component="div" variant="h1" className={classes.titleRoot}>
          <IntlMessages id="registration.companydetails" />
        </Typography>
        <form>
          <Grid xs={12} md={12} lg={12}>
            <TextField
              label={<IntlMessages id="registration.commercialregistration" />}
              fullWidth
              // defaultValue={name}
              margin="normal"
              variant="outlined"
              className={classes.textFieldRoot}
              onChange={event => setcompanyRegistrationCode(event.target.value)}
            />
          </Grid>
          <Grid xs={12} md={12} lg={12}>
            <Button
              fullWidth
              variant="contained"
              color="primary"
              onClick={() => {
                checkCompanyRegistration();
              }}>
              <IntlMessages id="registration.verify" />
            </Button>
          </Grid>

          <Grid md={12}>
            <TextField
              label={<IntlMessages id="registration.businessname" />}
              fullWidth
              disabled
              value={companyName}
              // defaultValue={com}
              margin="normal"
              variant="outlined"
              className={classes.textFieldRoot}
              onChange={event => setcompanyName(event.target.value)}
            />
          </Grid>
          <Grid md={12}>
            <TextField
              label={<IntlMessages id="registration.businessdescription" />}
              disabled
              fullWidth
              value={companyDescription}
              margin="normal"
              variant="outlined"
              className={classes.textFieldRoot}
              onChange={event => setcompanyDescription(event.target.value)}
            />
          </Grid>
          <Grid md={12}>
            <TextField
              label={<IntlMessages id="registration.commercialregdate" />}
              disabled
              fullWidth
              value={identityNumberOneIssueDate}
              margin="normal"
              variant="outlined"
              className={classes.textFieldRoot}
              onChange={event => setidentityNumberOneIssueDate(event.target.value)}
            />
            <Grid md={12}>
              <TextField
                label={<IntlMessages id="registration.commercialregexpdate" />}
                disabled
                fullWidth
                value={identityNumberTwoIssueDate}
                margin="normal"
                variant="outlined"
                className={classes.textFieldRoot}
                onChange={event => setidentityNumberTwoIssueDate(event.target.value)}
              />
            </Grid>
          </Grid>
          {/* <Box mb={2}>
            <label>Commercial Registration Date</label>
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-evenly', flexDirection: 'row' }}>
              <FormControl sx={{ m: 1, minWidth: 100 }} style={{ width: 500, marginTop: 20 }}>
                <InputLabel id="demo-simple-select-helper-label">Year</InputLabel>
                <Select
                  labelId="demo-simple-select-helper-label"
                  id="demo-simple-select-helper"
                  value={dateYear}
                  variant="outlined"
                  onChange={event => setDateYear(event.target.value)}
                  label="Year">
                  {datePicker?.year.map(item => {
                    return (
                      <MenuItem value={item.value} key={item.value}>
                        <em>{item.value}</em>
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
              <FormControl sx={{ m: 1, minWidth: 100 }} style={{ width: 500, marginTop: 20 }}>
                <InputLabel id="demo-simple-select-helper-label">Month</InputLabel>
                <Select
                  labelId="demo-simple-select-helper-label"
                  id="demo-simple-select-helper"
                  variant="outlined"
                  value={dateMonth}
                  onChange={event => setDateMonth(event.target.value)}
                  label="Month">
                  {datePicker?.month.map(item => {
                    return (
                      <MenuItem value={item.value} key={item.value}>
                        <em>{item.value}</em>
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
              <FormControl sx={{ m: 1, minWidth: 100 }} style={{ width: 500, marginTop: 20 }}>
                <InputLabel id="demo-simple-select-helper-label">Day</InputLabel>
                <Select
                  labelId="demo-simple-select-helper-label"
                  id="demo-simple-select-helper"
                  variant="outlined"
                  value={dateDay}
                  onChange={event => setDateDay(event.target.value)}
                  label="Day">
                  {datePicker?.day.map(item => {
                    return (
                      <MenuItem value={item.value} key={item.value}>
                        <em>{item.value}</em>
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </div>
          </Box> */}
          {/* <Box mb={2}>
            <label>Commercial Registration Expiry Date</label>
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-evenly', flexDirection: 'row' }}>
              <FormControl sx={{ m: 1, minWidth: 100 }} style={{ width: 500, marginTop: 20 }}>
                <InputLabel id="demo-simple-select-helper-label">Year</InputLabel>
                <Select
                  labelId="demo-simple-select-helper-label"
                  id="demo-simple-select-helper"
                  value={dateYearExpiry}
                  variant="outlined"
                  onChange={event => setDateYearExpiry(event.target.value)}
                  label="Year">
                  {datePicker?.year.map(item => {
                    return (
                      <MenuItem value={item.value} key={item.value}>
                        <em>{item.value}</em>
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
              <FormControl sx={{ m: 1, minWidth: 100 }} style={{ width: 500, marginTop: 20 }}>
                <InputLabel id="demo-simple-select-helper-label">Month</InputLabel>
                <Select
                  labelId="demo-simple-select-helper-label"
                  id="demo-simple-select-helper"
                  variant="outlined"
                  value={dateMonthExpiry}
                  onChange={event => setDateMonthExpiry(event.target.value)}
                  label="Month">
                  {datePicker?.month.map(item => {
                    return (
                      <MenuItem value={item.value} key={item.value}>
                        <em>{item.value}</em>
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
              <FormControl sx={{ m: 1, minWidth: 100 }} style={{ width: 500, marginTop: 20 }}>
                <InputLabel id="demo-simple-select-helper-label">Day</InputLabel>
                <Select
                  labelId="demo-simple-select-helper-label"
                  id="demo-simple-select-helper"
                  variant="outlined"
                  value={dateDayExpiry}
                  onChange={event => setDateDayExpiry(event.target.value)}
                  label="Day">
                  {datePicker?.day.map(item => {
                    return (
                      <MenuItem value={item.value} key={item.value}>
                        <em>{item.value}</em>
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </div>
          </Box> */}
          <Grid md={12}>
            <FormControl fullWidth style={{ marginTop: 20 }}>
              <InputLabel id="demo-simple-select-helper-label">
                <IntlMessages id="registration.type" />
              </InputLabel>
              <Select
                labelId="demo-simple-select-helper-label"
                id="demo-simple-select-helper"
                variant="outlined"
                label="Age">
                <MenuItem value="">
                  <em>None</em>
                </MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid md={12}>
            <FormControl fullWidth style={{ marginTop: 20 }}>
              <InputLabel id="demo-simple-select-helper-label">
                <IntlMessages id="registration.sector" />
              </InputLabel>
              <Select
                labelId="demo-simple-select-helper-label"
                id="demo-simple-select-helper"
                variant="outlined"
                onChange={event => setinvestorEmploymentDetailsTextVariable1(event.target.value)}
                value={investorEmploymentDetailsTextVariable1}
                label="Age">
                {industryList?.map(item => {
                  return (
                    <MenuItem value={item.name}>
                      <em>{item.name}</em>
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={6} md={6}>
              <div className={classes.container} noValidate>
                <TextField
                  label={<IntlMessages id="registration.addressline1" />}
                  fullWidth
                  // defaultValue={email}
                  margin="normal"
                  variant="outlined"
                  onChange={event => setline1(event.target.value)}
                  className={classes.textField}
                />
              </div>
            </Grid>
            <Grid item xs={6} md={6}>
              <Box>
                <TextField
                  label={<IntlMessages id="registration.addressline2" />}
                  fullWidth
                  // defaultValue={password}
                  margin="normal"
                  variant="outlined"
                  onChange={event => setline2(event.target.value)}
                  // className={classes.textFieldRoot}
                />
              </Box>
            </Grid>
            <Grid item xs={6} md={6}>
              <Box>
                <TextField
                  label={<IntlMessages id="registration.addressline3" />}
                  fullWidth
                  // defaultValue={password}
                  margin="normal"
                  onChange={event => setline3(event.target.value)}
                  variant="outlined"
                  // className={classes.textFieldRoot}
                />
              </Box>
            </Grid>
            <Grid item xs={6} md={6}>
              <Box>
                <TextField
                  label={<IntlMessages id="registration.zipcode" />}
                  fullWidth
                  // defaultValue={password}
                  margin="normal"
                  variant="outlined"
                  onChange={event => setzipCode(event.target.value)}
                  // className={classes.textFieldRoot}
                />
              </Box>
            </Grid>
          </Grid>
          <Grid md={12}>
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-helper-label">
                <IntlMessages id="registration.city" />
              </InputLabel>
              <Select
                labelId="demo-simple-select-helper-label"
                id="demo-simple-select-helper"
                variant="outlined"
                value={city}
                onChange={event => setcity(event.target.value)}
                label="city">
                {cityList?.map(item => {
                  return <MenuItem value={item.name}>{item.name}</MenuItem>;
                })}
              </Select>
            </FormControl>
          </Grid>
          <Grid
            style={{ marginTop: '10px' }}
            display="flex"
            flexDirection={{ xs: 'column', sm: 'row' }}
            alignItems={{ sm: 'center' }}
            justifyContent={{ sm: 'space-between' }}
            mb={3}>
            <Grid mb={{ xs: 2, sm: 0 }}>
              <Button variant="contained" color="primary" onClick={() => Register_step3()}>
                <IntlMessages id="appModule.next" />
              </Button>
            </Grid>
          </Grid>
        </form>
        <div>
          <Dialog>
            <DialogContent>
              <DialogContentText></DialogContentText>

              <DialogContentText></DialogContentText>
            </DialogContent>

            <DialogActions>
              <Button>Cancel</Button>
              <Button>Okay</Button>
            </DialogActions>
          </Dialog>
        </div>
        <div>
          <Dialog>
            <DialogContent>
              <DialogContentText>Otp verfication</DialogContentText>
              <TextField
                label=""
                fullWidth
                // defaultValue={password}
                margin="normal"
                variant="outlined"
                // className={classes.textFieldRoot}
              />
            </DialogContent>

            <DialogActions>
              <Button>Cancel</Button>
              <Button>Okay</Button>
            </DialogActions>
          </Dialog>
        </div>
        {/* <Box mb={3}>{dispatch(AuhMethods[method].getSocialMediaIcons())}</Box>

        <ContentLoader /> */}
      </Box>
    </AuthWrapper>
  );
};

export default SignupCompany;
