import Footer from '@jumbo/components/Footer/Footer';
import FooterTop from '@jumbo/components/Footer/FooterTop';
import Header from '@jumbo/components/Header/Header';
import React from 'react';
import SignUp from '../../@jumbo/components/Common/authComponents/SignUp';

const Register = () => {
  return (
    <div style={{ flex: 1 }}>
      <Header />
      <SignUp variant="standard" wrapperVariant="bgColor" />
      <FooterTop />
      <Footer />
    </div>
  );
};
export default Register;
