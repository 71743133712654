import * as types from '../actionTypes/types';
const initialState = {
  transactionsDetails: {},
  completedTransaction: [],
  pendingTransaction: [],
  awaitingAmountInTransaction: 0,
  isFetching: false,
  errorMessage: '',
  completedTransactionCount: 0,
  pendingTransactionCount: 0,
};
const transactionsReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.FETCHING_TRANSACTIONS_REQUEST:
      return { ...state, isFetching: true };
    case types.FETCHING_TRANSACTIONS_FAILED:
      return {
        ...state,
        isFetching: false,
        errorMessage: action.payload,
      };
    case types.FETCHING_TRANSACTIONS_SUCCESS:
      return {
        ...state,
        isFetching: false,
        transactionsDetails: action.payload,
        completedTransaction: action.completedTransaction,
        pendingTransaction: action.pendingTransaction,
        awaitingAmountInTransaction: action.awaitingAmountInTransaction,
        pendingTransactionCount: action.pendingTransactionCount,
        completedTransactionCount: action.completedTransactionCount,
      };
    case types.FETCHING_USER_LOGOUT_DETAILS_SUCCESS:
      state = initialState;
      return state;
    default:
      return state;
  }
};
export default transactionsReducer;
