import * as types from '../actionTypes/types';

const initialState = {
  registeredUserStepTwo: {},
  isFetching: false,
  errorMessage: '',
};

const registrationStepTwoIndividualReducer = (state = initialState, action) => {
  switch (action.type) {
      case types.FETCHING_REGIRSTRATION_STEP_TWO_INDIVIDUAL_REQUEST:
          return { ...state, isFetching: true };
      case types.FETCHING_REGIRSTRATION_STEP_TWO_INDIVIDUAL_FAILED:
          return {
              ...state,
              isFetching: false,
              errorMessage: action.payload,
          };
      case types.FETCHING_REGIRSTRATION_STEP_TWO_INDIVIDUAL_SUCCESS:
          return {
              ...state,
              isFetching: false,
              registeredUserStepTwo: action.payload,
          };

      case types.FETCHING_USER_LOGOUT_DETAILS_SUCCESS:
          state = initialState;
          return state;

      default:
          return state;
  }
};
export default registrationStepTwoIndividualReducer;
