import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import {
  Box,
  fade,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Text,
  Grid,
  InputLabel,
  MenuItem,
  Select,
} from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Typography from '@material-ui/core/Typography';
import { NavLink } from 'react-router-dom';
import { CurrentAuthMethod } from '@jumbo/constants/AppConstants';
import IntlMessages from '@jumbo/utils/IntlMessages';
import ContentLoader from '@jumbo/components/ContentLoader';
import AuthWrapper from '@jumbo/components/Common/authComponents/AuthWrapper';
import CmtImage from '@coremat/CmtImage';
import { AuhMethods } from 'services/auth';
import { history } from 'redux/store';
import Axios from 'axios';
import { headers, Url } from '../../../redux/NetworkConfig/ApiUrlConstatnts';
import { datePicker } from '../../../@jumbo/utils/dateHelper';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormLabel from '@material-ui/core/FormLabel';
import { citizenInfoInvestorFunction } from '../../../redux/actions/citizenInfoInvestor';
import { citiesListFunction } from '../../../redux/actions/citiesList';
import { companyListFunction } from '../../../redux/actions/company';
import { companyRegistrationCheckFunction } from '../../../redux/actions/companyRegistrationCheck';
import { Dialog, DialogActions, DialogContent, DialogContentText } from '@material-ui/core';
import { registrationStepTwoIndividualFunction } from '../../../redux/actions/registrationStepTwoIndividual';
import HijriDate, { toHijri } from 'hijri-date/lib/safe';
import Swal from 'sweetalert2';
import GridContainer from '@jumbo/components/GridContainer';
import SnackBarComponent from 'routes/Components/SnackBar';
import PageLoader from '@jumbo/components/PageComponents/PageLoader';

const useStyles = makeStyles(theme => ({
  authThumb: {
    backgroundColor: fade(theme.palette.primary.main, 0.12),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: 20,
    [theme.breakpoints.up('md')]: {
      width: '50%',
      order: 2,
    },
  },
  authContent: {
    padding: 30,
    [theme.breakpoints.up('md')]: {
      width: props => (props.variant === 'default' ? '50%' : '100%'),
      order: 1,
    },
    [theme.breakpoints.up('xl')]: {
      padding: 50,
    },
  },
  titleRoot: {
    marginBottom: 14,
    color: theme.palette.text.primary,
  },
  textFieldRoot: {
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: fade(theme.palette.common.dark, 0.12),
    },
  },
  textCapital: {
    textTransform: 'capitalize',
  },
  textAcc: {
    textAlign: 'center',
    '& a': {
      marginLeft: 4,
    },
  },
  alrTextRoot: {
    textAlign: 'center',
    [theme.breakpoints.up('sm')]: {
      textAlign: 'right',
    },
  },
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    width: '100%',
  },
}));

//variant = 'default', 'standard', 'bgColor'
const SignupBorrowerBusinessDescription = ({ variant = 'standard', wrapperVariant = 'default' }) => {
  const dispatch = useDispatch();
  const classes = useStyles({ variant });
  const [isFetching, setIsFetching] = useState(false);
  const [cityList, setCityList] = useState([]);
  const [companyList, setCompanyList] = useState([]);
  const [designation, setdesignation] = useState('');
  const [companyIdentityNumberFive, setcompanyIdentityNumberFive] = useState('');
  const [companyIdentityNumberSix, setcompanyIdentityNumberSix] = useState('');
  const [borrowerCompanyTextVariable1, setborrowerCompanyTextVariable1] = useState('');
  const [borrowerCompanyTextVariable2, setborrowerCompanyTextVariable2] = useState('');
  const [response, setResponse] = useState(false);
  const [responseMessage, setResponseMessage] = useState('');
  const [responseStatus, setResponseStatus] = useState('');
  const Register_step3 = async () => {
    let newUser = localStorage.getItem('newUser');
    let stepTwoData = localStorage.getItem('borrowerStepTwo');
    let finalDataStepTwo = JSON.parse(stepTwoData);
    let stepThreeData = localStorage.getItem('borrowerStepThree');
    let finalDataStepThree = JSON.parse(stepThreeData);
    let inputs = {
      designation: designation,
      companyIdentityNumberFive: companyIdentityNumberFive,
      companyIdentityNumberSix: companyIdentityNumberSix,
      borrowerCompanyTextVariable1: borrowerCompanyTextVariable1,
      borrowerCompanyTextVariable2: borrowerCompanyTextVariable2,
      companyFullName: finalDataStepTwo?.companyFullName,
      companyFullNameArabic: finalDataStepTwo?.companyFullNameArabic,
      access_token: newUser,
      line1: finalDataStepTwo?.line1,
      line2: finalDataStepTwo?.line2,
      line3: finalDataStepTwo?.line3,
      city: finalDataStepTwo?.city,
      zipCode: finalDataStepTwo?.zipCode,
      companyIdentityNumberOne: finalDataStepTwo?.companyIdentityNumberOne,
      companyCitizenship: finalDataStepTwo?.companyCitizenship,
      companyDateOfBirth: finalDataStepTwo?.companyDateOfBirth,
      companyName: finalDataStepThree?.companyName,
      companyRegistrationCode: finalDataStepThree?.companyRegistrationCode,
      businessVintage: finalDataStepThree?.businessVintage,
      dateOfArticle: finalDataStepThree?.dateOfArticle,
      companyRegistrationExpiryDate: finalDataStepThree?.companyRegistrationExpiryDate,
      companyCategory: finalDataStepThree?.companyCategory,
      landLineNumber: finalDataStepThree?.landLineNumber,
      companyWebsite: finalDataStepThree?.companyWebsite,
      companyAddressOneLine1: finalDataStepThree?.companyAddressOneLine1,
      companyAddressOneLine2: finalDataStepThree?.companyAddressOneLine2,
      companyAddressOneLine3: finalDataStepThree?.companyAddressOneLine3,
      companyAddressOneCity: finalDataStepThree?.companyAddressOneCity,
      companyAddressOneZipCode: finalDataStepThree?.companyAddressOneZipCode,
      companyIdentityNumberOneIssueDate: finalDataStepThree?.companyRegistrationExpiryDate,
    };
    // console.log(JSON.stringify(inputs), 'stepthree');
    // return;
    setIsFetching(true);
    const url = Url.baseUrl + Url.RegistrationBorrowerStepTwoCompany;

    // console.log(inputs, 'inputData');
    var config = {
      method: 'post',
      url: url,
      headers: headers.headFinancing,
      data: inputs,
    };
    const res = await Axios(config).catch(res => {
      setIsFetching(false);
      setResponseMessage(res?.message);
      setResponse(true);
      setIsFetching(false);
      setTimeout(() => {
        window.location.reload();
      }, 1500);
    });
    // console.log(res.data, 'uniqueemail');
    setIsFetching(false);

    if (res?.data?.code == '200') {
      setResponseMessage(res.data.message);
      setResponse(true);
      setIsFetching(false);

      setResponseStatus('200');
      localStorage.removeItem('borrowerStepTwo');
      localStorage.removeItem('borrowerStepThree');
      // localStorage.setItem('newUser', res.data.access_token);
      setTimeout(() => {
        history.push('/signupborrowersix');
      }, 2000);
    } else {
      setIsFetching(false);
      Swal.fire('Alert', res.data.message, 'info');
    }
  };

  useEffect(() => {
    dispatch(citiesListFunction()).then(res => {
      setCityList(res?.data);
    });

    dispatch(companyListFunction()).then(res => {
      setCompanyList(res?.data);
    });
  }, []);

  const employeescount = ['0-3', '3-10', '10-25', '25-50', '50-100', '100-250', '250+'];

  return (
    <AuthWrapper variant={'signup'}>
      {isFetching && (
        <div>
          <PageLoader />
        </div>
      )}
      <SnackBarComponent
        status={responseStatus}
        visible={response}
        setResponse={setResponse}
        message={responseMessage}
        setResponseStatus={setResponseStatus}
        setResponseMessage={setResponseMessage}
      />
      <Box className={classes.authContent}>
        <Typography component="div" variant="h1" className={classes.titleRoot}>
          <IntlMessages id="registration.companydetails" />
        </Typography>
        <form
          onSubmit={e => {
            Register_step3();
            e.preventDefault();
          }}>
          <Grid xs={12} md={12} lg={12}>
            <TextField
              label={<IntlMessages id="registration.desigination" />}
              fullWidth
              required
              multiline
              rows={5}
              // defaultValue={name}
              margin="normal"
              variant="outlined"
              className={classes.textFieldRoot}
              onChange={event => setdesignation(event.target.value)}
            />
          </Grid>

          <Grid md={12}>
            <TextField
              label={<IntlMessages id="registration.customers" />}
              fullWidth
              required
              multiline
              rows={5}
              // value={companyName}
              // defaultValue={com}
              margin="normal"
              variant="outlined"
              className={classes.textFieldRoot}
              onChange={event => setcompanyIdentityNumberFive(event.target.value)}
            />
          </Grid>
          <Grid md={12}>
            <TextField
              label={<IntlMessages id="registration.suppliers" />}
              fullWidth
              required
              multiline
              rows={5}
              // value={companyDescription}
              margin="normal"
              variant="outlined"
              className={classes.textFieldRoot}
              onChange={event => setcompanyIdentityNumberSix(event.target.value)}
            />
          </Grid>
          <Grid md={12}>
            <FormControl fullWidth style={{ marginTop: 20 }}>
              <InputLabel style={{ marginLeft: '10px' }} id="demo-simple-select-helper-label">
                <IntlMessages id="registration.companytype" />
              </InputLabel>
              <Select
                labelId="demo-simple-select-helper-label"
                id="demo-simple-select-helper"
                variant="outlined"
                required
                onChange={event => setborrowerCompanyTextVariable1(event.target.value)}
                value={borrowerCompanyTextVariable1}
                label="Age">
                {companyList?.map(item => {
                  return (
                    <MenuItem value={item.name}>
                      <em>{item.name}</em>
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
            <FormControl fullWidth style={{ marginTop: 20 }}>
              <InputLabel style={{ marginLeft: '10px' }} id="demo-simple-select-helper-label">
                <IntlMessages id="registration.employees" />
              </InputLabel>
              <Select
                labelId="demo-simple-select-helper-label"
                id="demo-simple-select-helper"
                variant="outlined"
                required
                onChange={event => setborrowerCompanyTextVariable2(event.target.value)}
                value={borrowerCompanyTextVariable2}
                label="Age">
                {employeescount?.map(item => {
                  return (
                    <MenuItem value={item}>
                      <em>{item}</em>
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Grid>
          <Grid
            style={{ marginTop: '10px' }}
            display="flex"
            flexDirection={{ xs: 'column', sm: 'row' }}
            alignItems={{ sm: 'center' }}
            justifyContent={{ sm: 'space-between' }}
            mb={3}>
            <Grid mb={{ xs: 2, sm: 0 }}>
              <Button variant="contained" color="primary" type="submit">
                <IntlMessages id="appModule.next" />
              </Button>
            </Grid>
          </Grid>
        </form>

        {/* <Box mb={3}>{dispatch(AuhMethods[method].getSocialMediaIcons())}</Box>

        <ContentLoader /> */}
      </Box>
    </AuthWrapper>
  );
};

export default SignupBorrowerBusinessDescription;
