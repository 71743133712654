import React, { cloneElement, isValidElement, useContext } from 'react';

import { NavLink } from 'react-router-dom';
import clsx from 'clsx';

import { List, ListItem, useMediaQuery, useTheme } from '@material-ui/core';
import makeStyles from '@material-ui/core/styles/makeStyles';
import SidebarThemeContext from '../../CmtLayouts/SidebarThemeContext/SidebarThemeContext';
// import { fetchingUserLogOutDetailsSuccess } from 'redux/actions/investorAllDetail';
import { history } from 'redux/store';
import { useDispatch } from 'react-redux';
import { fetchingUserLogOutDetailsSuccess } from 'redux/actions/loginFunction';
import LayoutContext from '@coremat/CmtLayouts/LayoutContext';
import { logoutFunction } from '../../../redux/actions/logoutFuntion';

const useStyles = makeStyles(theme => ({
  navMenuItem: {
    padding: '0 16px 0 0',
    position: 'relative',
    '.Cmt-miniLayout .Cmt-sidebar-content:not(:hover) &': {
      paddingLeft: 16,
    },
  },
  navMenuLink: {
    display: 'flex',
    alignItems: 'center',
    padding: '9px 16px 9px 32px',
    borderTopRightRadius: 30,
    borderBottomRightRadius: 30,
    color: props => props.sidebarTheme.textColor,
    '&:hover, &:focus': {
      color: props => props.sidebarTheme.textDarkColor,
      backgroundColor: props => props.sidebarTheme.navHoverBgColor,
      '& .Cmt-icon-root, & .Cmt-nav-text': {
        color: props => props.sidebarTheme.textDarkColor,
      },
    },
    '&.active': {
      color: props => props.sidebarTheme.textActiveColor,
      backgroundColor: props => props.sidebarTheme.navActiveBgColor,
      '& .Cmt-icon-root, & .Cmt-nav-text': {
        color: props => props.sidebarTheme.textActiveColor,
      },
      '&:hover, &:focus': {
        '& .Cmt-nav-text, & .Cmt-icon-root': {
          color: props => props.sidebarTheme.textActiveColor,
        },
      },
    },
    '.Cmt-miniLayout .Cmt-sidebar-content:not(:hover) &': {
      justifyContent: 'center',
      padding: 0,
      height: 40,
      width: 40,
      borderRadius: '50%',
      marginLeft: 4,
      marginRight: 4,
    },
  },
  navText: {
    flex: 1,
    fontSize: 14,
    '.Cmt-miniLayout .Cmt-sidebar-content:not(:hover) &': {
      display: 'none',
    },
  },
  iconRoot: {
    marginRight: 16,
    fontSize: 20,
    '.Cmt-miniLayout .Cmt-sidebar-content:not(:hover) &': {
      marginRight: 0,
    },
  },
}));
export const OnLogoutClick = dispatch => {
  let data = localStorage.getItem('userDetails');
  let userDetail = JSON.parse(data);
  // handlePopoverClose();
  dispatch(logoutFunction(userDetail.access_token)).then(res => {
    // console.log(res?.data, 'logoutres');

    if (res?.data?.responseAttr?.code == '200') {
      dispatch(fetchingUserLogOutDetailsSuccess(history));
      // localStorage.clear();
      localStorage.removeItem('userDetails');
      localStorage.removeItem('investorAllDetail');
      localStorage.removeItem('dash');
      localStorage.removeItem('pr');
      localStorage.removeItem('tp');
      localStorage.removeItem('of');
      localStorage.removeItem('loanUuid');
      return;
    }
  });
};
const NavMenuItem = props => {
  const { name, icon, link, islogout } = props;
  const { sidebarTheme } = useContext(SidebarThemeContext);
  const classes = useStyles({ sidebarTheme });
  const { isSidebarOpen, setSidebarOpen } = useContext(LayoutContext);
  const renderIcon = () => {
    if (icon && isValidElement(icon)) {
      return cloneElement(icon, {
        className: clsx(classes.iconRoot, 'Cmt-icon-root'),
      });
    }

    return null;
  };

  const [anchorEl, setAnchorEl] = React.useState(null);
  const dispatch = useDispatch();
  let data = localStorage.getItem('userDetails');
  let userDetail = JSON.parse(data);
  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const theme = useTheme();

  const veryWideScreen = useMediaQuery(theme.breakpoints.up('md'), {
    noSsr: true,
  });
  return (
    <List component="div" className={clsx(classes.navMenuItem, 'Cmt-nav-menu-item')}>
      {islogout !== true && link != 'logout' ? (
        <NavLink
          className={clsx(classes.navMenuLink, 'Cmt-nav-menu-link')}
          open={anchorEl}
          onClick={e => {
            if (!veryWideScreen) {
              setSidebarOpen(!isSidebarOpen);
            }
          }}
          to={!islogout && link}>
          {/* Display an icon if any */}
          {renderIcon()}
          <span className={clsx(classes.navText, 'Cmt-nav-text')}>{name}</span>
        </NavLink>
      ) : (
        <div onClick={() => OnLogoutClick(dispatch)} style={{ cursor: 'pointer' }}>
          <ListItem className={clsx(classes.navMenuLink, 'Cmt-nav-menu-link')}>
            {renderIcon()}
            <span className={clsx(classes.navText, 'Cmt-nav-text')}>{name}</span>
          </ListItem>
        </div>
      )}
    </List>
  );
};

export default NavMenuItem;
