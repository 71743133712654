import React from 'react';
import { List } from '@material-ui/core';
import NavMenuItem from './NavMenuItem';
import NavSection from './NavSection';
import NavCollapse from './NavCollapse';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { investorAllDetailFunction } from 'redux/actions/investorAllDetail';
import { useDispatch } from 'react-redux';
import { borrowerDashFunction } from 'redux/actions/borrower/borrowerDash';
import { logoutFunction } from 'redux/actions/logoutFuntion';

const useStyles = makeStyles(theme => ({
  sideNavMenu: {
    position: 'relative',
  },
}));

const CmtVertical = props => {
  const { menuItems } = props;
  const classes = useStyles();
  const dispatch = useDispatch();
  const checkSessionExpiry = () => {
    let user = localStorage.getItem('userDetails');
    let userDetail = JSON.parse(user);
    let languageCheck = localStorage.getItem('languageCheck');
    if (!languageCheck) {
      languageCheck = 'en';
    }

    try {
      const inputdata = userDetail.access_token;
      let userType = String(userDetail?.userType).toLowerCase();
      // console.log(inputdata, 'alldetailres');
      if (userType === 'borrower') {
        dispatch(borrowerDashFunction(inputdata)).then(res => {
          console.log(res, 'alldetailres');
          if (res == undefined) {
            dispatch(logoutFunction(inputdata));
            localStorage.setItem('sessionExpired', 'TRUE');
            localStorage.removeItem('userDetails');
            localStorage.removeItem('investorAllDetail');
            localStorage.removeItem('dash');
            localStorage.removeItem('pr');
            localStorage.removeItem('tp');
            localStorage.removeItem('of');
            localStorage.removeItem('loanUuid');
            window.open('/signin?lang=' + `${languageCheck}`, '_self');
            return;
          }
        });
      } else {
        dispatch(investorAllDetailFunction(inputdata)).then(res => {
          // console.log(res, 'alldetailres');
          if (res == undefined) {
            dispatch(logoutFunction(inputdata));
            localStorage.setItem('sessionExpired', 'TRUE');
            localStorage.removeItem('userDetails');
            localStorage.removeItem('investorAllDetail');
            localStorage.removeItem('dash');
            localStorage.removeItem('pr');
            localStorage.removeItem('tp');
            localStorage.removeItem('of');
            localStorage.removeItem('loanUuid');
            window.open('/signin?lang=' + `${languageCheck}`, '_self');
            return;
          }
        });
      }
    } catch (error) {
      console.log(error, '');
    }
  };
  return (
    <List component="nav" disablePadding className={classes.sideNavMenu}>
      <div onClick={() => checkSessionExpiry()}>
        {menuItems.map((item, index) => {
          switch (item.type) {
            case 'section':
              return <NavSection {...item} key={index} />;
            case 'collapse':
              return <NavCollapse {...item} key={index} />;
            case 'item':
              return <NavMenuItem {...item} key={index} />;
            default:
              return null;
          }
        })}
      </div>
    </List>
  );
};

export default CmtVertical;
