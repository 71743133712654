import React, { useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  fade,
  Button,
  Input,
  TextField,
  Paper,
  Grid,
} from '@material-ui/core';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Typography from '@material-ui/core/Typography';
import { CurrentAuthMethod } from '@jumbo/constants/AppConstants';
import IntlMessages from '@jumbo/utils/IntlMessages';
import ContentLoader from '@jumbo/components/ContentLoader';
import AuthWrapper from '@jumbo/components/Common/authComponents/AuthWrapper';
import { AuhMethods } from 'services/auth';
import { Checkbox } from '@material-ui/core';
import { Url } from '../../../redux/NetworkConfig/ApiUrlConstatnts';
import Axios from 'axios';
import { history } from 'redux/store';
import AttachmentIcon from '@material-ui/icons/Attachment';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { declarationFileUploadFunction } from '../../../redux/actions/declarationFIleUpload';
import PageLoader from '../../../@jumbo/components/PageComponents/PageLoader';
import colors from 'theme/Color';
import Swal from 'sweetalert2';
import Loader from 'routes/Components/pageLoaderCustom/loader';
import { declarationFileUploadBorrowerFunction } from 'redux/actions/declarationFileUploadBorrower';
import SnackBarComponent from 'routes/Components/SnackBar';
import { websiteUrl } from '@jumbo/components/Header/Header';
const useStyles = makeStyles(theme => ({
  authThumb: {
    backgroundColor: fade(theme.palette.primary.main, 0.12),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: 20,
    [theme.breakpoints.up('md')]: {
      width: '50%',
      order: 2,
    },
  },
  authContent: {
    padding: 30,
    [theme.breakpoints.up('md')]: {
      width: props => (props.variant === 'default' ? '70%' : '100%'),
      order: 1,
    },
    [theme.breakpoints.up('xl')]: {
      padding: 50,
    },
  },
  titleRoot: {
    // marginBottom: 14,
    color: theme.palette.text.primary,
  },
  textFieldRoot: {
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: fade(theme.palette.common.dark, 0.12),
    },
  },
  textCapital: {
    textTransform: 'capitalize',
  },
  textAcc: {
    textAlign: 'center',
    '& a': {
      marginLeft: 4,
    },
  },
  alrTextRoot: {
    textAlign: 'center',
    [theme.breakpoints.up('sm')]: {
      textAlign: 'right',
    },
  },
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
}));

//variant = 'default', 'standard', 'bgColor'
const SignUpBorrowerDocs = ({ method = CurrentAuthMethod, variant = 'standard', wrapperVariant = 'default' }) => {
  const [checkedOne, setCheckedOne] = React.useState(false);
  const [checkedTwo, setCheckedTwo] = React.useState(false);
  const [checkedThree, setCheckedThree] = React.useState(false);
  const [checkedFour, setCheckedFour] = React.useState(false);
  const [checkedFive, setCheckedFive] = React.useState(false);
  const [terms, setTerms] = React.useState(false);
  const [declarationFile, setDeclarationFile] = useState(null);
  const [declarationFileShow, setDeclarationFileShow] = useState(true);
  const [fileLoading, setFileLoading] = useState(false);
  const [commercialRegistrationFile, setCommercialRegistrationFile] = useState(null);
  const [commercialRegistrationFileShow, setCommercialRegistrationFileShow] = useState(true);
  const [articlesOfAssociationFile, setArticlesOfAssociationFile] = useState(null);
  const [articlesOfAssociationShow, setArticlesOfAssociationShow] = useState(true);
  const [boardResolutionFile, setBoardResolutionFile] = useState(null);
  const [boardResolutionShow, setBoardResolutionShow] = useState(true);
  const [response, setResponse] = useState(false);
  const [responseMessage, setResponseMessage] = useState('');
  const [responseStatus, setResponseStatus] = useState('');
  const [file2, setFile2] = useState(null);
  const [file3, setFile3] = useState(null);
  const [file4, setFile4] = useState(null);
  const dispatch = useDispatch();
  const classes = useStyles({ variant });
  const [isFetching, setIsFetching] = useState(false);
  const [open, setOpen] = React.useState(false);
  let languageCheck = localStorage.getItem('languageCheck');
  if (!languageCheck) {
    languageCheck = 'en';
  }
  const onSubmit = () => {
    if (checkedOne && checkedTwo && terms) {
      let newUser = localStorage.getItem('newUser');
      const url = Url.baseUrl + Url.RegistrationBorrowerStepFiveDeclaration + `?access_token=${newUser}`;
      // console.log(url, 'borrowerurl');
      setIsFetching(true);

      Axios.post(url)
        .then(function(response) {
          // console.log(response);
          if (response.data.code == '200') {
            setIsFetching(false);

            // alert('Success Reigistration process completed you are redirected to login!');
            Swal.fire(
              'Success',
              languageCheck == 'en'
                ? 'Reigistration has been completed successfully you are going to be redirected to login page!'
                : 'اكتملت عملية التسجيل بنجاح سيُعاد توجيهك لصفحة تسجيل الدخول!',
              'success',
            );
            history.push('/');
            localStorage.clear();
          } else {
            setIsFetching(false);

            // alert('Failed', 'Error saving details', [{ text: 'Okay' }]);
            Swal.fire(
              'Error/خطأ',
              languageCheck == 'en' ? 'Error in saving the details' : 'حدث خطأ في حفظ البيانات',
              'error',
            );
          }
        })
        .catch(function(error) {
          // handle error
          setIsFetching(false);

          console.log(error);
          Swal.fire('Error/خطأ', error.message, 'info');
        });
    } else {
      setResponseMessage(<IntlMessages id="registration.terms" />);
      setResponse(true);
      setIsFetching(false);

      setResponseStatus('error');
    }
  };
  const declarationFileRef = useRef(null);
  const commercialregistrationFileRef = useRef(null);
  const articlesOfAssociationsFileRef = useRef(null);
  const boardResolutionRef = useRef(null);

  const registrationType = localStorage.getItem('registrationType');
  let isLoading = useSelector(state => state.registrationStepFiveDeclarationReducer.isFetching);
  return (
    <AuthWrapper variant={'stepfive'}>
      {isFetching && (
        <div>
          <PageLoader />
        </div>
      )}
      <SnackBarComponent
        status={responseStatus}
        visible={response}
        setResponse={setResponse}
        message={responseMessage}
        setResponseStatus={setResponseStatus}
        setResponseMessage={setResponseMessage}
      />
      <Box className={classes.authContent}>
        <Loader isLoading={isLoading} />
        <Typography component="div" variant="h1" className={classes.titleRoot}>
          <IntlMessages id="registration.declaration" />
        </Typography>
        {/* <div className={classes.root}>
          <Grid container spacing={3}>
            <Grid item xs={6} sm={3} md={6}>
              <Paper style={{ height: '97px' }} className={classes.paper}>
                <div style={{ alignItems: 'center', display: 'flex' }}>
                  <input
                    type="file"
                    id="file"
                    ref={declarationFileRef}
                    onChange={event => selectDeclaration(event.target.files[0])}
                    style={{ display: 'none' }}
                  />

                  <Typography component="div" variant="h6" className={classes.titleRoot}>
                    <IntlMessages id="registration.commercialregisration" />
                  </Typography>
                </div>
                {declarationFileShow ? (
                  <div>
                    <Button onClick={() => declarationFileChoose()}>
                      <AttachmentIcon fontSize="200px" />
                      {'  choose file'}
                    </Button>
                    <Typography component="div" variant="h6" className={classes.titleRoot}>
                      {declarationFile?.name}
                    </Typography>
                    {declarationFile && (
                      <Button style={{ marginLeft: '3px' }} onClick={() => uploadDeclarationFile()}>
                        <IntlMessages id="registration.upload" />
                      </Button>
                    )}
                  </div>
                ) : (
                  <>
                    <CheckCircleIcon color={colors.green} />
                    <Typography component="div" variant="h6" className={classes.titleRoot}>
                      <IntlMessages id="registration.statementuploaded" />
                    </Typography>
                  </>
                )}
              </Paper>
            </Grid>
            <Grid item xs={6} sm={3} md={6}>
              <Paper className={classes.paper}>
                <div style={{ alignItems: 'center', display: 'flex' }}>
                  <input
                    type="file"
                    id="file"
                    ref={declarationFileRef}
                    onChange={event => selectDeclaration(event.target.files[0])}
                    style={{ display: 'none' }}
                  />

                  <Typography component="div" variant="h6" className={classes.titleRoot}>
                    <IntlMessages id="registration.articleofassociation" />
                  </Typography>
                </div>
                {declarationFileShow ? (
                  <div>
                    <Button onClick={() => declarationFileChoose()}>
                      <AttachmentIcon fontSize="200px" />
                      {'  choose file'}
                    </Button>
                    <Typography component="div" variant="h6" className={classes.titleRoot}>
                      {declarationFile?.name}
                    </Typography>
                    {declarationFile && (
                      <Button style={{ marginLeft: '3px' }} onClick={() => uploadDeclarationFile()}>
                        <IntlMessages id="registration.upload" />
                      </Button>
                    )}
                  </div>
                ) : (
                  <>
                    <CheckCircleIcon color={colors.green} />
                    <Typography component="div" variant="h6" className={classes.titleRoot}>
                      <IntlMessages id="registration.statementuploaded" />
                    </Typography>
                  </>
                )}
              </Paper>
            </Grid>
          </Grid>
        </div> */}

        {fileLoading && <PageLoader />}
        <form>
          <Box mb={2}>
            <Typography style={{ textAlign: 'justify' }}>
              <Checkbox onClick={() => setCheckedOne(true)} value={'one'} />
              <IntlMessages id="registration.borrowerdeclaration1" />
            </Typography>
          </Box>
          <Box mb={2}>
            <Typography style={{ textAlign: 'justify' }}>
              <Checkbox onClick={() => setCheckedTwo(true)} value={'two'} />
              <IntlMessages id="registration.borrowerdeclaration2" />
            </Typography>
          </Box>
          <Box mb={2}>
            <Typography style={{ textAlign: 'justify' }}>
              <Checkbox onClick={() => setTerms(true)} value={'terms'} />
              <IntlMessages id="registration.click" />{' '}
              <a href={websiteUrl + 'Terms'} target="_blank">
                <IntlMessages id="registration.conditions" />
              </a>
            </Typography>
          </Box>

          <Box
            display="flex"
            flexDirection={{ xs: 'column', sm: 'row' }}
            alignItems={{ sm: 'center' }}
            justifyContent={{ sm: 'space-between' }}
            mb={3}>
            <Box mb={{ xs: 2, sm: 0 }}>
              <Button onClick={onSubmit} variant="contained" color="primary">
                <IntlMessages id="appModule.submit" />
              </Button>
            </Box>
          </Box>
        </form>

        <Box mb={3}>{dispatch(AuhMethods[method].getSocialMediaIcons())}</Box>

        <ContentLoader />
      </Box>
    </AuthWrapper>
  );
};

export default SignUpBorrowerDocs;
