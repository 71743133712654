import * as types from '../actionTypes/types';
import { httpRequest } from '../NetworkConfig/Config';
import { Url, methods, headers } from '../NetworkConfig/ApiUrlConstatnts';
export const citizenAddressInfoInvestorFunction = inpuData => {
  return async dispatch => {
    dispatch(fetchingCitizenAddressInfoInvestorRequest());
    return httpRequest({
      method: methods.post,
      url: Url.GetCitizenAddressInfo,
      headers: headers.headFinancing,
      data: JSON.stringify(inpuData),
    }).then(JsonResponse => {
      if (JsonResponse !== undefined) {
        if (JsonResponse.status == 200 || JsonResponse.status == 203) {
          let fetchedData =
            JsonResponse.data !== undefined && JsonResponse.data !== null && Object.keys(JsonResponse.data).length !== 0
              ? JsonResponse.data
              : {};
          dispatch(fetchingCitizenAddressInfoInvestorSuccess(fetchedData));
        } else {
          dispatch(fetchingCitizenAddressInfoInvestorFailed());
        }
        return JsonResponse;
      } else {
        dispatch(fetchingCitizenAddressInfoInvestorFailed());
      }
    });
  };
};
export const fetchingCitizenAddressInfoInvestorRequest = () => ({
  type: types.FETCHING_CITIZEN_ADDRESS_INFO_REQUEST,
});
export const fetchingCitizenAddressInfoInvestorSuccess = json => ({
  type: types.FETCHING_CITIZEN_ADDRESS_INFO_SUCCESS,
  payload: json,
});
export const fetchingCitizenAddressInfoInvestorFailed = error => ({
  type: types.FETCHING_CITIZEN_ADDRESS_INFO_FAILED,
  payload: error,
});
export const fetchingUserLogOutDetailsSuccess = () => ({
  type: types.FETCHING_USER_LOGOUT_DETAILS_SUCCESS,
});
