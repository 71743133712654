import Footer from '@jumbo/components/Footer/Footer';
import FooterTop from '@jumbo/components/Footer/FooterTop';
import Header from '@jumbo/components/Header/Header';
import React from 'react';
import SignupStage from 'routes/ExtraPages/sign-up/SignupStage';

const RegisterStage = () => {
  return (
    <div style={{ flex: 1 }}>
      <Header />
      <SignupStage variant="standard" wrapperVariant="bgColor" />
      <FooterTop />
      <Footer />
    </div>
  );
};

export default RegisterStage;
