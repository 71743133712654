const logo = theme => ({
  logo: {
    [theme.breakpoints.up('xl')]: {
      width: '100%',
      height: '60px',
      position: 'relative',
    },
    [theme.breakpoints.down('lg')]: {
      width: '100%',
      height: '60px',
      position: 'relative',
    },
    [theme.breakpoints.down('md')]: {
      width: '100%',
      height: '60px',
      position: 'relative',
    },
    [theme.breakpoints.down('sm')]: {
      width: 'auto',
      height: '60px',
      position: 'relative',
    },
  },
});

export default logo;
