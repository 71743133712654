import Activation from '@jumbo/components/Common/authComponents/Activation';
import Footer from '@jumbo/components/Footer/Footer';
import FooterTop from '@jumbo/components/Footer/FooterTop';
import Header from '@jumbo/components/Header/Header';
import React from 'react';

const ActivationPage = () => {
  return (
    <div style={{ flex: 1 }}>
      <Header />
      <Activation variant="standard" wrapperVariant="bgColor" />
      <FooterTop />
      <Footer />
    </div>
  );
};

export default ActivationPage;
