import * as types from '../actionTypes/types';
import { httpRequest } from '../NetworkConfig/Config';
import { Url, methods, headers } from '../NetworkConfig/ApiUrlConstatnts';
export const logoutFunction = inpuData => {
  return async dispatch => {
    dispatch(fetchingUserLogoutDetailsRequest());
    return httpRequest({
      method: methods.post,
      url: Url.baseUrl + Url.LogOutURL + '?access_token=' + inpuData,
    }).then(JsonResponse => {
      // console.log(JsonResponse, 'logout details');
      if (JsonResponse !== undefined) {
        if (JsonResponse.status == 200 || JsonResponse.status == 203) {
          let fetchedData =
            JsonResponse.data !== undefined && JsonResponse.data !== null && Object.keys(JsonResponse.data).length !== 0
              ? JsonResponse.data
              : {};
          dispatch(fetchingUserLogoutDetailsSuccess(fetchedData));
        } else {
          dispatch(fetchingUserLogoutDetailsFailed());
        }
        return JsonResponse;
      } else {
        dispatch(fetchingUserLogoutDetailsFailed());
      }
    });
  };
};
export const fetchingUserLogoutDetailsRequest = () => ({
  type: types.FETCHING_USER_LOGOUT_DETAILS_REQUEST,
});
export const fetchingUserLogoutDetailsSuccess = json => ({
  type: types.FETCHING_USER_LOGOUT_DETAILS_SUCCESS,
  payload: json,
});
export const fetchingUserLogoutDetailsFailed = error => ({
  type: types.FETCHING_USER_LOGOUT_DETAILS_FAILED,
  payload: error,
});

// export const LogOutFunction = inpuData => {
//   return async dispatch => {
//     dispatch(fetchingUserLogOutDetailsRequest());
//     return httpRequest({
//       method: methods.post,
//       url: Url.LogOutURL,
//       headers: headers.head,
//       data: inpuData,
//     }).then(JsonResponse => {
//       if (JsonResponse !== undefined) {
//         if (JsonResponse.status == 200 || JsonResponse.status == 203) {
//           let fetchedData =
//             JsonResponse.data !== undefined &&
//             JsonResponse.data !== null &&
//             Object.keys(JsonResponse.data).length !== 0
//               ? JsonResponse.data
//               : {};
//           dispatch(fetchingUserLogOutDetailsSuccess(fetchedData));

//         } else {
//           dispatch(fetchingUserLogOutDetailsFailed());
//         }
//         return JsonResponse;
//       } else {
//         dispatch(fetchingUserLogOutDetailsFailed());
//       }
//     });
//   };
// };
// export const fetchingUserLogOutDetailsRequest = () => ({
//   type: types.FETCHING_USER_LOGOUT_DETAILS_REQUEST,
// });
export const fetchingUserLogOutDetailsSuccess = () => ({
  type: types.FETCHING_USER_LOGOUT_DETAILS_SUCCESS,
});
// export const fetchingUserLogOutDetailsFailed = (error) => ({
//   type: types.FETCHING_USER_LOGOUT_DETAILS_FAILED,
//   payload: error,
// });
