import * as types from '../actionTypes/types';

const initialState = {
  receivables: {},
  year: '',
  isFetching: false,
  errorMessage: '',
};
const receivablesReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.FETCHING_RECEIVABLES_REQUEST:
      return { ...state, isFetching: true };
    case types.FETCHING_RECEIVABLES_FAILED:
      return {
        ...state,
        isFetching: false,
        errorMessage: action.payload,
      };
    case types.FETCHING_RECEIVABLES_SUCCESS:
      return {
        ...state,
        isFetching: false,
        receivables: action.payload,
        year: action.year,
      };
    case types.FETCHING_USER_LOGOUT_DETAILS_SUCCESS:
      state = initialState;
      return state;
    default:
      return state;
  }
};
export default receivablesReducer;
