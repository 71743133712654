import * as types from '../actionTypes/types';
import { httpRequest } from '../NetworkConfig/Config';
import { Url, methods, headers } from '../NetworkConfig/ApiUrlConstatnts';
export const companyRegistrationCheckFunction = inpuData => {
  // console.log(inpuData.registrationNumber);
  return async dispatch => {
    dispatch(fetchingCompanyRegistrationCheckRequest());
    return httpRequest({
      method: methods.get,
      url: Url.CompanyRegistrationCheck + '/' + inpuData.registrationNumber,
      headers: headers.headWathq,
    }).then(JsonResponse => {
      if (JsonResponse != undefined) {
        if (JsonResponse.status == 200 || JsonResponse.status == 203) {
          let fetchedData =
            JsonResponse.data != undefined && JsonResponse.data != null && Object.keys(JsonResponse.data).length != 0
              ? JsonResponse.data
              : {};
          dispatch(fetchingCompanyRegistrationCheckSuccess(fetchedData));
        } else {
          dispatch(fetchingCompanyRegistrationCheckFailed());
        }
        return JsonResponse;
      } else {
        dispatch(fetchingCompanyRegistrationCheckFailed());
      }
    });
  };
};
export const fetchingCompanyRegistrationCheckRequest = () => ({
  type: types.FETCHING_COMAPANY_REGISTRATION_CHECK_REQUEST,
});
export const fetchingCompanyRegistrationCheckSuccess = json => ({
  type: types.FETCHING_COMAPANY_REGISTRATION_CHECK_SUCCESS,
  payload: json,
});
export const fetchingCompanyRegistrationCheckFailed = error => ({
  type: types.FETCHING_COMAPANY_REGISTRATION_CHECK_FAILED,
  payload: error,
});
export const fetchingUserLogOutDetailsSuccess = () => ({
  type: types.FETCHING_USER_LOGOUT_DETAILS_SUCCESS,
});
