import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Box, CardContent, fade, FormControl, FormHelperText, Grid, InputLabel, MenuItem, Select } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Typography from '@material-ui/core/Typography';
import { NavLink } from 'react-router-dom';
import { CurrentAuthMethod } from '@jumbo/constants/AppConstants';
import IntlMessages from '@jumbo/utils/IntlMessages';
import ContentLoader from '@jumbo/components/ContentLoader';
import AuthWrapper from '@jumbo/components/Common/authComponents/AuthWrapper';
import CmtImage from '@coremat/CmtImage';
import { AuhMethods } from 'services/auth';
import { history } from 'redux/store';
import { registrationStepThreeEmployeementFunction } from 'redux/actions/registrationStepThreeEmployeement';
import Alert from 'routes/Components/MuiComponents/Alert';
import { Url } from 'redux/NetworkConfig/ApiUrlConstatnts';
import Axios from 'axios';
import { Dialog, DialogActions, DialogContent, DialogContentText } from '@material-ui/core';
import GridContainer from '@jumbo/components/GridContainer';
import CmtCard from '@coremat/CmtCard';
import CmtCardHeader from '@coremat/CmtCard/CmtCardHeader';
import CmtCardContent from '@coremat/CmtCard/CmtCardContent';

const useStyles = makeStyles(theme => ({
  authThumb: {
    backgroundColor: fade(theme.palette.primary.main, 0.12),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: 20,
    [theme.breakpoints.up('md')]: {
      width: '50%',
      order: 2,
    },
  },
  authContent: {
    padding: 30,
    [theme.breakpoints.up('md')]: {
      width: props => (props.variant === 'default' ? '50%' : '100%'),
      order: 1,
    },
    [theme.breakpoints.up('xl')]: {
      padding: 50,
    },
  },
  titleRoot: {
    marginBottom: 14,
    color: theme.palette.text.primary,
  },
  textFieldRoot: {
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: fade(theme.palette.common.dark, 0.12),
    },
  },
  textCapital: {
    textTransform: 'capitalize',
  },
  textAcc: {
    textAlign: 'center',
    '& a': {
      marginLeft: 4,
    },
  },
  alrTextRoot: {
    textAlign: 'center',
    [theme.breakpoints.up('sm')]: {
      textAlign: 'right',
    },
  },
}));

//variant = 'default', 'standard', 'bgColor'
const SignupStage = ({ method = CurrentAuthMethod, variant = 'standard', wrapperVariant = 'default' }) => {
  return (
    <AuthWrapper variant={wrapperVariant}>
      <GridContainer>
        <Grid item xs={12} md={12} lg={12}>
          <CmtCard>
            <CardContent>
              <CmtCardHeader alignCenter title={<IntlMessages id={'registration.getstarted'} />} />
              <Box>
                <Grid item xs={12} md={12} lg={12}>
                  <Button variant="contained" fullWidth color="primary">
                    <NavLink onClick={() => localStorage.setItem('registrationType', 'individual')} to="/signup">
                      <IntlMessages id="registration.individuallender" />
                    </NavLink>
                  </Button>
                </Grid>
                <CardContent></CardContent>
                <Grid item xs={12} md={12} lg={12}>
                  <Button variant="contained" fullWidth color="primary">
                    <NavLink onClick={() => localStorage.setItem('registrationType', 'company')} to="/signup">
                      <IntlMessages id="registration.institutionallender" />
                    </NavLink>
                  </Button>
                </Grid>
              </Box>
            </CardContent>
          </CmtCard>
        </Grid>
      </GridContainer>
    </AuthWrapper>
  );
};

export default SignupStage;
