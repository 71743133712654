import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Box,
  DialogTitle,
  fade,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Snackbar,
  SnackbarContent,
} from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Typography from '@material-ui/core/Typography';
import { NavLink } from 'react-router-dom';
import { CurrentAuthMethod } from '@jumbo/constants/AppConstants';
import IntlMessages from '@jumbo/utils/IntlMessages';
import ContentLoader from '@jumbo/components/ContentLoader';
import AuthWrapper from '@jumbo/components/Common/authComponents/AuthWrapper';
import CmtImage from '@coremat/CmtImage';
import { AuhMethods } from 'services/auth';
import { history } from 'redux/store';
import Axios from 'axios';
import { headers, Url } from '../../../redux/NetworkConfig/ApiUrlConstatnts';
import { datePicker } from '../../../@jumbo/utils/dateHelper';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormLabel from '@material-ui/core/FormLabel';
import { citizenInfoInvestorFunction } from '../../../redux/actions/citizenInfoInvestor';
import { citiesListFunction } from '../../../redux/actions/citiesList';
import { Dialog, DialogActions, DialogContent, DialogContentText } from '@material-ui/core';
import { registrationStepTwoIndividualFunction } from '../../../redux/actions/registrationStepTwoIndividual';
import HijriDate, { toHijri } from 'hijri-date/lib/safe';
import Swal from 'sweetalert2';
import Loader from 'routes/Components/pageLoaderCustom/loader';
import { investorMandatoryFunction } from '../../../redux/actions/investorMandotaryFields';
import { GetAlienInfoFunction } from 'redux/actions/getAlienInfo';
import { rayahSendOtpFunction } from 'redux/actions/rayahOtpSend';
import { rayahVerifyOtpFunction } from 'redux/actions/rayahOtpVerify';
import { citizenAddressInfoInvestorFunction } from 'redux/actions/citizenAddressInfoInvestor';
import { alienAddressInfoFunction } from 'redux/actions/alienAddressInfo';
import { Alert } from '@material-ui/lab';
import GridContainer from '@jumbo/components/GridContainer';
import { registrationStepOneBasicFunction } from 'redux/actions/registrationStepOneBasic';
import SnackBar from 'routes/Components/SnackBar';
import SnackBarComponent from 'routes/Components/SnackBar';
import moment from 'moment';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import colors from 'theme/Color';
var momentHijri = require('moment-hijri');

const useStyles = makeStyles(theme => ({
  authThumb: {
    backgroundColor: fade(theme.palette.primary.main, 0.12),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: 20,
    [theme.breakpoints.up('md')]: {
      width: '50%',
      order: 2,
    },
  },
  authContent: {
    padding: 30,
    [theme.breakpoints.up('md')]: {
      width: props => (props.variant === 'default' ? '50%' : '100%'),
      order: 1,
    },
    [theme.breakpoints.up('xl')]: {
      padding: 50,
    },
  },
  titleRoot: {
    marginBottom: 14,
    color: theme.palette.text.primary,
  },
  title: {
    fontSize: 14,
  },
  textFieldRoot: {
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: fade(theme.palette.common.dark, 0.12),
    },
  },
  textCapital: {
    textTransform: 'capitalize',
  },
  textAcc: {
    textAlign: 'center',
    '& a': {
      marginLeft: 4,
    },
  },
  alrTextRoot: {
    textAlign: 'center',
    [theme.breakpoints.up('sm')]: {
      textAlign: 'right',
    },
  },
  decHeader: {
    textAlign: 'center',
    fontWeight: 'bold',
    fontSize: '18px',
    color: colors.gray,
    borderTopColor: colors.gray,
    borderTopWidth: 0.5,
    paddingTop: 20,
    paddingBottom: 10,
  },
  decText: {
    textAlign: 'justify',
    fontWeight: '300',
    paddingHorizontal: 5,
    fontSize: '12px',
    // lineHeight: 2,
    paddingBottom: 10,
    color: colors.black,
  },
}));

//variant = 'default', 'standard', 'bgColor'
const SignUpTwo = ({ method = CurrentAuthMethod, variant = 'standard', wrapperVariant = 'default' }) => {
  // useEffect(() => {
  //   let date = momentHijri('1389/07/01', 'iYYYY/iMM/iDD').format('DD/MM/YYYY');
  //   console.log(date, 'working');
  // }, []);
  const dispatch = useDispatch();
  const classes = useStyles({ variant });
  const [otpModal, setOtpModal] = useState(false);
  const [showsnakbar, setshowsnakbar] = useState(false);
  const [otp, setOtp] = useState('');
  const lenderCategoryArr = [
    {
      id: 15,
      name: 'Individual Financer / ممول فردي ',
      uuid: 'eeba1b3a-57cc-4656-88c8-df2cb9edf9c0',
      type: 'INDIVIDUAL',
    },
    {
      id: 16,
      name: 'Qualified Individual Financer / ممول فردي مؤهل',
      uuid: '72f981d0-b1b5-49ac-ab5e-b01faa651bc9',
      type: 'INDIVIDUAL',
    },
  ];
  const [iqama, setIqama] = useState('');
  const [showAll, setShowAll] = useState(false);
  const [showAllone, setShowAllone] = useState(false);
  const [dateType, setDateType] = useState('');
  const [nameArabic, setNameArabic] = useState('');
  const [nameEnglish, setNameEnglish] = useState('');
  const [dateDay, setDateDay] = useState('');
  const [dateMonth, setDateMonth] = useState('');
  const [dateYear, setDateYear] = useState('');
  const [nationality, setNationality] = useState('');
  const [lenderCategory, setLenderCategory] = useState(lenderCategoryArr[0].uuid);
  const [expiryDate, setExpiryDate] = useState('');
  const [line1, setLine1] = useState('');
  const [line2, setLine2] = useState('');
  const [line3, setLine3] = useState('');
  const [city, setCity] = useState('');
  const [zipCode, setZipCode] = useState('');
  const [cityArray, setCityArray] = useState([]);
  const [status, setStatus] = useState('');
  const [message, setMessage] = useState('');
  const [code, setCode] = useState('');
  const [mandatoryFields, setmandatoryFields] = useState({});
  const [showFields, setShowFields] = useState(false);
  const [firstName, setfirstName] = useState('');
  const [grossIncome, setgrossIncome] = useState('');
  const [amountToInvest, setamountToInvest] = useState('');
  const [investmentPurpose, setinvestmentPurpose] = useState('');
  const [interestRate, setinterestRate] = useState('');
  const [durationForInvest, setdurationForInvest] = useState('');
  const [employmentCategory, setemploymentCategory] = useState('');
  const [employerType, setemployerType] = useState('');
  const [educationType, seteducationType] = useState('');
  const [residenceType, setresidenceType] = useState('');
  const [residentOwnershipType, setresidentOwnershipType] = useState('');
  const [passportNumber, setpassportNumber] = useState('');
  const [homeExpenses, sethomeExpenses] = useState('');
  const [transportationExpenses, settransportationExpenses] = useState('');
  const [utilitiesExpenses, setutilitiesExpenses] = useState('');
  const [entertainmentExpenses, setentertainmentExpenses] = useState('');
  const [insuranceExpenses, setinsuranceExpenses] = useState('');
  const [otherExpenses, setotherExpenses] = useState('');
  const [creditCardExpenses, setcreditCardExpenses] = useState('');
  const [houseLoanExpenses, sethouseLoanExpenses] = useState('');
  const [citizenship, setcitizenship] = useState('');
  const [middleName, setmiddleName] = useState('');
  const [dateOfBirth, setdateOfBirth] = useState('');
  const [investorProfileDateVariable1, setinvestorProfileDateVariable1] = useState('');
  const [investorProfileTextVariable2, setinvestorProfileTextVariable2] = useState('');
  const [investorCategory, setinvestorCategory] = useState('');
  const [showAlert, setShowAlert] = useState(false);
  const [citizenShipArray, setCitizenShipArray] = useState([]);
  const [response, setResponse] = useState(false);
  const [responseMessage, setResponseMessage] = useState('');
  const [responseStatus, setResponseStatus] = useState('');
  const [categoryModal, setCategoryModal] = useState(false);
  const setHijriOrGregorian = val => {
    const value = val.slice(0, 1);
    // console.log(value, 'datetype');
    if (value == 1) {
      setDateType('hijri');
    } else {
      setDateType('gregorian');
    }
  };

  const fetchCitizenship = async () => {
    const url = Url.baseUrl + Url.CitizenShip;

    Axios.post(url)
      .then(function(response) {
        // handle success
        setCitizenShipArray(response.data);
        // console.log(response.data);
      })
      .catch(function(error) {
        alert(error);
      });
  };
  let languageCheck = localStorage.getItem('languageCheck');
  if (!languageCheck) {
    languageCheck = 'en';
  }
  // const dataAppend = mandatoryFields => {
  //   let append = '';
  //   console.log(Object.keys(mandatoryFields).length);
  //   for (const property in mandatoryFields) {
  //     append +=
  //       `const [set${mandatoryFields[property].variableName}, setset${mandatoryFields[property].variableName}] = useState('');` +
  //       '\n';
  //   }
  //   console.log(append, 'append');
  //   // return append;
  // };
  useEffect(() => {
    dispatch(investorMandatoryFunction(languageCheck)).then(res => {
      setmandatoryFields(res?.data);
      // dataAppend(res?.data);
      setShowFields(true);
    });
  }, [languageCheck]);
  useEffect(() => {
    fetchCitizenship();
    dispatch(citiesListFunction()).then(res => {
      setCityArray(res.data);
    });
  }, []);
  const [fetchingUser, setFetchingUser] = useState(false);
  const citizenInfo = async () => {
    setFetchingUser(true);
    let newUser = localStorage.getItem('newUser');
    const hijri = require('hijri');

    let dateInput = '';
    let dayGreg = dateYear + dateMonth + dateDay;
    let dateArabic = dateYear + dateMonth + dateDay;
    if (dateType === 'hijri') {
      dateInput = dateArabic;
    } else {
      dateInput = dayGreg;
    }
    let inputData = '';
    let url = '';

    if (dateType === 'hijri') {
      url = Url.baseUrl + Url.GetCitizenAddressInfo;
      inputData = {
        access_token: newUser,
        nin: iqama,
        dateOfBirthH: dateInput,
        addressLanguage: 'a',
      };
      // console.log(inputData, 'hijriaddress');
      dispatch(citizenAddressInfoInvestorFunction(inputData)).then(res => {
        if (res?.data?.Status === 0) {
          setFetchingUser(false);

          setResponseMessage(<IntlMessages id="input.error" />);
          setResponse(true);
          setResponseStatus(500);
          return;
        } else {
          setFetchingUser(false);

          let addressInfo = res.data;
          let Result = addressInfo?.Result;
          let Address = Result?.addressListList;
          let addressLine1 = Address[0]?.buildingNumber + ',' + Address[0]?.streetName;
          let addressLine2 = Address[0]?.unitNumber + '+' + Address[0].district;
          let city = Address[0]?.city;
          let zipCode = Address[0]?.postCode;
          setLine1(addressLine1);
          setLine2(addressLine2);
          setLine3(addressLine2);
          setCity(city);
          setZipCode(String(zipCode));
          setShowAll(true);
          setShowAllone(true);
        }
      });
    } else {
      url = Url.baseUrl + Url.GetAlienAddressInfo;
      inputData = {
        access_token: newUser,
        iqamaNumber: iqama,
        dateOfBirthG: dateInput,
        addressLanguage: 'e',
      };
      setFetchingUser(true);

      dispatch(alienAddressInfoFunction(inputData)).then(res => {
        // console.log(res.data, 'alienaddress');
        if (res?.data?.Status === 0) {
          setFetchingUser(false);

          setResponseMessage(<IntlMessages id="input.error" />);
          setResponse(true);
          setResponseStatus(500);
          return;
        } else {
          setFetchingUser(false);

          let addressInfo = res.data;
          let Result = addressInfo?.Result;
          let Address = Result?.addressListList;
          let addressLine1 = Address[0]?.buildingNumber + ',' + Address[0]?.streetName;
          let addressLine2 = Address[0]?.unitNumber + '+' + Address[0].district;
          let city = Address[0]?.city;
          let zipCode = Address[0]?.postCode;
          setLine1(addressLine1);
          setLine2(addressLine2);
          setLine3(addressLine2);
          setCity(city);
          setZipCode(String(zipCode));
          setShowAll(true);
          setShowAllone(true);
        }
      });
    }
    // console.log(inputData);

    const addressurl = url;
    // Axios.post(addressurl, JSON.stringify(inputData), headers.head)
    //   .then(async response => {
    //     // handle success
    //     console.log(response.data);
    //     return;
    //     let addressInfo = response.data;
    //     let Result = addressInfo?.Result;
    //     let Address = Result?.addressListList;
    //     let addressLine1 = Address[0]?.buildingNumber + ',' + Address[0]?.streetName;
    //     let addressLine2 = Address[0]?.unitNumber + '+' + Address[0].district;
    //     let city = Address[0]?.city;
    //     let zipCode = Address[0]?.postCode;
    //     setLine1(addressLine1);
    //     setLine2(addressLine2);
    //     setLine3('addressLine2');
    //     setCity(city);
    //     setZipCode(String(zipCode));
    //   })
    //   .catch(function(error) {
    //     // handle error
    //     alert(error);
    //   });
    let input = '';
    setFetchingUser(true);

    if (dateType === 'hijri') {
      input = {
        access_token: newUser,
        nin: iqama,
        dateOfBirthH: dateInput,
      };
      // console.log(input, 'hijriinput');
      dispatch(citizenInfoInvestorFunction(input)).then(res => {
        // console.log(res.data, 'citizeninfo');
        if (res?.data?.Status === 0) {
          setFetchingUser(false);

          setResponseMessage(<IntlMessages id="input.error" />);
          setResponse(true);
          setResponseStatus(500);
          return;
        } else {
          var dateValue = moment(res?.data?.Result?.idExpiryDate, 'DD-MM-YYYY');
          // console.log(dateValue);
          setFetchingUser(false);

          let formatDate = dateValue.format('DD/MM/YYYY');

          setExpiryDate(formatDate);
          setNationality('113');
          setNameArabic(
            res?.data?.Result?.firstName + ' ' + res?.data?.Result?.familyName + ' ' + res?.data?.Result?.fatherName,
          );
          setNameEnglish(
            res?.data?.Result?.englishFirstName +
              ' ' +
              res?.data?.Result?.englishSecondName +
              ' ' +
              res?.data.Result?.englishLastName,
          );
        }
      });
    } else {
      input = {
        access_token: newUser,
        iqamaNumber: iqama,
        dateOfBirthG: dateInput,
      };
      setFetchingUser(true);

      dispatch(GetAlienInfoFunction(input)).then(res => {
        // console.log(res?.data?.Result?.iqamaExpiryDateG);
        if (res?.data?.Status === 0) {
          setResponseMessage(<IntlMessages id="input.error" />);
          setResponse(true);
          setResponseStatus(500);
          setFetchingUser(false);

          return;
        } else {
          var dateValue = moment(res?.data?.Result?.iqamaExpiryDateG, 'DD-MM-YYYY');
          // console.log(dateValue);
          setFetchingUser(false);

          let formatDate = dateValue.format('DD/MM/YYYY');

          // console.log(formatDate);

          setExpiryDate(formatDate);
          setNameArabic(res?.data?.Result?.firstName + ' ' + res?.data?.Result?.lastName);
          setNationality(res?.data?.Result?.nationalityCode);
          setNameEnglish(
            res?.data?.Result?.englishFirstName +
              ' ' +
              res?.data?.Result?.englishSecondName +
              ' ' +
              res?.data?.Result?.englishLastName,
          );
        }
      });
    }
  };
  const onSubmit = () => {
    let dateInput = '';

    if (dateType === 'hijri') {
      let dateArabic = dateYear + '/' + dateMonth + '/' + dateDay;
      // console.log(dateArabic);
      let date = momentHijri(dateArabic, 'iYYYY/iMM/iDD').format('DD/MM/YYYY');

      dateInput = date;
    } else {
      let dateOther = dateDay + '/' + dateMonth + '/' + dateYear;
      dateInput = dateOther;
    }
    let idExpiryDate = '';
    if (dateType === 'hijri') {
      idExpiryDate = momentHijri(expiryDate, 'iDD/iMM/iYYYY').format('DD/MM/YYYY');
    } else {
      idExpiryDate = expiryDate;
    }
    let newUser = localStorage.getItem('newUser');
    // console.log(newUser, 'access_token');
    let inputData = {
      firstName: nameArabic,
      access_token: newUser,
      line1: line1,
      line2: line2,
      line3: line3,
      city: city,
      zipCode: zipCode,
      dateOfBirth: dateInput,
      investorCategory: lenderCategory,
      investorProfileDateVariable1: idExpiryDate,
      investorProfileTextVariable2: iqama,
      citizenship: nationality,
      investorProfileTextVariable3: nameEnglish,
      investorProfileTextVariable4: nameArabic,
      investorProfileTextVariable1: lenderCategory,
    };
    // console.log(inputData, 'steptwo');
    let fourthStepName =
      languageCheck == 'en' ? inputData.investorProfileTextVariable3 : inputData.investorProfileTextVariable4;
    localStorage.setItem('userName', fourthStepName);
    // return;

    dispatch(registrationStepTwoIndividualFunction(inputData)).then(res => {
      // console.log(res.data);
      if (res.data.code == '200') {
        setResponseMessage(res.data.message);
        setResponse(true);
        setResponseStatus(res.data.code);
        setTimeout(() => {
          localStorage.getItem('registrationType') === 'company'
            ? history.push('/signupcompany')
            : history.push('/signupthree');
        }, 2000);
        // Swal.fire({
        //   title: res.data.status,
        //   text: res.data.message,
        //   showDenyButton: false,
        //   showCancelButton: false,
        //   icon: 'success',
        //   confirmButtonText: 'Okay',
        // }).then(result => {
        //   /* Read more about isConfirmed, isDenied below */
        //   if (result.isConfirmed) {
        //     localStorage.getItem('registrationType') === 'company'
        //       ? history.push('/signupcompany')
        //       : history.push('/signupthree');
        //   } else if (result.isDenied) {
        //     return;
        //   }
        // });
      } else {
        if (splitKeyValue(res.data) == 'This ID/Iqama Number Is Already Exist') {
          let message = '';
          if (languageCheck == 'en') {
            message = 'This ID/Iqama Number Is Already Exist';
          } else {
            message = 'رقم الهوية / الإقامة موجود بالفعل';
          }
          setResponseMessage(splitKeyValue(message));
          setResponse(true);
          setResponseStatus(res.data.status);
          setTimeout(() => {
            localStorage.removeItem('investorStepOne');
            localStorage.removeItem('emailValidated');
            localStorage.removeItem('mobilevliadated');
            history.push('/signup');
          }, 2000);
        } else {
          setResponseMessage(splitKeyValue(res.data));
          setResponse(true);
          setResponseStatus(res.data.status);
        }

        // setResponseMessage(res.data.investorCategory);
        // setResponse(true);
        // setResponseStatus(res.data.code);
        // Swal.fire({
        //   title: res.data.status,
        //   text: res.data.investorCategory,
        //   icon: 'info',
        //   confirmButtonText: 'Okay',
        // });
      }
    });
    // dispatch(AuhMethods[method].onRegister({ name, email, password }));
    // history.push('/signupthree');
  };
  const [iqamaValidated, setIqamaValidated] = useState(false);
  const checkMobileLinkedWithId = () => {
    let stepOneData = localStorage.getItem('investorStepOne');
    let finalData = JSON.parse(stepOneData);
    const url = 'https://raqamyah.net:5000/yakeen/IDandMobileNoVerification/';
    // console.log(url);
    var datas = { iqama: iqama, mobile: '966' + finalData.contact };

    var config = {
      method: 'post',
      url: url,
      data: datas,
    };
    setFetchingUser(true);
    Axios(config)
      .then(function(response) {
        // console.log(response.data, 'response');
        if (response.data.isOwner === true) {
          // console.log(finalData, 'finaldata');
          setResponseMessage(<IntlMessages id="iqama.validation" />);
          setResponse(true);
          setResponseStatus('200');
          setFetchingUser(false);

          // return;
          setIqamaValidated(true);
          dispatch(registrationStepOneBasicFunction(finalData)).then(res => {
            // console.log(res.data);
            if (res.data.code === '200') {
              setResponseMessage(res.data.message);
              setResponse(true);

              setResponseStatus('200');
              // localStorage.removeItem('investorStepOne');
              localStorage.removeItem('emailValidated');
              localStorage.removeItem('mobilevliadated');
              localStorage.setItem('newUser', res.data.access_token);
              localStorage.setItem('userid', res.data.userId);
              checkValidId(res.data.access_token);
              // setTimeout(() => {
              //   history.push('/signuptwo');

              // }, 2000);
              // Swal.fire({
              //   title: res.data.status,
              //   text: res.data.message,
              //   showDenyButton: false,
              //   showCancelButton: false,
              //   icon: 'success',
              //   confirmButtonText: 'Okay',
              // }).then(result => {
              //   /* Read more about isConfirmed, isDenied below */
              //   if (result.isConfirmed) {
              //     history.push('/signuptwo');
              //   } else if (result.isDenied) {
              //     return;
              //   }
              // });
              // localStorage.setItem('newUser', res.data.access_token);
            } else {
              setResponseMessage(res.data.message);
              setResponse(true);
              setFetchingUser(false);

              setResponseStatus(res.data.code);
              setTimeout(() => {
                history.push('/signup');
              }, 2000);
              // Swal.fire({
              //   title: res.data.status,
              //   text: res.data.message,
              //   icon: 'info',
              //   confirmButtonText: 'Okay',
              // });
            }
          });
        } else {
          setFetchingUser(false);

          Swal.fire({
            title: 'Error/خطأ',
            text:
              languageCheck == 'en'
                ? 'Mobile and ID/ Iqama are not linked please enter linked mobile number'
                : 'رقم الجوال المدخل ليس مسجل بإسم صاحب هذه الهوية الرجاء ادخال رقم جوال مسجل بإسم صاحب الهوية المدخلة',
            showDenyButton: false,
            showCancelButton: false,
            icon: 'failed',
            confirmButtonText: 'Continue/اضغط للاستمرار',
          }).then(result => {
            /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed) {
              history.push('/signup');
            } else if (result.isDenied) {
              return;
            }
          });
        }
      })
      .catch(function(error) {
        setFetchingUser(false);

        Swal.fire({
          title: 'Error/خطأ',
          text: languageCheck == 'en' ? 'Request Rejected. Please try again!' : 'طلب رفض. حاول مرة اخرى!',
          showDenyButton: false,
          showCancelButton: false,
          icon: 'failed',
          confirmButtonText: 'Okay',
        }).then(result => {
          /* Read more about isConfirmed, isDenied below */
          if (result.isConfirmed) {
            history.push('/signup');
          } else if (result.isDenied) {
            return;
          }
        });
      });
  };
  const splitKeyValue = obj => {
    const keys = Object.keys(obj);
    const res = [];
    // console.log(keys);
    for (let i = 0; i < keys.length; i++) {
      if (keys[i] !== 'code' && keys[i] !== 'status') return obj[keys[i]];
    }

    return res;
  };
  const checkValidId = access_token => {
    // let newUser = localStorage.getItem('newUser');
    let inputData = {
      access_token: access_token,
      recipientId: iqama,
    };
    setFetchingUser(true);

    // console.log(inputData);
    // const url = Url.baseUrl + Url.RayahSendOtp;
    dispatch(rayahSendOtpFunction(inputData)).then(res => {
      // console.log(res?.data?.message);
      if (res?.data?.status === '200') {
        setshowsnakbar(true);
        setOtpModal(true);
        setFetchingUser(false);
      } else {
        alert('Error');
        setFetchingUser(false);
      }
    });

    // Axios.post(url, JSON.stringify(inputData), headers.header)
    //   .then(function(response) {
    //     // handle success
    //     console.log(response);
    //     setOtpModal(true);
    //     if (response.data.status === '200') {
    //       setOtpModal(true);
    //     }
    //   })
    //   .catch(function(error) {
    //     alert(error);
    //   });
  };
  const verifyOtp = async () => {
    let newUser = localStorage.getItem('newUser');
    let otpDetail = {
      access_token: newUser,
      otp: otp,
    };
    // Axios.post(Url.baseUrl + Url.RayahVerifyOtp, otpDetail)
    //   .then(response => {
    //     if (response.data.status == '200') {
    //       // alert('otp verified successfully');
    //       Swal.fire('Success', 'OTP Verified Successfully', 'success');
    //       setOtp('');
    //     } else {
    //       // alert('otp verification failed');
    //       Swal.fire('Error', 'OTP Verification Failed', 'error');
    //       setOtp('');
    //     }
    //   })
    //   .catch(function(error) {
    //     // handle error
    //     alert(error.message);
    //   });
    setFetchingUser(false);

    dispatch(rayahVerifyOtpFunction(otpDetail)).then(res => {
      // console.log(res?.data?.message);
      if (res?.data?.status === '200') {
        setResponseMessage(<IntlMessages id="accountdetail.otp.verify" />);
        setResponse(true);
        setResponseStatus('200');
        setOtp('');
        // setShowAllone(true);
        setOtpModal(false);
        setIqamaValidated(true);
        setFetchingUser(false);
        setShowAll(true);
      } else {
        // Swal.fire('Error', 'OTP Verification Failed', 'error');
        setResponseMessage(<IntlMessages id="accountdetail.otp.verify.fail" />);
        setResponse(true);
        setResponseStatus('500');
        setOtp('');
        setOtpModal(false);
        setFetchingUser(false);
      }
    });
  };
  let isLoading = useSelector(state => state.registrationStepTwoIndividualReducer.isFetching);
  const [seconds, setSeconds] = useState(60);
  const [resendButton, setResendButton] = useState(true);
  const [resendButtonCount, setResendButtonCount] = useState(3);
  const [timeLeft, setTimeLeft] = useState(seconds);
  useEffect(() => {
    if (!otpModal) {
      return;
    }
    if (timeLeft == 0) {
      setResendButton(false);
    }
    // exit early when we reach 0
    if (!timeLeft) return;
    // save intervalId to clear the interval when the
    // component re-renders
    const intervalId = setInterval(() => {
      setTimeLeft(timeLeft - 1);
    }, 1000);
    // clear interval on re-render to avoid memory leaks
    return () => {
      clearInterval(intervalId);
    };
    // add timeLeft as a dependency to re-rerun the effect
    // when we update it
  }, [timeLeft, otpModal]);
  const refreshButton = () => {
    setTimeLeft(seconds);
    setResendButton(true);
    setResendButtonCount(resendButtonCount - 1);
    let newUser = localStorage.getItem('newUser');
    checkValidId(newUser);
  };
  // console.log(expiryDate, 'baladate');
  // console.log(dateType, 'datetype');
  return (
    <AuthWrapper variant={'signup'}>
      <SnackBarComponent
        status={responseStatus}
        visible={response}
        setResponse={setResponse}
        message={responseMessage}
        setResponseStatus={setResponseStatus}
        setResponseMessage={setResponseMessage}
      />
      <Box className={classes.authContent}>
        <Loader isLoading={isLoading} />
        <Loader isLoading={fetchingUser} />
        <Typography component="div" variant="h1" className={classes.titleRoot}>
          <IntlMessages id="registration.personaldetails" />
        </Typography>
        {showFields && (
          <form>
            {mandatoryFields['FIRST_NAME']?.showField && (
              <Box mb={2}>
                <TextField
                  label={mandatoryFields['FIRST_NAME'].fieldLabel}
                  fullWidth
                  onChange={event => setfirstName(event.target.value)}
                  // defaultValue={name}
                  margin="normal"
                  variant="outlined"
                  className={classes.textFieldRoot}
                  disabled={iqamaValidated}
                />
              </Box>
            )}
            {mandatoryFields['GROSS_INCOME']?.showField && (
              <Box mb={2}>
                <TextField
                  label={mandatoryFields['GROSS_INCOME'].fieldLabel}
                  fullWidth
                  onChange={event => setgrossIncome(event.target.value)}
                  // defaultValue={name}
                  margin="normal"
                  variant="outlined"
                  className={classes.textFieldRoot}
                />
              </Box>
            )}
            {mandatoryFields['AMOUNT_TO_INVEST']?.showField && (
              <Box mb={2}>
                <TextField
                  label={mandatoryFields['AMOUNT_TO_INVEST'].fieldLabel}
                  fullWidth
                  onChange={event => setamountToInvest(event.target.value)}
                  // defaultValue={name}
                  margin="normal"
                  variant="outlined"
                  className={classes.textFieldRoot}
                />
              </Box>
            )}
            {mandatoryFields['INVESTMENT_PURPOSE']?.showField && (
              <Box mb={2}>
                <TextField
                  label={mandatoryFields['INVESTMENT_PURPOSE'].fieldLabel}
                  fullWidth
                  onChange={event => setinvestmentPurpose(event.target.value)}
                  // defaultValue={name}
                  margin="normal"
                  variant="outlined"
                  className={classes.textFieldRoot}
                />
              </Box>
            )}
            {mandatoryFields['REQUIRED_INTEREST_RATE']?.showField && (
              <Box mb={2}>
                <TextField
                  label={mandatoryFields['REQUIRED_INTEREST_RATE'].fieldLabel}
                  fullWidth
                  onChange={event => setinterestRate(event.target.value)}
                  // defaultValue={name}
                  margin="normal"
                  variant="outlined"
                  className={classes.textFieldRoot}
                />
              </Box>
            )}
            {mandatoryFields['DURATION_FOR_INVEST_AMOUNT']?.showField && (
              <Box mb={2}>
                <TextField
                  label={mandatoryFields['DURATION_FOR_INVEST_AMOUNT'].fieldLabel}
                  fullWidth
                  onChange={event => setdurationForInvest(event.target.value)}
                  // defaultValue={name}
                  margin="normal"
                  variant="outlined"
                  className={classes.textFieldRoot}
                />
              </Box>
            )}
            {/* {mandatoryFields['EMPLOYEMENT_CATEGORY']?.showField && (
              <Box mb={2}>
                <TextField
                  label={mandatoryFields['EMPLOYEMENT_CATEGORY'].fieldLabel}
                  fullWidth
                  onChange={event => setemploymentCategory(event.target.value)}
                  // defaultValue={name}
                  margin="normal"
                  variant="outlined"
                  className={classes.textFieldRoot}
                />
              </Box>
            )} */}
            {mandatoryFields['EMPLOYER_TYPE']?.showField && (
              <Box mb={2}>
                <TextField
                  label={mandatoryFields['EMPLOYER_TYPE'].fieldLabel}
                  fullWidth
                  onChange={event => setemployerType(event.target.value)}
                  // defaultValue={name}
                  margin="normal"
                  variant="outlined"
                  className={classes.textFieldRoot}
                />
              </Box>
            )}
            {mandatoryFields['EDUCATION_TYPE']?.showField && (
              <Box mb={2}>
                <TextField
                  label={mandatoryFields['EDUCATION_TYPE'].fieldLabel}
                  fullWidth
                  onChange={event => seteducationType(event.target.value)}
                  // defaultValue={name}
                  margin="normal"
                  variant="outlined"
                  className={classes.textFieldRoot}
                />
              </Box>
            )}
            {mandatoryFields['RESIDENCE_TYPE']?.showField && (
              <Box mb={2}>
                <TextField
                  label={mandatoryFields['RESIDENCE_TYPE'].fieldLabel}
                  fullWidth
                  onChange={event => setresidenceType(event.target.value)}
                  // defaultValue={name}
                  margin="normal"
                  variant="outlined"
                  className={classes.textFieldRoot}
                />
              </Box>
            )}
            {mandatoryFields['RESIDENT_OWNERSHIP_TYPE']?.showField && (
              <Box mb={2}>
                <TextField
                  label={mandatoryFields['RESIDENT_OWNERSHIP_TYPE'].fieldLabel}
                  fullWidth
                  onChange={event => setresidentOwnershipType(event.target.value)}
                  // defaultValue={name}
                  margin="normal"
                  variant="outlined"
                  className={classes.textFieldRoot}
                />
              </Box>
            )}
            {mandatoryFields['PASSPORT_NUMBER']?.showField && (
              <Box mb={2}>
                <TextField
                  label={mandatoryFields['PASSPORT_NUMBER'].fieldLabel}
                  fullWidth
                  onChange={event => setpassportNumber(event.target.value)}
                  // defaultValue={name}
                  margin="normal"
                  variant="outlined"
                  className={classes.textFieldRoot}
                />
              </Box>
            )}
            {mandatoryFields['HOME_EXPENSES']?.showField && (
              <Box mb={2}>
                <TextField
                  label={mandatoryFields['HOME_EXPENSES'].fieldLabel}
                  fullWidth
                  onChange={event => sethomeExpenses(event.target.value)}
                  // defaultValue={name}
                  margin="normal"
                  variant="outlined"
                  className={classes.textFieldRoot}
                />
              </Box>
            )}
            {mandatoryFields['TRANSPORTATION_EXPENSES']?.showField && (
              <Box mb={2}>
                <TextField
                  label={mandatoryFields['TRANSPORTATION_EXPENSES'].fieldLabel}
                  fullWidth
                  onChange={event => settransportationExpenses(event.target.value)}
                  // defaultValue={name}
                  margin="normal"
                  variant="outlined"
                  className={classes.textFieldRoot}
                />
              </Box>
            )}
            {mandatoryFields['UTILITY_EXPENSES']?.showField && (
              <Box mb={2}>
                <TextField
                  label={mandatoryFields['UTILITY_EXPENSES'].fieldLabel}
                  fullWidth
                  onChange={event => setutilitiesExpenses(event.target.value)}
                  // defaultValue={name}
                  margin="normal"
                  variant="outlined"
                  className={classes.textFieldRoot}
                />
              </Box>
            )}
            {mandatoryFields['ENTERTAINMENT_EXPENSES']?.showField && (
              <Box mb={2}>
                <TextField
                  label={mandatoryFields['ENTERTAINMENT_EXPENSES'].fieldLabel}
                  fullWidth
                  onChange={event => setentertainmentExpenses(event.target.value)}
                  // defaultValue={name}
                  margin="normal"
                  variant="outlined"
                  className={classes.textFieldRoot}
                />
              </Box>
            )}
            {mandatoryFields['INSURANCE_EXPENSES']?.showField && (
              <Box mb={2}>
                <TextField
                  label={mandatoryFields['INSURANCE_EXPENSES'].fieldLabel}
                  fullWidth
                  onChange={event => setinsuranceExpenses(event.target.value)}
                  // defaultValue={name}
                  margin="normal"
                  variant="outlined"
                  className={classes.textFieldRoot}
                />
              </Box>
            )}
            {mandatoryFields['OTHER_EXPENSES']?.showField && (
              <Box mb={2}>
                <TextField
                  label={mandatoryFields['OTHER_EXPENSES'].fieldLabel}
                  fullWidth
                  onChange={event => setotherExpenses(event.target.value)}
                  // defaultValue={name}
                  margin="normal"
                  variant="outlined"
                  className={classes.textFieldRoot}
                />
              </Box>
            )}
            {mandatoryFields['CREDIT_CARD_EXPENSES']?.showField && (
              <Box mb={2}>
                <TextField
                  label={mandatoryFields['CREDIT_CARD_EXPENSES'].fieldLabel}
                  fullWidth
                  onChange={event => setcreditCardExpenses(event.target.value)}
                  // defaultValue={name}
                  margin="normal"
                  variant="outlined"
                  className={classes.textFieldRoot}
                />
              </Box>
            )}
            {mandatoryFields['HOUSE_LOAN_EXPENSES']?.showField && (
              <Box mb={2}>
                <TextField
                  label={mandatoryFields['HOUSE_LOAN_EXPENSES'].fieldLabel}
                  fullWidth
                  onChange={event => sethouseLoanExpenses(event.target.value)}
                  // defaultValue={name}
                  margin="normal"
                  variant="outlined"
                  className={classes.textFieldRoot}
                />
              </Box>
            )}
            {/* {mandatoryFields['CITIZENSHIP']?.showField && (
              <Box mb={2}>
                <TextField
                  label={mandatoryFields['CITIZENSHIP'].fieldLabel}
                  fullWidth
                  onChange={event => setcitizenship(event.target.value)}
                  // defaultValue={name}
                  margin="normal"
                  variant="outlined"
                  className={classes.textFieldRoot}
                />
              </Box>
            )} */}
            {mandatoryFields['MIDDLE_NAME']?.showField && (
              <Box mb={2}>
                <TextField
                  label={mandatoryFields['MIDDLE_NAME'].fieldLabel}
                  fullWidth
                  onChange={event => setmiddleName(event.target.value)}
                  // defaultValue={name}
                  margin="normal"
                  variant="outlined"
                  className={classes.textFieldRoot}
                />
              </Box>
            )}
            {/* {mandatoryFields['DATE_OF_BIRTH']?.showField && (
              <Box mb={2}>
                <TextField
                  label={mandatoryFields['DATE_OF_BIRTH'].fieldLabel}
                  fullWidth
                  onChange={event => setdateOfBirth(event.target.value)}
                  // defaultValue={name}
                  margin="normal"
                  variant="outlined"
                  className={classes.textFieldRoot}
                />
              </Box>
            )} */}
            {/* {mandatoryFields['INVESTOR_PROFILE_DATE_VARIABLE_1']?.showField && (
              <Box mb={2}>
                <TextField
                  label={mandatoryFields['INVESTOR_PROFILE_DATE_VARIABLE_1'].fieldLabel}
                  fullWidth
                  onChange={event => setinvestorProfileDateVariable1(event.target.value)}
                  // defaultValue={name}
                  margin="normal"
                  variant="outlined"
                  className={classes.textFieldRoot}
                />
              </Box>
            )} */}
            {/* {mandatoryFields['INVESTOR_PROFILE_TEXT_VARIABLE_2']?.showField && (
              <Box mb={2}>
                <TextField
                  label={mandatoryFields['INVESTOR_PROFILE_TEXT_VARIABLE_2'].fieldLabel}
                  fullWidth
                  onChange={event => setinvestorProfileTextVariable2(event.target.value)}
                  // defaultValue={name}
                  margin="normal"
                  variant="outlined"
                  className={classes.textFieldRoot}
                />
              </Box>
            )} */}
            {/* {mandatoryFields['CATEGORY']?.showField && (
              <Box mb={2}>
                <TextField
                  label={mandatoryFields['CATEGORY'].fieldLabel}
                  fullWidth
                  onChange={event => setinvestorCategory(event.target.value)}
                  // defaultValue={name}
                  margin="normal"
                  variant="outlined"
                  className={classes.textFieldRoot}
                />
              </Box>
            )} */}
            <GridContainer
              sm={12}
              md={12}
              lg={12}
              style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
              <Grid sm={12} md={10} lg={10}>
                {' '}
                <TextField
                  label={<IntlMessages id="registration.idiquama" />}
                  fullWidth
                  disabled={showAll}
                  onChange={event => setIqama(event.target.value)}
                  // defaultValue={name}
                  margin="normal"
                  onBlur={event => setHijriOrGregorian(event.target.value)}
                  variant="outlined"
                  className={classes.textFieldRoot}
                />
              </Grid>
              <Grid style={{ padding: '18px' }} sm={12} md={2} lg={2}>
                <Button disabled={showAll} onClick={() => checkMobileLinkedWithId()} variant="contained" color="primary">
                  <IntlMessages id="registration.verify" />
                </Button>
              </Grid>
            </GridContainer>

            {showAll ? (
              <div>
                <GridContainer sm={12} md={12} lg={12} style={{ padding: '10px', marginTop: '10px' }}>
                  <FormControl component="fieldset">
                    <FormLabel component="legend">
                      <IntlMessages id="registration.datetype" />
                    </FormLabel>
                    <RadioGroup row aria-label="position" name="position" defaultValue="top">
                      <FormControlLabel
                        disabled
                        checked={dateType == 'gregorian' ? true : false}
                        onClick={() => setDateType('gregorian')}
                        control={<Radio color="primary" />}
                        label={<IntlMessages id="registration.gregorian" />}
                      />
                      <FormControlLabel
                        disabled
                        checked={dateType == 'hijri' ? true : false}
                        onClick={() => setDateType('hijri')}
                        control={<Radio color="primary" />}
                        label={<IntlMessages id="registration.hijri" />}
                      />
                    </RadioGroup>
                  </FormControl>
                </GridContainer>
                <Grid container spacing={2}>
                  <Grid item xs={6} md={4}>
                    <Typography component="div" variant="h6" className={classes.titleRoot}>
                      <IntlMessages id="registration.dateofbirth" />
                    </Typography>
                    <FormControl fullWidth>
                      <InputLabel style={{ marginLeft: '10px' }} id="demo-simple-select-helper-label">
                        <IntlMessages id="registration.selectyear" />
                      </InputLabel>
                      <Select
                        disabled={showAllone}
                        labelId="demo-simple-select-helper-label"
                        id="demo-simple-select-helper"
                        variant="outlined"
                        value={dateYear}
                        onChange={event => setDateYear(event.target.value)}
                        label="Age">
                        <MenuItem value="">
                          <em>None</em>
                        </MenuItem>
                        {dateType === 'hijri'
                          ? datePicker.year.map(item => {
                              return <MenuItem value={item.value}>{item.value}</MenuItem>;
                            })
                          : datePicker.yearg.map(item => {
                              return <MenuItem value={item.value}>{item.value}</MenuItem>;
                            })}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={6} md={4}>
                    <FormControl fullWidth style={{ marginTop: 36 }}>
                      <InputLabel style={{ marginLeft: '10px' }} id="demo-simple-select-helper-label">
                        <IntlMessages id="registration.selectmonth" />
                      </InputLabel>
                      <Select
                        disabled={showAllone}
                        labelId="demo-simple-select-helper-label"
                        id="demo-simple-select-helper"
                        variant="outlined"
                        value={dateMonth}
                        label="Age"
                        onChange={event => setDateMonth(event.target.value)}>
                        <MenuItem value="">
                          <em>None</em>
                        </MenuItem>
                        {datePicker.month.map(item => {
                          return <MenuItem value={item.value}>{item.value}</MenuItem>;
                        })}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <FormControl fullWidth style={{ marginTop: 36 }}>
                      <InputLabel style={{ marginLeft: '10px' }} id="demo-simple-select-helper-label">
                        <IntlMessages id="registration.selectdate" />
                      </InputLabel>
                      <Select
                        disabled={showAllone}
                        labelId="demo-simple-select-helper-label"
                        id="demo-simple-select-helper"
                        variant="outlined"
                        value={dateDay}
                        label="Age"
                        onChange={event => setDateDay(event.target.value)}>
                        <MenuItem value="">
                          <em>None</em>
                        </MenuItem>
                        {datePicker.day.map(item => {
                          return <MenuItem value={item.value}>{item.value}</MenuItem>;
                        })}
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>
                <Grid item xs={12} md={12}>
                  <Button
                    disabled={showAllone}
                    style={{ marginTop: 20 }}
                    fullWidth
                    onClick={() => citizenInfo()}
                    variant="contained"
                    color="primary">
                    <IntlMessages id="registration.citizeninfo" />
                  </Button>
                </Grid>
                <Box mb={2}>
                  <FormControl style={{ marginTop: 20, display: 'flex', flexDirection: 'row', width: '100%' }}>
                    <InputLabel style={{ marginLeft: '10px' }} id="demo-simple-select-helper-label">
                      <IntlMessages id="registration.lendercategory" />
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-helper-label"
                      id="demo-simple-select-helper"
                      value={lenderCategory}
                      style={{ width: '95%' }}
                      variant="outlined"
                      onChange={event => setLenderCategory(event.target.value)}
                      label="Age">
                      {lenderCategoryArr.map(item => {
                        return <MenuItem value={item.uuid}>{item.name}</MenuItem>;
                      })}
                    </Select>
                    <IconButton
                      className="standard-button"
                      size="small"
                      onClick={() => {
                        setCategoryModal(true);
                      }}>
                      <HelpOutlineIcon style={{ fontSize: 18 }} />
                    </IconButton>
                  </FormControl>
                </Box>
                {/* <Box mb={2}>
              <TextField
                label={<IntlMessages id="registration.nameid" />}
                disabled
                fullWidth
                onChange={event => setNameEnglish(event.target.value)}
                value={nameEnglish}
                margin="normal"
                variant="outlined"
                className={classes.textFieldRoot}
              />
            </Box>
            <Box mb={2}>
              <TextField
                label={<IntlMessages id="registration.nameidarabic" />}
                disabled
                fullWidth
                onChange={event => setNameArabic(event.target.value)}
                value={nameArabic}
                margin="normal"
                variant="outlined"
                className={classes.textFieldRoot}
              />
            </Box> */}
                <GridContainer style={{ marginTop: 15, marginBottom: 15 }}>
                  <Grid item xs={12} sm={12} md={6} lg={6}>
                    <Typography className={classes.title} variant="h1" color="textprimary" gutterBottom>
                      <IntlMessages id="registration.nameid" />
                    </Typography>
                    <Typography className={classes.title} variant="h1" color="textSecondary" gutterBottom>
                      {nameEnglish}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={12} md={6} lg={6}>
                    <Typography className={classes.title} variant="h1" color="textprimary" gutterBottom>
                      <IntlMessages id="registration.nameidarabic" />
                    </Typography>
                    <Typography className={classes.title} variant="h1" color="textSecondary" gutterBottom>
                      {nameArabic}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={12} md={6} lg={6}>
                    <Box mb={2}>
                      {/* <FormControl fullWidth style={{ marginTop: 20 }}>
                    <InputLabel style={{ marginLeft: '10px' }} id="demo-simple-select-helper-label">
                      <IntlMessages id="registration.nationality" />
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-helper-label"
                      id="demo-simple-select-helper"
                      disabled
                      value={nationality}
                      variant="outlined"
                      onChange={event => setNationality(event.target.value)}
                      label="Age">
                      <MenuItem value="">
                        <em>None</em>
                      </MenuItem>
                      {citizenShipArray?.map(item => {
                        return <MenuItem value={item.value}>{item.name}</MenuItem>;
                      })}
                    </Select>
                  </FormControl> */}
                      <FormControl className={classes.formControl}>
                        <InputLabel htmlFor="age-native-simple">
                          <IntlMessages id="registration.nationality" />
                        </InputLabel>
                        <Select
                          native
                          disabled
                          value={nationality}
                          onChange={event => setNationality(event.target.value)}
                          inputProps={{
                            name: 'age',
                            id: 'age-native-simple',
                          }}>
                          <option aria-label="None" value="" />
                          {citizenShipArray?.map(item => {
                            return <option value={item.value}>{item.name}</option>;
                          })}
                        </Select>
                      </FormControl>
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={12} md={6} lg={6}>
                    <Typography className={classes.title} variant="h1" color="textprimary" gutterBottom>
                      <IntlMessages id="registration.expirydate" />
                    </Typography>
                    <Typography className={classes.title} variant="h1" color="textSecondary" gutterBottom>
                      {expiryDate}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={12} md={6} lg={6}>
                    <Typography className={classes.title} variant="h1" color="textprimary" gutterBottom>
                      <IntlMessages id="registration.addressline1" />
                    </Typography>
                    <Typography className={classes.title} variant="h1" color="textSecondary" gutterBottom>
                      {line1}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={12} md={6} lg={6}>
                    <Typography className={classes.title} variant="h1" color="textprimary" gutterBottom>
                      <IntlMessages id="registration.addressline2" />
                    </Typography>
                    <Typography className={classes.title} variant="h1" color="textSecondary" gutterBottom>
                      {line2}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={12} md={6} lg={6}>
                    <Typography className={classes.title} variant="h1" color="textprimary" gutterBottom>
                      <IntlMessages id="registration.addressline3" />
                    </Typography>
                    <Typography className={classes.title} variant="h1" color="textSecondary" gutterBottom>
                      {line3}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={12} md={6} lg={6}>
                    <Typography className={classes.title} variant="h1" color="textprimary" gutterBottom>
                      <IntlMessages id="registration.zipcode" />
                    </Typography>
                    <Typography className={classes.title} variant="h1" color="textSecondary" gutterBottom>
                      {zipCode}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={12} md={6} lg={6}>
                    <Typography className={classes.title} variant="h1" color="textprimary" gutterBottom>
                      <IntlMessages id="registration.city" />
                    </Typography>
                    <Typography className={classes.title} variant="h1" color="textSecondary" gutterBottom>
                      {city}
                    </Typography>
                  </Grid>
                </GridContainer>
                {/* <Box mb={2}>
              <FormControl fullWidth style={{ marginTop: 20 }}>
                <InputLabel style={{ marginLeft: '10px' }} id="demo-simple-select-helper-label">
                  <IntlMessages id="registration.nationality" />
                </InputLabel>
                <Select
                  labelId="demo-simple-select-helper-label"
                  id="demo-simple-select-helper"
                  disabled
                  value={nationality}
                  variant="outlined"
                  onChange={event => setNationality(event.target.value)}
                  label="Age">
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                  {citizenShipArray?.map(item => {
                    return <MenuItem value={item.value}>{item.name}</MenuItem>;
                  })}
                </Select>
              </FormControl>
            </Box>

            <Box mb={2}>
              <TextField
                label={<IntlMessages id="registration.expirydate" />}
                fullWidth
                disabled
                // onChange={event => setinvestorProfileDateVariable1(event.target.value)}
                value={expiryDate}
                margin="normal"
                variant="outlined"
                className={classes.textFieldRoot}
              />
            </Box>
            <Grid container spacing={2}>
              <Grid item xs={12} md={12}>
                <TextField
                  label={<IntlMessages id="registration.addressline1" />}
                  fullWidth
                  disabled
                  onChange={event => setLine1(event.target.value)}
                  value={line1}
                  margin="normal"
                  variant="outlined"
                  className={classes.textFieldRoot}
                />
              </Grid>
              <Grid item xs={12} md={12}>
                <Box>
                  <TextField
                    label={<IntlMessages id="registration.addressline2" />}
                    fullWidth
                    disabled
                    onChange={event => setLine2(event.target.value)}
                    value={line2}
                    margin="normal"
                    variant="outlined"
                    // className={classes.textFieldRoot}
                  />
                </Box>
              </Grid>
              <Grid item xs={12} md={12}>
                <Box>
                  <TextField
                    label={<IntlMessages id="registration.addressline3" />}
                    fullWidth
                    disabled
                    onChange={event => setLine3(event.target.value)}
                    value={line3}
                    margin="normal"
                    variant="outlined"
                    // className={classes.textFieldRoot}
                  />
                </Box>
              </Grid>
              <Grid item xs={6} md={6}>
                <Box>
                  <TextField
                    label={<IntlMessages id="registration.zipcode" />}
                    fullWidth
                    disabled
                    onChange={event => setZipCode(event.target.value)}
                    value={zipCode}
                    margin="normal"
                    variant="outlined"
                    // className={classes.textFieldRoot}
                  />
                </Box>
              </Grid>
              <Grid item xs={6} md={6}>
                <Box>
                  <TextField
                    label={<IntlMessages id="registration.city" />}
                    fullWidth
                    disabled
                    onChange={event => setZipCode(event.target.value)}
                    value={city}
                    margin="normal"
                    variant="outlined"
                    // className={classes.textFieldRoot}
                  />
                </Box>
              </Grid>
            </Grid> */}
                {/* <Box mb={2}>
              <FormControl sx={{ m: 1, minWidth: 120 }} style={{ width: 500, marginTop: 55 }}>
                <InputLabel id="demo-simple-select-helper-label">
                  <IntlMessages id="registration.city" />
                </InputLabel>
                <Select
                  labelId="demo-simple-select-helper-label"
                  id="demo-simple-select-helper"
                  variant="outlined"
                  value={city}
                  label="city"
                  onChange={event => setCity(event.target.value)}>
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                  {cityArray.map(item => {
                    return <MenuItem value={item.name}>{item.name}</MenuItem>;
                  })}
                </Select>
              </FormControl>
            </Box> */}
                <Box
                  display="flex"
                  flexDirection={{ xs: 'column', sm: 'row' }}
                  alignItems={{ sm: 'center' }}
                  justifyContent={{ sm: 'space-between' }}
                  mb={3}>
                  <Box mb={{ xs: 2, sm: 0 }}>
                    <Button onClick={() => onSubmit()} variant="contained" color="primary">
                      <IntlMessages id="appModule.next" />
                    </Button>
                  </Box>
                </Box>
              </div>
            ) : null}
          </form>
        )}
        <div>
          <Dialog open={showAlert} fullWidth>
            <DialogContent>
              <DialogContentText>{status}</DialogContentText>

              <DialogContentText>{message}</DialogContentText>
            </DialogContent>

            <DialogActions>
              <Button
                onClick={() => {
                  setShowAlert(false);
                }}>
                Cancel
              </Button>
              <Button
                onClick={() =>
                  code === '200'
                    ? localStorage.getItem('registrationType') === 'company'
                      ? history.push('/signupcompany')
                      : history.push('/signupthree')
                    : setShowAlert(false)
                }>
                Okay
              </Button>
            </DialogActions>
          </Dialog>
        </div>
        <div>
          <Dialog fullWidth open={otpModal}>
            <DialogContent>
              <DialogContentText>
                <IntlMessages id="registration.otpverifiy" />
              </DialogContentText>
              <TextField
                label=""
                autoComplete="off"
                fullWidth
                onChange={event => setOtp(event.target.value)}
                // defaultValue={password}
                margin="normal"
                variant="outlined"
                // className={classes.textFieldRoot}
              />
              <Snackbar open={showsnakbar} autoHideDuration={6000} onClose={() => setshowsnakbar(false)}>
                <Alert onClose={() => setshowsnakbar(false)} severity="success">
                  <IntlMessages id="registration.otpsend" />
                </Alert>
              </Snackbar>
            </DialogContent>

            <DialogActions>
              <Button onClick={() => setOtpModal(false)}>
                <IntlMessages id="registration.cancel" />
              </Button>
              {resendButton == true ? (
                <Button onClick={() => verifyOtp()}>
                  <IntlMessages id="registration.submit" />
                </Button>
              ) : (
                <Button
                  onClick={() => {
                    refreshButton();
                  }}>
                  <IntlMessages id="registration.resend" />
                </Button>
              )}
            </DialogActions>
            {timeLeft != 0 ? (
              <DialogContentText style={{ textAlign: 'center' }}>
                <IntlMessages id="registration.resendotp" /> {timeLeft}s
              </DialogContentText>
            ) : null}
          </Dialog>
        </div>
        <div>
          <Dialog style={{ height: 'auto' }} fullWidth open={categoryModal}>
            <DialogContent>
              {languageCheck == 'en' ? (
                <div>
                  <Typography className={classes.decHeader} variant="h2">
                    Raqamyah is open to the following financers:
                  </Typography>
                  <Typography className={classes.decHeader}>Individual Financers:</Typography>
                  <Typography className={classes.decText}>
                    {<br />}
                    Any natural person who registers to finance through the platform under his/her own capacity. There are
                    limits on Individual Financers as follows:
                    {<br />}
                    The maximum you can transfer to Raqamyah in any 12 months period is SAR 200,000
                    {<br />}
                    The maximum exposure to any finance request is SAR 50,000
                  </Typography>
                  <Typography className={classes.decHeader}>Qualified Individual Financer:</Typography>
                  <Typography className={classes.decText}>
                    Any natural person who registers to finance through the platform under his/her own capacity. To remove
                    the limits set out above, Qualified Individual Financer must sign a Declaration stating that he/she
                    fulfils at least one of the following:
                    {<br />}
                    own net assets with a value not less than three (3) Million Saudi Riyals;
                    {<br />}
                    work or have worked for at least three (3) years in the financial sector in a position related to finance
                    or investment;
                    {<br />}
                    hold a professional certificate in finance or investment is approved by an internationally or national
                    recognized establishment; or
                    {<br />}
                    have an annual income that is not less than six hundred thousand Saudi Riyals (SAR600,000) in the two
                    most recent years. The Individual Financer hereby agrees that Raqamyah shall access the Yakeen service to
                    validate his/her National Identification Card or Iqama (Resident Identity).
                  </Typography>
                  <a href="https://raqamyah.com/assetsNew/pdf/Declaration.pdf" target="_blank">
                    <Typography className={classes.decText} style={{ color: 'blue' }}>
                      Click here to download the form
                    </Typography>
                  </a>
                </div>
              ) : (
                <div>
                  <Typography className={classes.decHeader} variant="h2">
                    أنواع المُمَوِلين
                  </Typography>
                  <Typography className={classes.decHeader}>المُمَوِلين الأفراد</Typography>
                  {<br />}
                  <Typography className={classes.decText}>
                    أي شخص طبيعي سجل من أجل التمويل على المنصة بموجب صفته الخاصة. هذا وتوجد الضوابط التالية على المُمَوِلين
                    الأفراد
                  </Typography>
                  <Typography className={classes.decText}>
                    يبلغ الحد الأقصى لما يمكن تحويله إلى منصة رقمية خلال فترة أي 12 شهر 200,000ريال سعودي
                    {<br />}
                    يبلغ الحد الأقصى للتعرض لأي تمويل واحد 50,000 ريال سعودي
                    {<br />}
                  </Typography>
                  <Typography className={classes.decHeader}>المُمَوِلين الأفراد المؤهلين</Typography>
                  {<br />}
                  <Typography className={classes.decText}>
                    أي شخص طبيعي سجل من أجل التمويل على المنصة بموجب صفته الخاصة. ولكي يتم إعفاء المُمَوِلين الأفراد المؤهلين
                    من الضوابط الموضحة أعلاه ، يستلزم من المُمَوِلين المؤهلين توقيع نموذج يؤكد به على تلبية معيار واحد على
                    الأقل من الآتي
                  </Typography>
                  <Typography className={classes.decText}>يمتلك أصولاً لا تقل عن 3 ملايين ريال سعودي؛</Typography>
                  <Typography className={classes.decText}>
                    أن يعمل أو قد عمل لمدة لا تقل عن ثلاث سنوات في القطاع المالي؛
                  </Typography>
                  <Typography className={classes.decText}>
                    يحمل شهادة متخصصة ترتبط بالتحليل المالي ومعتمدة من كيان معترف به محليّاً أو عالميّاً؛ أو
                  </Typography>
                  <Typography className={classes.decText}>
                    حائز على مؤهل الشهادة العامة للتعامل في الأوراق المالية معترف بها من هيئة السوق المالية، ولديه دخل سنوي
                    لايقل عن 600,000 ريال سعودي
                  </Typography>
                  <a href="https://raqamyah.com/assetsNew/pdf/Declaration.pdf" target="_blank">
                    <Typography className={classes.decText} style={{ color: 'blue' }}>
                      اضعط هنا لتحميل النموذج
                    </Typography>
                  </a>

                  {<br />}
                  <Typography className={classes.decText}> </Typography>
                </div>
              )}
            </DialogContent>
            <DialogActions>
              <Button onClick={() => setCategoryModal(false)}>
                <IntlMessages id="dashboard.close" />
              </Button>
            </DialogActions>
          </Dialog>
        </div>
        {/* <Box mb={3}>{dispatch(AuhMethods[method].getSocialMediaIcons())}</Box>

        <ContentLoader /> */}
      </Box>
    </AuthWrapper>
  );
};

export default SignUpTwo;
