import * as types from '../actionTypes/types';
import { httpRequest } from '../NetworkConfig/Config';
import { Url, methods, headers } from '../NetworkConfig/ApiUrlConstatnts';
export const sendOtpMobileFunction = inpuData => {
  console.log(inpuData, 'inpouuuu');
  return async dispatch => {
    dispatch(fetchingSendOtpMobileRequest());
    return httpRequest({
      method: methods.post,
      url: Url.SendOtpMobile,
      headers: headers.headFinancing,
      data: JSON.stringify(inpuData),
    }).then(JsonResponse => {
      if (JsonResponse !== undefined) {
        if (JsonResponse.status == 200 || JsonResponse.status == 203) {
          let fetchedData =
            JsonResponse.data !== undefined && JsonResponse.data !== null && Object.keys(JsonResponse.data).length !== 0
              ? JsonResponse.data
              : {};
          dispatch(fetchingSendOtpMobileSuccess(fetchedData));
        } else {
          dispatch(fetchingSendOtpMobileFailed());
        }
        return JsonResponse;
      } else {
        dispatch(fetchingSendOtpMobileFailed());
      }
    });
  };
};
export const fetchingSendOtpMobileRequest = () => ({
  type: types.FETCHING_SEND_OTP_MOBILE_REQUEST,
});
export const fetchingSendOtpMobileSuccess = json => ({
  type: types.FETCHING_SEND_OTP_MOBILE_SUCCESS,
  payload: json,
});
export const fetchingSendOtpMobileFailed = error => ({
  type: types.FETCHING_SEND_OTP_MOBILE_FAILED,
  payload: error,
});
export const fetchingUserLogOutDetailsSuccess = () => ({
  type: types.FETCHING_USER_LOGOUT_DETAILS_SUCCESS,
});
