import React, { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, fade, FormControl, FormHelperText, Grid, InputLabel, MenuItem, Select, Snackbar } from '@material-ui/core';
import MuiAlert from '@material-ui/lab/Alert';
import IntlMessages from '../../../utils/IntlMessages';
import { AuhMethods } from '../../../../services/auth';
import ContentLoader from '../../ContentLoader';
import makeStyles from '@material-ui/core/styles/makeStyles';
import CmtImage from '../../../../@coremat/CmtImage';
import Typography from '@material-ui/core/Typography';
import { CurrentAuthMethod } from '../../../constants/AppConstants';
import AuthWrapper from './AuthWrapper';
import { NavLink } from 'react-router-dom';
import { history } from 'redux/store';
import { registrationStepOneBasicFunction } from 'redux/actions/registrationStepOneBasic';
// import Alert from 'routes/Components/MuiComponents/Alert';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField } from '@material-ui/core';
import Swal from 'sweetalert2';
import Loader from 'routes/Components/pageLoaderCustom/loader';
import { investorMandatoryFunction } from '../../../../redux/actions/investorMandotaryFields';
import Axios from 'axios';
import { Url } from '../../../../redux/NetworkConfig/ApiUrlConstatnts';
import { borrowerMandatoryFunction } from '../../../../redux/actions/borrowerMandatoryFields';
import { registrationBorrowerStepOneBasicFunction } from 'redux/actions/registrationBorrowerStepOneBasic';
import { citiesListFunction } from 'redux/actions/citiesList';
import SnackBarComponent from 'routes/Components/SnackBar';
import PageLoader from '@jumbo/components/PageComponents/PageLoader';
import AppContext from '@jumbo/components/contextProvider/AppContextProvider/AppContext';
// import { initTwitter } from './TwitterScript';

const useStyles = makeStyles(theme => ({
  authThumb: {
    backgroundColor: fade(theme.palette.primary.main, 0.12),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: 20,
    [theme.breakpoints.up('md')]: {
      width: '50%',
      order: 2,
    },
  },
  authContent: {
    padding: 30,
    [theme.breakpoints.up('md')]: {
      width: props => (props.variant === 'default' ? '50%' : '100%'),
      order: 1,
    },
    [theme.breakpoints.up('xl')]: {
      padding: 50,
    },
  },
  titleRoot: {
    marginBottom: 14,
    color: theme.palette.text.primary,
  },
  textFieldRoot: {
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: fade(theme.palette.common.dark, 0.12),
    },
  },
  textCapital: {
    textTransform: 'capitalize',
  },
  textAcc: {
    textAlign: 'center',
    '& a': {
      marginLeft: 4,
    },
  },
  alrTextRoot: {
    textAlign: 'center',
    [theme.breakpoints.up('sm')]: {
      textAlign: 'right',
    },
  },
}));
//variant = 'default', 'standard', 'bgColor'
const SignUpBorrower = ({ method = CurrentAuthMethod, variant = 'default', wrapperVariant = 'default' }) => {
  const [showAlert, setShowAlert] = useState(false);
  const classes = useStyles({ variant });
  const [showFields, setShowFields] = useState(false);
  const dispatch = useDispatch();
  const [mandatoryFields, setmandatoryFields] = useState({});
  const [uniqueEmail, setUniqueEmail] = useState(false);
  const [uniqueEmailMessage, setUniqueEmailMessage] = useState('');
  const [uniqueMobile, setUniqueMobile] = useState(false);
  const [uniqueMobileMessage, setUniqueMobileMessage] = useState('');
  const [citizenShipArray, setCitizenShipArray] = useState([]);
  const [cityArray, setCityArray] = useState([]);
  const [response, setResponse] = useState(false);
  const [responseMessage, setResponseMessage] = useState('');
  const [responseStatus, setResponseStatus] = useState('');
  const [emailValidated, setEmailValidated] = useState(false);
  const [mobilevliadated, setMobileValidated] = useState(false);
  const [isFetching, setisFetching] = React.useState(false);
  const [showsnakbar, setshowsnakbar] = useState('');
  const [otpModal, setOtpModal] = useState(false);
  const [otp, setOtp] = useState('');
  const { locale, setLocale } = useContext(AppContext);

  const query = new URLSearchParams(window.location.search);
  const token = query.get('lang');
  const switchLanguage = async item => {
    await setLocale(item);
    if (item.locale === 'en') {
      // setDirection('ltr');
      localStorage.setItem('languageCheck', 'en');
      window.location.reload();
    } else {
      // setDirection('rtl');
      localStorage.setItem('languageCheck', 'ar_JO');
      window.location.reload();
    }
    // handleClose();
  };
  let languagedata = [
    {
      languageId: 'english',
      locale: 'en',
      name: 'English',
      icon: 'us',
    },
    {
      languageId: 'saudi-arabia',
      locale: 'ar',
      name: 'عربى',
      icon: 'sa',
    },
  ];
  useEffect(() => {
    localStorage.removeItem('borrowerStepThree');
    if (token !== null) {
      if (token.includes('en') || token.includes('ar')) {
        if (languageCheck.includes(token)) {
          return;
        } else if (token == 'en') {
          switchLanguage(languagedata[0]);
        } else {
          switchLanguage(languagedata[1]);
        }
      }
    }
  }, []);
  const checkUniqueEmail = async () => {
    const url = Url.baseUrl + Url.CheckUniqueEmail + `${emailAddress}`;
    const res = await Axios.post(url);
    // console.log(res.data, 'uniqueemail');
    if (res.data.status == '200') {
      setEmailValidated(true);
    } else {
      setEmailValidated(false);
    }
    setUniqueEmailMessage(res?.data);
    setUniqueEmail(true);
  };
  function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }
  const checkUniqueMobile = async () => {
    const url = Url.baseUrl + Url.CheckUniqueMobile + `${mobileNumber}`;
    const res = await Axios.get(url);
    if (res.data.status == '200') {
      setMobileValidated(true);
    } else {
      setMobileValidated(false);
    }
    setUniqueMobileMessage(res?.data);
    setUniqueMobile(true);
  };
  function checkPassword(str) {
    var re = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[#$@!%&*?])[A-Za-z\d#$@!%&*?]{8,20}$/;
    return re.test(str);
  }

  const handlePassword = val => {
    var p = val;
    let errors = [];
    if (p.length < 8) {
      errors.push(<IntlMessages id="error.passwordcharacter" />);
    }
    if (p.search(/[a-z]/i) < 0) {
      errors.push(<IntlMessages id="error.passwordletter" />);
    }
    if (!/[A-Z]/.test(p)) {
      errors.push(<IntlMessages id="error.passworduppercase" />);
    }
    if (p.search(/[0-9]/) < 0) {
      errors.push(<IntlMessages id="error.passworddigits" />);
    }
    if (p.search(/.*[\W_]/) < 0) {
      errors.push(<IntlMessages id="error.passwordspecialchar" />);
    }

    if (errors.length > 0) {
      setErrorMsg(errors);

      return false;
    } else {
      setErrorMsg([]);
      setpassword(p);
    }

    return true;
  };
  const [errorMsg, setErrorMsg] = useState([]);
  const sendOtp = () => {
    if (emailAddress.length <= 0) {
      setResponseMessage(<IntlMessages id="error.emailmsg" />);
      setResponse(true);
      setResponseStatus(500);
      return;
    }
    if (emailAddress != confirmEmailAddress) {
      setResponseMessage(<IntlMessages id="error.emailmatch" />);
      setResponse(true);
      setResponseStatus(500);
      return;
    }
    if (password != confirmPassword) {
      setResponseMessage(<IntlMessages id="error.passwordmatch" />);
      setResponse(true);
      setResponseStatus(500);
      return;
    }
    if (!checkPassword(password)) {
      Swal.fire({
        title: 'Error/خطأ',
        text: `
      Min 1 uppercase letter.
      Min 1 lowercase letter.
      Min 1 special character.
      Min 1 number.
      Min 8 characters.
      Max 20 characters.`,
        showDenyButton: false,
        showCancelButton: false,
        icon: 'failed',
        confirmButtonText: 'Okay',
      });
      return;
    }
    if (!emailValidated) {
      setResponseMessage(<IntlMessages id="error.emailexists" />);
      setResponse(true);
      setResponseStatus(500);
      return;
    }
    if (!mobilevliadated) {
      setResponseMessage(<IntlMessages id="error.mobileexists" />);
      setResponse(true);
      setResponseStatus(500);
      return;
    }
    setisFetching(true);
    const url = Url.baseUrl + Url.MobileNumberOtp;
    const inputData = {
      phoneNumber: `${mobileNumberCountryCode}` + `${mobileNumber}`,
    };
    // console.log(url, 'otpurl');
    // console.log(inputData, 'otpurl');
    var data = JSON.stringify({
      phoneNumber: `${mobileNumberCountryCode}` + `${mobileNumber}`,
    });
    var config = {
      method: 'post',
      url: url,
      headers: {
        'Content-Type': 'application/json',
      },
      data: data,
    };
    if (!emailValidated) {
      setResponseMessage(<IntlMessages id="error.emailexists" />);
      setResponse(true);
      setResponseStatus(500);
      return;
    }
    if (!mobilevliadated) {
      setResponseMessage(<IntlMessages id="error.mobileexists" />);
      setResponse(true);
      setResponseStatus(500);
      return;
    }

    Axios(config)
      .then(function(response) {
        // handle success
        // console.log(response.data, 'otpurl');
        if (response.data.code === '200') {
          setisFetching(false);
          setshowsnakbar(true);
          setOtpModal(true);
        } else {
          setResponseMessage(response.data.message);
          setResponse(true);
          setisFetching(false);
          setResponseStatus(response.data.status);
        }
      })
      .catch(function(error) {
        setResponseMessage(error.message);
        setResponse(true);
        setisFetching(false);
        setResponseStatus('500');
      });
  };

  const verifyOtp = () => {
    setisFetching(true);
    const url = Url.baseUrl + Url.MobileNumberVerifyOtp;
    var data = JSON.stringify({
      otp: otp,
      phoneNumber: `${mobileNumberCountryCode}` + `${mobileNumber}`,
    });

    var config = {
      method: 'post',
      url: url,
      headers: {
        'Content-Type': 'application/json',
      },
      data: data,
    };

    Axios(config)
      .then(response => {
        if (response.data.code == '200') {
          // console.log(response.data, 'otpurl');
          setResponseMessage(response.data.message);
          setResponse(true);
          setisFetching(false);
          setResponseStatus(response.data.code);
          setOtp('');
          setOtpModal(false);
          history.push('/signupborrowertwo');
          let inputs = {
            emailAddress: emailAddress,
            confirmEmailAddress: confirmEmailAddress,
            password: password,
            confirmPassword: confirmPassword,
            firstName: firstName,
            middleName: middleName,
            lastName: lastName,
            secondLastName: secondLastName,
            mobileNumber: mobileNumber,
            mobileNumberCountryCode: '+966',
            alternativeNumber: alternativeNumber,
            alternativeNumberCountryCode: alternativeNumberCountryCode,
            landLineNumber: landLineNumber,
            dateOfBirth: dateOfBirth,
            referralSource: referralSource,
            gender: gender,
            maritalStatus: maritalStatus,
            individualOrCompany: individualOrCompany,
            referralCode: referralCode,
            placeOfBirthState: placeOfBirthState,
            placeOfBirthCity: placeOfBirthCity,
            identityNumberOne: identityNumberOne,
            identityNumberTwo: identityNumberTwo,
            borrowerCategory: borrowerCategory,
            // citizenship: 'Saudi Arabia',
            religion: religion,
            fatherOrHusbandName: fatherOrHusbandName,
            motherMaidenName: motherMaidenName,
            spouseName: spouseName,
            numberOfDependents: numberOfDependents,
            employmentStatusOfSpouse: employmentStatusOfSpouse,
            casteCategory: casteCategory,
            identityNumberThree: identityNumberThree,
            identityNumberFour: identityNumberFour,
            isPersonWithDisability: isPersonWithDisability,
            vehicleOwnershipType: vehicleOwnershipType,
            alternativeUsername: alternativeUsername,
            title: title,
            firstSchoolName: firstSchoolName,
            addressOneLine1: addressOneLine1,
            addressOneLine2: addressOneLine2,
            addressOneLine3: addressOneLine3,
            addressOneCity: addressOneCity,
            addressOneState: addressOneState,
            addressOneZipCode: addressOneZipCode,
            addressOneLivingSince: addressOneLivingSince,
            promotionCode: promotionCode,
            borrowerProfileTextVariable1: borrowerProfileTextVariable1,
            borrowerProfileTextVariable2: borrowerProfileTextVariable2,
            borrowerProfileTextVariable3: borrowerProfileTextVariable3,
            borrowerProfileTextVariable4: borrowerProfileTextVariable4,
            borrowerProfileTextVariable5: borrowerProfileTextVariable5,
            borrowerProfileTextVariable6: borrowerProfileTextVariable6,
            borrowerProfileTextVariable7: borrowerProfileTextVariable7,
            borrowerProfileTextVariable8: borrowerProfileTextVariable8,
            borrowerProfileTextVariable9: borrowerProfileTextVariable9,
            borrowerProfileTextVariable10: borrowerProfileTextVariable10,
            borrowerProfileDateVariable1: borrowerProfileDateVariable1,
            borrowerProfileDateVariable2: borrowerProfileDateVariable2,
            borrowerProfileDateVariable3: borrowerProfileDateVariable3,
            fatherFullName: fatherFullName,
            fatherFirstName: fatherFirstName,
            fatherMiddleName: fatherMiddleName,
            fatherLastName: fatherLastName,
            motherFullName: motherFullName,
            motherFirstName: motherFirstName,
            motherMiddleName: motherMiddleName,
            motherLastName: motherLastName,
            husbandFullName: husbandFullName,
            husbandFirstName: husbandFirstName,
            husbandLastName: husbandLastName,
            husbandMiddleName: husbandMiddleName,
            spouseFullName: spouseFullName,
            spouseFirstName: spouseFirstName,
            spouseMiddleName: spouseMiddleName,
            spouseLastName: spouseLastName,
          };
          let borrowerStepOne = JSON.stringify(inputs);
          localStorage.setItem('borrowerStepOne', borrowerStepOne);
          localStorage.setItem('emailValidated', emailValidated);
          localStorage.setItem('mobilevliadated', mobilevliadated);
          // console.log(borrowerStepOne, 'count');
        } else {
          setResponseMessage(response.data.message);
          setResponse(true);
          setResponseStatus(response.data.code);
          setOtp('');
        }
      })
      .catch(function(error) {
        // handle error
        setResponseMessage(error.message);
        setResponse(true);
        setResponseStatus('500');
      });
  };
  window.onunload = function() {
    localStorage.removeItem('borrowerStepOne');
  };
  let languageCheck = localStorage.getItem('languageCheck');
  if (!languageCheck) {
    languageCheck = 'en';
  }
  //   const dataAppend = mandatoryFields => {
  //     let append = '';
  //     console.log(Object.keys(mandatoryFields).length);
  //     for (const property in mandatoryFields) {
  //       append +=
  //         `const [${mandatoryFields[property].variableName}, set${mandatoryFields[property].variableName}] = useState('');` +
  //         '\n';
  //       // `{mandatoryFields['${property}']?.showField && (<Box mb={2}>
  //       //     <TextField
  //       //       label={mandatoryFields['${property}'].fieldLabel}
  //       //       fullWidth

  //       //       onChange={event => set${mandatoryFields[property].variableName}(event.target.value)}
  //       //       margin="normal"
  //       //       variant="outlined"
  //       //       className={classes.textFieldRoot}
  //       //     />
  //       //   </Box>)}`;
  //     }
  //     console.log(append, 'append');
  //     // return append;
  //   };
  const fetchCitizenship = async () => {
    const url = Url.baseUrl + Url.CitizenShip;

    Axios.post(url)
      .then(function(response) {
        // handle success
        setCitizenShipArray(response.data);
        // console.log(response.data);
      })
      .catch(function(error) {
        alert(error);
      });
  };
  const [stepOneDetailBorrower, setstepOneDetailBorrower] = useState('');
  useEffect(() => {
    dispatch(borrowerMandatoryFunction(languageCheck)).then(res => {
      setmandatoryFields(res?.data);
      //   dataAppend(res?.data);
      getStepOneDetailsBorrower();
      setShowFields(true);
    });
  }, [languageCheck]);
  useEffect(() => {
    fetchCitizenship();
    // initTwitter();
    dispatch(citiesListFunction()).then(res => {
      setCityArray(res.data);
    });
  }, []);
  // console.log(mandatoryFields, 'mandatory');
  const [emailAddress, setemailAddress] = useState(
    stepOneDetailBorrower?.emailAddress ? stepOneDetailBorrower?.emailAddress : '',
  );
  const [confirmEmailAddress, setconfirmEmailAddress] = useState(
    stepOneDetailBorrower?.confirmEmailAddress ? stepOneDetailBorrower?.confirmEmailAddress : '',
  );
  const [password, setpassword] = useState(stepOneDetailBorrower?.password ? stepOneDetailBorrower?.password : '');
  const [confirmPassword, setconfirmPassword] = useState(
    stepOneDetailBorrower?.confirmPassword ? stepOneDetailBorrower?.confirmPassword : '',
  );
  const [firstName, setfirstName] = useState(stepOneDetailBorrower?.firstName ? stepOneDetailBorrower?.firstName : '');
  const [middleName, setmiddleName] = useState(stepOneDetailBorrower?.middleName ? stepOneDetailBorrower?.middleName : '');
  const [lastName, setlastName] = useState(stepOneDetailBorrower?.lastName ? stepOneDetailBorrower?.lastName : '');
  const [secondLastName, setsecondLastName] = useState(
    stepOneDetailBorrower?.secondLastName ? stepOneDetailBorrower?.secondLastName : '',
  );
  const [mobileNumber, setmobileNumber] = useState(
    stepOneDetailBorrower?.mobileNumber ? stepOneDetailBorrower?.mobileNumber : '',
  );
  const [mobileNumberCountryCode, setmobileNumberCountryCode] = useState('+966');
  const [alternativeNumber, setalternativeNumber] = useState(
    stepOneDetailBorrower?.alternativeNumber ? stepOneDetailBorrower?.alternativeNumber : '',
  );
  const [alternativeNumberCountryCode, setalternativeNumberCountryCode] = useState(
    stepOneDetailBorrower?.alternativeNumberCountryCode ? stepOneDetailBorrower?.alternativeNumberCountryCode : '',
  );
  const [landLineNumber, setlandLineNumber] = useState(
    stepOneDetailBorrower?.landLineNumber ? stepOneDetailBorrower?.landLineNumber : '',
  );
  const [dateOfBirth, setdateOfBirth] = useState(
    stepOneDetailBorrower?.dateOfBirth ? stepOneDetailBorrower?.dateOfBirth : '',
  );
  const [referralSource, setreferralSource] = useState(
    stepOneDetailBorrower?.referralSource ? stepOneDetailBorrower?.referralSource : '',
  );
  const [gender, setgender] = useState(stepOneDetailBorrower?.gender ? stepOneDetailBorrower?.gender : '');
  const [maritalStatus, setmaritalStatus] = useState(
    stepOneDetailBorrower?.maritalStatus ? stepOneDetailBorrower?.maritalStatus : '',
  );
  const [individualOrCompany, setindividualOrCompany] = useState(
    stepOneDetailBorrower?.individualOrCompany ? stepOneDetailBorrower?.individualOrCompany : '',
  );
  const [referralCode, setreferralCode] = useState(
    stepOneDetailBorrower?.referralCode ? stepOneDetailBorrower?.referralCode : '',
  );
  const [placeOfBirthState, setplaceOfBirthState] = useState(
    stepOneDetailBorrower?.placeOfBirthState ? stepOneDetailBorrower?.placeOfBirthState : '',
  );
  const [placeOfBirthCity, setplaceOfBirthCity] = useState(
    stepOneDetailBorrower?.placeOfBirthCity ? stepOneDetailBorrower?.placeOfBirthCity : '',
  );
  const [identityNumberOne, setidentityNumberOne] = useState(
    stepOneDetailBorrower?.identityNumberOne ? stepOneDetailBorrower?.identityNumberOne : '',
  );
  const [identityNumberTwo, setidentityNumberTwo] = useState(
    stepOneDetailBorrower?.identityNumberTwo ? stepOneDetailBorrower?.identityNumberTwo : '',
  );
  const [borrowerCategory, setborrowerCategory] = useState(
    stepOneDetailBorrower?.borrowerCategory ? stepOneDetailBorrower?.borrowerCategory : '',
  );
  const [citizenship, setcitizenship] = useState(
    stepOneDetailBorrower?.citizenship ? stepOneDetailBorrower?.citizenship : '',
  );
  const [religion, setreligion] = useState(stepOneDetailBorrower?.religion ? stepOneDetailBorrower?.religion : '');
  const [fatherOrHusbandName, setfatherOrHusbandName] = useState(
    stepOneDetailBorrower?.fatherOrHusbandName ? stepOneDetailBorrower?.fatherOrHusbandName : '',
  );
  const [motherMaidenName, setmotherMaidenName] = useState(
    stepOneDetailBorrower?.motherMaidenName ? stepOneDetailBorrower?.motherMaidenName : '',
  );
  const [spouseName, setspouseName] = useState(stepOneDetailBorrower?.spouseName ? stepOneDetailBorrower?.spouseName : '');
  const [numberOfDependents, setnumberOfDependents] = useState(
    stepOneDetailBorrower?.numberOfDependents ? stepOneDetailBorrower?.numberOfDependents : '',
  );
  const [employmentStatusOfSpouse, setemploymentStatusOfSpouse] = useState(
    stepOneDetailBorrower?.employmentStatusOfSpouse ? stepOneDetailBorrower?.employmentStatusOfSpouse : '',
  );
  const [casteCategory, setcasteCategory] = useState(
    stepOneDetailBorrower?.casteCategory ? stepOneDetailBorrower?.casteCategory : '',
  );
  const [identityNumberThree, setidentityNumberThree] = useState(
    stepOneDetailBorrower?.identityNumberThree ? stepOneDetailBorrower?.identityNumberThree : '',
  );
  const [identityNumberFour, setidentityNumberFour] = useState(
    stepOneDetailBorrower?.identityNumberFour ? stepOneDetailBorrower?.identityNumberFour : '',
  );
  const [isPersonWithDisability, setisPersonWithDisability] = useState(
    stepOneDetailBorrower?.isPersonWithDisability ? stepOneDetailBorrower?.isPersonWithDisability : '',
  );
  const [vehicleOwnershipType, setvehicleOwnershipType] = useState(
    stepOneDetailBorrower?.vehicleOwnershipType ? stepOneDetailBorrower?.vehicleOwnershipType : '',
  );
  const [alternativeUsername, setalternativeUsername] = useState(
    stepOneDetailBorrower?.alternativeUsername ? stepOneDetailBorrower?.alternativeUsername : '',
  );
  const [title, settitle] = useState(stepOneDetailBorrower?.title ? stepOneDetailBorrower?.title : []);
  const [firstSchoolName, setfirstSchoolName] = useState(
    stepOneDetailBorrower?.firstSchoolName ? stepOneDetailBorrower?.firstSchoolName : '',
  );
  const [addressOneLine1, setaddressOneLine1] = useState(
    stepOneDetailBorrower?.addressOneLine1 ? stepOneDetailBorrower?.addressOneLine1 : '',
  );
  const [addressOneLine2, setaddressOneLine2] = useState(
    stepOneDetailBorrower?.addressOneLine2 ? stepOneDetailBorrower?.addressOneLine2 : '',
  );
  const [addressOneLine3, setaddressOneLine3] = useState(
    stepOneDetailBorrower?.addressOneLine3 ? stepOneDetailBorrower?.addressOneLine3 : '',
  );
  const [addressOneCity, setaddressOneCity] = useState(
    stepOneDetailBorrower?.addressOneCity ? stepOneDetailBorrower?.addressOneCity : '',
  );
  const [addressOneState, setaddressOneState] = useState(
    stepOneDetailBorrower?.addressOneState ? stepOneDetailBorrower?.addressOneState : '',
  );
  const [addressOneZipCode, setaddressOneZipCode] = useState(
    stepOneDetailBorrower?.addressOneZipCode ? stepOneDetailBorrower?.addressOneZipCode : '',
  );
  const [addressOneLivingSince, setaddressOneLivingSince] = useState(
    stepOneDetailBorrower?.addressOneLivingSince ? stepOneDetailBorrower?.addressOneLivingSince : '',
  );
  const [promotionCode, setpromotionCode] = useState(
    stepOneDetailBorrower?.promotionCode ? stepOneDetailBorrower?.promotionCode : '',
  );
  const [borrowerProfileTextVariable1, setborrowerProfileTextVariable1] = useState(
    stepOneDetailBorrower?.borrowerProfileTextVariable1 ? stepOneDetailBorrower?.borrowerProfileTextVariable1 : '',
  );
  const [borrowerProfileTextVariable2, setborrowerProfileTextVariable2] = useState(
    stepOneDetailBorrower?.borrowerProfileTextVariable2 ? stepOneDetailBorrower?.borrowerProfileTextVariable2 : '',
  );
  const [borrowerProfileTextVariable3, setborrowerProfileTextVariable3] = useState(
    stepOneDetailBorrower?.borrowerProfileTextVariable3 ? stepOneDetailBorrower?.borrowerProfileTextVariable3 : '',
  );
  const [borrowerProfileTextVariable4, setborrowerProfileTextVariable4] = useState(
    stepOneDetailBorrower?.borrowerProfileTextVariable4 ? stepOneDetailBorrower?.borrowerProfileTextVariable4 : '',
  );
  const [borrowerProfileTextVariable5, setborrowerProfileTextVariable5] = useState(
    stepOneDetailBorrower?.borrowerProfileTextVariable5 ? stepOneDetailBorrower?.borrowerProfileTextVariable5 : '',
  );
  const [borrowerProfileTextVariable6, setborrowerProfileTextVariable6] = useState(
    stepOneDetailBorrower?.borrowerProfileTextVariable6 ? stepOneDetailBorrower?.borrowerProfileTextVariable6 : '',
  );
  const [borrowerProfileTextVariable7, setborrowerProfileTextVariable7] = useState(
    stepOneDetailBorrower?.borrowerProfileTextVariable7 ? stepOneDetailBorrower?.borrowerProfileTextVariable7 : '',
  );
  const [borrowerProfileTextVariable8, setborrowerProfileTextVariable8] = useState(
    stepOneDetailBorrower?.borrowerProfileTextVariable8 ? stepOneDetailBorrower?.borrowerProfileTextVariable8 : '',
  );
  const [borrowerProfileTextVariable9, setborrowerProfileTextVariable9] = useState(
    stepOneDetailBorrower?.borrowerProfileTextVariable9 ? stepOneDetailBorrower?.borrowerProfileTextVariable9 : '',
  );
  const [borrowerProfileTextVariable10, setborrowerProfileTextVariable10] = useState(
    stepOneDetailBorrower?.borrowerProfileTextVariable10 ? stepOneDetailBorrower?.borrowerProfileTextVariable10 : '',
  );
  const [borrowerProfileDateVariable1, setborrowerProfileDateVariable1] = useState(
    stepOneDetailBorrower?.borrowerProfileDateVariable1 ? stepOneDetailBorrower?.borrowerProfileDateVariable1 : '',
  );
  const [borrowerProfileDateVariable2, setborrowerProfileDateVariable2] = useState(
    stepOneDetailBorrower?.borrowerProfileDateVariable2 ? stepOneDetailBorrower?.borrowerProfileDateVariable2 : '',
  );
  const [borrowerProfileDateVariable3, setborrowerProfileDateVariable3] = useState(
    stepOneDetailBorrower?.borrowerProfileDateVariable3 ? stepOneDetailBorrower?.borrowerProfileDateVariable3 : '',
  );
  const [fatherFullName, setfatherFullName] = useState(
    stepOneDetailBorrower?.fatherFullName ? stepOneDetailBorrower?.fatherFullName : '',
  );
  const [fatherFirstName, setfatherFirstName] = useState(
    stepOneDetailBorrower?.fatherFirstName ? stepOneDetailBorrower?.fatherFirstName : '',
  );
  const [fatherMiddleName, setfatherMiddleName] = useState(
    stepOneDetailBorrower?.fatherMiddleName ? stepOneDetailBorrower?.fatherMiddleName : '',
  );
  const [fatherLastName, setfatherLastName] = useState(
    stepOneDetailBorrower?.fatherLastName ? stepOneDetailBorrower?.fatherLastName : '',
  );
  const [motherFullName, setmotherFullName] = useState(
    stepOneDetailBorrower?.motherFullName ? stepOneDetailBorrower?.motherFullName : '',
  );
  const [motherFirstName, setmotherFirstName] = useState(
    stepOneDetailBorrower?.motherFirstName ? stepOneDetailBorrower?.motherFirstName : '',
  );
  const [motherMiddleName, setmotherMiddleName] = useState(
    stepOneDetailBorrower?.motherMiddleName ? stepOneDetailBorrower?.motherMiddleName : '',
  );
  const [motherLastName, setmotherLastName] = useState(
    stepOneDetailBorrower?.motherLastName ? stepOneDetailBorrower?.motherLastName : '',
  );
  const [husbandFullName, sethusbandFullName] = useState(
    stepOneDetailBorrower?.husbandFullName ? stepOneDetailBorrower?.husbandFullName : '',
  );
  const [husbandFirstName, sethusbandFirstName] = useState(
    stepOneDetailBorrower?.husbandFirstName ? stepOneDetailBorrower?.husbandFirstName : '',
  );
  const [husbandLastName, sethusbandLastName] = useState(
    stepOneDetailBorrower?.husbandLastName ? stepOneDetailBorrower?.husbandLastName : '',
  );
  const [husbandMiddleName, sethusbandMiddleName] = useState(
    stepOneDetailBorrower?.husbandMiddleName ? stepOneDetailBorrower?.husbandMiddleName : '',
  );
  const [spouseFullName, setspouseFullName] = useState(
    stepOneDetailBorrower?.spouseFullName ? stepOneDetailBorrower?.spouseFullName : '',
  );
  const [spouseFirstName, setspouseFirstName] = useState(
    stepOneDetailBorrower?.spouseFirstName ? stepOneDetailBorrower?.spouseFirstName : '',
  );
  const [spouseMiddleName, setspouseMiddleName] = useState(
    stepOneDetailBorrower?.spouseMiddleName ? stepOneDetailBorrower?.spouseMiddleName : '',
  );
  const [spouseLastName, setspouseLastName] = useState(
    stepOneDetailBorrower?.spouseLastName ? stepOneDetailBorrower?.spouseLastName : '',
  );

  const getStepOneDetailsBorrower = async () => {
    let stepOneDetailsBorrower = localStorage.getItem('borrowerStepOne');
    let emailValidated = localStorage.getItem('emailValidated');
    let mobilevliadated = localStorage.getItem('mobilevliadated');
    // console.log(stepOneDetailsBorrower, 'stepOneDetailsBorrower');
    if (stepOneDetailsBorrower) {
      let value = JSON.parse(stepOneDetailsBorrower);
      setstepOneDetailBorrower(value);
      setemailAddress(value.emailAddress);
      setconfirmEmailAddress(value.confirmEmailAddress);
      setpassword(value.password);
      setconfirmPassword(value.confirmPassword);
      setfirstName(value.firstName);
      setmiddleName(value.middleName);
      setlastName(value.lastName);
      setsecondLastName(value.secondLastName);
      setmobileNumber(value.mobileNumber);
      setmobileNumberCountryCode(value.mobileNumberCountryCode);
      setalternativeNumber(value.alternativeNumber);
      setalternativeNumberCountryCode(value.alternativeNumberCountryCode);
      setlandLineNumber(value.landLineNumber);
      setdateOfBirth(value.dateOfBirth);
      setreferralSource(value.referralSource);
      setgender(value.gender);
      setmaritalStatus(value.maritalStatus);
      setindividualOrCompany(value.individualOrCompany);
      setreferralCode(value.referralCode);
      setplaceOfBirthState(value.placeOfBirthState);
      setplaceOfBirthCity(value.placeOfBirthCity);
      setidentityNumberOne(value.identityNumberOne);
      setidentityNumberTwo(value.identityNumberTwo);
      setborrowerCategory(value.borrowerCategory);
      setreligion(value.religion);
      setfatherOrHusbandName(value.fatherOrHusbandName);
      setmotherMaidenName(value.motherMaidenName);
      setspouseName(value.spouseName);
      setnumberOfDependents(value.numberOfDependents);
      setemploymentStatusOfSpouse(value.employmentStatusOfSpouse);
      setcasteCategory(value.casteCategory);
      setidentityNumberThree(value.identityNumberThree);
      setidentityNumberFour(value.identityNumberFour);
      setisPersonWithDisability(value.isPersonWithDisability);
      setvehicleOwnershipType(value.vehicleOwnershipType);
      setalternativeUsername(value.alternativeUsername);
      settitle(value.title);
      setfirstSchoolName(value.firstSchoolName);
      setaddressOneLine1(value.addressOneLine1);
      setaddressOneLine2(value.addressOneLine2);
      setaddressOneLine3(value.addressOneLine3);
      setaddressOneCity(value.addressOneCity);
      setaddressOneState(value.addressOneState);
      setaddressOneZipCode(value.addressOneZipCode);
      setaddressOneLivingSince(value.addressOneLivingSince);
      setpromotionCode(value.promotionCode);
      setborrowerProfileTextVariable1(value.borrowerProfileTextVariable1);
      setborrowerProfileTextVariable2(value.borrowerProfileTextVariable2);
      setborrowerProfileTextVariable3(value.borrowerProfileTextVariable3);
      setborrowerProfileTextVariable4(value.borrowerProfileTextVariable4);
      setborrowerProfileTextVariable5(value.borrowerProfileTextVariable5);
      setborrowerProfileTextVariable6(value.borrowerProfileTextVariable6);
      setborrowerProfileTextVariable7(value.borrowerProfileTextVariable7);
      setborrowerProfileTextVariable8(value.borrowerProfileTextVariable8);
      setborrowerProfileTextVariable9(value.borrowerProfileTextVariable9);
      setborrowerProfileTextVariable10(value.borrowerProfileTextVariable10);
      setborrowerProfileDateVariable1(value.borrowerProfileDateVariable1);
      setborrowerProfileDateVariable2(value.borrowerProfileDateVariable2);
      setborrowerProfileDateVariable3(value.borrowerProfileDateVariable3);
      setfatherFullName(value.fatherFullName);
      setfatherFirstName(value.fatherFirstName);
      setfatherMiddleName(value.fatherMiddleName);
      setfatherLastName(value.fatherLastName);
      setmotherFullName(value.motherFullName);
      setmotherFirstName(value.motherFirstName);
      setmotherMiddleName(value.motherMiddleName);
      setmotherLastName(value.motherLastName);
      sethusbandFullName(value.husbandFullName);
      sethusbandFirstName(value.husbandFirstName);
      sethusbandLastName(value.husbandLastName);
      sethusbandMiddleName(value.husbandMiddleName);
      setspouseFullName(value.spouseFullName);
      setspouseFirstName(value.spouseFirstName);
      setspouseMiddleName(value.spouseMiddleName);
      setspouseLastName(value.spouseLastName);
      setMobileValidated(mobilevliadated);
      setEmailValidated(emailValidated);
    }
  };

  const Register_step1 = () => {
    let inputs = {
      // lastName: name.replace(/\s/g, ''),
      // userName: email.replace(/\s/g, ''),
      // confirmUserName: confirmEmail.replace(/\s/g, ''),
      // password: password.replace(/\s/g, ''),
      // retypePassword: confirmPassword,
      // contact: mobile,
      // contactCountryCode: '+966',
      //   lastName: lastName,
      //   userName: userName,
      //   confirmUserName: confirmUserName,
      //   password: password,
      //   retypePassword: retypePassword,
      //   contact: contact,
      //   contactCountryCode: '+966',
      //   referralSource: referralSource,
      //   religion: religion,
      //   gender: gender,
      //   maritalStatus: maritalStatus,
      //   referralCode: referralCode,
      //   secondLastName: secondLastName,
      //   alternativeNumber: alternativeNumber,
      //   alternativeNumberCountryCode: alternativeNumberCountryCode,
      //   landLineNumber: landLineNumber,
      //   placeOfBirthState: placeOfBirthState,
      //   placeOfBirthCity: placeOfBirthCity,
      //   identityNumberOne: identityNumberOne,
      //   identityNumberTwo: identityNumberTwo,
      //   alternativeUsername: alternativeUsername,
      //   casteCategory: casteCategory,
      //   identityNumberThree: identityNumberThree,
      //   identityNumberFour: identityNumberFour,
      //   isPersonWithDisability: isPersonWithDisability,
      //   title: title,
      //   firstSchoolName: firstSchoolName,
      emailAddress: emailAddress,
      confirmEmailAddress: confirmEmailAddress,
      password: password,
      confirmPassword: confirmPassword,
      firstName: firstName,
      middleName: middleName,
      lastName: lastName,
      secondLastName: secondLastName,
      mobileNumber: mobileNumber,
      mobileNumberCountryCode: '+966',
      alternativeNumber: alternativeNumber,
      alternativeNumberCountryCode: alternativeNumberCountryCode,
      landLineNumber: landLineNumber,
      dateOfBirth: dateOfBirth,
      referralSource: referralSource,
      gender: gender,
      maritalStatus: maritalStatus,
      individualOrCompany: individualOrCompany,
      referralCode: referralCode,
      placeOfBirthState: placeOfBirthState,
      placeOfBirthCity: placeOfBirthCity,
      identityNumberOne: identityNumberOne,
      identityNumberTwo: identityNumberTwo,
      borrowerCategory: borrowerCategory,
      // citizenship: 'Saudi Arabia',
      religion: religion,
      fatherOrHusbandName: fatherOrHusbandName,
      motherMaidenName: motherMaidenName,
      spouseName: spouseName,
      numberOfDependents: numberOfDependents,
      employmentStatusOfSpouse: employmentStatusOfSpouse,
      casteCategory: casteCategory,
      identityNumberThree: identityNumberThree,
      identityNumberFour: identityNumberFour,
      isPersonWithDisability: isPersonWithDisability,
      vehicleOwnershipType: vehicleOwnershipType,
      alternativeUsername: alternativeUsername,
      title: title,
      firstSchoolName: firstSchoolName,
      addressOneLine1: addressOneLine1,
      addressOneLine2: addressOneLine2,
      addressOneLine3: addressOneLine3,
      addressOneCity: addressOneCity,
      addressOneState: addressOneState,
      addressOneZipCode: addressOneZipCode,
      addressOneLivingSince: addressOneLivingSince,
      promotionCode: promotionCode,
      borrowerProfileTextVariable1: borrowerProfileTextVariable1,
      borrowerProfileTextVariable2: borrowerProfileTextVariable2,
      borrowerProfileTextVariable3: borrowerProfileTextVariable3,
      borrowerProfileTextVariable4: borrowerProfileTextVariable4,
      borrowerProfileTextVariable5: borrowerProfileTextVariable5,
      borrowerProfileTextVariable6: borrowerProfileTextVariable6,
      borrowerProfileTextVariable7: borrowerProfileTextVariable7,
      borrowerProfileTextVariable8: borrowerProfileTextVariable8,
      borrowerProfileTextVariable9: borrowerProfileTextVariable9,
      borrowerProfileTextVariable10: borrowerProfileTextVariable10,
      borrowerProfileDateVariable1: borrowerProfileDateVariable1,
      borrowerProfileDateVariable2: borrowerProfileDateVariable2,
      borrowerProfileDateVariable3: borrowerProfileDateVariable3,
      fatherFullName: fatherFullName,
      fatherFirstName: fatherFirstName,
      fatherMiddleName: fatherMiddleName,
      fatherLastName: fatherLastName,
      motherFullName: motherFullName,
      motherFirstName: motherFirstName,
      motherMiddleName: motherMiddleName,
      motherLastName: motherLastName,
      husbandFullName: husbandFullName,
      husbandFirstName: husbandFirstName,
      husbandLastName: husbandLastName,
      husbandMiddleName: husbandMiddleName,
      spouseFullName: spouseFullName,
      spouseFirstName: spouseFirstName,
      spouseMiddleName: spouseMiddleName,
      spouseLastName: spouseLastName,
    };
    // let inputData = inputs;
    // return;
    // console.log(inputs, 'inputs');

    return;
    dispatch(registrationBorrowerStepOneBasicFunction(inputs)).then(res => {
      // console.log(res.data);
      if (res.data.code === '200') {
        Swal.fire({
          title: res.data.status,
          text: res.data.message,
          showDenyButton: false,
          showCancelButton: false,
          icon: 'success',
          confirmButtonText: 'Okay',
        }).then(result => {
          /* Read more about isConfirmed, isDenied below */
          if (result.isConfirmed) {
            history.push('/signupborrowertwo');
          } else if (result.isDenied) {
            return;
          }
        });
        localStorage.setItem('newUser', res.data.access_token);
      } else {
        Swal.fire({
          title: res.data.status,
          text: res.data.message,
          icon: 'info',
          confirmButtonText: 'Okay',
        });
      }
    });
  };
  let isLoading = useSelector(state => state.registrationBorrowerStepOneBasicReducer.isFetching);
  const [seconds, setSeconds] = useState(60);
  const [resendButton, setResendButton] = useState(true);
  const [resendButtonCount, setResendButtonCount] = useState(3);
  const [timeLeft, setTimeLeft] = useState(seconds);
  useEffect(() => {
    if (!otpModal) {
      return;
    }
    if (timeLeft == 0) {
      setResendButton(false);
    }
    // exit early when we reach 0
    if (!timeLeft) return;
    // save intervalId to clear the interval when the
    // component re-renders
    const intervalId = setInterval(() => {
      setTimeLeft(timeLeft - 1);
    }, 1000);
    // clear interval on re-render to avoid memory leaks
    return () => {
      clearInterval(intervalId);
    };
    // add timeLeft as a dependency to re-rerun the effect
    // when we update it
  }, [timeLeft, otpModal]);
  const refreshButton = () => {
    setTimeLeft(seconds);
    setResendButton(true);
    setResendButtonCount(resendButtonCount - 1);
    sendOtp();
  };
  return (
    <AuthWrapper variant={'signup'}>
      {isFetching && (
        <div>
          <PageLoader />
        </div>
      )}
      <SnackBarComponent
        status={responseStatus}
        visible={response}
        setResponse={setResponse}
        message={responseMessage}
        setResponseStatus={setResponseStatus}
        setResponseMessage={setResponseMessage}
      />
      {variant === 'default' ? (
        <>
          <Box className={classes.authThumb}>
            <CmtImage src={'/images/auth/sign-up-img.png'} />
          </Box>
        </>
      ) : null}
      <Box className={classes.authContent}>
        <Loader isLoading={isLoading} />
        <Typography component="div" variant="h1" className={classes.titleRoot}>
          <IntlMessages id="registration.borrowerregistration" />
        </Typography>

        {showFields && (
          <>
            <form
              onSubmit={e => {
                sendOtp();
                e.preventDefault();
              }}>
              {mandatoryFields['FIRST_NAME']?.showField && (
                <Box mb={2}>
                  <TextField
                    label={mandatoryFields['FIRST_NAME'].fieldLabel}
                    fullWidth
                    required
                    onChange={event => setfirstName(event.target.value)}
                    margin="normal"
                    value={firstName}
                    variant="outlined"
                    className={classes.textFieldRoot}
                  />
                </Box>
              )}
              {mandatoryFields['MIDDLE_NAME']?.showField && (
                <Box mb={2}>
                  <TextField
                    label={mandatoryFields['MIDDLE_NAME'].fieldLabel}
                    fullWidth
                    required
                    onChange={event => setmiddleName(event.target.value)}
                    margin="normal"
                    value={middleName}
                    variant="outlined"
                    className={classes.textFieldRoot}
                  />
                </Box>
              )}
              {mandatoryFields['LAST_NAME']?.showField && (
                <Box mb={2}>
                  <TextField
                    label={mandatoryFields['LAST_NAME'].fieldLabel}
                    fullWidth
                    required
                    onChange={event => setlastName(event.target.value)}
                    margin="normal"
                    value={lastName}
                    variant="outlined"
                    className={classes.textFieldRoot}
                  />
                </Box>
              )}
              {mandatoryFields['SECOND_LAST_NAME']?.showField && (
                <Box mb={2}>
                  <TextField
                    label={mandatoryFields['SECOND_LAST_NAME'].fieldLabel}
                    fullWidth
                    required
                    onChange={event => setsecondLastName(event.target.value)}
                    margin="normal"
                    value={secondLastName}
                    variant="outlined"
                    className={classes.textFieldRoot}
                  />
                </Box>
              )}
              {mandatoryFields['EMAIL_ADDRESS']?.showField && (
                <Box mb={2}>
                  <TextField
                    label={mandatoryFields['EMAIL_ADDRESS'].fieldLabel}
                    fullWidth
                    required
                    onBlur={() => checkUniqueEmail()}
                    onChange={event => setemailAddress(event.target.value)}
                    margin="normal"
                    value={emailAddress}
                    variant="outlined"
                    className={classes.textFieldRoot}
                  />
                </Box>
              )}
              <Snackbar open={uniqueEmail} autoHideDuration={2000} onClose={() => setUniqueEmail(false)}>
                <Alert
                  onClose={() => setUniqueEmail()}
                  severity={uniqueEmailMessage?.status === '200' ? 'success' : 'error'}>
                  {uniqueEmailMessage?.message}
                </Alert>
              </Snackbar>
              {mandatoryFields['CONFIRM_EMAIL_ADDRESS']?.showField && (
                <Box mb={2}>
                  <TextField
                    label={mandatoryFields['CONFIRM_EMAIL_ADDRESS'].fieldLabel}
                    fullWidth
                    required
                    onChange={event => setconfirmEmailAddress(event.target.value)}
                    margin="normal"
                    value={confirmEmailAddress}
                    variant="outlined"
                    className={classes.textFieldRoot}
                  />
                </Box>
              )}
              <Grid container spacing={12}>
                <Grid item xs={3} md={2} lg={2}>
                  {mandatoryFields['MOBILE_NUMBER_COUNTRY_CODE']?.showField && (
                    <Box mb={2}>
                      <TextField
                        fullWidth
                        onChange={event => setmobileNumberCountryCode(event.target.value)}
                        margin="normal"
                        value={'+966'}
                        disabled
                        variant="outlined"
                        className={classes.textFieldRoot}
                      />
                    </Box>
                  )}
                </Grid>
                <Grid item xs={9} md={10} lg={10}>
                  {mandatoryFields['MOBILE_NUMBER']?.showField && (
                    <Box mb={2}>
                      <TextField
                        label={languageCheck == 'en' ? 'Mobile Number' : 'رقم الجوال'}
                        fullWidth
                        required
                        onBlur={() => checkUniqueMobile()}
                        onChange={event => setmobileNumber(event.target.value)}
                        margin="normal"
                        value={mobileNumber}
                        variant="outlined"
                        className={classes.textFieldRoot}
                      />
                    </Box>
                  )}
                  <Snackbar open={uniqueMobile} autoHideDuration={2000} onClose={() => setUniqueMobile(false)}>
                    <Alert
                      onClose={() => setUniqueMobile()}
                      severity={uniqueMobileMessage?.status === '200' ? 'success' : 'error'}>
                      {uniqueMobileMessage?.message}
                    </Alert>
                  </Snackbar>
                </Grid>
              </Grid>
              {mandatoryFields['PASSWORD']?.showField && (
                <Box mb={2}>
                  <TextField
                    label={mandatoryFields['PASSWORD'].fieldLabel}
                    fullWidth
                    required
                    type="password"
                    onChange={event => handlePassword(event.target.value)}
                    margin="normal"
                    variant="outlined"
                    className={classes.textFieldRoot}
                  />
                </Box>
              )}
              {errorMsg.length > 0 && (
                <Box mb={2}>
                  {errorMsg[0] && <Typography variant="caption">{errorMsg[0]}</Typography>}
                  <br />
                  {errorMsg[1] && <Typography variant="caption">{errorMsg[1]}</Typography>}
                  <br />
                  {errorMsg[2] && <Typography variant="caption">{errorMsg[2]}</Typography>}
                  <br />
                  {errorMsg[3] && <Typography variant="caption">{errorMsg[3]}</Typography>}
                </Box>
              )}
              {mandatoryFields['CONFIRM_PASSWORD']?.showField && (
                <Box mb={2}>
                  <TextField
                    label={mandatoryFields['CONFIRM_PASSWORD'].fieldLabel}
                    fullWidth
                    required
                    type="password"
                    onChange={event => setconfirmPassword(event.target.value)}
                    margin="normal"
                    variant="outlined"
                    className={classes.textFieldRoot}
                  />
                </Box>
              )}
              {mandatoryFields['ALTERNATIVE_NUMBER']?.showField && (
                <Box mb={2}>
                  <TextField
                    label={mandatoryFields['ALTERNATIVE_NUMBER'].fieldLabel}
                    fullWidth
                    required
                    onChange={event => setalternativeNumber(event.target.value)}
                    margin="normal"
                    value={mobileNumber}
                    variant="outlined"
                    className={classes.textFieldRoot}
                  />
                </Box>
              )}
              {mandatoryFields['ALTERNATIVE_NUMBER_COUNTRY_CODE']?.showField && (
                <Box mb={2}>
                  <TextField
                    label={mandatoryFields['ALTERNATIVE_NUMBER_COUNTRY_CODE'].fieldLabel}
                    fullWidth
                    required
                    onChange={event => setalternativeNumberCountryCode(event.target.value)}
                    margin="normal"
                    value={alternativeNumberCountryCode}
                    variant="outlined"
                    className={classes.textFieldRoot}
                  />
                </Box>
              )}
              {mandatoryFields['LANDLINE_NUMBER']?.showField && (
                <Box mb={2}>
                  <TextField
                    label={mandatoryFields['LANDLINE_NUMBER'].fieldLabel}
                    fullWidth
                    required
                    onChange={event => setlandLineNumber(event.target.value)}
                    margin="normal"
                    variant="outlined"
                    value={landLineNumber}
                    className={classes.textFieldRoot}
                  />
                </Box>
              )}
              {mandatoryFields['DATE_OF_BIRTH']?.showField && (
                <Box mb={2}>
                  <TextField
                    label={mandatoryFields['DATE_OF_BIRTH'].fieldLabel}
                    fullWidth
                    required
                    onChange={event => setdateOfBirth(event.target.value)}
                    margin="normal"
                    value={dateOfBirth}
                    variant="outlined"
                    className={classes.textFieldRoot}
                  />
                </Box>
              )}
              {mandatoryFields['REFERRAL_SOURCE']?.showField && (
                <Box mb={2}>
                  <TextField
                    label={mandatoryFields['REFERRAL_SOURCE'].fieldLabel}
                    fullWidth
                    required
                    onChange={event => setreferralSource(event.target.value)}
                    margin="normal"
                    value={referralSource}
                    variant="outlined"
                    className={classes.textFieldRoot}
                  />
                </Box>
              )}
              {mandatoryFields['PERSONAL_GENDER']?.showField && (
                <Box mb={2}>
                  <TextField
                    label={mandatoryFields['PERSONAL_GENDER'].fieldLabel}
                    fullWidth
                    required
                    onChange={event => setgender(event.target.value)}
                    margin="normal"
                    value={gender}
                    variant="outlined"
                    className={classes.textFieldRoot}
                  />
                </Box>
              )}
              {mandatoryFields['PERSONAL_MARITAL_STATUS']?.showField && (
                <Box mb={2}>
                  <TextField
                    label={mandatoryFields['PERSONAL_MARITAL_STATUS'].fieldLabel}
                    fullWidth
                    required
                    onChange={event => setmaritalStatus(event.target.value)}
                    margin="normal"
                    value={maritalStatus}
                    variant="outlined"
                    className={classes.textFieldRoot}
                  />
                </Box>
              )}
              {mandatoryFields['OPTION_TO_CHOOSE_INDIVIDUAL_OR_COMPANY']?.showField && (
                <Box mb={2}>
                  <TextField
                    label={mandatoryFields['OPTION_TO_CHOOSE_INDIVIDUAL_OR_COMPANY'].fieldLabel}
                    fullWidth
                    required
                    onChange={event => setindividualOrCompany(event.target.value)}
                    margin="normal"
                    value={individualOrCompany}
                    variant="outlined"
                    className={classes.textFieldRoot}
                  />
                </Box>
              )}
              {mandatoryFields['REFERRAL_CODE']?.showField && (
                <Box mb={2}>
                  <TextField
                    label={mandatoryFields['REFERRAL_CODE'].fieldLabel}
                    fullWidth
                    required
                    onChange={event => setreferralCode(event.target.value)}
                    margin="normal"
                    value={referralCode}
                    variant="outlined"
                    className={classes.textFieldRoot}
                  />
                </Box>
              )}
              {mandatoryFields['PLACE_OF_BIRTH_STATE']?.showField && (
                <Box mb={2}>
                  <TextField
                    label={mandatoryFields['PLACE_OF_BIRTH_STATE'].fieldLabel}
                    fullWidth
                    required
                    onChange={event => setplaceOfBirthState(event.target.value)}
                    margin="normal"
                    value={placeOfBirthState}
                    variant="outlined"
                    className={classes.textFieldRoot}
                  />
                </Box>
              )}
              {mandatoryFields['PLACE_OF_BIRTH_CITY']?.showField && (
                <Box mb={2}>
                  <TextField
                    label={mandatoryFields['PLACE_OF_BIRTH_CITY'].fieldLabel}
                    fullWidth
                    required
                    onChange={event => setplaceOfBirthCity(event.target.value)}
                    margin="normal"
                    value={placeOfBirthCity}
                    variant="outlined"
                    className={classes.textFieldRoot}
                  />
                </Box>
              )}
              {mandatoryFields['IDENTITY_NUMBER_ONE']?.showField && (
                <Box mb={2}>
                  <TextField
                    label={mandatoryFields['IDENTITY_NUMBER_ONE'].fieldLabel}
                    fullWidth
                    required
                    onChange={event => setidentityNumberOne(event.target.value)}
                    margin="normal"
                    value={identityNumberOne}
                    variant="outlined"
                    className={classes.textFieldRoot}
                  />
                </Box>
              )}
              {mandatoryFields['IDENTITY_NUMBER_TWO']?.showField && (
                <Box mb={2}>
                  <TextField
                    label={mandatoryFields['IDENTITY_NUMBER_TWO'].fieldLabel}
                    fullWidth
                    required
                    onChange={event => setidentityNumberTwo(event.target.value)}
                    margin="normal"
                    value={identityNumberTwo}
                    variant="outlined"
                    className={classes.textFieldRoot}
                  />
                </Box>
              )}
              {mandatoryFields['BORROWER_CATEGORY']?.showField && (
                <Box mb={2}>
                  <TextField
                    label={mandatoryFields['BORROWER_CATEGORY'].fieldLabel}
                    fullWidth
                    required
                    onChange={event => setborrowerCategory(event.target.value)}
                    margin="normal"
                    value={borrowerCategory}
                    variant="outlined"
                    className={classes.textFieldRoot}
                  />
                </Box>
              )}
              {/* {mandatoryFields['PERSONAL_CITIZENSHIP']?.showField && (
                <Box mb={2}>
                  <TextField
                    label={mandatoryFields['PERSONAL_CITIZENSHIP'].fieldLabel}
                    fullWidth
                    required
                    onChange={event => setcitizenship(event.target.value)}
                    margin="normal"
                    variant="outlined"
                    className={classes.textFieldRoot}
                  />
                </Box>
              )} */}
              {mandatoryFields['RELIGION_TYPE']?.showField && (
                <Box mb={2}>
                  <TextField
                    label={mandatoryFields['RELIGION_TYPE'].fieldLabel}
                    fullWidth
                    required
                    onChange={event => setreligion(event.target.value)}
                    margin="normal"
                    value={religion}
                    variant="outlined"
                    className={classes.textFieldRoot}
                  />
                </Box>
              )}
              {mandatoryFields['FATHER_HUSBAND_NAME']?.showField && (
                <Box mb={2}>
                  <TextField
                    label={mandatoryFields['FATHER_HUSBAND_NAME'].fieldLabel}
                    fullWidth
                    required
                    onChange={event => setfatherOrHusbandName(event.target.value)}
                    margin="normal"
                    value={fatherOrHusbandName}
                    variant="outlined"
                    className={classes.textFieldRoot}
                  />
                </Box>
              )}
              {mandatoryFields['MOTHER_MAIDEN_NAME']?.showField && (
                <Box mb={2}>
                  <TextField
                    label={mandatoryFields['MOTHER_MAIDEN_NAME'].fieldLabel}
                    fullWidth
                    required
                    onChange={event => setmotherMaidenName(event.target.value)}
                    margin="normal"
                    value={motherMaidenName}
                    variant="outlined"
                    className={classes.textFieldRoot}
                  />
                </Box>
              )}
              {mandatoryFields['SPOUSE_NAME']?.showField && (
                <Box mb={2}>
                  <TextField
                    label={mandatoryFields['SPOUSE_NAME'].fieldLabel}
                    fullWidth
                    required
                    onChange={event => setspouseName(event.target.value)}
                    margin="normal"
                    value={spouseName}
                    variant="outlined"
                    className={classes.textFieldRoot}
                  />
                </Box>
              )}
              {mandatoryFields['NUMBER_OF_DEPENDENTS']?.showField && (
                <Box mb={2}>
                  <TextField
                    label={mandatoryFields['NUMBER_OF_DEPENDENTS'].fieldLabel}
                    fullWidth
                    required
                    onChange={event => setnumberOfDependents(event.target.value)}
                    margin="normal"
                    value={numberOfDependents}
                    variant="outlined"
                    className={classes.textFieldRoot}
                  />
                </Box>
              )}
              {mandatoryFields['EMPLOYEMENT_STATUS_OF_SPOUSE']?.showField && (
                <Box mb={2}>
                  <TextField
                    label={mandatoryFields['EMPLOYEMENT_STATUS_OF_SPOUSE'].fieldLabel}
                    fullWidth
                    required
                    onChange={event => setemploymentStatusOfSpouse(event.target.value)}
                    margin="normal"
                    value={employmentStatusOfSpouse}
                    variant="outlined"
                    className={classes.textFieldRoot}
                  />
                </Box>
              )}
              {mandatoryFields['CASTE_CATEGORY']?.showField && (
                <Box mb={2}>
                  <TextField
                    label={mandatoryFields['CASTE_CATEGORY'].fieldLabel}
                    fullWidth
                    required
                    onChange={event => setcasteCategory(event.target.value)}
                    margin="normal"
                    value={casteCategory}
                    variant="outlined"
                    className={classes.textFieldRoot}
                  />
                </Box>
              )}
              {mandatoryFields['IDENTITY_NUMBER_THREE']?.showField && (
                <Box mb={2}>
                  <TextField
                    label={mandatoryFields['IDENTITY_NUMBER_THREE'].fieldLabel}
                    fullWidth
                    required
                    onChange={event => setidentityNumberThree(event.target.value)}
                    margin="normal"
                    value={identityNumberThree}
                    variant="outlined"
                    className={classes.textFieldRoot}
                  />
                </Box>
              )}
              {mandatoryFields['IDENTITY_NUMBER_FOUR']?.showField && (
                <Box mb={2}>
                  <TextField
                    label={mandatoryFields['IDENTITY_NUMBER_FOUR'].fieldLabel}
                    fullWidth
                    required
                    onChange={event => setidentityNumberFour(event.target.value)}
                    margin="normal"
                    value={identityNumberFour}
                    variant="outlined"
                    className={classes.textFieldRoot}
                  />
                </Box>
              )}
              {mandatoryFields['PERSON_WITH_DISABILITY']?.showField && (
                <Box mb={2}>
                  <TextField
                    label={mandatoryFields['PERSON_WITH_DISABILITY'].fieldLabel}
                    fullWidth
                    required
                    onChange={event => setisPersonWithDisability(event.target.value)}
                    margin="normal"
                    value={isPersonWithDisability}
                    variant="outlined"
                    className={classes.textFieldRoot}
                  />
                </Box>
              )}
              {mandatoryFields['TYPE_OF_VEHICLE_OWNERSHIP']?.showField && (
                <Box mb={2}>
                  <TextField
                    label={mandatoryFields['TYPE_OF_VEHICLE_OWNERSHIP'].fieldLabel}
                    fullWidth
                    required
                    onChange={event => setvehicleOwnershipType(event.target.value)}
                    margin="normal"
                    value={vehicleOwnershipType}
                    variant="outlined"
                    className={classes.textFieldRoot}
                  />
                </Box>
              )}
              {mandatoryFields['ALTERNATIVE_USERNAME']?.showField && (
                <Box mb={2}>
                  <TextField
                    label={mandatoryFields['ALTERNATIVE_USERNAME'].fieldLabel}
                    fullWidth
                    required
                    onChange={event => setalternativeUsername(event.target.value)}
                    margin="normal"
                    value={alternativeUsername}
                    variant="outlined"
                    className={classes.textFieldRoot}
                  />
                </Box>
              )}
              {mandatoryFields['TITLE']?.showField && (
                <Box mb={2}>
                  <TextField
                    label={mandatoryFields['TITLE'].fieldLabel}
                    fullWidth
                    required
                    onChange={event => settitle(event.target.value)}
                    margin="normal"
                    value={title}
                    variant="outlined"
                    className={classes.textFieldRoot}
                  />
                </Box>
              )}
              {mandatoryFields['FIRST_SCHOOL_NAME']?.showField && (
                <Box mb={2}>
                  <TextField
                    label={mandatoryFields['FIRST_SCHOOL_NAME'].fieldLabel}
                    fullWidth
                    required
                    onChange={event => setfirstSchoolName(event.target.value)}
                    margin="normal"
                    value={firstSchoolName}
                    variant="outlined"
                    className={classes.textFieldRoot}
                  />
                </Box>
              )}
              {mandatoryFields['ADDRESS_ONE']?.showField && (
                <Box mb={2}>
                  <TextField
                    label={'Line1'}
                    fullWidth
                    onChange={event => setaddressOneLine1(event.target.value)}
                    margin="normal"
                    value={addressOneLine1}
                    variant="outlined"
                    className={classes.textFieldRoot}
                  />
                </Box>
              )}
              {mandatoryFields['ADDRESS_ONE']?.showField && (
                <Box mb={2}>
                  <TextField
                    label={'Line2'}
                    fullWidth
                    onChange={event => setaddressOneLine2(event.target.value)}
                    margin="normal"
                    value={addressOneLine2}
                    variant="outlined"
                    className={classes.textFieldRoot}
                  />
                </Box>
              )}
              {mandatoryFields['ADDRESS_ONE']?.showField && (
                <Box mb={2}>
                  <TextField
                    label={'Line3'}
                    fullWidth
                    onChange={event => setaddressOneLine3(event.target.value)}
                    margin="normal"
                    value={addressOneLine3}
                    variant="outlined"
                    className={classes.textFieldRoot}
                  />
                </Box>
              )}
              {mandatoryFields['ADDRESS_ONE']?.showField && (
                <Box mb={2}>
                  <TextField
                    label={'City'}
                    fullWidth
                    onChange={event => setaddressOneCity(event.target.value)}
                    margin="normal"
                    value={addressOneCity}
                    variant="outlined"
                    className={classes.textFieldRoot}
                  />
                </Box>
              )}
              {mandatoryFields['ADDRESS_ONE']?.showField && (
                <Box mb={2}>
                  <TextField
                    label={'State'}
                    fullWidth
                    onChange={event => setaddressOneState(event.target.value)}
                    margin="normal"
                    value={addressOneState}
                    variant="outlined"
                    className={classes.textFieldRoot}
                  />
                </Box>
              )}
              {mandatoryFields['ADDRESS_ONE']?.showField && (
                <Box mb={2}>
                  <TextField
                    label={'Zipcode'}
                    fullWidth
                    onChange={event => setaddressOneZipCode(event.target.value)}
                    margin="normal"
                    value={addressOneZipCode}
                    variant="outlined"
                    className={classes.textFieldRoot}
                  />
                </Box>
              )}
              {mandatoryFields['ADDRESS_ONE']?.showField && (
                <Box mb={2}>
                  <TextField
                    label={'Living since'}
                    fullWidth
                    onChange={event => setaddressOneLivingSince(event.target.value)}
                    margin="normal"
                    value={addressOneLivingSince}
                    variant="outlined"
                    className={classes.textFieldRoot}
                  />
                </Box>
              )}
              {mandatoryFields['PERSONAL_PROMOTION_CODE']?.showField && (
                <Box mb={2}>
                  <TextField
                    label={mandatoryFields['PERSONAL_PROMOTION_CODE'].fieldLabel}
                    fullWidth
                    required
                    onChange={event => setpromotionCode(event.target.value)}
                    margin="normal"
                    value={promotionCode}
                    variant="outlined"
                    className={classes.textFieldRoot}
                  />
                </Box>
              )}
              {mandatoryFields['BORROWER_PROFILE_TEXT_VARIABLE_1']?.showField && (
                <Box mb={2}>
                  <TextField
                    label={mandatoryFields['BORROWER_PROFILE_TEXT_VARIABLE_1'].fieldLabel}
                    fullWidth
                    required
                    onChange={event => setborrowerProfileTextVariable1(event.target.value)}
                    margin="normal"
                    value={borrowerProfileTextVariable1}
                    variant="outlined"
                    className={classes.textFieldRoot}
                  />
                </Box>
              )}
              {mandatoryFields['BORROWER_PROFILE_TEXT_VARIABLE_2']?.showField && (
                <Box mb={2}>
                  <TextField
                    label={mandatoryFields['BORROWER_PROFILE_TEXT_VARIABLE_2'].fieldLabel}
                    fullWidth
                    required
                    onChange={event => setborrowerProfileTextVariable2(event.target.value)}
                    margin="normal"
                    value={borrowerProfileTextVariable2}
                    variant="outlined"
                    className={classes.textFieldRoot}
                  />
                </Box>
              )}
              {mandatoryFields['BORROWER_PROFILE_TEXT_VARIABLE_3']?.showField && (
                <Box mb={2}>
                  <TextField
                    label={mandatoryFields['BORROWER_PROFILE_TEXT_VARIABLE_3'].fieldLabel}
                    fullWidth
                    required
                    onChange={event => setborrowerProfileTextVariable3(event.target.value)}
                    margin="normal"
                    value={borrowerProfileTextVariable3}
                    variant="outlined"
                    className={classes.textFieldRoot}
                  />
                </Box>
              )}
              {mandatoryFields['BORROWER_PROFILE_TEXT_VARIABLE_4']?.showField && (
                <Box mb={2}>
                  <TextField
                    label={mandatoryFields['BORROWER_PROFILE_TEXT_VARIABLE_4'].fieldLabel}
                    fullWidth
                    required
                    onChange={event => setborrowerProfileTextVariable4(event.target.value)}
                    margin="normal"
                    value={borrowerProfileTextVariable4}
                    variant="outlined"
                    className={classes.textFieldRoot}
                  />
                </Box>
              )}
              {mandatoryFields['BORROWER_PROFILE_TEXT_VARIABLE_5']?.showField && (
                <Box mb={2}>
                  <TextField
                    label={mandatoryFields['BORROWER_PROFILE_TEXT_VARIABLE_5'].fieldLabel}
                    fullWidth
                    required
                    onChange={event => setborrowerProfileTextVariable5(event.target.value)}
                    margin="normal"
                    variant="outlined"
                    className={classes.textFieldRoot}
                  />
                </Box>
              )}
              {mandatoryFields['BORROWER_PROFILE_TEXT_VARIABLE_6']?.showField && (
                <Box mb={2}>
                  <TextField
                    label={mandatoryFields['BORROWER_PROFILE_TEXT_VARIABLE_6'].fieldLabel}
                    fullWidth
                    required
                    onChange={event => setborrowerProfileTextVariable6(event.target.value)}
                    margin="normal"
                    variant="outlined"
                    className={classes.textFieldRoot}
                  />
                </Box>
              )}
              {mandatoryFields['BORROWER_PROFILE_TEXT_VARIABLE_7']?.showField && (
                <Box mb={2}>
                  <TextField
                    label={mandatoryFields['BORROWER_PROFILE_TEXT_VARIABLE_7'].fieldLabel}
                    fullWidth
                    required
                    onChange={event => setborrowerProfileTextVariable7(event.target.value)}
                    margin="normal"
                    variant="outlined"
                    className={classes.textFieldRoot}
                  />
                </Box>
              )}
              {mandatoryFields['BORROWER_PROFILE_TEXT_VARIABLE_8']?.showField && (
                <Box mb={2}>
                  <TextField
                    label={mandatoryFields['BORROWER_PROFILE_TEXT_VARIABLE_8'].fieldLabel}
                    fullWidth
                    required
                    onChange={event => setborrowerProfileTextVariable8(event.target.value)}
                    margin="normal"
                    variant="outlined"
                    className={classes.textFieldRoot}
                  />
                </Box>
              )}
              {mandatoryFields['BORROWER_PROFILE_TEXT_VARIABLE_9']?.showField && (
                <Box mb={2}>
                  <TextField
                    label={mandatoryFields['BORROWER_PROFILE_TEXT_VARIABLE_9'].fieldLabel}
                    fullWidth
                    required
                    onChange={event => setborrowerProfileTextVariable9(event.target.value)}
                    margin="normal"
                    variant="outlined"
                    className={classes.textFieldRoot}
                  />
                </Box>
              )}
              {mandatoryFields['BORROWER_PROFILE_TEXT_VARIABLE_10']?.showField && (
                <Box mb={2}>
                  <TextField
                    label={mandatoryFields['BORROWER_PROFILE_TEXT_VARIABLE_10'].fieldLabel}
                    fullWidth
                    required
                    onChange={event => setborrowerProfileTextVariable10(event.target.value)}
                    margin="normal"
                    variant="outlined"
                    className={classes.textFieldRoot}
                  />
                </Box>
              )}
              {mandatoryFields['BORROWER_PROFILE_DATE_VARIABLE_1']?.showField && (
                <Box mb={2}>
                  <TextField
                    label={mandatoryFields['BORROWER_PROFILE_DATE_VARIABLE_1'].fieldLabel}
                    fullWidth
                    required
                    onChange={event => setborrowerProfileDateVariable1(event.target.value)}
                    margin="normal"
                    variant="outlined"
                    className={classes.textFieldRoot}
                  />
                </Box>
              )}
              {mandatoryFields['BORROWER_PROFILE_DATE_VARIABLE_2']?.showField && (
                <Box mb={2}>
                  <TextField
                    label={mandatoryFields['BORROWER_PROFILE_DATE_VARIABLE_2'].fieldLabel}
                    fullWidth
                    required
                    onChange={event => setborrowerProfileDateVariable2(event.target.value)}
                    margin="normal"
                    variant="outlined"
                    className={classes.textFieldRoot}
                  />
                </Box>
              )}
              {mandatoryFields['BORROWER_PROFILE_DATE_VARIABLE_3']?.showField && (
                <Box mb={2}>
                  <TextField
                    label={mandatoryFields['BORROWER_PROFILE_DATE_VARIABLE_3'].fieldLabel}
                    fullWidth
                    required
                    onChange={event => setborrowerProfileDateVariable3(event.target.value)}
                    margin="normal"
                    variant="outlined"
                    className={classes.textFieldRoot}
                  />
                </Box>
              )}
              {mandatoryFields['FATHER_FULL_NAME']?.showField && (
                <Box mb={2}>
                  <TextField
                    label={mandatoryFields['FATHER_FULL_NAME'].fieldLabel}
                    fullWidth
                    required
                    onChange={event => setfatherFullName(event.target.value)}
                    margin="normal"
                    variant="outlined"
                    className={classes.textFieldRoot}
                  />
                </Box>
              )}
              {mandatoryFields['FATHER_FIRST_NAME']?.showField && (
                <Box mb={2}>
                  <TextField
                    label={mandatoryFields['FATHER_FIRST_NAME'].fieldLabel}
                    fullWidth
                    required
                    onChange={event => setfatherFirstName(event.target.value)}
                    margin="normal"
                    variant="outlined"
                    className={classes.textFieldRoot}
                  />
                </Box>
              )}
              {mandatoryFields['FATHER_MIDDLE_NAME']?.showField && (
                <Box mb={2}>
                  <TextField
                    label={mandatoryFields['FATHER_MIDDLE_NAME'].fieldLabel}
                    fullWidth
                    required
                    onChange={event => setfatherMiddleName(event.target.value)}
                    margin="normal"
                    variant="outlined"
                    className={classes.textFieldRoot}
                  />
                </Box>
              )}
              {mandatoryFields['FATHER_LAST_NAME']?.showField && (
                <Box mb={2}>
                  <TextField
                    label={mandatoryFields['FATHER_LAST_NAME'].fieldLabel}
                    fullWidth
                    required
                    onChange={event => setfatherLastName(event.target.value)}
                    margin="normal"
                    variant="outlined"
                    className={classes.textFieldRoot}
                  />
                </Box>
              )}
              {mandatoryFields['MOTHER_FULL_NAME']?.showField && (
                <Box mb={2}>
                  <TextField
                    label={mandatoryFields['MOTHER_FULL_NAME'].fieldLabel}
                    fullWidth
                    required
                    onChange={event => setmotherFullName(event.target.value)}
                    margin="normal"
                    variant="outlined"
                    className={classes.textFieldRoot}
                  />
                </Box>
              )}
              {mandatoryFields['MOTHER_FIRST_NAME']?.showField && (
                <Box mb={2}>
                  <TextField
                    label={mandatoryFields['MOTHER_FIRST_NAME'].fieldLabel}
                    fullWidth
                    required
                    onChange={event => setmotherFirstName(event.target.value)}
                    margin="normal"
                    variant="outlined"
                    className={classes.textFieldRoot}
                  />
                </Box>
              )}
              {mandatoryFields['MOTHER_MIDDLE_NAME']?.showField && (
                <Box mb={2}>
                  <TextField
                    label={mandatoryFields['MOTHER_MIDDLE_NAME'].fieldLabel}
                    fullWidth
                    required
                    onChange={event => setmotherMiddleName(event.target.value)}
                    margin="normal"
                    variant="outlined"
                    className={classes.textFieldRoot}
                  />
                </Box>
              )}
              {mandatoryFields['MOTHER_LAST_NAME']?.showField && (
                <Box mb={2}>
                  <TextField
                    label={mandatoryFields['MOTHER_LAST_NAME'].fieldLabel}
                    fullWidth
                    required
                    onChange={event => setmotherLastName(event.target.value)}
                    margin="normal"
                    variant="outlined"
                    className={classes.textFieldRoot}
                  />
                </Box>
              )}
              {mandatoryFields['HUSBAND_FULL_NAME']?.showField && (
                <Box mb={2}>
                  <TextField
                    label={mandatoryFields['HUSBAND_FULL_NAME'].fieldLabel}
                    fullWidth
                    required
                    onChange={event => sethusbandFullName(event.target.value)}
                    margin="normal"
                    variant="outlined"
                    className={classes.textFieldRoot}
                  />
                </Box>
              )}
              {mandatoryFields['HUSBAND_FIRST_NAME']?.showField && (
                <Box mb={2}>
                  <TextField
                    label={mandatoryFields['HUSBAND_FIRST_NAME'].fieldLabel}
                    fullWidth
                    required
                    onChange={event => sethusbandFirstName(event.target.value)}
                    margin="normal"
                    variant="outlined"
                    className={classes.textFieldRoot}
                  />
                </Box>
              )}
              {mandatoryFields['HUSBAND_LAST_NAME']?.showField && (
                <Box mb={2}>
                  <TextField
                    label={mandatoryFields['HUSBAND_LAST_NAME'].fieldLabel}
                    fullWidth
                    required
                    onChange={event => sethusbandLastName(event.target.value)}
                    margin="normal"
                    variant="outlined"
                    className={classes.textFieldRoot}
                  />
                </Box>
              )}
              {mandatoryFields['HUSBAND_MIDDLE_NAME']?.showField && (
                <Box mb={2}>
                  <TextField
                    label={mandatoryFields['HUSBAND_MIDDLE_NAME'].fieldLabel}
                    fullWidth
                    required
                    onChange={event => sethusbandMiddleName(event.target.value)}
                    margin="normal"
                    variant="outlined"
                    className={classes.textFieldRoot}
                  />
                </Box>
              )}
              {mandatoryFields['SPOUSE_FULL_NAME']?.showField && (
                <Box mb={2}>
                  <TextField
                    label={mandatoryFields['SPOUSE_FULL_NAME'].fieldLabel}
                    fullWidth
                    required
                    onChange={event => setspouseFullName(event.target.value)}
                    margin="normal"
                    variant="outlined"
                    className={classes.textFieldRoot}
                  />
                </Box>
              )}
              {mandatoryFields['SPOUSE_FIRST_NAME']?.showField && (
                <Box mb={2}>
                  <TextField
                    label={mandatoryFields['SPOUSE_FIRST_NAME'].fieldLabel}
                    fullWidth
                    required
                    onChange={event => setspouseFirstName(event.target.value)}
                    margin="normal"
                    variant="outlined"
                    className={classes.textFieldRoot}
                  />
                </Box>
              )}
              {mandatoryFields['SPOUSE_MIDDLE_NAME']?.showField && (
                <Box mb={2}>
                  <TextField
                    label={mandatoryFields['SPOUSE_MIDDLE_NAME'].fieldLabel}
                    fullWidth
                    required
                    onChange={event => setspouseMiddleName(event.target.value)}
                    margin="normal"
                    variant="outlined"
                    className={classes.textFieldRoot}
                  />
                </Box>
              )}
              {mandatoryFields['SPOUSE_LAST_NAME']?.showField && (
                <Box mb={2}>
                  <TextField
                    label={mandatoryFields['SPOUSE_LAST_NAME'].fieldLabel}
                    fullWidth
                    required
                    onChange={event => setspouseLastName(event.target.value)}
                    margin="normal"
                    variant="outlined"
                    className={classes.textFieldRoot}
                  />
                </Box>
              )}
              <Box
                display="flex"
                flexDirection={{ xs: 'column', sm: 'row' }}
                alignItems={{ sm: 'center' }}
                justifyContent={{ sm: 'space-between' }}
                mb={3}>
                <Box mb={{ xs: 2, sm: 0 }}>
                  <Button type="submit" variant="contained" color="primary">
                    <IntlMessages id="appModule.next" />
                  </Button>
                </Box>
              </Box>
            </form>
            <Typography className={classes.textAcc}>
              <IntlMessages id="registration.haveanaccount" />
              <NavLink to="/signin">
                <IntlMessages id="registration.signin" />
              </NavLink>
              {/* <NavLink to="/signuptwo">Step two</NavLink> */}
            </Typography>
          </>
        )}
        <div>
          <Dialog open={showAlert}>
            <DialogContent>
              <DialogContentText>{}</DialogContentText>

              <DialogContentText>{}</DialogContentText>
            </DialogContent>

            <DialogActions>
              <Button onClick={() => setShowAlert(false)}>Cancel</Button>
              {/* <Button onClick={() => (code === '200' ? history.push('/signuptwo') : setShowAlert(false))}>Ok</Button> */}
            </DialogActions>
          </Dialog>
        </div>
        <div>
          <Dialog fullWidth open={otpModal}>
            <DialogContent>
              <DialogContentText>
                <IntlMessages id="registration.otpverifiy" />
              </DialogContentText>
              <TextField
                label=""
                autoComplete="off"
                fullWidth
                onChange={event => setOtp(event.target.value)}
                // defaultValue={password}
                margin="normal"
                variant="outlined"
                // className={classes.textFieldRoot}
              />
              <Snackbar open={showsnakbar} autoHideDuration={6000} onClose={() => setshowsnakbar(false)}>
                <Alert onClose={() => setshowsnakbar(false)} severity="success">
                  <IntlMessages id="registration.otpsend" />
                </Alert>
              </Snackbar>
            </DialogContent>

            <DialogActions>
              <Button onClick={() => setOtpModal(false)}>
                <IntlMessages id="registration.cancel" />
              </Button>
              {resendButton == true ? (
                <Button onClick={() => verifyOtp()}>
                  <IntlMessages id="registration.submit" />
                </Button>
              ) : (
                <Button
                  onClick={() => {
                    refreshButton();
                  }}>
                  <IntlMessages id="registration.resend" />
                </Button>
              )}
            </DialogActions>
            {timeLeft != 0 ? (
              <DialogContentText style={{ textAlign: 'center' }}>
                {' '}
                <IntlMessages id="registration.resendotp" /> {timeLeft}s
              </DialogContentText>
            ) : null}
          </Dialog>
        </div>
      </Box>
    </AuthWrapper>
  );
};

export default SignUpBorrower;
