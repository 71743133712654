import React, { lazy, Suspense } from 'react';
import { Redirect, Route, Switch } from 'react-router';
import PageLoader from '../../@jumbo/components/PageComponents/PageLoader';
// import SessionExpiry from '../SessionExpiry/SessionExpiry';

const Dashboards = ({ match }) => {
  // SessionExpiry();
  const requestedUrl = match.url.replace(/\/$/, '');
  return (
    <Suspense fallback={<PageLoader />}>
      <Switch>
        <Redirect exact from={`${requestedUrl}/`} to={`${requestedUrl}/Dashboard`} />
        <Route path={`${requestedUrl}/crypto`} component={lazy(() => import('./Crypto'))} />
        <Route path={`${requestedUrl}/Dashboard`} component={lazy(() => import('./Dashboard'))} />
        <Route path={`${requestedUrl}/crm`} component={lazy(() => import('./Crm'))} />
        <Route path={`${requestedUrl}/financeRequest`} component={lazy(() => import('./FinanceRequest'))} />
        <Route path={`${requestedUrl}/intranet`} component={lazy(() => import('./Intranet'))} />
        <Route path={`${requestedUrl}/allOffers`} component={lazy(() => import('./Offers'))} />
        <Route path={`${requestedUrl}/receivables`} component={lazy(() => import('./Receivables'))} />
        <Route path={`${requestedUrl}/transactions`} component={lazy(() => import('./Transactions'))} />
        <Route path={`${requestedUrl}/financedAmount`} component={lazy(() => import('./FinancedAmount'))} />
        <Route path={`${requestedUrl}/financingCriteria`} component={lazy(() => import('./FinancingCreteria'))} />
        <Route path={`${requestedUrl}/loanPublishingSoon`} component={lazy(() => import('./LoanPublishingSoon'))} />
        <Route path={`${requestedUrl}/dashboardStatus`} component={lazy(() => import('./DashboardStatus'))} />
        <Route path={`${requestedUrl}/accountDetail`} component={lazy(() => import('./AccountDetail'))} />
        <Route path={`${requestedUrl}/changePassword`} component={lazy(() => import('./PasswordReset'))} />
        <Route path={`${requestedUrl}/recentTransactions`} component={lazy(() => import('./RecentTransactions'))} />
        <Route path={`${requestedUrl}/sessionExpiry`} component={lazy(() => import('./SessionExpiry'))} />
        <Route component={lazy(() => import('../ExtraPages/404'))} />
      </Switch>
    </Suspense>
  );
};

export default Dashboards;
