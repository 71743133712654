import React from 'react';
import { Box, Fab } from '@material-ui/core';
import makeStyles from '@material-ui/core/styles/makeStyles';
import colors from '../../../../theme/Color';
import LanguageSwitcher from '@jumbo/components/AppLayout/partials/LanguageSwitcher';
const bgImage = '/images/auth/auth-bg-pattern.png';

const useStyles = makeStyles(theme => ({
  authWrap: {
    width: '100%',
    display: 'flex',
    height: 'auto',
    [theme.breakpoints.up('xl')]: {
      height: '80%',
    },
    flexDirection: 'column',
    minHeight: props =>
      props.variant === 'signup'
        ? '110vh'
        : props.variant === 'signupborrower'
        ? '140vh'
        : props.variant === 'stepfive'
        ? '110vh'
        : '80vh',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundImage: props => (props.variant === 'bgColor' ? `URL(${bgImage})` : ''),
    backgroundPosition: props => (props.variant === 'bgColor' ? 'center center' : ''),
    backgroundRepeat: props => (props.variant === 'bgColor' ? 'no-repeat' : ''),
    backgroundSize: props => (props.variant === 'bgColor' ? 'cover' : ''),
    position: 'relative',
    // padding: 20,
    [theme.breakpoints.up('sm')]: {
      padding: 40,
    },
    '&:before': {
      content: '""',
      position: 'absolute',
      left: 0,
      top: 0,
      zIndex: 1,
      width: '100%',
      height: '100%',
      backgroundImage: colors.gradient,
    },
  },
  authCard: {
    position: 'relative',
    zIndex: 3,
    maxWidth: props => (props.variant === 'stepfive' ? '800px' : props.variant === 'default' ? '850px' : '550px'),
    width: '100%',
    boxShadow: '0px 2px 2px rgba(0, 0, 0, 0.14), 0px 3px 1px rgba(0, 0, 0, 0.12), 0px 1px 5px rgba(0, 0, 0, 0.2)',
    borderRadius: 4,
    backgroundColor: theme.palette.background.paper,
    color: theme.palette.text.secondary,
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.up('md')]: {
      flexDirection: 'row',
    },
    // [theme.breakpoints.up('xl')]: {
    //   maxWidth: props => (props.variant === 'default' ? '1050px' : '600px'),
    //   marginRight: props => (props.variant === 'default' ? '' : '150px'),
    // },
  },
}));

const AuthWrapper = ({ children, variant }) => {
  const classes = useStyles({ variant });
  let selected = localStorage.getItem('languageCheck')?.includes('en') ? 'en' : 'ar';
  if (selected === undefined || selected === null) {
    selected = 'en';
  }
  return (
    <Box className={classes.authWrap}>
      {window.location.pathname.includes('signin') && (
        <Fab
          color="transparent"
          aria-label="edit"
          style={{
            position: 'fixed',
            left: '10px',
            top: '100px',
            zIndex: 100000000000,
            backgroundColor: 'white',
          }}>
          <LanguageSwitcher />
        </Fab>
      )}
      <Box className={classes.authCard}>{children}</Box>
    </Box>
  );
};

export default AuthWrapper;
