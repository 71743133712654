import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, fade, FormControl, FormHelperText, Grid, InputLabel, MenuItem, Select, withStyles } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Typography from '@material-ui/core/Typography';
import { NavLink } from 'react-router-dom';
import { CurrentAuthMethod } from '@jumbo/constants/AppConstants';
import IntlMessages from '@jumbo/utils/IntlMessages';
import ContentLoader from '@jumbo/components/ContentLoader';
import AuthWrapper from '@jumbo/components/Common/authComponents/AuthWrapper';
import CmtImage from '@coremat/CmtImage';
import { AuhMethods } from 'services/auth';
import { history } from 'redux/store';
import Alert from 'routes/Components/MuiComponents/Alert';
import { registrationStepFourBankFunction } from 'redux/actions/registrationStepFourBank';
import { Url } from 'redux/NetworkConfig/ApiUrlConstatnts';
import Axios from 'axios';
import { Dialog, DialogActions, DialogContent, DialogContentText } from '@material-ui/core';
import Swal from 'sweetalert2';
import Loader from 'routes/Components/pageLoaderCustom/loader';
import SnackBarComponent from 'routes/Components/SnackBar';
import PageLoader from '@jumbo/components/PageComponents/PageLoader';

const useStyles = makeStyles(theme => ({
  authThumb: {
    backgroundColor: fade(theme.palette.primary.main, 0.12),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: 20,
    [theme.breakpoints.up('md')]: {
      width: '50%',
      order: 2,
    },
  },
  authContent: {
    padding: 30,
    [theme.breakpoints.up('md')]: {
      width: props => (props.variant === 'default' ? '50%' : '100%'),
      order: 1,
    },
    [theme.breakpoints.up('xl')]: {
      padding: 50,
    },
  },
  titleRoot: {
    marginBottom: 14,
    color: theme.palette.text.primary,
  },
  textFieldRoot: {
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: fade(theme.palette.common.dark, 0.12),
    },
  },
  textCapital: {
    textTransform: 'capitalize',
  },
  textAcc: {
    textAlign: 'center',
    '& a': {
      marginLeft: 4,
    },
  },
  alrTextRoot: {
    textAlign: 'center',
    [theme.breakpoints.up('sm')]: {
      textAlign: 'right',
    },
  },
}));

const StyledSelect = withStyles({
  root: {
    '& label': {
      transformOrigin: 'top left',
      right: 10,
      right: 'auto',
    },
    '& legend': {
      textAlign: 'left',
    },
  },
})(FormControl);

//variant = 'default', 'standard', 'bgColor'
const SignUpFour = ({ method = CurrentAuthMethod, variant = 'standard', wrapperVariant = 'default' }) => {
  const [bankName, setBankName] = useState('');
  let userName = localStorage.getItem('userName');
  const [accountName, setAccountName] = useState(userName);
  const [IBAN, setIBAN] = useState('');
  const dispatch = useDispatch();
  const [status, setStatus] = useState('');
  const [message, setMessage] = useState('');
  const [code, setCode] = useState('');
  const [showAlert, setShowAlert] = useState(false);
  const [response, setResponse] = useState(false);
  const [responseMessage, setResponseMessage] = useState('');
  const [responseStatus, setResponseStatus] = useState('');
  const [IBANLoader, setIBANLoader] = useState(false);
  const [showall, setshowall] = useState(false);
  const classes = useStyles({ variant });

  function includeWords(speechResult, expectSt) {
    // Create arrays of words from above sentences
    let speechResultWords = speechResult.split(/\s+/);
    let expectStWords = expectSt.split(/\s+/);
    // Declare a variable to hold the count number of matches
    let arr = [];
    for (let a = 0; a < speechResultWords.length; a++) {
      for (let b = 0; b < expectStWords.length; b++) {
        if (similarity(speechResultWords[a], expectStWords[b]) > 69) {
          arr.push(speechResultWords[a]);

          console.log(speechResultWords[a] + ' includes in ' + expectStWords[b]);
        }
      } // End of first for loop
    } // End of second for loop
    let uniq = [...new Set(arr)];
    let flag;
    if (uniq.length === expectStWords.length) {
      flag = true;
    } else {
      flag = false;
    }
    return flag;
  }

  function similarity(s1, s2) {
    var longer = s1;
    var shorter = s2;
    if (s1.length < s2.length) {
      longer = s2;
      shorter = s1;
    }
    var longerLength = longer.length;
    if (longerLength == 0) {
      return 1.0;
    }
    return ((longerLength - editDistance(longer, shorter)) / parseFloat(longerLength)) * 100;
  }

  function editDistance(s1, s2) {
    s1 = s1.toLowerCase();
    s2 = s2.toLowerCase();

    var costs = new Array();
    for (var i = 0; i <= s1.length; i++) {
      var lastValue = i;
      for (var j = 0; j <= s2.length; j++) {
        if (i == 0) costs[j] = j;
        else {
          if (j > 0) {
            var newValue = costs[j - 1];
            if (s1.charAt(i - 1) != s2.charAt(j - 1)) newValue = Math.min(Math.min(newValue, lastValue), costs[j]) + 1;
            costs[j - 1] = lastValue;
            lastValue = newValue;
          }
        }
      }
      if (i > 0) costs[s2.length] = lastValue;
    }
    return costs[s2.length];
  }

  const fetchIbanDetail = async () => {
    setIBANLoader(true);
    if (IBAN.length != 24) {
      setResponseMessage(<IntlMessages id="registration.verifyIban" />);
      setResponse(true);
      setResponseStatus(500);
      setIBANLoader(false);
      return;
    }
    setIBANLoader(true);
    const url = 'https://raqamyah.net:5000/api/bank/iban/';
    var datas = { iban: IBAN };
    // console.log(datas, 'url');
    // return;
    var config = {
      method: 'post',
      url: url,
      data: datas,
    };

    Axios(config)
      .then(function(response) {
        // handle success
        // alert(JSON.stringify(response.data.totalInvestmentCount));
        // setBankNameArray(response.data);
        // console.log(response.data, 'bankres');

        if (response && response.data) {
          let bankFromApi = String(response?.data?.data?.bank_data.bank).toLowerCase();
          // console.log(bankFromApi, 'bank');
          if (bankFromApi === '') {
            setResponseMessage(<IntlMessages id="registration.validIban" />);
            setResponse(true);
            setResponseStatus(500);
            setIBANLoader(false);
            return;
          }
          // console.log(bankNamearray, 'bank');
          const results = bankNamearray.filter(entry => {
            let objName = String(entry?.name).toLowerCase();
            let replaceName = objName.replace(' the', ' ');
            // console.log(String(entry?.name), 'bankarr');
            return includeWords(objName, bankFromApi);
            // return objName < bankFromApi ? -1 : objName > bankFromApi ? 1 : 0;
          });

          // console.log(results[0].name, 'bankresss');
          setBankName(results[0].name);
          setIBANLoader(false);
          setshowall(true);
        }
      })
      .catch(function(error) {
        // handle error
        alert(error.message);
      });
    // return;
  };

  const Register_step4 = () => {
    let newUser = localStorage.getItem('newUser');

    // navigation.navigate('Register_investor_step_five');
    let inputData = {
      access_token: newUser,
      bankName: bankName,
      accountHolderName: accountName,
      accountNumber: IBAN,
    };
    // console.log(inputData);
    dispatch(registrationStepFourBankFunction(inputData, 'add')).then(res => {
      // console.log(res.data);
      if (res.data.code === '200') {
        setResponseMessage(res.data.message);
        setResponse(true);
        setResponseStatus(res.data.code);
        setTimeout(() => {
          history.push('/signupfive');
        }, 2000);
        // Swal.fire({
        //   title: res.data.status,
        //   text: res.data.message,
        //   showDenyButton: false,
        //   showCancelButton: false,
        //   icon: 'success',
        //   confirmButtonText: 'Okay',
        // }).then(result => {
        //   /* Read more about isConfirmed, isDenied below */
        //   if (result.isConfirmed) {
        //     history.push('/signupfive');
        //   } else if (result.isDenied) {
        //     return;
        //   }
        // });
      } else {
        // Swal.fire(res.data.status, res.data.accountHolderName, 'error');
        setResponseMessage(res.data.accountHolderName);
        setResponse(true);
        setResponseStatus(res.data.status);
      }
    });
  };
  const [bankNamearray, setBankNameArray] = useState([]);
  const getBankName = () => {
    Axios.post(Url.baseUrl + Url.BankName)
      .then(function(response) {
        // handle success
        // alert(JSON.stringify(response.data.totalInvestmentCount));
        setBankNameArray(response.data);
        // console.log(response.data, 'banknamefirst');
      })
      .catch(function(error) {
        // handle error
        alert(error.message);
      });
  };
  useEffect(() => {
    getBankName();
  }, []);
  let isLoading = useSelector(state => state.registrationStepFourBankReducer.isFetching);
  return (
    <AuthWrapper variant={wrapperVariant}>
      <SnackBarComponent
        status={responseStatus}
        visible={response}
        setResponse={setResponse}
        message={responseMessage}
        setResponseStatus={setResponseStatus}
        setResponseMessage={setResponseMessage}
      />

      <Box className={classes.authContent}>
        <Loader isLoading={(isLoading, IBANLoader)} />
        <Typography component="div" variant="h1" className={classes.titleRoot}>
          <IntlMessages id="registration.bankdetails" />
        </Typography>
        <Typography component="div" variant="h6" className={classes.titleRoot} style={{ color: 'grey' }}>
          <IntlMessages id="investor.register.bankDetailsContent" />
        </Typography>
        <form>
          <Box mb={2}>
            <TextField
              label={<IntlMessages id="registration.ibannumber" />}
              fullWidth
              onChange={event => setIBAN(event.target.value)}
              // defaultValue={email}
              margin="normal"
              variant="outlined"
              className={classes.textFieldRoot}
            />
          </Box>
          {!showall ? (
            <div>
              <Box mb={2}>
                <Button fullWidth onClick={() => fetchIbanDetail()} variant="contained" color="primary">
                  <IntlMessages id="registration.verify" />
                </Button>
              </Box>
            </div>
          ) : null}
          {showall ? (
            <div style={{ marginTop: 10 }}>
              <Box mb={2} style={{ cursor: 'no-drop' }}>
                <StyledSelect fullWidth variant="outlined">
                  <InputLabel
                    style={{ paddingBottom: 0, marginTop: 0, fontWeight: 500, backgroundColor: 'white' }}
                    id="demo-simple-select-helper-label">
                    <IntlMessages id="registration.bankname" />
                  </InputLabel>
                  <Select
                    disabled
                    style={{ cursor: 'no-drop' }}
                    labelId="demo-simple-select-helper-label"
                    id="demo-simple-select-helper"
                    value={bankName}
                    variant="outlined"
                    onChange={event => setBankName(event.target.value)}
                    label="Age">
                    <MenuItem value="">
                      <em>None</em>
                    </MenuItem>
                    {bankNamearray.map(item => {
                      return (
                        <MenuItem key={item.uuid} value={item.name}>
                          {item.name}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </StyledSelect>
              </Box>
              <Box mb={2} style={{ cursor: 'no-drop' }}>
                <TextField
                  label={<IntlMessages id="registration.accountholdername" />}
                  fullWidth
                  style={{ cursor: 'no-drop' }}
                  disabled
                  value={userName}
                  onChange={event => setAccountName(event.target.value)}
                  // defaultValue={email}
                  margin="normal"
                  variant="outlined"
                  className={classes.textFieldRoot}
                />
              </Box>
              <Box
                display="flex"
                flexDirection={{ xs: 'column', sm: 'row' }}
                alignItems={{ sm: 'center' }}
                justifyContent={{ sm: 'space-between' }}
                mb={3}>
                <Box mb={{ xs: 2, sm: 0 }}>
                  <Button onClick={() => Register_step4()} variant="contained" color="primary">
                    <IntlMessages id="appModule.next" />
                  </Button>
                </Box>
              </Box>
            </div>
          ) : null}
        </form>
        <div>
          <Dialog open={showAlert}>
            <DialogContent>
              <DialogContentText>{status}</DialogContentText>

              <DialogContentText>{message}</DialogContentText>
            </DialogContent>

            <DialogActions>
              <Button onClick={() => setShowAlert(false)}>Cancel</Button>
              <Button onClick={() => (code === '200' ? history.push('/signupfive') : setShowAlert(false))}>Ok</Button>
            </DialogActions>
          </Dialog>
        </div>
      </Box>
    </AuthWrapper>
  );
};

export default SignUpFour;
