import Footer from '@jumbo/components/Footer/Footer';
import FooterTop from '@jumbo/components/Footer/FooterTop';
import Header from '@jumbo/components/Header/Header';
import React from 'react';
import SignupCompany from 'routes/ExtraPages/sign-up/SignupCompany';

const RegisterCompany = () => {
  return (
    <div style={{ flex: 1 }}>
      <Header />
      <SignupCompany variant="standard" wrapperVariant="bgColor" />
      <FooterTop />
      <Footer />
    </div>
  );
};

export default RegisterCompany;
