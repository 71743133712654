import * as types from '../../actionTypes/types';

const initialState = {
  BorrowerQuestionAdd: {},
  isFetching: false,
  errorMessage: '',
};

const BorrowerQuestionAddReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.FETCHING_BORROWER_QUESTION_ADD_REQUEST:
      return { ...state, isFetching: true };
    case types.FETCHING_BORROWER_QUESTION_ADD_FAILED:
      return {
        ...state,
        isFetching: false,
        errorMessage: action.payload,
      };
    case types.FETCHING_BORROWER_QUESTION_ADD_SUCCESS:
      return {
        ...state,
        isFetching: false,
        BorrowerQuestionAdd: action.payload,
      };

    case types.FETCHING_USER_LOGOUT_DETAILS_SUCCESS:
      state = initialState;
      return state;

    default:
      return state;
  }
};
export default BorrowerQuestionAddReducer;
