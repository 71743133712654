import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Modal from '@material-ui/core/Modal';
import { Box, CircularProgress, Grid } from '@material-ui/core';
import PageContainer from '@jumbo/components/PageComponents/layouts/PageContainer';
import GridContainer from '@jumbo/components/GridContainer';
import PageLoader from '@jumbo/components/PageComponents/PageLoader';
function rand() {
  return Math.round(Math.random() * 20) - 10;
}
function getModalStyle() {
  const top = 50 + rand();
  const left = 50 + rand();
  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}
const useStyles = makeStyles(theme => ({
  circularProgressRoot: {
    position: 'absolute',
    left: 0,
    top: 0,
    zIndex: 123123123213123123123,
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
}));
export default function Loader(props) {
  const classes = useStyles();
  return (
    <PageContainer>
      <GridContainer>
        <Grid item xs={12} lg={12} xl={8}>
          <Modal open={props.isLoading} className={classes.circularProgressRoot}>
            <PageLoader />
          </Modal>
        </Grid>
      </GridContainer>
    </PageContainer>
  );
}
