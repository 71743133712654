import * as types from '../actionTypes/types';
import { httpRequest } from '../NetworkConfig/Config';
import { Url, methods, headers } from '../NetworkConfig/ApiUrlConstatnts';

export const declarationFileUploadBorrowerFunction = inputData => {
  const formData = new FormData();

  return async dispatch => {
    dispatch(declarationFileUploadBorrowerRequest());
    return httpRequest({
      method: methods.post,
      url: Url.DeclarationFileUploadBorrower,
      headers: headers.headwithMultipart,
      data: inputData,
    }).then(JsonResponse => {
      if (JsonResponse !== undefined) {
        if (JsonResponse.status == 200 || JsonResponse.status == 203) {
          let fetchedData =
            JsonResponse.data !== undefined && JsonResponse.data !== null && Object.keys(JsonResponse.data).length !== 0
              ? JsonResponse.data
              : {};

          dispatch(declarationFileUploadBorrowerSuccess(fetchedData));
        } else {
          dispatch(declarationFileUploadBorrowerFailed());
        }
        return JsonResponse;
      } else {
        dispatch(declarationFileUploadBorrowerFailed());
      }
    });
  };
};
export const declarationFileUploadBorrowerRequest = () => ({
  type: types.FETCHING_DECLARATION_FILE_UPLOAD_BORROWER_REQUEST,
});
export const declarationFileUploadBorrowerSuccess = json => ({
  type: types.FETCHING_DECLARATION_FILE_UPLOAD_BORROWER_SUCCESS,
  payload: json,
});
export const declarationFileUploadBorrowerFailed = error => ({
  type: types.FETCHING_DECLARATION_FILE_UPLOAD_BORROWER_FAILED,
  payload: error,
});
export const fetchingUserLogOutDetailsSuccess = () => ({
  type: types.FETCHING_USER_LOGOUT_DETAILS_SUCCESS,
});
