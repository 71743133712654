import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, fade, FormControl, FormHelperText, Grid, InputLabel, MenuItem, Select, withStyles } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Typography from '@material-ui/core/Typography';
import { NavLink } from 'react-router-dom';
import { CurrentAuthMethod } from '@jumbo/constants/AppConstants';
import IntlMessages from '@jumbo/utils/IntlMessages';
import ContentLoader from '@jumbo/components/ContentLoader';
import AuthWrapper from '@jumbo/components/Common/authComponents/AuthWrapper';
import CmtImage from '@coremat/CmtImage';
import { AuhMethods } from 'services/auth';
import { history } from 'redux/store';
import { registrationStepThreeEmployeementFunction } from 'redux/actions/registrationStepThreeEmployeement';
import Alert from 'routes/Components/MuiComponents/Alert';
import { Url } from 'redux/NetworkConfig/ApiUrlConstatnts';
import Axios from 'axios';
import { Dialog, DialogActions, DialogContent, DialogContentText } from '@material-ui/core';
import Swal from 'sweetalert2';
import { Fastfood } from '@material-ui/icons';
import { fetchSuccess } from 'redux/actions';
import Loader from 'routes/Components/pageLoaderCustom/loader';
import SnackBarComponent from 'routes/Components/SnackBar';

const useStyles = makeStyles(theme => ({
  authThumb: {
    backgroundColor: fade(theme.palette.primary.main, 0.12),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: 20,
    [theme.breakpoints.up('md')]: {
      width: '50%',
      order: 2,
    },
  },
  authContent: {
    padding: 30,
    [theme.breakpoints.up('md')]: {
      width: props => (props.variant === 'default' ? '50%' : '100%'),
      order: 1,
    },
    [theme.breakpoints.up('xl')]: {
      padding: 50,
    },
  },
  titleRoot: {
    marginBottom: 14,
    color: theme.palette.text.primary,
  },
  textFieldRoot: {
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: fade(theme.palette.common.dark, 0.12),
    },
  },
  textCapital: {
    textTransform: 'capitalize',
  },
  textAcc: {
    textAlign: 'center',
    '& a': {
      marginLeft: 4,
    },
  },
  alrTextRoot: {
    textAlign: 'center',
    [theme.breakpoints.up('sm')]: {
      textAlign: 'right',
    },
  },
}));
const StyledSelect = withStyles({
  root: {
    '& label': {
      transformOrigin: 'top left',
      right: 10,
      right: 'auto',
    },
    '& legend': {
      textAlign: 'left',
    },
  },
})(FormControl);
//variant = 'default', 'standard', 'bgColor'
const SignUpThree = ({ method = CurrentAuthMethod, variant = 'standard', wrapperVariant = 'default' }) => {
  const [empStatus, setEmpStatus] = useState('');
  const [politicalStatus, setPoliticalStatus] = useState('');
  const [salaryRange, setSalaryRange] = useState('');
  const [employer, setEmployer] = useState('');
  const [industry, setIndustry] = useState('');
  const [Occupation, setOccupation] = useState('');
  const [status, setStatus] = useState('');
  const [message, setMessage] = useState('');
  const [code, setCode] = useState('');
  const [showAlert, setShowAlert] = useState(false);
  const dispatch = useDispatch();
  const classes = useStyles({ variant });
  const [response, setResponse] = useState(false);
  const [responseMessage, setResponseMessage] = useState('');
  const [responseStatus, setResponseStatus] = useState('');
  let languageCheck = localStorage.getItem('languageCheck');
  if (!languageCheck) {
    languageCheck = 'en';
  }
  const Register_step3 = () => {
    let newUser = localStorage.getItem('newUser');
    let inputData = {
      access_token: newUser,
      employmentType: empStatus,
      organizationType: employer,
      investorEmploymentDetailsTextVariable1: industry,
      investorEmploymentDetailsTextVariable2: Occupation,
      investorEmploymentDetailsTextVariable3: politicalStatus,
      investorEmploymentDetailsTextVariable4: salaryRange,
    };

    // console.log(inputData);
    // return;
    dispatch(registrationStepThreeEmployeementFunction(inputData)).then(async res => {
      // console.log(res.data,"Check response");
      if (res.data.responseAttr.code === '200') {
        // Swal.fire(res.data.responseAttr.status, res.data.responseAttr.message, 'success');
        setResponseMessage(res.data.responseAttr.message);
        setResponse(true);
        setResponseStatus(res.data.responseAttr.code);
        setTimeout(() => {
          history.push('/signupfour');
        }, 2000);
        // Swal.fire({
        //   title: res.data.responseAttr.status,
        //   text: res.data.responseAttr.message,
        //   showDenyButton: false,
        //   showCancelButton: false,
        //   icon: 'success',
        //   confirmButtonText: 'Okay',
        // }).then(result => {
        //   /* Read more about isConfirmed, isDenied below */
        //   if (result.isConfirmed) {
        //     history.push('/signupfour');
        //   } else if (result.isDenied) {
        //     return;
        //   }
        // });
      } else {
        setResponseMessage(res.data.responseAttr.message);
        setResponse(true);
        setResponseStatus(res.data.responseAttr.status);
        // Swal.fire(res.data.responseAttr.status, res.data.responseAttr.message, 'error');
      }
    });
  };
  const [industryarray, setIndustryArray] = useState([]);
  const [exposureArray, setExposureArray] = useState([]);
  const [incomeArray, setIncomeArray] = useState([]);
  const getIndustry = () => {
    Axios.post(Url.baseUrl + Url.IndustryList)
      .then(function(response) {
        // handle success
        // alert(JSON.stringify(response.data.totalInvestmentCount));
        setIndustryArray(response.data);
        // console.log(response.data);
      })
      .catch(function(error) {
        // handle error
        alert(error.message);
      });
  };
  const getExposureDetails = () => {
    Axios.post(Url.baseUrl + Url.ExposureList)
      .then(function(response) {
        // handle success
        // alert(JSON.stringify(response.data.totalInvestmentCount));
        setExposureArray(response.data);
        // console.log(response.data);
      })
      .catch(function(error) {
        // handle error
        alert(error.message);
      });
  };
  const getIncome = () => {
    Axios.post(Url.baseUrl + Url.IncomeList)
      .then(function(response) {
        // handle success
        // alert(JSON.stringify(response.data.totalInvestmentCount));
        setIncomeArray(response.data);
        // console.log(response.data);
      })
      .catch(function(error) {
        // handle error
        alert(error.message);
      });
  };
  useEffect(() => {
    getIndustry();
    getExposureDetails();
    getIncome();
  }, []);

  const [employmentarray, setEmploymentArray] = useState([]);
  const getEmployment = () => {
    Axios.post(Url.baseUrl + Url.EmploymentStatus)
      .then(function(response) {
        // handle success
        // alert(JSON.stringify(response.data.totalInvestmentCount));
        setEmploymentArray(response.data);
        // console.log(response.data);
      })
      .catch(function(error) {
        // handle error
        alert(error.message);
      });
  };
  useEffect(() => {
    getEmployment();
  }, []);
  let isLoading = useSelector(state => state.registrationStepThreeEmployeementReducer.isFetching);
  return (
    <AuthWrapper variant={'signup'}>
      <SnackBarComponent
        status={responseStatus}
        visible={response}
        setResponse={setResponse}
        message={responseMessage}
        setResponseStatus={setResponseStatus}
        setResponseMessage={setResponseMessage}
      />
      <Box className={classes.authContent}>
        <Loader isLoading={isLoading} />
        <Typography component="div" variant="h1" className={classes.titleRoot}>
          <IntlMessages id="registration.employmentdetails" />
        </Typography>
        <form>
          <Box mb={2}>
            <StyledSelect fullWidth variant="outlined">
              <InputLabel
                style={{ paddingBottom: 0, marginTop: 0, fontWeight: 500, backgroundColor: 'white' }}
                id="demo-simple-select-helper-label">
                <IntlMessages id="registration.employmentstatus" />
              </InputLabel>
              <Select
                labelId="demo-simple-select-helper-label"
                id="demo-simple-select-helper"
                value={empStatus}
                variant="outlined"
                onChange={event => {
                  // console.log(event.target.value);
                  setEmpStatus(event.target.value);
                }}
                label="Age">
                <MenuItem value="">
                  <em>None</em>
                </MenuItem>
                {employmentarray.map(item => {
                  return (
                    <MenuItem key={item.uuid} value={item.name}>
                      {item.name}
                    </MenuItem>
                  );
                })}
              </Select>
            </StyledSelect>
          </Box>
          <Box mb={2}>
            <TextField
              label={<IntlMessages id="registration.employer" />}
              fullWidth
              onChange={event => setEmployer(event.target.value)}
              // defaultValue={email}
              margin="normal"
              variant="outlined"
              className={classes.textFieldRoot}
            />
          </Box>
          <Box mb={2}>
            <StyledSelect fullWidth variant="outlined">
              <InputLabel
                style={{ paddingBottom: 0, marginTop: 0, fontWeight: 500, backgroundColor: 'white' }}
                id="demo-simple-select-helper-label">
                <IntlMessages id="registration.industry" />
              </InputLabel>
              <Select
                labelId="demo-simple-select-helper-label"
                id="demo-simple-select-helper"
                value={industry}
                variant="outlined"
                onChange={event => {
                  // console.log(event.target.value);
                  setIndustry(event.target.value);
                }}
                label="Age">
                <MenuItem value="">
                  <em>None</em>
                </MenuItem>
                {industryarray.map(item => {
                  return (
                    <MenuItem key={item.uuid} value={item.name}>
                      {item.name}
                    </MenuItem>
                  );
                })}
              </Select>
            </StyledSelect>
          </Box>

          <Box mb={2}>
            <TextField
              label={<IntlMessages id="registration.occupation" />}
              fullWidth
              onChange={event => setOccupation(event.target.value)}
              // defaultValue={email}
              margin="normal"
              variant="outlined"
              className={classes.textFieldRoot}
            />
          </Box>

          <Box mb={2}>
            <StyledSelect fullWidth variant="outlined">
              <InputLabel
                style={{ paddingBottom: 0, marginTop: 0, fontWeight: 500, backgroundColor: 'white' }}
                id="demo-simple-select-helper-label">
                <IntlMessages id="investor.register.politicallyexposed" />
              </InputLabel>
              <Select
                labelId="demo-simple-select-helper-label"
                id="demo-simple-select-helper"
                value={politicalStatus}
                variant="outlined"
                onChange={event => {
                  // console.log(event.target.value);
                  setPoliticalStatus(event.target.value);
                }}
                label="Age">
                {exposureArray.map(item => {
                  return (
                    <MenuItem key={item.name} value={item.name}>
                      {item.name}
                    </MenuItem>
                  );
                })}
              </Select>
            </StyledSelect>
          </Box>
          <Box mb={2}>
            <StyledSelect style={{ marginTop: 10 }} fullWidth variant="outlined">
              <InputLabel
                style={{ paddingBottom: 0, marginTop: 0, fontWeight: 500, backgroundColor: 'white' }}
                id="demo-simple-select-helper-label">
                <IntlMessages id="investor.register.salary" />
              </InputLabel>
              <Select
                labelId="demo-simple-select-helper-label"
                id="demo-simple-select-helper"
                value={salaryRange}
                variant="outlined"
                onChange={event => {
                  // console.log(event.target.value);
                  setSalaryRange(event.target.value);
                }}
                label="Age">
                {incomeArray.map(item => {
                  return (
                    <MenuItem key={item.name} value={item.name}>
                      {item.name}
                    </MenuItem>
                  );
                })}
              </Select>
            </StyledSelect>
          </Box>
          <Box
            display="flex"
            flexDirection={{ xs: 'column', sm: 'row' }}
            alignItems={{ sm: 'center' }}
            justifyContent={{ sm: 'space-between' }}
            mb={3}>
            <Box mb={{ xs: 2, sm: 0 }}>
              <Button onClick={() => Register_step3()} variant="contained" color="primary">
                <IntlMessages id="appModule.next" />
              </Button>
            </Box>
          </Box>
        </form>

        <div>
          <Dialog open={showAlert}>
            <DialogContent>
              <DialogContentText>{status}</DialogContentText>

              <DialogContentText>{message}</DialogContentText>
            </DialogContent>

            <DialogActions>
              <Button onClick={() => setShowAlert(false)}>Cancel</Button>
              <Button
                onClick={() =>
                  code === 'Employment History successfully saved/تم حفظ المعلومات الوظيفية بنجاح'
                    ? history.push('/signupfour')
                    : setShowAlert(false)
                }>
                Okay
              </Button>
            </DialogActions>
          </Dialog>
        </div>
      </Box>
    </AuthWrapper>
  );
};

export default SignUpThree;
