import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink, useHistory } from 'react-router-dom';

import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import {
  Box,
  Collapse,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  fade,
  IconButton,
  Link,
} from '@material-ui/core';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Typography from '@material-ui/core/Typography';
import { Alert } from '@material-ui/lab';
import CloseIcon from '@material-ui/icons/Close';

import CmtImage from '../../../../@coremat/CmtImage';

import IntlMessages from '../../../utils/IntlMessages';
import { AuhMethods } from '../../../../services/auth';
import ContentLoader from '../../ContentLoader';
import { CurrentAuthMethod } from '../../../constants/AppConstants';
import AuthWrapper from './AuthWrapper';
import { setForgetPassMailSent } from '../../../../redux/actions/Auth';
import { forgotPasswordFunction } from '../../../../redux/actions/forgotPassword';
import { Url } from 'redux/NetworkConfig/ApiUrlConstatnts';
import Axios from 'axios';
import Swal from 'sweetalert2';
import SnackBarComponent from 'routes/Components/SnackBar';

const useStyles = makeStyles(theme => ({
  authThumb: {
    backgroundColor: fade(theme.palette.primary.main, 0.12),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: 20,
    [theme.breakpoints.up('md')]: {
      width: '50%',
      order: 2,
    },
  },
  authContent: {
    padding: 30,
    [theme.breakpoints.up('md')]: {
      order: 1,
      width: props => (props.variant === 'default' ? '50%' : '100%'),
    },
    [theme.breakpoints.up('xl')]: {
      padding: 50,
    },
  },
  titleRoot: {
    marginBottom: 14,
    color: theme.palette.text.primary,
  },
  textFieldRoot: {
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: fade(theme.palette.common.dark, 0.12),
    },
  },
  alertRoot: {
    marginBottom: 10,
  },
}));

//variant = 'default', 'standard', 'bgColor'
const ForgotPassword = ({ method = CurrentAuthMethod, variant = 'default', wrapperVariant = 'default' }) => {
  const { send_forget_password_email } = useSelector(({ auth }) => auth);
  const [open, setOpen] = React.useState(false);
  const [email, setEmail] = useState('');
  const dispatch = useDispatch();
  const classes = useStyles({ variant });
  const history = useHistory();
  const [otp, setOtp] = useState(false);
  const [otpModal, setOtpModal] = useState(false);
  const [userUuid, setUserUuid] = useState('');
  const [response, setResponse] = useState(false);
  const [responseMessage, setResponseMessage] = useState('');
  const [responseStatus, setResponseStatus] = useState('');

  const [seconds, setSeconds] = useState(60);
  const [resendButton, setResendButton] = useState(true);
  const [resendButtonCount, setResendButtonCount] = useState(3);
  const [timeLeft, setTimeLeft] = useState(seconds);
  useEffect(() => {
    if (!otpModal) {
      return;
    }
    if (timeLeft == 0) {
      setResendButton(false);
    }
    // exit early when we reach 0
    if (!timeLeft) return;
    // save intervalId to clear the interval when the
    // component re-renders
    const intervalId = setInterval(() => {
      setTimeLeft(timeLeft - 1);
    }, 1000);
    // clear interval on re-render to avoid memory leaks
    return () => {
      clearInterval(intervalId);
    };
    // add timeLeft as a dependency to re-rerun the effect
    // when we update it
  }, [timeLeft, otpModal]);
  const transformEntry = (item, type) => {
    switch (type) {
      case 'email':
        var parts = item.split('@'),
          len = parts[0].length;
        return email.replace(parts[0].slice(1, -1), '*'.repeat(len - 2));
      case 'phone':
        return item[0] + item[1] + '*'.repeat(item.length - 4) + item.slice(-2);
      default:
        throw new Error('Undefined type: ' + type);
    }
  };
  const forgotPassword = () => {
    let inputData = {
      emailId: email.replace(/\s/g, ''),
    };
    dispatch(forgotPasswordFunction(inputData)).then(async res => {
      if (res.data.code === '200') {
        await setOtpModal(true);
        await setUserUuid(res.data.userUuid);
        // alert(res.data.status + '' + res.data.message);
        // Swal.fire(res.data.status, res.data.message, 'success');
        let msg = transformEntry(res.data.message.slice(28, 50), 'phone');
        setResponseMessage('OTP send to the number ' + msg);
        setResponse(true);
        setResponseStatus('200');
      } else {
        // alert(res.data.status + '' + res.data.message);
        Swal.fire(res.data.status, res.data.message, 'info');
      }
    });
  };
  const verifyOtp = async () => {
    const url = Url.baseUrl + Url.ForgotPasswordVerification + `?otp=${otp}&userUuid=${userUuid}`;

    Axios.get(url)
      .then(response => {
        if (response.data.code == '200') {
          setOtpModal(false);
          setOtp('');
          history.push('/');

          // alert(response.data.status + ' ' + response.data.message);
          Swal.fire(response.data.status, response.data.message, 'success');
        } else {
          // alert(response.data.status + ' ' + response.data.message);
          Swal.fire(response.data.status, response.data.message, 'info');
        }
      })
      .catch(function(error) {
        // alert(error.message);
        Swal.fire(error.message);
      });
  };
  useEffect(() => {
    let timeOutStopper = null;
    if (send_forget_password_email) {
      setOpen(true);

      timeOutStopper = setTimeout(() => {
        dispatch(setForgetPassMailSent(false));
        history.push('/');
      }, 1500);
    }

    return () => {
      if (timeOutStopper) clearTimeout(timeOutStopper);
    };
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [send_forget_password_email]);

  const onSubmit = () => {
    dispatch(AuhMethods[method].onForgotPassword({ email }));
  };
  const refreshButton = () => {
    setTimeLeft(seconds);
    setResendButton(true);
    setResendButtonCount(resendButtonCount - 1);
    forgotPassword();
  };
  return (
    <AuthWrapper variant={wrapperVariant}>
      <SnackBarComponent
        status={responseStatus}
        visible={response}
        setResponse={setResponse}
        message={responseMessage}
        setResponseStatus={setResponseStatus}
        setResponseMessage={setResponseMessage}
      />
      {variant === 'default' ? (
        <div className={classes.authThumb}>
          <CmtImage src={'/images/auth/forgot-img.png'} />
        </div>
      ) : null}
      <div className={classes.authContent}>
        <Typography component="div" variant="h1" className={classes.titleRoot}>
          <IntlMessages id="registration.forgotpassword" />
        </Typography>
        <Collapse in={open}>
          <Alert
            variant="outlined"
            severity="success"
            className={classes.alertRoot}
            action={
              <IconButton
                aria-label="close"
                color="inherit"
                size="small"
                onClick={() => {
                  setOpen(false);
                }}>
                <CloseIcon fontSize="inherit" />
              </IconButton>
            }>
            A mail has been sent on your email address with reset password link.
          </Alert>
        </Collapse>
        <form>
          <div className={'mb-5'}>
            <TextField
              label={<IntlMessages id="appModule.email" />}
              fullWidth
              onChange={event => setEmail(event.target.value)}
              defaultValue={email}
              margin="normal"
              variant="outlined"
              className={classes.textFieldRoot}
            />
          </div>
          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', flexDirection: 'row' }}>
            <div className={'mb-5'}>
              <Button onClick={() => forgotPassword()} variant="contained" color="primary">
                <IntlMessages id="appModule.resetPassword" />
              </Button>
            </div>
            <div>
              <Box component="p" fontSize={{ xs: 12, sm: 16 }}>
                <NavLink to="/">
                  <IntlMessages id="profile.backtosignin" />
                </NavLink>
              </Box>
            </div>
          </div>
        </form>
        <ContentLoader />
        <div>
          <Dialog fullWidth maxWidth={'xs'} open={otpModal}>
            <DialogTitle>{<IntlMessages id="registration.enterotp" />}</DialogTitle>
            <DialogContent>
              <TextField
                autoFocus
                margin="dense"
                id="name"
                label="OTP"
                autoComplete="off"
                type="text"
                onKeyPress={event => {
                  if (!/[0-9]/.test(event.key)) {
                    event.preventDefault();
                  }
                }}
                InputProps={{ inputProps: { min: 0 } }}
                onChange={e => {
                  const { value } = e.target;
                  //  console.log(value)
                  setOtp(value);
                }}
                fullWidth
                variant="outlined"
              />
            </DialogContent>
            <DialogActions style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <Button
                variant="contained"
                color="primary"
                onClick={() => {
                  setOtpModal(false);
                }}>
                <IntlMessages id="registration.cancel" />
              </Button>

              {resendButton == true ? (
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    verifyOtp();
                  }}>
                  <IntlMessages id="registration.submit" />
                </Button>
              ) : (
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    refreshButton();
                  }}>
                  <IntlMessages id="registration.resend" />
                </Button>
              )}
            </DialogActions>
            {timeLeft != 0 ? (
              <DialogContentText style={{ textAlign: 'center' }}>
                <IntlMessages id="registration.resendotp" /> {timeLeft}s
              </DialogContentText>
            ) : null}
          </Dialog>
        </div>
      </div>
    </AuthWrapper>
  );
};

export default ForgotPassword;
